import { hubConnection, signalR, SignalR } from '../../scripts/agito.signalr.cjs';

import { HttpRequest } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Deferred } from '@profis-engineering/pe-ui-common/helpers/deferred';
import { LogType } from '@profis-engineering/pe-ui-common/services/logger.common';
import {
    ConnectionType, ErrorReason, InvokeOptions, IPendingRequest, ISignalRError,
    SignalRConnectionOptions
} from '@profis-engineering/pe-ui-common/services/signalr.common';
import {
    OptimizeDesignInput, OptimizeDesignOutput
} from '@profis-engineering/pe-ui-shared/entities/signalr';
import {
    SignalRError
} from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Common.SignalR';
import {
    CalculateDesignRequest, CalculationResultEntity
} from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation';
import {
    SignalRConnectionPe, SignalRServicePeBase
} from '@profis-engineering/pe-ui-shared/services/signalr.service.base';

import * as signalRCore from '@microsoft/signalr';

import { environment } from '../../environments/environment';
import {
    CalculateDesignRequestC2C, CalculationResultEntityC2C
} from '@profis-engineering/pe-ui-c2c/generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import { ApiService } from './api.service';
import { AuthenticationService } from './authentication.service';
import { GuidService } from './guid.service';
import { LocalizationService } from './localization.service';
import { LoggerService } from './logger.service';
import { ModalService } from './modal.service';
import { UserService } from './user.service';

declare const Zone: any;

export function buildSignalRHubConnection(baseUrl: string, accessTokenFactory: () => string, onDisconnected: (coreConnection: signalRCore.HubConnection) => void) {
    const options = {
        accessTokenFactory,
        skipNegotiation: true,
        transport: signalRCore.HttpTransportType.WebSockets,

    } as signalRCore.IHttpConnectionOptions;

    const coreConnection = new signalRCore.HubConnectionBuilder()
        .withUrl(baseUrl, options)
        .configureLogging(signalRCore.LogLevel.Warning)
        .build();

    coreConnection.onclose(() => onDisconnected(coreConnection));
    return coreConnection;
}

export class SignalRConnection extends SignalRConnectionPe {
    private pendingRequests: Record<string, IPendingRequest>;
    private connectionOptions: SignalRConnectionOptions;

    private connection: SignalR.Hub.Connection;
    private hubProxy: SignalR.Hub.Proxy;

    private coreConnection: signalR.HubConnection;

    // Unknown => not known yet if websockets (SignalR Core) or http long polling (SignalR legacy).
    private connectionType: ConnectionType;
    private idleTimeoutId: number;

    private connectingPromise: Promise<void>;

    private lastRequestId: string;
    private lastRequestData: string | object;
    private lastResponseData: string | object;

    constructor(
        private modalService: ModalService,
        private loggerService: LoggerService,
        private userService: UserService,
        private guidService: GuidService,
        private localizationService: LocalizationService,
        private apiService: ApiService,
        private authenticationService: AuthenticationService,
        private ngZone: NgZone,
        useHttpLongPolling: boolean
    ) {
        super();

        NgZone.assertInAngularZone();

        this.onProgress = this.onProgress.bind(this);
        this.onDone = this.onDone.bind(this);
        this.onFail = this.onFail.bind(this);

        this.pendingRequests = {};

        this.connectionType = useHttpLongPolling ? ConnectionType.Http : ConnectionType.Unknown;

        // when auth token changes
        this.userService.authenticated.subscribe(() => {
            this.setHubConnections();
        });

        /*
        * Set initial lastRequestId, usable when:
        * - the calculation was not invoked
        * - the TransactionId is not accessible
        */
        this.lastRequestId = this.guidService.new();
    }

    public get connectionUrl() {
        if (this.connectionType != ConnectionType.Http && this.coreConnection != null) {
            return this.coreConnection.baseUrl;
        }
        else {
            return this.connection != null
                ? this.connection.url
                : '';
        }
    }

    public get requestData() {
        return this.lastRequestData;
    }

    public get responseData() {
        return this.lastResponseData;
    }

    public get requestId() {
        return this.lastRequestId;
    }

    public setHubConnections(connectionOptions?: SignalRConnectionOptions) {
        NgZone.assertInAngularZone();

        if (connectionOptions != null) {
            this.connectionOptions = connectionOptions;
        }

        if (this.connectionOptions == null) {
            // no connection set
            return;
        }

        // no need for websockets (SignalR Core) if Http
        // if Unknown we don't yet know if it's websockets (SignalR Core) or http long polling (SignalR legacy) so we run both hub connection functions
        if (this.connectionType != ConnectionType.Http) {
            this.setHubConnectionInternal(this.connectionOptions.signalRCoreServerUrl, this.connectionOptions.signalRCoreServerHub);
        }

        this.setHttpHubConnectionInternal(this.connectionOptions.legacySignalRServerUrl ?? '', this.connectionOptions.legacySignalRCoreServerHub);
    }

    public calculateDesign(calculateDesignRequest: CalculateDesignRequest, options?: InvokeOptions) {
        this.lastRequestData = calculateDesignRequest;
        return this.invoke<CalculationResultEntity>('CalculateDesign', options, calculateDesignRequest);
    }

    public generateAndDownloadReport(calculateDesignRequest: CalculateDesignRequest, options?: InvokeOptions) {
        this.lastRequestData = calculateDesignRequest;
        return this.invoke<CalculationResultEntity>('GenerateAndDownloadReport', options, calculateDesignRequest);
    }

    public async calculateDesignC2C(calculateDesignRequest: CalculateDesignRequestC2C, options?: InvokeOptions) {
        this.lastRequestData = calculateDesignRequest;

        const resp = await this.invoke<CalculationResultEntityC2C>('CalculateDesign', options, calculateDesignRequest);
        return this.modifyCalculationResultsC2C(resp);
    }

    public asadOptimize(request: OptimizeDesignInput, options: InvokeOptions) {
        this.lastRequestData = request;
        return this.invoke<OptimizeDesignOutput>('OptimizeDesign', { ...options, timeout: 0 }, request);
    }

    // this is needed in case of using legacy fallback.
    private modifyCalculationResultsC2C(result: unknown) {
        // calculation legacy hub sends json string that needs to be parsed into object in the right way.
        if (typeof (result) == 'string' || (result instanceof String)) {
            return JSON.parse(result as string) as CalculationResultEntityC2C;
        }

        return result as CalculationResultEntityC2C;
    }

    private setHttpHubConnectionInternal(url: string, hub: string) {
        NgZone.assertInAngularZone();
        const zone = Zone.current;

        const headers = this.userService.getHeaders(url, false);

        if (headers != null && headers['HC-TransactionId'] != null) {
            this.lastRequestId = headers['HC-TransactionId'];
        }

        signalR.ajaxDefaults.headers = headers;

        // if we are not connected to the correct SignalR instance (C2C for example) disconnect first but only if no requests are running
        // no need to check the token since it can be changed on existing connection (line above)
        // on connect we also check if we need to reconnect by calling setHubConnections
        if (this.connection != null && this.connection.url != url) {
            // stop will also call onDisconnected but it will be async and ignored
            // since we might already have a new connection with new pending requests that must not be canceled
            this.onDisconnected(this.connection);

            this.connection.stop();
            this.connection = null;
        }

        // connect if we don't have a connection
        if (this.connection == null) {
            const qs = environment.c2cDemoFeatures ? `${encodeURIComponent('c2cdemo')}=${encodeURIComponent('true')}` : '';
            // legacy SignalR
            this.connection = hubConnection(url, {
                useDefaultPath: false,
                qs: qs
            });
            this.hubProxy = this.connection.createHubProxy(hub);

            this.hubProxy.on('Progress', (requestId: string, progress: unknown) => {
                NgZone.assertNotInAngularZone();
                zone.run(() => this.onProgress(requestId, progress));
            });
            this.hubProxy.on('Done', (requestId: string, result: unknown) => {
                NgZone.assertNotInAngularZone();
                zone.run(() => this.onDone(requestId, result));
            });
            this.hubProxy.on('Fail', (requestId: string, response: string) => {
                NgZone.assertNotInAngularZone();
                zone.run(() => this.onFail(requestId, response));
            });

            const connection = this.connection;
            this.connection.disconnected(() => {
                NgZone.assertNotInAngularZone();
                zone.run(() => this.onDisconnected(connection));
            });
        }
    }

    private addQueryToUrl(url: string): string {
        const hasQueries = url.includes('?');
        const queryAppendStartChar = hasQueries ? '&' : '?';

        if (environment.c2cDemoFeatures) {
            url += queryAppendStartChar + encodeURIComponent('c2cdemo') + '=' + encodeURIComponent('true');
        }

        return url;
    }

    private setHubConnectionInternal(url: string, hub: string) {
        NgZone.assertInAngularZone();

        // convert headers to query string
        const headers = this.userService.getHeaders(url, false);
        let headerQuery = '';

        if (this.connectionOptions.accessToken == 'local') {
            for (const headerName in headers) {
                headerQuery += '&' + encodeURIComponent(headerName) + '=' + encodeURIComponent(headers[headerName]);
            }
        }

        if (headerQuery.startsWith('&')) {
            headerQuery = '?' + headerQuery.substring(1);
        }

        const baseUrl = this.addQueryToUrl(url + hub + headerQuery);
        const onDisconnected = this.onDisconnected.bind(this);

        if (headers != null && headers['HC-TransactionId'] != null) {
            this.lastRequestId = headers['HC-TransactionId'];
        }

        // if we are not connected to the correct SignalR instance (C2C for example or token changes) disconnect first but only if no requests are running
        // on connect we also check if we need to reconnect by calling setHubConnections
        if (this.coreConnection != null &&
            this.coreConnection.baseUrl != baseUrl &&
            Object.keys(this.pendingRequests).length == 0) {

            // stop will also call onDisconnected but it will be async and ignored
            // since we might already have a new connection with new pending requests that must not be canceled
            this.coreConnection.stop();

            this.onDisconnected(this.coreConnection);
            this.coreConnection = null;
        }

        // create connection if we don't have it
        if (this.coreConnection == null) {
            // SignalR Core
            if (this.connectionOptions.accessToken == 'header' || (this.connectionOptions.accessToken == 'local')) {
                this.coreConnection = buildSignalRHubConnection(baseUrl, () => this.userService.authentication.accessToken, onDisconnected);
            }
            else if ((this.connectionOptions.accessToken == 'cookie')) {
                this.coreConnection = buildSignalRHubConnection(baseUrl, undefined, onDisconnected);
            }
            else {
                this.loggerService.log(
                    'SignalRConnection::setHubConnectionInternal connectionOptions.accessToken is Unknown',
                    LogType.error);
            }
        }
    }

    private async cancelCalculation(requestId: string): Promise<void> {
        NgZone.assertInAngularZone();

        const request = this.pendingRequests[requestId];
        const methodName = 'CancelCalculation';

        if (request != null) {
            delete this.pendingRequests[requestId];
            request.deferred.reject();

            try {
                await this.connect();
                await this.invokeInternal<void>(methodName, this.guidService.new(), requestId);
            }
            catch (error) {
                // catchException will throw the error or block for redirect
                await this.catchException(error, requestId);
            }
        }
    }

    private invoke<T>(methodName: string, options: InvokeOptions, ...args: any[]): Promise<T> {
        NgZone.assertInAngularZone();

        options = options ?? {};

        const deferred = new Deferred<T>();
        let isCanceled = false;

        // request for invoke (with optional connect)
        const requestId = this.guidService.new();
        this.lastRequestId = requestId;

        this.connect()
            .then(() => this.invokeInternal<T>(methodName, requestId, ...args))
            .then(result => {
                this.lastResponseData = result as unknown as object;
                if (!isCanceled) {
                    // LEGACY - if we get back null the request has transitioned into async mode and is not done yet so we ignore the result
                    if (this.connectionType == ConnectionType.Websocket || result != null) {
                        this.onDone(requestId, result);
                    }
                }
            })
            .catch((reason: ISignalRError) => {
                this.lastResponseData = reason;
                if (!isCanceled) {
                    if (this.connectionType == ConnectionType.Websocket) {
                        const request = this.pendingRequests[requestId];

                        if (request != null) {
                            delete this.pendingRequests[requestId];
                            clearTimeout(request.timeoutId);
                        }
                    }

                    this.catchException(reason, requestId)
                        .catch(error => deferred.reject(error));
                }
            });

        if (options.cancel != null) {
            options.cancel.finally(() => {
                isCanceled = true;

                if (pendingRequest != null) {
                    pendingRequest.isCanceled = true;
                }

                return this.cancelCalculation(requestId);
            });
        }

        // save pending request
        const pendingRequest: IPendingRequest = this.pendingRequests[requestId] = {
            deferred,
            onProgress: options.onProgress,
            timeoutId: this.createTimeout(methodName, requestId, deferred, options.timeout),
            cancel: options.cancel,
            isCanceled
        };

        return deferred.promise;
    }

    private catchException(reason: ISignalRError | string, requestId?: string): Promise<void> {
        NgZone.assertInAngularZone();

        // eslint-disable-next-line no-async-promise-executor
        return new Promise<void>(async (resolve, reject) => {
            const endPointUrl = this.connectionUrl;

            // can't send back data on error in signalr core
            if (this.connectionType == ConnectionType.Websocket && typeof reason == 'string') {
                // Handle websocket status code 1006 (connection was closed abnormally)
                const webSocketExceptionIndex = reason.indexOf('WebSocket');
                if (webSocketExceptionIndex >= 0) {
                    // connection closed status
                    const webSocketClosedIndex = reason.indexOf('1006');
                    if (webSocketClosedIndex >= 0) {
                        this.modalService.openAlertSignalRError(
                            {
                                response: this.localizationService.getString('Agito.Hilti.Profis3.ServerErrorAlert.ConnectionClosed'),
                                correlationId: requestId,
                                responsePayload: reason,
                                endPointUrl
                            }
                        );
                        reject(reason);

                        return;
                    }
                }

                let hubExceptionIndex = reason.indexOf('HubException: {');
                if (hubExceptionIndex >= 0) {
                    hubExceptionIndex += 'HubException: '.length;

                    const hubExceptionJson = reason.substring(hubExceptionIndex);
                    try {
                        const signalRError: SignalRError = JSON.parse(hubExceptionJson);

                        reason = {
                            message: signalRError.ExceptionMessage,
                            data: signalRError
                        };
                    }
                    catch (error) {
                        console.error(error);
                    }
                }
            }

            if (typeof reason == 'string') {
                this.modalService.openAlertSignalRError({
                    response: reason,
                    correlationId: requestId,
                    endPointUrl
                });
                reject(reason);

                return;
            }

            if (reason != null && reason.data != null && reason.data.ReasonPhrase == ErrorReason.CalculationCanceled) {
                reject(reason);
                return;
            }

            // First part handles error from "hubProxy.invoke", second part from "connection.start"
            if (SignalRService.getStatusCode(reason) == 401) {
                // do not resolve or reject the promise
                // openUnauthorizedAccess and logout will both redirect to the login page
                if (reason != null && reason.data != null && reason.data.ReasonPhrase == ErrorReason.LoginOtherDevice) {
                    this.modalService.openUnauthorizedAccess();
                }
                else {
                    const token = await this.authenticationService.tryExtendToken();
                    if (!token) {
                        // token cannot be extended, user is logged out
                        await this.authenticationService.logout();
                    }
                    else {
                        // token is extended, user is authorized and can continue
                        return;
                    }
                }
            }
            else {
                this.modalService.openAlertSignalRError({
                    response: reason,
                    correlationId: requestId,
                    endPointUrl
                });
                reject(reason);
            }
        });
    }

    private invokeInternal<T>(methodName: string, requestId: string, ...args: any[]): Promise<T> {
        NgZone.assertInAngularZone();
        const zone = Zone.current;

        return new Promise<T>((resolve, reject) => {
            if (this.connectionType == ConnectionType.Websocket) {
                this.ngZone.runOutsideAngular(() => this.coreConnection.stream(methodName, ...[requestId, ...args])
                    .subscribe({
                        next: value => {
                            NgZone.assertNotInAngularZone();
                            zone.run(() => {
                                // we have progress or result
                                if (value.progress != null || value.Progress != null) {
                                    this.onProgress(requestId, value.progress || value.Progress);
                                }
                                else {
                                    this.startIdleTimeout();
                                    resolve(value.result || value.Result);
                                }
                            });
                        },
                        complete: () => {
                            // already handled in the next part
                        },
                        error: error => {
                            NgZone.assertNotInAngularZone();
                            zone.run(() => {
                                // if we get an Error object just return the message
                                if (error != null && error instanceof Error) {
                                    error = error.message;
                                }

                                this.startIdleTimeout();
                                reject(error);
                            });
                        }
                    }));
            }
            else if (this.connectionType == ConnectionType.Http) {
                this.ngZone.runOutsideAngular(() => this.hubProxy.invoke(methodName, ...[requestId, ...args])
                    .done((arg: any) => {
                        NgZone.assertNotInAngularZone();
                        zone.run(() => resolve(arg));
                    })
                    .fail((...args: any[]) => {
                        NgZone.assertNotInAngularZone();
                        zone.run(() => reject(...args));
                    }));
            }
            else {
                throw new Error('connection type not set');
            }
        });
    }

    private startIdleTimeout() {
        NgZone.assertInAngularZone();

        if (this.idleTimeoutId != null) {
            clearTimeout(this.idleTimeoutId);
        }

        this.idleTimeoutId = setTimeout(() => {
            this.idleTimeoutId = null;

            // there might be new pending requests when timeout is triggered
            // ignore it since the pending request will set the timeout
            if (Object.keys(this.pendingRequests).length == 0) {
                // disconnect
                if (this.coreConnection != null) {
                    this.coreConnection.stop();
                    this.onDisconnected(this.coreConnection);
                    this.coreConnection = null;
                }

                // set up connection for the next connect that might come later
                this.setHubConnections();
            }
        }, SignalRConnection.idleTimeout);
    }

    private createTimeout(methodName: string, requestId: string, deferred: Deferred<unknown>, timeout: number = undefined): number {
        NgZone.assertInAngularZone();

        if (timeout == 0) {
            return setTimeout(() => { return; }, 0);
        }

        return setTimeout(() => {
            const request = this.pendingRequests[requestId];

            if (request != null) {
                delete this.pendingRequests[requestId];

                const message = `Operation ${methodName} has timed out.`;

                // Only show timeout error if request wasn't canceled.
                if (request.cancel == null || !request.isCanceled) {
                    this.modalService.openAlertSignalRError({
                        response: message,
                        correlationId: requestId,
                        endPointUrl: this.coreConnection.baseUrl
                    });
                }

                deferred.reject(message);
            }
        }, timeout ?? this.connectionOptions.signalRTimeoutInMilliseconds);
    }

    private connect(): Promise<void> {
        NgZone.assertInAngularZone();

        this.setHubConnections();

        if (this.connection.state == SignalR.ConnectionState.Connected) {
            return Promise.resolve();
        }

        if (this.connectingPromise != null) {
            return this.connectingPromise;
        }

        return this.connectingPromise = (async () => {
            try {
                // ASP.NET Core SignalR
                if (this.connectionType != ConnectionType.Http) {
                    await this.signalRConnect();
                    this.connectionType = ConnectionType.Websocket;
                }
                else {
                    // go to catch and try legacy
                    throw new Error('websockets not supported');
                }
            }
            catch (error) {
                // ASP.NET SignalR (Legacy)
                await this.signalRConnectLegacy();

                this.connectionType = ConnectionType.Http;
            }
            finally {
                this.connectingPromise = null;
            }
        })();
    }

    private async signalRConnect(): Promise<void> {
        NgZone.assertInAngularZone();

        // return if we are already connected
        if (this.coreConnection.state == signalRCore.HubConnectionState.Connected) {
            return;
        }

        if (this.connectionOptions.accessToken == 'header' || this.connectionOptions.accessToken == 'local') {
            await this.connectionStart();
        }
        else if (this.connectionOptions.accessToken == 'cookie') {
            await this.apiService.request(new HttpRequest('GET', environment.signalRCoreInitSessionUrl, { withCredentials: true }));
            await this.connectionStart();
        }
        else {
            throw new Error('SignalRConnection::connect this.connectionOptions.accessToken is Unknown');
        }
    }

    private async connectionStart(): Promise<void> {
        NgZone.assertInAngularZone();

        try {
            await this.ngZone.runOutsideAngular(async () => await this.coreConnection.start());
            this.loggerService.log('ASP.NET Core SignalR connected. Protocol: WebSockets', LogType.info);
        }
        catch (error) {
            this.loggerService.log('ASP.NET Core SignalR connection failed.', LogType.error, error);

            throw error;
        }
    }

    private signalRConnectLegacy(): Promise<void> {
        NgZone.assertInAngularZone();
        const zone = Zone.current;

        return new Promise<void>((resolve, reject) => {
            this.ngZone.runOutsideAngular(() => this.connection
                .start({
                    transport: 'longPolling',
                    withCredentials: false
                })
                .done(value => {
                    NgZone.assertNotInAngularZone();
                    zone.run(() => {
                        this.loggerService.log('ASP.NET SignalR Legacy connected. Protocol: ' + value.transport.name, LogType.warn);

                        resolve();
                    });
                })
                .fail(reason => {
                    NgZone.assertNotInAngularZone();
                    zone.run(() => {
                        this.loggerService.log('ASP.NET SignalR Legacy connection failed.', LogType.error, reason);

                        const response: ISignalRError = {
                            message: reason != null && reason.context != null && reason.context.statusText != null && reason.context.statusText != '' ? reason.context.statusText : 'Unknown error',
                            data: {
                                StatusCode: reason != null && reason.context != null && reason.context.status != null && typeof reason.context.status == 'number' ? reason.context.status : 500
                            }
                        };
                        reject(response);
                    });
                }));
        });
    }

    private onProgress(requestId: string, progress: unknown) {
        NgZone.assertInAngularZone();

        const request = this.pendingRequests[requestId];
        request?.onProgress?.(progress);
    }

    private onDone(requestId: string, result: unknown) {
        NgZone.assertInAngularZone();

        const request = this.pendingRequests[requestId];

        if (request != null) {
            delete this.pendingRequests[requestId];
            clearTimeout(request.timeoutId);

            this.lastRequestId = requestId;

            request.deferred.resolve(result);
        }
    }

    private onFail(requestId: string, response: string) {
        NgZone.assertInAngularZone();

        const request = this.pendingRequests[requestId];

        if (request != null) {
            delete this.pendingRequests[requestId];
            clearTimeout(request.timeoutId);

            const data = JSON.parse(response);

            // In case request was canceled, we don't show error.
            if (request.cancel != null && request.isCanceled) {
                request.deferred.reject(data);
            }
            else {
                this.catchException({ message: data.ExceptionMessage, data }, requestId)
                    .catch(error => request.deferred.reject(error));
            }
        }
    }

    private onDisconnected(connection: SignalR.Hub.Connection | signalR.HubConnection) {
        NgZone.assertInAngularZone();

        const message = 'Client was disconnected.';

        // reject all requests for a connection
        // we might be closing an old connection while a new one is already opened
        // so we check the connection object and ignore if it's an old connection
        if (this.connection === connection || this.coreConnection === connection) {
            for (const requestId in this.pendingRequests) {
                const request = this.pendingRequests[requestId];

                delete this.pendingRequests[requestId];
                clearTimeout(request.timeoutId);

                request.deferred.reject(message);
            }

            // for Core SignalR also clear the connection object since it's in a broken state
            // first request will always fail with "WebSocket is not in the OPEN state" (bug in ASP.NET Core SignalR?)
            if (this.coreConnection === connection) {
                // we are already disconnected so we can just set it to null
                // on next request the connection will be reopened
                this.coreConnection = null;
            }
        }

        this.connectionType = ConnectionType.Unknown;
    }
}

@Injectable({
    providedIn: 'root'
})
export class SignalRService extends SignalRServicePeBase {
    // TOOD BUDQBP-24026: will be joined in one SignalRConnection
    public common: SignalRConnection;
    public asad: SignalRConnection;

    constructor(
        private userService: UserService,
        modal: ModalService,
        logger: LoggerService,
        guid: GuidService,
        localization: LocalizationService,
        apiService: ApiService,
        authenticationService: AuthenticationService,
        ngZone: NgZone,
    ) {
        super();
        this.common = new SignalRConnection(
            modal,
            logger,
            userService,
            guid,
            localization,
            apiService,
            authenticationService,
            ngZone,
            // use http long polling (SignalR legacy) if no signalRCoreServerUrl is provided
            environment.signalRCoreServerUrl == null
        );

        this.asad = new SignalRConnection(
            modal,
            logger,
            userService,
            guid,
            localization,
            apiService,
            authenticationService,
            ngZone,
            // use http long polling (SignalR legacy) if no signalRCoreServerUrl is provided
            environment.asadSignalRCoreServerUrl == null
        );
    }

    public setHubConnections() {
        const connectionOptions: SignalRConnectionOptions = {
            signalRCoreServerUrl: environment.signalRCoreServerUrl,
            signalRCoreServerHub: 'calculation-hub',
            legacySignalRServerUrl: environment.signalRServerUrl,
            legacySignalRCoreServerHub: 'CalculationHub',
            accessToken: environment.accessToken,
            signalRCoreInitSessionUrl: environment.signalRCoreInitSessionUrl,
            signalRTimeoutInMilliseconds: environment.signalRTimeoutInMilliseconds,
            useHttpLongPolling: environment.signalRCoreServerUrl == null
        };

        this.common.setHubConnections(connectionOptions);

        const asadConnectionOptions: SignalRConnectionOptions = {
            signalRCoreServerUrl: environment.asadSignalRCoreServerUrl,
            signalRCoreServerHub: 'asad-hub',
            legacySignalRServerUrl: environment.asadSignalRServerUrl,
            legacySignalRCoreServerHub: 'AsadHub',
            accessToken: environment.accessToken,
            signalRCoreInitSessionUrl: environment.signalRCoreInitSessionUrl,
            signalRTimeoutInMilliseconds: environment.signalRTimeoutInMilliseconds,
            useHttpLongPolling: environment.asadSignalRCoreServerUrl == null
        };

        this.asad.setHubConnections(asadConnectionOptions);
    }

    public setHubConnectionsC2C() {
        const connectionOptions: SignalRConnectionOptions = {
            signalRCoreServerUrl: environment.c2cCalculationServiceSignalRCoreUrl,
            signalRCoreServerHub: 'calculationHub',
            legacySignalRServerUrl: environment.c2cCalculationServiceSignalRServer,
            legacySignalRCoreServerHub: 'CalculationHub',
            accessToken: environment.accessToken,
            signalRCoreInitSessionUrl: environment.signalRCoreInitSessionUrl,
            signalRTimeoutInMilliseconds: environment.signalRTimeoutInMilliseconds,
            useHttpLongPolling: environment.c2cCalculationServiceSignalRCoreUrl == null
        };

        this.common.setHubConnections(connectionOptions);
    }
}
