import { Component, Input, DoCheck, TrackByFunction } from '@angular/core';
import clone from 'lodash-es/clone';
import isEqual from 'lodash-es/isEqual';
import {
    ITrimbleConnectBrowserTreeContext, ITrimbleConnectItem, TrimbleConnectItemType
} from '../trimble-connect-browser/trimble-connect-browser-models';

@Component({
    selector: 'app-trimble-connect-browser-tree-node',
    templateUrl: './trimble-connect-browser-tree-node.component.html',
    styleUrls: ['./trimble-connect-browser-tree-node.component.scss']
})
export class TrimbleConnectBrowserTreeNodeComponent implements DoCheck {
    @Input()
    public item: ITrimbleConnectItem;

    @Input()
    public expanded = false;

    @Input()
    public level = 0;

    @Input()
    public context: ITrimbleConnectBrowserTreeContext;

    public loading: boolean;
    public folderItems: ITrimbleConnectItem[];

    private oldItem: ITrimbleConnectItem;

    public get hasNoFolders() {
        return this.item != null
            && this.item.items != null
            && (this.item.items.length == 0 || !this.item.items.some(item => item.type == TrimbleConnectItemType.folder));
    }

    public get isSelected() {
        if (this.context == null || this.context.selectedItem == null) {
            return false;
        }

        if (this.item == null) {
            return false;
        }

        return this.context.selectedItem.id == this.item.id;
    }

    ngDoCheck(): void {
        // Items
        let itemsChanged = false;
        const oldItems = this.oldItem?.items;
        const newItems = this.item?.items;

        if (oldItems == null) {
            if (newItems != null) {
                itemsChanged = true;
            }
        }
        else {
            if (newItems != null) {
                itemsChanged = !isEqual(oldItems, newItems);
            }
            else {
                // Collapse current item since children are not loaded
                this.expanded = false;
                itemsChanged = true;
            }
        }

        if (itemsChanged) {
            this.oldItem = clone(this.item);
            this.itemsChanged();
        }
    }

    public itemClick(_: MouseEvent) {
        const select = this.context.selectedItem.id != this.item.id;

        this.loading = true;
        this.context.openFolder(this.item, select)
            .finally(() => {
                this.loading = false;
            });
    }

    public expand(event: MouseEvent) {
        event.stopPropagation();
        this.expanded = !this.expanded;

        if (this.expanded) {
            this.loading = true;
            this.context.openFolder(this.item)
                .then(() => {
                    this.itemsChanged();
                })
                .catch((err) => {
                    if (err instanceof Error) {
                        console.error(err);
                    }

                    this.expanded = false;
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }

    public trackTreeNodeById: TrackByFunction<ITrimbleConnectItem> = function(_: number, item: ITrimbleConnectItem) {
        return item.id;
    };

    private itemsChanged() {
        if(this.item != null) {
            this.folderItems = (this.item.items ?? []).filter(item => item.type == TrimbleConnectItemType.folder);
        }
        else {
            this.folderItems = [];
        }
    }
}
