import {
    ProjectDesignEntityC2C
} from '@profis-engineering/pe-ui-c2c/generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import {
    IDetailedDisplayDesign as IDetailedDisplayDesignC2C
} from '@profis-engineering/pe-ui-c2c/entities/display-design';
import { Design as DesignCommon } from '@profis-engineering/pe-ui-common/entities/design';
import { ProjectType, ProjectUser } from '@profis-engineering/pe-ui-common/entities/project';
import {
    ArchiveDocumentResponseModel,
    DocumentGetResponse
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.Entities.Documents';
import {
    ArchiveProjectResponseModel, ProjectExpandedState
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.Entities.Projects';
import {
    DocumentAccessMode, DocumentServiceBase, IDesignListItem as IDesignListItemCommon
} from '@profis-engineering/pe-ui-common/services/document.common';
import {
    UIProperty
} from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import {
    ProjectDesignBaseEntity
} from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign';

import { ModalDialogType } from '../components/add-edit-design/add-edit-design-models';
import { Design, IBaseDesign } from '../entities/design';
import { DesignExternalMetaData } from '../entities/design-external-meta-data';
import { IDetailedDisplayDesign } from '../entities/display-design';
import { IProjectArchive, Project } from '../entities/project';
import { LoggerService } from './logger.service';
import { DocumentUser, IDocumentArchive, SharedDocumentUser } from '../entities/document';
import { DropdownItem } from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import cloneDeep from 'lodash-es/cloneDeep';
import sortBy from 'lodash-es/sortBy';
import { BaseDesign } from '@profis-engineering/pe-ui-decking/src/decking/entities/decking-design/base-design';

/**
 *  The interface for basic list items.
 */
export interface IDesignListItem extends IBaseDesign {
    metaData: DesignExternalMetaData;
    isSharedByMe?: boolean,
}

export abstract class DocumentService extends DocumentServiceBase {
    protected _pendingRequests = 0;

    // a singleton list of root projects with sub projects stored as sub nodes
    protected _projects: Record<string, Project> = {};
    protected _projectsArchive: IProjectArchive[] = [];
    protected _draftsProject: Project = null;
    protected _projectsLoaded = false;
    protected _documentsArchive: IDocumentArchive[] = [];
    protected _myProjects: Record<string, Project> = {};

    // a singleton flat dictionary of all projects
    protected _projectsFlat: Record<string, Project> = {};
    protected _desingsFlat: Record<string, IDesignListItem | IDesignListItemCommon> = {};

    constructor(
        protected serviceName: string,
        protected loggerService: LoggerService
    ) {
        super();
    }

    public get pendingRequests(): number {
        return this._pendingRequests;
    }

    /**
     * A list of root level projects. Sub projects are accessible in subprojects node of the root level projects.
     */
    public get projects(): Record<string, Project> {
        return this._projects;
    }

    /**
     * A list of root level my projects. Sub projects are accessible in subprojects node of the root level projects.
     */
    public get myProjects(): Record<string, Project> {
        return this._myProjects;
    }

    /**
     * a list of all archived projects
     */
    public get projectsArchive(): IProjectArchive[] {
        return this._projectsArchive;
    }

    /**
     * a list of all archived documents
     */
    public get documentsArchive(): IDocumentArchive[] {
        return this._documentsArchive;
    }

    /**
     * The drafts folder if it exits,
     */
    public get draftsProject(): Project {
        return this._draftsProject;
    }

    /**
     * True if projects are allready loaded. This means that the public properties projects, projectsFlat, designsFlat, and draftProject are loaded.
     */
    public get projectsLoaded(): boolean {
        return this._projectsLoaded;
    }

    /**
     * A dictionary of all projects no matter where in the hierarchy tree.
     */
    public get projectsFlat(): Record<string, Project> {
        return this._projectsFlat;
    }

    /**
     * A list of all designs flat ( no matter in what project a design is in).
     */
    public get designsFlat(): Record<string, IDesignListItem | IDesignListItemCommon> {
        return this._desingsFlat;
    }

    /**
     * Find the project of the desing with the specified id.
     * @param designId - Design id
     */
    public findProjectByDesignId(designId: string): Project {
        return this.findProjectByDesignIdInternal(this.projectsFlat, designId);
    }

    /**
     * Find design by id.addDesign
     * @param designId - Design id
     */
    public findDesignById(designId: string): IDesignListItem {
        return this.findDesignByIdInternal(this.projectsFlat, designId);
    }

    public findDesignByIdCommon(designId: string): IDesignListItemCommon {
        return this.findDesignByIdInternal(this.projectsFlat, designId);
    }

    /**
     * Find project by id.
     * @param projectId - Project id
     */
    public findProjectById(projectId: string): Project {
        return this._projectsFlat[projectId];
    }

    /**
     * Check is there are already project with the same name
     * @param projectName - Project name
     * @param parentId - Parent project id
     * @param projectId - Project id
     */
    public projectNameExists(projectName: string, parentId: string, projectId?: string): boolean {
        return Object.values(this._projectsFlat).some((p) => p.name.toLowerCase().trim() == projectName.toLowerCase().trim() &&
            (p.parentId == parentId || p.id == parentId) &&
            (projectId == null || p.id != projectId));
    }

    /**
     * Check is there are already design with the same name for a given project
     */
    public designNameExistsOnEdit(project: Project, design: IDesignListItem): boolean {
        if (project == null || design == null) {
            return false;
        }

        const parentProject = project.parentId ? this.findProjectById(project.parentId) : project;

        if (Object.values(this.designsFlat).some((d) => d.projectId == parentProject.id && d.id != design.id && d.designName.toLowerCase().trim() == design.designName.toLowerCase().trim())) {
            return true;
        }
        else {
            for (const subProjectId in parentProject.subProjects) {
                if (Object.values(this.designsFlat).some((d) => d.projectId == subProjectId && d.id != design.id && d.designName.toLowerCase().trim() == design.designName.toLowerCase().trim())) {
                    return true;
                }
            }
        }
        return false;
    }

    /**
     * Check is there are already design with the same name for a given project
     */
    public designNameExistsOnNew(project: Project, designName: string): boolean {
        if (project == null || designName == null) {
            return false;
        }

        const parentProject = project.parentId ? this.findProjectById(project.parentId) : project;

        if (Object.values(this.designsFlat).some((d) => d.projectId == parentProject.id && d.designName.toLowerCase().trim() == designName.toLowerCase().trim())) {
            return true;
        }
        else {
            for (const subProjectId in parentProject.subProjects) {
                if (Object.values(this.designsFlat).some((d) => d.projectId == subProjectId && d.designName.toLowerCase().trim() == designName.toLowerCase().trim())) {
                    return true;
                }
            }
        }
        return false;
    }

    /**
     * Find project in any tree data structure by Id
     */
    public findProject(id: string, projects: { [id: string]: Project }): Project {
        if (!id) {
            return null;
        }

        if (projects[id]) {
            return projects[id];
        }
        for (const item in projects) {
            const project = projects[item];
            const result = this.findProject(id, project.subProjects);
            if (result) {
                return result;
            }
        }

        return null;
    }

    public sortAllProjectList(): void {
        this._projects = this.getSortedProjects(this._projects);
        this._projectsFlat = this.getSortedProjects(this._projectsFlat);
    }

    /**
     * Get sorted project tree data
     */
    public getSortedProjects(nodes: { [id: string]: Project }): Record<string, Project> {
        const sortedNodes = this.sortProjectsByName(nodes);

        for (const node in sortedNodes) {
            const project = sortedNodes[node];
            if (project.subProjects && Object.entries(project.subProjects).length > 0) {
                project.subProjects = this.getSortedProjects(project.subProjects);
            }
        }

        return sortedNodes;
    }
    /**
     * Creates a unique file name from a given name
     */
    public abstract createUniqueName(oldName: string, usedNames: string[]): string;

    public abstract findDesignByName(designName: string, projectId: string, forceFromServer?: boolean): Promise<IDesignListItemCommon>;

    public abstract initialize(data: DocumentGetResponse, dataArchive: ArchiveProjectResponseModel[], dataDocumentArchive?: ArchiveDocumentResponseModel[]): void;

    public abstract saveProject(project: Project, projectLevelType: ModalDialogType): Promise<void>;

    public abstract toggleExpandProject(project: Project): Promise<void>;

    public abstract removeArchivedProject(projectId: string): Promise<void>;

    public abstract removeProject(projectId: string): Promise<void>;

    public abstract convertProject(projectId: string): Promise<void>;

    public abstract archiveProject(projectId: string): Promise<void>;

    public abstract restoreProject(projectId: string, projectLevelType: ModalDialogType): Promise<string[]>;

    public abstract openDesignExclusivePe(design: IBaseDesign): Promise<ProjectDesignBaseEntity>;

    public abstract openDesignExclusiveC2C(design: IBaseDesign): Promise<ProjectDesignEntityC2C>;

    public abstract openDesignExclusiveDecking(design: IBaseDesign): Promise<ProjectDesignBaseEntity>;

    public abstract openDesign(design: IBaseDesign): Promise<ProjectDesignBaseEntity>;

    public abstract openDesignC2C(design: IBaseDesign): Promise<ProjectDesignEntityC2C>;

    public abstract addDesign(projectId: string, design: Design, canGenerateUniqueName: boolean, ignoreConflict: boolean, useDeckingDesign: boolean, deckingProject: BaseDesign): Promise<IDesignListItem>;

    public abstract updateDesign(design: Design): Promise<void>;

    public abstract updateDesignProject(designId: string, projectId: string): Promise<void>;

    public abstract updateDesignWithNewContent(designId: string, projectId: string, designName: string, contentOverride: ProjectDesignBaseEntity, metadataOverride: DesignExternalMetaData, displayDesign: IDetailedDisplayDesign, unlock?: boolean, exclusiveLock?: boolean, documentAccessMode?: DocumentAccessMode): Promise<void>;

    public abstract updateDesignWithNewContentC2C(designId: string, projectId: string, designName: string, contentOverride: ProjectDesignEntityC2C, metadataOverride: DesignExternalMetaData, displayDesign: IDetailedDisplayDesignC2C, unlock?: boolean, exclusiveLock?: boolean, documentAccessMode?: DocumentAccessMode): Promise<void>;

    public abstract archiveDesign(designId: string): Promise<void>;

    public abstract removeDesigns(designIds: string[]): Promise<void>;

    public abstract getUsersOnProjectById(projectId: string): Promise<ProjectUser[]>;

    public abstract addUsersOnProjectById(data: ProjectUser): Promise<void>;

    public abstract removeUsersOnProjectById(data: ProjectUser): Promise<void>;

    public abstract userHasAccessToProject(projectId: string): Promise<boolean>;

    public abstract deleteProjectLocal(projectId: string): void;

    public abstract getDocumentThumbnails(documentIds: string[]): Promise<Record<string, string>>;

    public abstract getDeckingDesignContent(designId: string, sessionId: string, documentId: string, isLock: boolean): Promise<any>;

    public abstract openNewSessionForDesign(designId: string): string;

    public abstract getNumberOfDocumentsOwnedByUser(): number;

    public abstract updateDocumentDesignContent(document: IDesignListItem, base64Content: string, unlock: boolean, exclusiveLock: boolean, documentAccessMode: DocumentAccessMode): Promise<void>;

    public abstract findAllDesignsByProject(project: Project): { [id: string]: IDesignListItemCommon } ;

    public abstract restoreExistingDesign(documentId: string): Promise<void>;

    public abstract removeArchivedDesign(documentIds: string[]): Promise<void>;
    /**
     * Copy the parameters of the designPresentation entity (used for displaying design info on lists or for communication with document service to the full design entity.
     * @param wholeDesign - whole design
     * @param designPresentation - desing presentation
     */
    protected setDesingPropertiesFromServiceRecordToWholeDesingEntitiy(wholeDesign: Design, designPresentation: IDesignListItem): void {
        wholeDesign.id = designPresentation.id;
        wholeDesign.createDate = designPresentation.createDate;
        wholeDesign.designName = designPresentation.designName;
        wholeDesign.projectId = designPresentation.projectId;
        wholeDesign.projectName = designPresentation.projectName;
        wholeDesign.changeDate = designPresentation.changeDate;
        wholeDesign.locked = designPresentation.locked;
        wholeDesign.lockedUser = designPresentation.lockedUser;
        wholeDesign.owner = designPresentation.owner;

        // change project design name if it was changed by document service
        if (wholeDesign.designData.projectDesign.DesignName != designPresentation.designName) {
            wholeDesign.designData.projectDesign = {
                ...wholeDesign.designData.projectDesign,
                DesignName: designPresentation.designName
            };

            if (!wholeDesign.currentState != null) {
                wholeDesign.currentState.projectDesign = wholeDesign.designData.projectDesign;
                wholeDesign.currentState.model[UIProperty.Option_DesignName] = designPresentation.designName;
            }

            wholeDesign.model[UIProperty.Option_DesignName] = designPresentation.designName;
        }
    }

    protected setDesignPropertiesCommon(design: DesignCommon, documentDesign: IDesignListItemCommon): void {
        design.id = documentDesign.id;
        design.designName = documentDesign.designName;
        design.createDate = documentDesign.createDate;
        design.projectId = documentDesign.projectId;
        design.changeDate = documentDesign.changeDate;
        design.locked = documentDesign.locked;
        design.lockedUser = documentDesign.lockedUser;
        design.owner = documentDesign.owner;
    }

    protected setDesingPropertiesFromServiceRecordToWholeDesingEntitiyC2C(wholeDesign: Design, desingPresentation: IDesignListItem): void {
        wholeDesign.id = desingPresentation.id;
        wholeDesign.createDate = desingPresentation.createDate;
        wholeDesign.designName = desingPresentation.designName;
        wholeDesign.projectId = desingPresentation.projectId;
        wholeDesign.projectName = desingPresentation.projectName;
        wholeDesign.changeDate = desingPresentation.changeDate;
        wholeDesign.locked = desingPresentation.locked;
        wholeDesign.lockedUser = desingPresentation.lockedUser;
        wholeDesign.owner = desingPresentation.owner;

        // change project design name if it was changed by document service
        if (wholeDesign.designData.projectDesignC2C.designName != desingPresentation.designName) {
            wholeDesign.designData.projectDesignC2C = {
                ...wholeDesign.designData.projectDesignC2C,
                designName: desingPresentation.designName
            };

            wholeDesign.model[UIProperty.Option_DesignName] = desingPresentation.designName;
        }
    }

    protected logServiceRequest(fnName: string, ...args: any[]): void {
        this.loggerService.logServiceRequest(this.serviceName, fnName, ...args);
    }

    protected logServiceResponse(fnName: string, ...args: any[]): void {
        this.loggerService.logServiceResponse(this.serviceName, fnName, ...args);
    }

    private findProjectByDesignIdInternal(projects: { [id: string]: Project; }, designId: string): Project {
        if (projects == null) {
            return null;
        }

        return Object.values(projects).find((p) => {
            return Object.values(p.directChildDesigns).some((d) => d.id == designId);
        });
    }

    private findDesignByIdInternal(projects: { [id: string]: Project; }, designId: string): IDesignListItem {
        if (projects == null) {
            return null;
        }

        for (const pKey in projects) {
            const foundDesign = Object.values(projects[pKey].directChildDesigns).find((design) => design.id == designId);

            if (foundDesign != null) {
                return foundDesign as IDesignListItem;
            }
        }

        return null;
    }

    protected sortProjectsByName(projects: Record<string, Project>): Record<string, Project> {
        const sortedProjects: Record<string, Project> = {};

        // Get the keys of the projects object and sort them alphabetically
        const sortedKeys = Object.keys(projects).sort((a, b) => {
            const projectA = projects[a].name.toLowerCase();
            const projectB = projects[b].name.toLowerCase();
            if (projectA < projectB) {
                return -1;
            } else if (projectA > projectB) {
                return 1;
            } else {
                return 0;
            }
        });

        // Create a new object with sorted projects
        sortedKeys.forEach((key) => {
            sortedProjects[key] = projects[key];
        });

        return sortedProjects;
    }

    /* This section will be made abstract once implementation of new home page is complete */

    // Remove from favourites
    public removeFromFavorite(documentIds: string[], documentType?: number): Promise<void> {
        return Promise.resolve();
    }

    // Add to favourites
    public addToFavorite(documentIds: string[], documentType?: number): Promise<void> {
        return Promise.resolve();
    }

    public getUsersOnDocumentById(documentId: string): Promise<DocumentUser[]> {
        const user = {} as DocumentUser[];
        return Promise.resolve(user);
    }

    public addUsersOnDocument(data: SharedDocumentUser): Promise<void> {
        return Promise.resolve();
    }

    public removeUsersOnDocument(data: SharedDocumentUser, markDesign = false): Promise<void> {
        return Promise.resolve();
    }

    public deleteDocumentLocal(projectId: string): Promise<void> {
        return Promise.resolve();
    }

    public archiveDocument(documentIds: string[]): Promise<void> {
        return Promise.resolve();
    }

    public getProjectDropdownItemsNewHomePage(isCompanyProject = false) {
        return this.flattenProjectsForControl(this.getSortedProjects(this.projects), isCompanyProject);
    }

    public async toggleExpandProjectV2(projectId: string, expandedState: ProjectExpandedState): Promise<void> {
        return Promise.resolve();
    }

    public getMissingThumbnailList(documentIds: string[]): string[]{
        return [];
    }

    private flattenProjectsForControl(projects: Record<string, Project>, isCompanyProject?: boolean): DropdownItem<Project>[] {
        const flattenedProjects: DropdownItem<Project>[] = [];

        function flattenHelper(node: Project, level: number) {
            const { subProjects } = node;
            if (node.projectType == ProjectType.common) {
                flattenedProjects.push({ text: node.name, level: level, value: cloneDeep(node) })
                for (const childId in subProjects) {
                    flattenHelper(subProjects[childId], level + 1);
                }
            }

        }

        for (const key in projects) {
            if (projects[key].isCompanyProject == isCompanyProject) {
                flattenHelper(projects[key], 0);
            }
        }

        for (const item in flattenedProjects) {
            flattenedProjects[item].value.subProjects = {};
            flattenedProjects[item].value.designs = {};
        }

        return sortBy(flattenedProjects);
    }

    public getDesignIdsByProjectId(projectId: string): string[] {

        const documentIds: string[] = [];
        if (projectId) {
            const project = this.findProject(projectId, this.projects);
            if (project?.designs) {
                Object.values(project.designs).forEach((design) => {
                    documentIds.push(design.id);
                });
            }
        }
        return documentIds;
    }

    /* End - This section will be made abstract once implementation of new home page is complete */
}
