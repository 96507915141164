import cloneDeep from 'lodash-es/cloneDeep';

import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    ApplicationType
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/application-type';
import { BarType } from '@profis-engineering/pe-ui-c2c/entities/code-lists/bar-type';
import {
    BaseMaterialStructure
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/base-material-structure';
import { BondCondition } from '@profis-engineering/pe-ui-c2c/entities/code-lists/bond-condition';
import {
    CalculationMode
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/calculation-mode';
import {
    ConcreteDensity
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/concrete-density';
import {
    ConcreteReinforcement
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/concrete-reinforcement';
import {
    ConcreteShearInterfaceMode
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/concrete-shear-interface-mode';
import {
    ConnectorLength
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/connector-length';
import {
    ContactSurfaceCondition
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/contact-surface-condition';
import { CrackWidth } from '@profis-engineering/pe-ui-c2c/entities/code-lists/crack-width';
import { CrossSection } from '@profis-engineering/pe-ui-c2c/entities/code-lists/cross-section';
import {
    DesignMethodGroup
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/design-method-group';
import {
    DesignMethod
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/design-method';
import {
    DesignWorkingLife
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/design-working-life';
import { DrillingAid } from '@profis-engineering/pe-ui-c2c/entities/code-lists/drilling-aid';
import { DrillingType } from '@profis-engineering/pe-ui-c2c/entities/code-lists/drilling-type';
import {
    EdgeDistanceMode
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/edge-distance-mode';
import {
    EdgeReinforcement
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/edge-reinforcement';
import { EmbedmentDepth } from '@profis-engineering/pe-ui-c2c/entities/code-lists/embedment-depth';
import {
    EmbedmentDepthExisting
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/embedment-depth-existing';
import {
    EmbedmentDepthMode
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/embedment-depth-mode';
import {
    EmbedmentDepthOverlay
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/embedment-depth-overlay';
import {
    DesignMethodConnectionTypeMethodGroup
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/design-method-connection-type-method-group';
import {
    ExistingReinforcementDiameter
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/existing-reinforcement-diameter';
import { FastenerFamily } from '@profis-engineering/pe-ui-c2c/entities/code-lists/fastener-family';
import { FastenerSize } from '@profis-engineering/pe-ui-c2c/entities/code-lists/fastener-size';
import { FastenerType } from '@profis-engineering/pe-ui-c2c/entities/code-lists/fastener-type';
import { FireDuration } from '@profis-engineering/pe-ui-c2c/entities/code-lists/fire-duration';
import { FireInputTypes } from '@profis-engineering/pe-ui-c2c/entities/code-lists/fire-input-types';
import { GenericRebar } from '@profis-engineering/pe-ui-c2c/entities/code-lists/generic-rebar';
import { HoleCondition } from '@profis-engineering/pe-ui-c2c/entities/code-lists/hole-condition';
import { HoleType } from '@profis-engineering/pe-ui-c2c/entities/code-lists/hole-type';
import {
    InterfaceShearType
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/interface-shear-type';
import { JointRoughness } from '@profis-engineering/pe-ui-c2c/entities/code-lists/joint-roughness';
import {
    KBNumberControlRegion
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/KB-number-control-region';
import {
    LoadDefinitionSection
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/load-definition-section';
import {
    LoadDefinitionType
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/load-definition-type';
import {
    LoadType as LoadTypeC2CEntity
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/load-type';
import {
    LoadTypeCategory
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/load-type-category';
import {
    LoadingDefinitionType
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/loading-definition-type';
import { LocationFactor } from '@profis-engineering/pe-ui-c2c/entities/code-lists/location-factor';
import {
    LongitudinalReinforcementOption
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/longitudinal-reinforcement-option';
import { NumberOfLayers } from '@profis-engineering/pe-ui-c2c/entities/code-lists/number-of-layers';
import {
    OptimizationType
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/optimization-type';
import {
    OverlayPosition
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/overlay-position';
import { Overstrength } from '@profis-engineering/pe-ui-c2c/entities/code-lists/overstrength';
import { ProductFilter } from '@profis-engineering/pe-ui-c2c/entities/code-lists/product-filter';
import {
    ProductFilterGroup
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/product-filter-group';
import {
    RebarDiameterMode
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/rebar-diameter-mode';
import { RebarMaterial } from '@profis-engineering/pe-ui-c2c/entities/code-lists/rebar-material';
import {
    ReinforcedInterfaces
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/reinforced-interfaces';
import {
    ReinforcementTensionCondition
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/reinforcement-tension-condition';
import {
    ReinforcementType
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/reinforcement-type';
import {
    ReinforcmentAlphaCoefficient
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/reinforcment-alpha-coefficient';
import { Roughness } from '@profis-engineering/pe-ui-c2c/entities/code-lists/roughness';
import { RowsNumberMode } from '@profis-engineering/pe-ui-c2c/entities/code-lists/rows-number-mode';
import { ShapeType } from '@profis-engineering/pe-ui-c2c/entities/code-lists/shape-type';
import {
    SpacingBarsOption
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/spacing-bars-option';
import { SpacingMode } from '@profis-engineering/pe-ui-c2c/entities/code-lists/spacing-mode';
import { SpliceClass } from '@profis-engineering/pe-ui-c2c/entities/code-lists/splice-class';
import {
    SurfaceTreatment
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/surface-treatment';
import {
    TemperatureInstallation
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/temperature-installation';
import {
    TransverseReinforcementOption
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/transverse-reinforcement-option';
import {
    ZonesNumber
} from '@profis-engineering/pe-ui-c2c/entities/code-lists/zones-number';
import { DesignC2C, ICodeListsC2C } from '@profis-engineering/pe-ui-c2c/entities/design-c2c';
import { DesignCodeList } from '@profis-engineering/pe-ui-c2c/entities/design-code-list';
import { DesignType } from '@profis-engineering/pe-ui-c2c/entities/tracking-data';
import {
    CalculateDesignRequestC2C, CalculationOptionsC2C,
    CalculationResultEntityC2C as ICalculationResultEntityC2C,
    DesignCodeListEntityC2C as DesignCodeListsC2C,
    DesignReportDataClientEntityC2C as IDesignReportDataClientEntityC2C, DialogsEntityC2C,
    NewDesignDataEntityC2C, NewDesignFromProjectEntityC2C, ProjectDesignEntityC2C,
    StaticDesignDataC2C, UIProperty, WarningMessageEntityC2C, EntityBaseC2C
} from '@profis-engineering/pe-ui-c2c/generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import {
    ConnectionType, DesignForYield as DesignForYieldEnum
} from '@profis-engineering/pe-ui-c2c/generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import {
    ReportDesignRequestC2C, ReportOptionEntityC2C, ReportResponseEntityC2C
} from '@profis-engineering/pe-ui-c2c/generated-modules/Hilti.PE.ReportService.Shared.Entities';
import {
    UIPropertyConfigC2C, UIPropertyValueC2C
} from '@profis-engineering/pe-ui-c2c/generated-modules/Hilti.PE.UserInterfaceProperties';
import {
    sanitizePropertyValueForJson
} from '@profis-engineering/pe-ui-c2c/helpers/ui-property-helpers';
import {
    PropertyMetaDataC2C, UIPropertiesC2C
} from '@profis-engineering/pe-ui-c2c/properties/properties';
import {
    CalculationType, DesignEvent, StateChange
} from '@profis-engineering/pe-ui-common/entities/design';
import { Deferred } from '@profis-engineering/pe-ui-common/helpers/deferred';
import {
    getNumberDecimalSeparator, getNumberGroupSeparator
} from '@profis-engineering/pe-ui-common/helpers/localization-helper';
import { ApiOptions } from '@profis-engineering/pe-ui-common/services/api.common';
import { Change } from '@profis-engineering/pe-ui-common/services/changes.common';
import { DocumentAccessMode } from '@profis-engineering/pe-ui-common/services/document.common';
import { ReportData } from '@profis-engineering/pe-ui-shared/entities/design-pe';
import {
    ValidationErrorEntity
} from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation';
import {
    UIPropertyTexts
} from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import {
    ProjectOpenType
} from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display.Enums';
import { PropertyMetaData } from '@profis-engineering/pe-ui-shared/properties/properties';

import { environment } from '../../../environments/environment';
import { Design, IAllDesignDeps, IBaseDesign, ICalculationResult } from '../../entities/design';
import { DesignExternalMetaData } from '../../entities/design-external-meta-data';
import { ApiService } from '../api.service';
import { BrowserService } from '../browser.service';
import { ChangesService } from '../changes.service';
import { CodeListServiceC2C } from '../code-list-c2c.service';
import { CodeListService } from '../code-list.service';
import { CommonCodeListService } from '../common-code-list.service';
import { DesignTemplateService } from '../design-template.service';
import { DocumentService } from '../document.service';
import { GuidService } from '../guid.service';
import { LocalizationService } from '../localization.service';
import { LoggerService } from '../logger.service';
import { ModalService } from '../modal.service';
import { ReportService } from '../report.service';
import { SignalRService } from '../signalr.service';
import { TrackingServiceC2C } from '../tracking-c2c.service';
import { UnitService } from '../unit.service';
import { UserSettingsService } from '../user-settings.service';
import { UserService } from '../user.service';
import {
    afterCalculation, afterCalculationUpdates, BaseCalculateDesignRequestData, calculateAsyncHelper,
    catchCalculationException, changeModel, createOnDocumentServiceAndTrack,
    ICalculateInternalOptions, loadStateBase, removeLoadingFlag, resetModel, saveDesignStateInternal, saveState,
    setDesignFromDocumentDesign, triggerDesignEvent, UIPropertyConfigExtended,
    updateDesignCodeLists, updateFromProperties, updatePropertyValues
} from './calculation-service-base';
import { ModulesService } from '../modules.service';
import { CodeList } from '@profis-engineering/pe-ui-c2c/entities/code-lists/code-list';
import { ApplicationTypeEntityC2C, BarTypeEntityC2C, BaseMaterialStructureEntityC2C, BondConditionEntityC2C, CalculationModeEntityC2C, ConcreteDensityEntityC2C, ConcreteReinforcementEntityC2C, ConcreteShearInterfaceModeEntityC2C, ConnectorLengthEntityC2C, ContactSurfaceConditionEntityC2C, CrackWidthEntityC2C, CrossSectionEntityC2C, DesignMethodConnectionTypeMethodGroupEntityC2C, DesignMethodEntityC2C, DesignMethodGroupEntityC2C, DesignWorkingLifeEntityC2C, DrillingAidEntityC2C, DrillingTypeEntityC2C, EdgeDistanceModeEntityC2C, EdgeReinforcementEntityC2C, EmbedmentDepthEntityC2C, EmbedmentDepthExistingEntityC2C, EmbedmentDepthModeEntityC2C, EmbedmentDepthOverlayEntityC2C, ExistingReinforcementDiameterEntityC2C, FastenerSizeEntityC2C, FastenerTypeEntityC2C, FireDurationEntityC2C, FireInputTypeEntityC2C, GenericRebarRegionEntityC2C, HoleTypeEntityC2C, JointRoughnessEntityC2C, KBNumberControlRegionEntityC2C, LoadDefinitionSectionEntityC2C, LoadDefinitionTypeEntityC2C, LoadTypeCategoryEntityC2C, LoadTypeEntityC2C, LoadingDefinitionTypeEntityC2C, LocationFactorEntityC2C, LongitudinalReinforcementOptionEntityC2C, NumberOfLayersEntityC2C, OptimizationTypeEntityC2C, OverlayPositionEntityC2C, OverstrengthEntityC2C, ProductFilterEntityC2C, ProductFilterGroupEntityC2C, RebarDiameterModeEntityC2C, RebarMaterialEntityC2C, ReinforcedInterfaceEntityC2C, ReinforcementTensionConditionEntityC2C, ReinforcementTypeEntityC2C, ReinforcmentAlphaCoefficientEntityC2C, RoughnessEntityC2C, RowsNumberModeEntityC2C, ShapeTypeEntityC2C, ShearInterfaceTypeEntityC2C, SpacingBarsOptionEntityC2C, SpacingModeEntityC2C, SpliceClassEntityC2C, SurfaceTreatmentEntityC2C, TemperatureInstallationEntityC2C, TooltipEntityBaseC2C, TransverseReinforcementOptionEntityC2C, ZonesNumberEntityC2C, DesignForYieldEntityC2C } from '@profis-engineering/pe-ui-c2c/generated-modules/Hilti.PE.C2CCodeListService.Entities';
import { ProductFamilyDetailedEntityC2C } from '@profis-engineering/pe-ui-c2c/generated-modules/Hilti.PE.CalculationService.Shared.Entities.DesignCodeList';
import { UnitType } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { UnitValue } from '@profis-engineering/pe-ui-common/services/unit.common';
import { format } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { DesignForYield } from '@profis-engineering/pe-ui-c2c/entities/code-lists/design-for-yield';

@Injectable({
    providedIn: 'root'
})
export class CalculationServiceC2C {

    constructor(
        protected signalr: SignalRService,
        protected tracking: TrackingServiceC2C,
        public userSettings: UserSettingsService,
        public localization: LocalizationService,
        protected documentService: DocumentService,
        public logger: LoggerService,
        public unitService: UnitService,
        protected reportService: ReportService,
        protected browser: BrowserService,
        protected designTemplateService: DesignTemplateService,
        protected apiService: ApiService,
        protected changesService: ChangesService,
        protected modalService: ModalService,
        private guid: GuidService,
        private user: UserService,
        private codeList: CodeListService,
        private codeListCommon: CommonCodeListService,
        private codeListC2C: CodeListServiceC2C,
        private modulesService: ModulesService
    ) {
    }

    public createAndOpenC2C(dataEntity: NewDesignDataEntityC2C) {
        const designDeps = this.getDesignDeps();
        const design = new Design(designDeps, DesignC2C.commonUiProperties);

        return this.createInternalC2C(design, dataEntity)
            .then(() => {
                return createOnDocumentServiceAndTrack(
                    this.documentService,
                    this.signalr,
                    design,
                    dataEntity.projectId,
                    ProjectOpenType.Blank,
                    true,
                    false,
                    () => {
                        this.trackCreatedDesign(design);
                    }
                );
            }).then(() => design);
    }

    /**
     * Opens the design as a template. No changes are stored to the document service.
     */
    public createAndOpenTemplateC2C(projectDesign: ProjectDesignEntityC2C, templateId: string, templateName: string) {
        const designDeps = this.getDesignDeps();
        const design = new Design(designDeps, DesignC2C.commonUiProperties);

        design.templateId = templateId;
        design.templateName = templateName;

        return this.createFromProjectDesignInternal(design, projectDesign)
            .then(() => {
                design.isTemplate = true;
                design.projectOpenType = ProjectOpenType.TemplateEdit;
                this.signalr.setHubConnectionsC2C();
                return design;
            });
    }

    public createAndOpenFromProjectDesignC2C(projectDesign: ProjectDesignEntityC2C, projectId: string, templateId?: string, apiOptions?: ApiOptions) {
        const designDeps = this.getDesignDeps();
        const design = new Design(designDeps, DesignC2C.commonUiProperties);
        design.templateId = templateId;

        const openType = templateId != null ? ProjectOpenType.BlankFromTemplate : ProjectOpenType.OpenExisting;
        return this.createFromProjectDesignInternal(design, projectDesign, null, apiOptions)
            .then(() => createOnDocumentServiceAndTrack(
                this.documentService,
                this.signalr,
                design,
                projectId,
                openType,
                true,
                false,
                () => {
                    this.trackCreatedDesign(design);
                }
            ));
    }

    public createFromProjectDesignC2C(projectDesign: ProjectDesignEntityC2C, projectId: string) {
        const designDeps = this.getDesignDeps();
        const design = new Design(designDeps, DesignC2C.commonUiProperties);

        return this.createFromProjectDesignInternal(design, projectDesign)
            .then(() => createOnDocumentServiceAndTrack(
                this.documentService,
                this.signalr,
                design,
                projectId,
                ProjectOpenType.Blank/*TODO: when is this called, is this really opening of design form document service*/,
                false,
                false,
                () => {
                    this.trackCreatedDesign(design);
                }
            ));
    }

    public openFromDocumentDesignC2C(documentDesign: IBaseDesign) {
        const designDeps = this.getDesignDeps();
        const design = new Design(designDeps, DesignC2C.commonUiProperties);

        return this.documentService.openDesignExclusiveC2C(documentDesign)
            .then((projectDesign) => this.createFromProjectDesignInternal(design, projectDesign))
            .then((result) => {
                this.setDesign(design, documentDesign);
                return result;
            });
    }

    public openFromProjectDesignC2C(projectDesign: ProjectDesignEntityC2C, designId: string, trackingEnabled = true) {
        const designDeps = this.getDesignDeps();
        const design = new Design(designDeps, DesignC2C.commonUiProperties);

        return this.createFromProjectDesignInternal(design, projectDesign)
            .then((result) => {
                this.setDesign(design, this.documentService.findDesignById(designId), trackingEnabled);

                return result;
            });
    }

    public updateDesignFromExternalFileC2C(oldDesign: IBaseDesign, projectDesign: ProjectDesignEntityC2C, disableCalcMessages?: boolean) {
        const designDeps = this.getDesignDeps();
        const design = new Design(designDeps, DesignC2C.commonUiProperties);

        return this.documentService.openDesignExclusivePe(oldDesign)
            .then(() => {
                let update: Promise<any>;

                // The calculation messages are disabled (called from updateDesignWithNewContentC2C) when importing from opened design
                // in order to avoid duplicate messages as the new design is shown in new tab ->
                if (disableCalcMessages) {
                    update = this.documentService.updateDesignWithNewContentC2C(oldDesign.id, oldDesign.projectId, oldDesign.designName, projectDesign, null, null, false, false, DocumentAccessMode.Update)
                        .then(() => {
                            return this.createFromProjectDesignInternal(design, projectDesign, disableCalcMessages);
                        });
                }
                // Updating from main page, updating the design
                else {
                    update = this.createFromProjectDesignInternal(design, projectDesign)
                        .then(() => {
                            return this.documentService.updateDesignWithNewContentC2C(oldDesign.id, oldDesign.projectId, oldDesign.designName, design.designData.projectDesignC2C, null, null, false, false, DocumentAccessMode.Update);
                        });
                }

                return update.then(() => {
                    this.setDesign(design, oldDesign);
                    return design;
                });
            });
    }

    public generateAndDownloadReport(design: Design, downloadPdf: boolean, options: ReportOptionEntityC2C, designImages: string[]) {
        design.updateProjectDesignOptionsC2C(options);

        this.saveTrackingActData(design);

        const request = this.getReportRequest(design, options, designImages);
        return this.reportService.generateAndDownloadReportC2C(request)
            .then((response: ReportResponseEntityC2C) => {

                if (response?.reportChanges?.length > 0) {
                    const changes = this.localization.getString('Agito.Hilti.C2C.Messages.ReportFieldsTruncated.Message');

                    const confirmDialog = this.modalService.openConfirmChange({
                        id: 'ConfirmReportChangesC2C',
                        title: this.localization.getString('Agito.Hilti.Profis3.Warning'),
                        message: `${changes} ${response.reportChanges.map(x => this.localization.getString(x)).join(', ')}`,
                        confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.Ok'),
                        onConfirm: (modal) => {
                            modal.close();
                        }
                    }).closed;

                    return confirmDialog.then(() => response);
                }

                return response;
            })
            .then((data) => {
                const reportBlob = this.browser.base64toBlob(data.base64Pdf, 'application/pdf');
                const hasApproval = data.approvalBase64Pdf != null && data.approvalBase64Pdf != '';
                const approvalBlob = hasApproval ? this.browser.base64toBlob(data.approvalBase64Pdf, 'application/pdf') : null;
                const fileName = `${design.designData.projectDesignC2C.projectName}_${design.designData.projectDesignC2C.designName}`;

                if (downloadPdf) {
                    this.browser.downloadBlob(reportBlob, `${fileName}.pdf`, true, true);
                    if (hasApproval) {
                        this.browser.downloadBlob(approvalBlob, `${fileName}_approval.pdf`, true, true);
                    }
                }

                return reportBlob;
            })
            .finally(() => {
                design.cancellationToken = null;
            });
    }

    public calculateAllC2CSignalR(
        design: Design,
        changes?: Change[],
        onProgress?: (progress: unknown) => void) {
        const deferred = new Deferred();

        if (design.designData.calculateAllData == null) {
            design.cancellationToken = new Deferred<void>();
            const requestData = this.getCalculateDesignRequestData(design, changes);

            this.signalr.common.calculateDesignC2C(requestData, { cancel: design.cancellationToken.promise, onProgress })
                .then((data: ICalculationResultEntityC2C) => {
                    this.updateCalculationData(design, data, requestData.localizationOptions.language, CalculationType.valueChange);
                    // save state if validation was ok
                    saveState(this.userSettings, this.localization, design, StateChange.server);

                    this.saveTrackingActData(design);

                    // update design content on document service.
                    this.documentService.updateDesign(design);

                    deferred.resolve();
                })
                .catch(err => deferred.reject(err))
                .finally(() => design.cancellationToken = null);

            return deferred.promise;
        }

        deferred.resolve();
        return deferred.promise;
    }

    public calculateAsync(design: Design, changeFn?: (design: Design) => void, options?: ICalculateInternalOptions) {
        return calculateAsyncHelper(this.logger, this.calculate.bind(this), design, this.modalService, this.localization, changeFn, options);
    }

    public calculate(design: Design, calculateId: string, options: BaseCalculateDesignRequestData) {
        design.cancellationToken = new Deferred<void>();

        let isRequestCanceled = false;
        const cancellationToken = design.cancellationToken.promise;
        cancellationToken
            .finally(() => {
                isRequestCanceled = true;
            });

        const data = this.getCalculateDesignRequestData(design, null, options);
        design.confirmedProperties = [];

        this.signalr.common.calculateDesignC2C(data, { cancel: cancellationToken })
            .then(response => afterCalculationUpdates(
                this.updateCalculationData.bind(this),
                this.userSettings,
                this.localization,
                this.logger,
                () => this.saveTrackingActData(design),
                isRequestCanceled,
                response,
                design,
                calculateId,
                data.localizationOptions.language)
            )
            .then(response => {
                // don't update the design on document service if calculation fails
                if (response.validationError) {
                    return response;
                }

                const metaDataCandidate: DesignExternalMetaData = DesignExternalMetaData.getMetaDataFromDesign(design);

                // extract the needed values from response that we send to the document service (we need the new ones not the old ones in this. ...)
                const name = response.properties[PropertyMetaData.Option_DesignName.name].value;

                if (design.isTemplate) {
                    return this.designTemplateService.update({
                        designTemplateDocumentId: design.templateId,
                        designTypeId: DesignType.Concrete2Concrete,
                        designStandardId: response.projectDesign.options.designStandard,
                        regionId: response.projectDesign.options.regionId,
                        templateName: design.templateName,
                        anchorName: null,
                        approvalNumber: metaDataCandidate.approvalNumber,
                        projectDesign: JSON.stringify(response.projectDesign)
                    }).then(() => response)
                }
                // do not wait for document service response
                // multiple calls are already handled by updateDesignWithNewContent with deffered
                this.documentService.updateDesignWithNewContentC2C(design.id, design.projectId, name, response.projectDesign, metaDataCandidate, null, false, false, DocumentAccessMode.Update);

                return response;
            })
            .catch((err) => catchCalculationException(err, design, isRequestCanceled, calculateId, this.logger))
            .then((response) => removeLoadingFlag(response, design, calculateId));

        afterCalculation(design, options.calculateAll, options.calculateAdvancedBaseplate, this.logger);
    }

    public updateReportData(design: Design, reportData: IDesignReportDataClientEntityC2C) {

        design.designData.reportDataC2C = {
            ...reportData,
            scopeChecks: reportData.scopeCheckResultItems ?? []
        };

        return design.designData.reportDataC2C;
    }

    public updateCalculationData(
        design: Design,
        data: ICalculationResultEntityC2C, calculationLanguage: string, calculationType: CalculationType, messagesClosedDeferred?: Deferred<void>,
        disableCalcMessages?: boolean,
        keepMissingProperties?: boolean) {
        if (data.validationError == null) {
            design.validationError = null;

            design.updateProjectDesignC2C(data.projectDesign);
            const codeListsC2C = this.createDesignCodeLists(data.designCodeLists);

            updateDesignCodeLists(design, codeListsC2C, true);

            if (data.designReportDataClient != null) {
                this.updateReportData(design, data.designReportDataClient);
            }

            const { modelChanges, propertyChanges } = this.updateFromProperties(design, data.properties as any, keepMissingProperties);

            design.designData.reportData = ({} as ReportData);

            design.designData.enabledFeatures = data.enabledFeatures;
            design.designData.dialogs = data.dialogs;
            design.calculationLanguage = calculationLanguage;

            if (design.confirmChangeInProgress === false) {
                design.confirmChangeInProgress = true;
                this.checkDialogsC2C(design, data.dialogs, messagesClosedDeferred);
            }
            else if (messagesClosedDeferred != null) {
                messagesClosedDeferred.resolve();
            }

            triggerDesignEvent(design, propertyChanges);

            design.isReadOnlyDesign = data.isReadOnlyDesign;

            return modelChanges;
        }
        else {
            design.validationError = ({
                Id: data.validationError.id,
                Message: this.localization.getString(data.validationError.messageKey)
            } as ValidationErrorEntity);

            if (messagesClosedDeferred != null) {
                messagesClosedDeferred.resolve();
            }

            // load previous values
            design.reloadState();

            return null;
        }
    }

    public updateFromProperties(design: Design, propertiesC2C: UIPropertiesC2C, keepMissingProperties?: boolean) {
        const modelChanges = this.updateModel(design, propertiesC2C);
        const propertyChanges = this.updatePropertyValues(design, propertiesC2C, keepMissingProperties);

        updateFromProperties(design.properties, modelChanges, propertyChanges, this.logger);

        return {
            modelChanges,
            propertyChanges
        };
    }

    public updatePropertyValues(design: Design, properties: UIPropertiesC2C, keepMissingProperties?: boolean) {
        const propertyConfigs: UIPropertyConfigExtended[] = [];

        for (const name in properties) {

            // FIX MODULARIZATION: remove "as any" and fix all the issues
            const propertyC2C = properties[name as keyof typeof properties] as any;

            const propertyConfig: UIPropertyConfigExtended = {
                Property: propertyC2C.property,
                Editable: propertyC2C.editable,
                Value: propertyC2C.value,
                Visible: propertyC2C.visible,
                Texts: {
                    displayKey: propertyC2C.texts.displayKey,
                    displayName: propertyC2C.texts.displayKey,
                    titleDisplayKey: propertyC2C.texts.titleDisplayKey,
                    tooltip: propertyC2C.texts.tooltip,
                    tooltipTitle: propertyC2C.texts.tooltipTitle
                } as UIPropertyTexts,
                Size: propertyC2C.size,
                ItemsTexts: {},
                MaxValue: undefined,
                MinValue: undefined,
                DefaultValue: undefined
            };

            if (propertyC2C['allowedValues']) {
                // FIX MODULARIZATION: remove "as any" and fix all the issues
                (propertyConfig as any)['AllowedValues'] = propertyC2C['allowedValues'];
                (propertyConfig as any)['DisabledValues'] = propertyC2C['disabledValues'];
                (propertyConfig as any)['AllowedValuesUniqueId'] = propertyC2C['allowedValuesUniqueId'];
            }

            propertyConfigs.push(propertyConfig);
        }

        return updatePropertyValues(design, propertyConfigs, keepMissingProperties);
    }

    public loadState(
        design: Design,
        index: number,
    ) {
        loadStateBase(
            this.unitService,
            this.localization,
            this.userSettings,
            design,
            index,
            (designObj) => {
                designObj.updateProjectDesignC2C(designObj.currentState.projectDesignC2C);
                designObj.updateModelFromModel(cloneDeep(designObj.currentState.model));
                this.updateReportData(designObj, designObj.currentState.reportDataC2C);
            }
        );
    }

    public undo(design: Design) {
        if (!design.canUndo) {
            return Promise.resolve();
        }

        design.resolveCalculation();
        this.loadState(design, design.states.findIndex((state) => state === design.currentState) - 1);

        return this.undoRedoCommon(design, false);
    }

    public redo(design: Design) {
        if (!design.canRedo) {
            return Promise.resolve();
        }

        design.resolveCalculation();
        this.loadState(design, design.states.findIndex((state) => state === design.currentState) + 1);

        return this.undoRedoCommon(design, true);
    }

    private getCalculateDesignRequestData(
        design: Design, changedProperties?: Change[],
        calculationOptionsC2C?: CalculationOptionsC2C): CalculateDesignRequestC2C {
        const changes = changedProperties != null ? changedProperties : design.modelChanges.changes;
        return {
            projectDesign: design.designData.projectDesignC2C,
            localizationOptions: {
                language: this.localization.selectedLanguage,
                numberDecimalSeparator: getNumberDecimalSeparator(this.localization.numberFormat(), this.userSettings),
                numberThousandsSeparator: getNumberGroupSeparator(this.localization.numberFormat(), this.userSettings),
            },
            properties: changes.map((change: Change): UIPropertyValueC2C => {
                return {
                    property: parseInt(change.name, 10),
                    valueJsonElement: sanitizePropertyValueForJson(change.newValue),
                    confirmed: design.confirmedProperties?.some((confirmation) => confirmation == parseInt(change.name, 10)) ?? false,
                    generateReport: false,
                    runAdvancedCalculation: false,
                };
            }),
            calculationOptions: calculationOptionsC2C
        };
    }

    private async createFromProjectDesignInternal(design: Design, projectDesign: ProjectDesignEntityC2C, disableCalcMessages?: boolean, apiOptions?: ApiOptions) {
        /* eslint-disable @typescript-eslint/no-explicit-any */
        const c2cForceAvailableCountries: number[] = (window as any).environmentC2C?.c2cForceAvailableCountries ?? environment.c2cForceAvailableCountries;
        const c2cForceAvailableCountriesPir: number[] = (window as any).environmentC2C?.c2cForceAvailableCountriesPir ?? environment.c2cForceAvailableCountriesPir;
        const c2cForceAvailableCountriesEnabled: boolean = (window as any).environmentC2C?.c2cForceAvailableCountriesEnabled ?? environment.c2cForceAvailableCountriesEnabled;
        const c2cForceAvailableConnections: number[] = (window as any).environmentC2C?.c2cForceAvailableConnections ?? environment.c2cForceAvailableConnections;
        /* eslint-enable @typescript-eslint/no-explicit-any */

        let url = `${environment.c2cCalculationServiceUrl}Calculation/NewDesignFromProject`;
        url = this.addC2CdemoQueryToUrl(url);

        projectDesign.options.debugMode = {
            enabled: environment.debugModeEnabled,
            disableSc: false
        };

        const data: NewDesignFromProjectEntityC2C = {
            projectDesign,
            language: this.localization.selectedLanguage,
            numberDecimalSeparator: getNumberDecimalSeparator(this.localization.numberFormat(), this.userSettings),
            numberThousandsSeparator: getNumberGroupSeparator(this.localization.numberFormat(), this.userSettings)
        };

        return this.apiService.request<StaticDesignDataC2C>(new HttpRequest('POST', url, data), apiOptions)
            .then((response) => {
                let isDisabled = !c2cForceAvailableCountries.includes(data.projectDesign.options.regionId)
                    || !c2cForceAvailableConnections.includes(data.projectDesign.options.connectionType);

                if ([ConnectionType.Splices, ConnectionType.StructuralJoints].includes(data.projectDesign.options.connectionType)) {
                    isDisabled = isDisabled || !c2cForceAvailableCountriesPir.includes(data.projectDesign.options.regionId);
                }

                isDisabled = isDisabled && c2cForceAvailableCountriesEnabled;

                if (isDisabled) {
                    this.modalService
                        .openAlertError(
                            this.localization.getString('Agito.Hilti.Profis3.FileUpload.UnsupportedFileAlert.Title'),
                            this.localization.getString('Agito.Hilti.C2C.DesignVerification.Obsolete.StandardOrRegion')
                        );
                    throw 'notAllowedC2C';
                }

                // set data
                resetModel(design);
                const messagesClosedDeferred = new Deferred();
                this.updateCalculationData(design, response.body.calculationResults, data.language, CalculationType.openDesign, messagesClosedDeferred, disableCalcMessages);
                this.modalService.dialogDataC2C = response.body.calculationResults.dialogs;

                // save state
                saveState(this.userSettings, this.localization, design);

                // trigger create design event
                design.trigger(DesignEvent.createDesign);

                return {
                    design,
                    calculationCanceled: false,
                    messagesClosed: messagesClosedDeferred.promise
                } as ICalculationResult;
            })
            .catch((response) => {
                this.logger.logServiceError(response, 'Design', 'createDesignFromProject');

                throw response;
            });
    }

    private getReportRequest(design: Design, options: ReportOptionEntityC2C, designImages: string[]): ReportDesignRequestC2C {
        return {
            projectDesign: design.designData.projectDesignC2C,
            language: design.getLanguageById(options.reportLanguageId).culture,
            numberDecimalSeparator: getNumberDecimalSeparator(this.localization.numberFormat(), this.userSettings),
            numberThousandsSeparator: getNumberGroupSeparator(this.localization.numberFormat(), this.userSettings),
            reportOptions: options,
            designId: design.id,
            appVersionUI: environment.applicationVersion,
            designImages,
            hiltiOnlineUrl: this.userSettings.getCommonRegionById(design.designData.projectDesignC2C.options.regionId).hiltiOnlineUrl
        };
    }

    private updateModel(design: Design, properties: UIPropertiesC2C) {
        return changeModel(design.model, design.modelChanges, this.changesService, (model) => {
            for (const name in properties) {
                // FIX MODULARIZATION: remove "as unknown as UIPropertyConfigC2C" and fix all the issues
                const propertyConfig = properties[name as keyof typeof properties] as unknown as UIPropertyConfigC2C;

                // don't update the model if there's already a change pending
                if (propertyConfig != null && !design.modelChanges.changes.some((change) => change.name == propertyConfig.property.toString())) {
                    model[propertyConfig.property] = propertyConfig.value;
                }
            }
        });
    }

    private createDesignCodeLists(serviceDesignCodeLists: DesignCodeListsC2C) {
        const designCodeLists: ICodeListsC2C = {};

        if (serviceDesignCodeLists != null) {
            designCodeLists[DesignCodeList.BaseMaterialStructure] = this.getCodeListItems(serviceDesignCodeLists.baseMaterialStructure, x => BaseMaterialStructure.fromService(x as BaseMaterialStructureEntityC2C));
            designCodeLists[DesignCodeList.BondCondition] = this.getCodeListItems(serviceDesignCodeLists.bondConditions, x => BondCondition.fromService(x as BondConditionEntityC2C));
            designCodeLists[DesignCodeList.ConcreteDensity] = this.getCodeListItems(serviceDesignCodeLists.concreteDensity, x => ConcreteDensity.fromService(x as ConcreteDensityEntityC2C));
            designCodeLists[DesignCodeList.ConcreteReinforcement] = this.getCodeListItems(serviceDesignCodeLists.concreteReinforcement, x => ConcreteReinforcement.fromService(x as ConcreteReinforcementEntityC2C));
            designCodeLists[DesignCodeList.ConcreteShearInterfaceMode] = this.getCodeListItems(serviceDesignCodeLists.concreteShearInterfaceModes, x => ConcreteShearInterfaceMode.fromService(x as ConcreteShearInterfaceModeEntityC2C));
            designCodeLists[DesignCodeList.ConnectorLength] = this.getCodeListItems(serviceDesignCodeLists.connectorLengths, x => ConnectorLength.fromService(x as ConnectorLengthEntityC2C));
            designCodeLists[DesignCodeList.DrillingAid] = this.getCodeListItems(serviceDesignCodeLists.drillingAid, x => DrillingAid.fromService(x as DrillingAidEntityC2C));
            designCodeLists[DesignCodeList.DrillingType] = this.getCodeListItems(serviceDesignCodeLists.drillingTypes, x => DrillingType.fromService(x as DrillingTypeEntityC2C));
            designCodeLists[DesignCodeList.EdgeDistanceMode] = this.getCodeListItems(serviceDesignCodeLists.edgeDistanceModes, x => EdgeDistanceMode.fromService(x as EdgeDistanceModeEntityC2C));
            designCodeLists[DesignCodeList.EdgeReinforcement] = this.getCodeListItems(serviceDesignCodeLists.edgeReinforcement, x => EdgeReinforcement.fromService(x as EdgeReinforcementEntityC2C));
            designCodeLists[DesignCodeList.EmbedmentDepth] = this.getCodeListItems(serviceDesignCodeLists.embedmentDepths, x => EmbedmentDepth.fromService(x as EmbedmentDepthEntityC2C));
            designCodeLists[DesignCodeList.EmbedmentDepthExisting] = this.getCodeListItems(serviceDesignCodeLists.embedmentDepthExisting, x => EmbedmentDepthExisting.fromService(x as EmbedmentDepthExistingEntityC2C));
            designCodeLists[DesignCodeList.EmbedmentDepthMode] = this.getCodeListItems(serviceDesignCodeLists.embedmentDepthModes, x => EmbedmentDepthMode.fromService(x as EmbedmentDepthModeEntityC2C));
            designCodeLists[DesignCodeList.EmbedmentDepthOverlay] = this.getCodeListItems(serviceDesignCodeLists.embedmentDepthOverlay, x => EmbedmentDepthOverlay.fromService(x as EmbedmentDepthOverlayEntityC2C));
            designCodeLists[DesignCodeList.ExistingReinforcementDiameter] = this.getCodeListItems(serviceDesignCodeLists.existingReinforcementDiameters, x => ExistingReinforcementDiameter.fromService(x as ExistingReinforcementDiameterEntityC2C));
            designCodeLists[DesignCodeList.FastenerFamily] = this.getCodeListItems(serviceDesignCodeLists.fastenerFamilies, x => FastenerFamily.fromService(x as ProductFamilyDetailedEntityC2C))
            designCodeLists[DesignCodeList.FastenerSize] = this.getCodeListItems(serviceDesignCodeLists.fastenerSizes, x => FastenerSize.fromService(x as FastenerSizeEntityC2C));
            designCodeLists[DesignCodeList.FastenerType] = this.getCodeListItems(serviceDesignCodeLists.fastenerTypes, x => FastenerType.fromService(x as FastenerTypeEntityC2C));
            designCodeLists[DesignCodeList.FireDuration] = this.getCodeListItems(serviceDesignCodeLists.fireDurations, x => FireDuration.fromService(x as FireDurationEntityC2C));
            designCodeLists[DesignCodeList.HoleType] = this.getCodeListItems(serviceDesignCodeLists.holeTypes, x => HoleType.fromService(x as HoleTypeEntityC2C));
            designCodeLists[DesignCodeList.JointRoughness] = this.getCodeListItems(serviceDesignCodeLists.jointRoughnesses, x => JointRoughness.fromService(x as JointRoughnessEntityC2C));
            designCodeLists[DesignCodeList.LoadDefinitionTypes] = this.getCodeListItems(serviceDesignCodeLists.loadDefinitionTypes, x => LoadDefinitionType.fromService(x as LoadDefinitionTypeEntityC2C))
            designCodeLists[DesignCodeList.LoadingDefinitionTypes] = this.getCodeListItems(serviceDesignCodeLists.loadingDefinitionTypes, x => LoadingDefinitionType.fromService(x as LoadingDefinitionTypeEntityC2C))
            designCodeLists[DesignCodeList.LoadType] = this.getCodeListItems(serviceDesignCodeLists.loadTypes, x => LoadTypeC2CEntity.fromService(x as LoadTypeEntityC2C))
            designCodeLists[DesignCodeList.LoadTypeCategory] = this.getCodeListItems(serviceDesignCodeLists.loadTypeCategories, x => LoadTypeCategory.fromService(x as LoadTypeCategoryEntityC2C))
            designCodeLists[DesignCodeList.OptimizationType] = this.getCodeListItems(serviceDesignCodeLists.optimizationTypes, x => OptimizationType.fromService(x as OptimizationTypeEntityC2C))
            designCodeLists[DesignCodeList.OverlayPosition] = this.getCodeListItems(serviceDesignCodeLists.overlayPositions, x => OverlayPosition.fromService(x as OverlayPositionEntityC2C))
            designCodeLists[DesignCodeList.ProductFilter] = this.getCodeListItems(serviceDesignCodeLists.productFilters, x => ProductFilter.fromService(x as ProductFilterEntityC2C))
            designCodeLists[DesignCodeList.ProductFilterGroup] = this.getCodeListItems(serviceDesignCodeLists.productFilterGroups, x => ProductFilterGroup.fromService(x as ProductFilterGroupEntityC2C))
            designCodeLists[DesignCodeList.RebarDiameterMode] = this.getCodeListItems(serviceDesignCodeLists.rebarDiameterModes, x => RebarDiameterMode.fromService(x as RebarDiameterModeEntityC2C))
            designCodeLists[DesignCodeList.ReinforcedInterface] = this.getCodeListItems(serviceDesignCodeLists.reinforcedInterfaces, x => ReinforcedInterfaces.fromService(x as ReinforcedInterfaceEntityC2C))
            designCodeLists[DesignCodeList.ReinforcmentAlphaCoefficient] = this.getCodeListItems(serviceDesignCodeLists.alphaCoefficientTypes, x => ReinforcmentAlphaCoefficient.fromService(x as ReinforcmentAlphaCoefficientEntityC2C))
            designCodeLists[DesignCodeList.Roughness] = this.getCodeListItems(serviceDesignCodeLists.roughness, x => Roughness.fromService(x as RoughnessEntityC2C))
            designCodeLists[DesignCodeList.RowsNumberMode] = this.getCodeListItems(serviceDesignCodeLists.rowsNumberModes, x => RowsNumberMode.fromService(x as RowsNumberModeEntityC2C))
            designCodeLists[DesignCodeList.SpacingMode] = this.getCodeListItems(serviceDesignCodeLists.spacingModes, x => SpacingMode.fromService(x as SpacingModeEntityC2C))
            designCodeLists[DesignCodeList.SpliceClass] = this.getCodeListItems(serviceDesignCodeLists.spliceClasses, x => SpliceClass.fromService(x as SpliceClassEntityC2C))
            designCodeLists[DesignCodeList.SurfaceTreatment] = this.getCodeListItems(serviceDesignCodeLists.surfaceTreatments, x => SurfaceTreatment.fromService(x as SurfaceTreatmentEntityC2C));
            designCodeLists[DesignCodeList.TemperatureInstallation] = this.getCodeListItems(serviceDesignCodeLists.temperatureInstallations, x => TemperatureInstallation.fromService(x as TemperatureInstallationEntityC2C));
            designCodeLists[DesignCodeList.ZonesNumber] = this.getCodeListItems(serviceDesignCodeLists.zonesNumber, x => ZonesNumber.fromService(x as ZonesNumberEntityC2C));
            designCodeLists[DesignCodeList.BarType] = this.getCodeListItems(serviceDesignCodeLists.barTypes, x => BarType.fromService(x as BarTypeEntityC2C));
            designCodeLists[DesignCodeList.LocationFactor] = this.getCodeListItems(serviceDesignCodeLists.locationFactors, x => LocationFactor.fromService(x as LocationFactorEntityC2C));
            designCodeLists[DesignCodeList.Overstrength] = this.getCodeListItems(serviceDesignCodeLists.overstrengths, x => Overstrength.fromService(x as OverstrengthEntityC2C));
            designCodeLists[DesignCodeList.HoleCondition] = this.getCodeListItems(serviceDesignCodeLists.holeCondition, x => HoleCondition.fromService(x as TooltipEntityBaseC2C));
            designCodeLists[DesignCodeList.ContactSurfaceCondition] = this.getCodeListItems(serviceDesignCodeLists.contactSurfaceCondition, x => ContactSurfaceCondition.fromService(x as ContactSurfaceConditionEntityC2C));
            designCodeLists[DesignCodeList.ReinforcementTensionCondition] = this.getCodeListItems(serviceDesignCodeLists.reinforcementTensionConditions, x => ReinforcementTensionCondition.fromService(x as ReinforcementTensionConditionEntityC2C));
            designCodeLists[DesignCodeList.RebarMaterial] = this.getCodeListItems(serviceDesignCodeLists.rebarMaterials, x => RebarMaterial.fromService(x as RebarMaterialEntityC2C));
            designCodeLists[DesignCodeList.GenericRebarDiameter] = this.getCodeListItems(serviceDesignCodeLists.genericRebars, x => GenericRebar.fromService(x as GenericRebarRegionEntityC2C));
            designCodeLists[DesignCodeList.KBNumberControlRegion] = this.getCodeListItems(serviceDesignCodeLists.kbNumberControlRegions, x => KBNumberControlRegion.fromService(x as KBNumberControlRegionEntityC2C));
            designCodeLists[DesignCodeList.CrossSection] = this.getCodeListItems(serviceDesignCodeLists.crossSections, x => CrossSection.fromService(x as CrossSectionEntityC2C));
            designCodeLists[DesignCodeList.LongitudinalReinforcementOption] = this.getCodeListItems(serviceDesignCodeLists.longitudinalReinforcementOptions, x => LongitudinalReinforcementOption.fromService(x as LongitudinalReinforcementOptionEntityC2C));
            designCodeLists[DesignCodeList.ShapeType] = this.getCodeListItems(serviceDesignCodeLists.shapeTypes, x => ShapeType.fromService(x as ShapeTypeEntityC2C));
            designCodeLists[DesignCodeList.SpacingBarsOption] = this.getCodeListItems(serviceDesignCodeLists.spacingBarsOptions, x => SpacingBarsOption.fromService(x as SpacingBarsOptionEntityC2C));
            designCodeLists[DesignCodeList.TransverseReinforcementOption] = this.getCodeListItems(serviceDesignCodeLists.transverseReinforcementOptions, x => TransverseReinforcementOption.fromService(x as TransverseReinforcementOptionEntityC2C));
            designCodeLists[DesignCodeList.CalculationMode] = this.getCodeListItems(serviceDesignCodeLists.calculationModes, x => CalculationMode.fromService(x as CalculationModeEntityC2C));
            designCodeLists[DesignCodeList.DesignWorkingLife] = this.getCodeListItems(serviceDesignCodeLists.designWorkingLife, x => DesignWorkingLife.fromService(x as DesignWorkingLifeEntityC2C));
            designCodeLists[DesignCodeList.ShearInterfaceType] = this.getCodeListItems(serviceDesignCodeLists.shearInterfaceTypes, x => InterfaceShearType.fromService(x as ShearInterfaceTypeEntityC2C));
            designCodeLists[DesignCodeList.LoadDefinitionSections] = this.getCodeListItems(serviceDesignCodeLists.loadDefinitionSections, x => LoadDefinitionSection.fromService(x as LoadDefinitionSectionEntityC2C));
            designCodeLists[DesignCodeList.ReinforcementType] = this.getCodeListItems(serviceDesignCodeLists.reinforcementTypes, x => ReinforcementType.fromService(x as ReinforcementTypeEntityC2C));
            designCodeLists[DesignCodeList.NumberOfLayers] = this.getCodeListItems(serviceDesignCodeLists.numberOfLayers, x => NumberOfLayers.fromService(x as NumberOfLayersEntityC2C));
            designCodeLists[DesignCodeList.FireInputTypes] = this.getCodeListItems(serviceDesignCodeLists.fireInputTypes, x => FireInputTypes.fromService(x as FireInputTypeEntityC2C));
            designCodeLists[DesignCodeList.CrackWidth] = this.getCodeListItems(serviceDesignCodeLists.crackWidths, x => CrackWidth.fromService(x as CrackWidthEntityC2C));
            designCodeLists[DesignCodeList.ApplicationTypesC2C] = this.getCodeListItems(serviceDesignCodeLists.applicationTypes, x => ApplicationType.fromService(x as ApplicationTypeEntityC2C));
            designCodeLists[DesignCodeList.DesignMethodGroupsC2C] = this.getCodeListItems(serviceDesignCodeLists.designMethodGroups, x => DesignMethodGroup.fromService(x as DesignMethodGroupEntityC2C));
            designCodeLists[DesignCodeList.DesignMethods] = this.getCodeListItems(serviceDesignCodeLists.designMethods, x => DesignMethod.fromService(x as DesignMethodEntityC2C));
            designCodeLists[DesignCodeList.DesignMethodConnectionTypeMethodGroup] = this.getCodeListItems(serviceDesignCodeLists.designMethodConnectionTypeMethodGroups, x => DesignMethodConnectionTypeMethodGroup.fromService(x as DesignMethodConnectionTypeMethodGroupEntityC2C));
            designCodeLists[DesignCodeList.DesignForYield] = this.getCodeListItems(serviceDesignCodeLists.designForYield, x => DesignForYield.fromService(x as DesignForYieldEntityC2C));
        }

        return designCodeLists;
    }

    private getCodeListItems(entityItems: EntityBaseC2C[], mappingFn: (entity: EntityBaseC2C) => CodeList) {
        return entityItems?.map(codeList => mappingFn(codeList)) ?? [];
    }

    private checkDialogsC2C(design: Design, dialogs: DialogsEntityC2C, messagesClosedDeferred: Deferred<void>) {
        let messagesToProcess: WarningMessageEntityC2C[] = [];

        messagesToProcess = this.getMessagesToProcess(dialogs);
        this.showPopUp(messagesToProcess, design, dialogs, messagesClosedDeferred);
    }

    private createInternalC2C(design: Design, dataEntity: NewDesignDataEntityC2C) {
        let url = `${environment.c2cCalculationServiceUrl}Calculation/CreateNewDesign`;
        url = this.addC2CdemoQueryToUrl(url);

        dataEntity.language = this.localization.selectedLanguage;
        dataEntity.numberDecimalSeparator = getNumberDecimalSeparator(this.localization.numberFormat(), this.userSettings);
        dataEntity.numberThousandsSeparator = getNumberGroupSeparator(this.localization.numberFormat(), this.userSettings);

        return this.apiService.request<StaticDesignDataC2C>(new HttpRequest('POST', url, dataEntity))
            .then((response) => {
                // set data
                resetModel(design);
                const messagesClosedDeferred = new Deferred();
                this.updateCalculationData(design, response.body.calculationResults, dataEntity.language, CalculationType.newDesign, messagesClosedDeferred);
                this.modalService.dialogDataC2C = response.body.calculationResults.dialogs;

                return saveDesignStateInternal(design, messagesClosedDeferred, this.userSettings, this.localization);
            })
            .catch((response) => {
                this.logger.logServiceError(response, 'Design', 'createDesign');

                throw response;
            });
    }

    private setDesign(
        design: Design,
        documentDesign: IBaseDesign,
        trackingEnabled = true
    ) {

        setDesignFromDocumentDesign(design, documentDesign);
        design.projectOpenType = ProjectOpenType.OpenExisting;

        if (design.isC2C) {
            this.signalr.setHubConnectionsC2C();
        }
        else {
            this.signalr.setHubConnections();
        }

        design.usageCounterC2C.DateAccessed = new Date();

        this.trackOnDesignOpen(trackingEnabled, design);
    }

    private trackOnDesignOpen(trackingEnabled: boolean, design: Design) {
        if (!trackingEnabled) {
            return;
        }

        this.tracking.trackOnDesignOpen(design.designType.id, design.id, design.designData.projectDesignC2C.options.designStandard, design.designData.projectDesignC2C.options.connectionType)
            .then(() => this.saveTrackingActData(design));
    }

    private trackCreatedDesign(design: Design) {
        this.tracking.trackOnDesignOpen(design.designType.id, design.id, design.designData.projectDesignC2C.options.designStandard, design.designData.projectDesignC2C.options.connectionType)
            .then(() => this.saveTrackingActData(design));
    }

    private saveTrackingActData(design: Design) {
        if (environment.c2cTrackingServiceEnabled) {
            this.tracking.saveTrackingActDataC2C(
                design.designData.projectDesignC2C,
                design.usageCounterC2C.toUsageCounterC2C(),
                design.projectOpenTypeC2C,
                design.id,
                design.createdFromTemplate,
                design.templateId
            );
        }
    }

    private getDesignDeps() {
        return {
            logger: this.logger,
            guid: this.guid,
            changes: this.changesService,
            user: this.user,
            localization: this.localization,
            commonCodeList: this.codeListCommon,
            modulesService: this.modulesService,

            tracking: undefined,
            codeList: this.codeList,    // Should be removed once we remove common codelist from PE one.
            calculation: undefined,

            trackingC2C: this.tracking,
            codeListC2C: this.codeListC2C,
            calculationC2C: this,
        } as IAllDesignDeps;
    }

    private addC2CdemoQueryToUrl(url: string): string {
        if (environment.c2cDemoFeatures) {
            url = `${url}?c2cdemo=true`;
        }

        return url;
    }

    private undoRedoCommon(design: Design, redoStep: boolean) {
        let promise: Promise<void>;

        if (!design.isTemplate) {
            promise = this.documentService.updateDesign(design);
        }
        else {
            const anchorName = design.anchorType != null && design.anchorType.name != null
                && design.anchorSize != null && design.anchorSize.name != null ? design.anchorType.name + ' ' + design.anchorSize.name : null;

            promise = this.designTemplateService.update({
                designTemplateDocumentId: design.templateId,
                designTypeId: DesignType.Concrete2Concrete,
                designStandardId: design.designData.projectDesignC2C.options.designStandard,
                regionId: design.designData.projectDesignC2C.options.regionId,
                templateName: design.templateName,
                anchorName: anchorName,
                approvalNumber: design.approvalNumber,
                projectDesign: JSON.stringify(design.designData.projectDesignC2C)
            });
        }

        return promise.catch((err) => {
            if (err instanceof Error) {
                console.error(err);
            }

            // undo redo
            if (redoStep) {
                this.loadState(design, design.states.findIndex((state) => state === design.currentState) - 1);
            }
            else {
                this.loadState(design, design.states.findIndex((state) => state === design.currentState) + 1);
            }
        });
    }

    private getMessagesToProcess(dialogs: DialogsEntityC2C) {
        let messagesToProcess: WarningMessageEntityC2C[] = [];
        const messages = dialogs.warningMessages;

        const getTranslationIfAnyAndEscape = (stringValue: string) => {
            let message = '';
            if (this.localization.getKeyExists(stringValue)) {
                message = this.localization.getString(stringValue);
            }
            else if (stringValue?.length > 0) {
                message = stringValue;
            }

            const sanitizeTags = { ...LocalizationService.PBrB, ...LocalizationService.H1OlLi, ...LocalizationService.SubSup, ...LocalizationService.I };

            return this.localization.sanitizeText(message, sanitizeTags);
        };

        if (messages?.length > 0) {
            // Get changes with confirm button only and merge them into one change message
            let msgMash: WarningMessageEntityC2C = null;
            if (messages.length > 0) {
                let messageStrings = '';

                if (messages.length == 1) {
                    messageStrings = getTranslationIfAnyAndEscape(messages[0].message);
                }
                else if (messages.length > 1) {
                    if (dialogs.firstMessageIsTitle) {
                        messageStrings = `<html lang="en"><b>` + getTranslationIfAnyAndEscape(messages[0].message) + `</b><br><ul>`;
                        for (let i = 1; i < messages.length; i++) {
                            messageStrings += `<li>${getTranslationIfAnyAndEscape(messages[i].message)}</li>`;
                        }
                        messageStrings += `</ul></html>`;

                    }
                    else {
                        messageStrings = `<html lang="en"><ul>` + messages.reduce((acc, msg) => acc + `<br>${getTranslationIfAnyAndEscape(msg.message)}</li>`, '') + '</ul></html>';
                    }
                }

                msgMash = ({
                    title: this.localization.getString(messages[0].title) ?? this.localization.getString('Agito.Hilti.Profis3.Warning'),
                    message: `${messageStrings}`
                } as WarningMessageEntityC2C);
            }

            if (msgMash != null) {
                messagesToProcess.push(msgMash);
            }
            messagesToProcess = [...messagesToProcess];
        }

        return messagesToProcess;
    }

    private showPopUp(messagesToProcess: WarningMessageEntityC2C[], design: Design, dialogs: DialogsEntityC2C, messagesClosedDeferred: Deferred<void>) {
        const messageEntity = messagesToProcess.length > 0 ? messagesToProcess[0] : null;
        if (messageEntity != null) {
            const onClose = () => {
                dialogs.warningMessages = messagesToProcess.slice(1);
                this.checkDialogsC2C(design, dialogs, messagesClosedDeferred);
            };

            if (messageEntity.message != '' && messageEntity.title != '') {
                this.modalService.openConfirmChange({
                    id: 'warning-c2c',
                    title: messageEntity.title,
                    message: messageEntity.message,
                    confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.Ok'),
                    onConfirm: (modal) => {
                        design.trigger(DesignEvent.designChangesConfirmed);
                        modal.close();
                    }
                }).closed.then(onClose);
            }
        }
        else if (dialogs.showLShapeLimitationPopup) {
            const onClose = () => {
                dialogs.showLShapeLimitationPopup = false;
                this.checkDialogsC2C(design, dialogs, messagesClosedDeferred);
            };

            this.modalService.openLShapeLimitation().closed.then(onClose);
        }
        else if (dialogs.showConnectionApplicationChangePopup) {
            const onClose = () => {
                dialogs.showConnectionApplicationChangePopup = false;
                this.checkDialogsC2C(design, dialogs, messagesClosedDeferred);
            };
            this.modalService.openConfirmChange({
                id: 'openChangeConnectionApplicationModal',
                title: this.localization.getString('Agito.Hilti.Profis3.Warning'),
                message: this.localization.getString('Agito.Hilti.C2C.ApplicationTab.ApplicationSwitch.Message'),
                confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.Ok'),
                cancelButtonText: this.localization.getString('Agito.Hilti.Profis3.Cancel'),
                onConfirm: (modal) => {
                    modal.close();
                },
                onCancel: (modal) => {
                    this.undo(design);
                    // removing last (cancelled) state
                    design.states = design.states.slice(0, design.states.length - 1);
                    modal.close();
                }
            }).closed.then(onClose);
        }
        else if (dialogs.showFireLoadRecommendationPopup) {
            this.showFireLoadRecommendationPopUp(design, dialogs);
        }
        else if (dialogs.showDesignForYieldSelectionPopup) {
            this.showDesignForYieldSelectionPopup(design, dialogs, messagesClosedDeferred);
        }
        else if (dialogs.showGeometryChangePopup) {
            const onClose = () => {
                dialogs.showGeometryChangePopup = false;
                this.checkDialogsC2C(design, dialogs, messagesClosedDeferred);
            };
            this.modalService.openConfirmChange({
                id: 'openGeometryChangeModal',
                title: this.localization.getString('Agito.Hilti.Profis3.Warning'),
                message: this.localization.getString('Agito.Hilti.C2C.GeometryChange.Popup.Message'),
                confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.Ok'),
                cancelButtonText: this.localization.getString('Agito.Hilti.Profis3.Cancel'),
                onConfirm: (modal) => {
                    modal.close();
                },
                onCancel: (modal) => {
                    this.undo(design);
                    design.states = design.states.slice(0, design.states.length - 1);
                    modal.close();
                }
            }).closed.then(onClose);
        }
        else {
            design.confirmChangeInProgress = false;
            if (messagesClosedDeferred != null) {
                messagesClosedDeferred.resolve();
            }
        }
    }

    private showDesignForYieldSelectionPopup(design: Design, dialogs: DialogsEntityC2C, messagesClosedDeferred: Deferred<void>) {
        const onClose = () => {
            dialogs.showDesignForYieldSelectionPopup = false;
            this.checkDialogsC2C(design, dialogs, messagesClosedDeferred);
        };
        this.modalService.openConfirmChange({
            id: 'openDesignForYieldSelectionModal',
            title: this.localization.getString('Agito.Hilti.Profis3.Warning'),
            message: this.localization.getString('Agito.Hilti.C2C.Navigation.TabLoads.SeismicLoad.NoYieldDesignPopup.Message'),
            confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.Yes'),
            cancelButtonText: this.localization.getString('Agito.Hilti.Profis3.No'),
            onConfirm: (modal) => {
                modal.close();

                //Backward compatibility
                const designForYieldCodelist = design.designData.designCodeListsC2C[DesignCodeList.DesignForYield];
                const hasDesignForYieldCodelist = designForYieldCodelist && designForYieldCodelist.length > 0;

                design.addModelChange(UIProperty.Loads_C2C_DesignForYield, true, hasDesignForYieldCodelist ? DesignForYieldEnum.DesignForYield : true);
            },
            onCancel: (modal) => {
                modal.close();
            }
        }).closed.then(onClose);
    }

    private showFireLoadRecommendationPopUp(design: Design, dialogs: DialogsEntityC2C) {
        dialogs.showFireLoadRecommendationPopup = false;
        design.confirmChangeInProgress = false;

        const currentRebarFamilyId = design.model[design.globalMetaProperties.sourceMetaProperty] as number;
        const recommenededFireRebarFamilyId = design.model[PropertyMetaDataC2C.Product_C2C_RecommenededFireRebarFamilyId.id] as number;
        const showProductRecommendation = recommenededFireRebarFamilyId != null && (recommenededFireRebarFamilyId != currentRebarFamilyId);

        if (showProductRecommendation) {
            const temperature = this.unitService.formatUnitValue(this.unitService.convertUnitValueToUnit( new UnitValue(504, UnitType.C), design.unitTemperature), 0);

            const message = format(this.localization.getString(`Agito.Hilti.C2C.RecommendDifferentProduct.Message.${design.isPirEu ? 'PIREU' : 'HNA'}`), temperature);

            this.modalService.openRecommendDifferentProductC2C({
                titleKey: 'Agito.Hilti.C2C.RecommendDifferentProduct.Title',
                message: message,
                imageKey: 'fp700',
                recommendedRebarFamilyId: recommenededFireRebarFamilyId,
                command: 'OpenRecommendFireFastener'
            });
        }
    }
}
