import cloneDeep from 'lodash-es/cloneDeep';

import { HttpErrorResponse, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    detailedDisplayDesignToProjectDesignC2C, IDetailedDisplayDesign as IDetailedDisplayDesignC2C
} from '@profis-engineering/pe-ui-c2c/entities/display-design';
import {
    ProjectDesignEntityC2C
} from '@profis-engineering/pe-ui-c2c/generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import { Design as DesignCommon } from '@profis-engineering/pe-ui-common/entities/design';
import { ProjectType, ProjectUser } from '@profis-engineering/pe-ui-common/entities/project';
import {
    DocumentGetDocument, DocumentGetProject, DocumentGetResponse
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.Entities.Documents';
import {
    ArchiveProjectResponseModel
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.Entities.Projects';
import { Deferred } from '@profis-engineering/pe-ui-common/helpers/deferred';
import { formatKeyValue } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { ApiOptions } from '@profis-engineering/pe-ui-common/services/api.common';
import {
    CantArchiveDesignBecauseLockedByOtherUser, CantArchiveProjectsBecauseDocumentInUse,
    CantDeleteProjectsBecauseDocumentInUse, CantOpenDesignBecauseLockedByOtherUser,
    CantPerformActionReasonProblematicDocumentInformation, CantRestoreProjectsBecauseDocumentInUse,
    DesignExternalMetaData as DesignExternalMetaDataCommon, DocumentAccessMode, DocumentServiceBase,
    IDefferedData as IDefferedDataCommon, IDesignListItem as IDesignListItemCommon,
    IDocumentServiceDesign
} from '@profis-engineering/pe-ui-common/services/document.common';
import {
    detailedDisplayDesignToProjectDesign, IDetailedDisplayDesign as IDetailedDisplayDesignPe
} from '@profis-engineering/pe-ui-shared/entities/display-design';
import {
    ProjectDesignBaseEntity
} from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign';
import {
    DesignType
} from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';

import { environment } from '../../environments/environment';
import { ModalDialogType } from '../components/add-edit-design/add-edit-design-models';
import { Design, IBaseDesign } from '../entities/design';
import { DesignExternalMetaData } from '../entities/design-external-meta-data';
import { IProjectArchive, Project } from '../entities/project';
import {
    IDefferedData, IDefferedDataC2C, IDefferedImageData, IDefferedRequests
} from '../helpers/document-helper';
import { urlPath } from '../module-constants';
import { ApiService } from './api.service';
import { BrowserService } from './browser.service';
import { DocumentService, IDesignListItem } from './document.service';
import { ErrorHandlerService } from './error-handler.service';
import { GuidService } from './guid.service';
import { LocalizationService } from './localization.service';
import { LoggerService } from './logger.service';
import { ModalService } from './modal.service';
import { RoutingService } from './routing.service';
import { UserService } from './user.service';
import { BaseDesign } from '@profis-engineering/pe-ui-decking/src/decking/entities/decking-design/base-design';

interface IGetDocumentResponse {
    documentid: string;
    project: IGetDocumentResponseProject;
    name: string;
    lastchange: number;
    created: number;
    locked: boolean;
    lockedby: string;
    metadata: { key: string, value: string }[];
    owner: boolean;
}

interface IGetDocumentResponseProject {
    projectid: string;
    parentProjectId: string;
    name: string;
}

class DesingSessionPair {
    /*
    * design Id
    */
    public designId: string;

    /*
    * Session id of the desing.
    */
    public sessionId: string;

    /*
    * This session was explicitly cancelled.
    */
    public isCancelled = false;
}

@Injectable({
    providedIn: 'root'
})
export class DocumentServiceWebV1 extends DocumentService implements DocumentServiceBase {

    private defferedRequests: IDefferedRequests<any, any> = {};
    private defferedImageUpload: IDefferedRequests<any, any> = {};

    private internalRawProject: DocumentGetResponse;
    private documentThumbnails: Record<string, string> = {};
    private documentThumbnailPromise: Record<string, Promise<unknown>> = {};

    // holds instances of all open sessions (should be just one really).
    private designSessionMapping: DesingSessionPair[] = [];

    constructor(
        loggerService: LoggerService,
        private routingService: RoutingService,
        private apiService: ApiService,
        private browserService: BrowserService,
        private modalService: ModalService,
        private localizationService: LocalizationService,
        private guidService: GuidService,
        private userService: UserService,
        private ErrorHandlerService: ErrorHandlerService
    ) {
        super(
            'DocumentService',
            loggerService
        );
    }

    /**
     * Creates a unique file name from a given name
     */
    public createUniqueName(oldName: string, usedNames: string[]): string {
        let newName = oldName;
        let index = 0;

        while (usedNames.find((item) => item == newName) != null) {
            index++;
            newName = oldName + ' (' + index + ')';
        }

        return newName;
    }

    public async updateDesignProject(designId: string, projectId: string): Promise<void> {
        const design = this.findDesignById(designId);
        if (design == null) {
            throw new Error('Invalid design id.');
        }

        const project = this.findProjectById(projectId);
        if (project == null) {
            throw new Error('Invalid project id.');
        }

        return this.openDesignExclusivePe(design)
            .then((projectDesign) => {
                projectDesign.ProjectName = project.name;

                return this.updateDesignWithNewContent(design.id, project.id, design.designName, projectDesign, null, null, true, false, DocumentAccessMode.Update);
            })
            .then(() => {
                this.updateProject(designId, projectId);
            });
    }

    /**
     * Deletes (permanently) the project from internal store and call external document service.
     * @projectId project
     */
    public async removeArchivedProject(projectId: string): Promise<void> {
        const pToDeleteCandidate = Object.values(this._projectsArchive).find((item) => item.id == projectId);
        if (pToDeleteCandidate == null) {
            throw new Error('Project with ID does not exist.');
        }

        try {
            await this.removeExistingArchivedProject(projectId);

            // remove from project flat list of projects
            this._projectsArchive = this._projectsArchive.filter((x) => x.id != projectId);
        }
        catch (ex) {
            // catch exception code that happens when project cannot be deleted because a file in the project is locked, and fetch additional details from the document service to display to the user whitch document is locked and by whom.
            if (ex instanceof CantDeleteProjectsBecauseDocumentInUse) {
                const lockedFiles = await this.readLockedDesignsOfProject(projectId);
                ex.documentsInQuestion = lockedFiles;
            }

            throw ex;
        }
    }

    /**
     * Deletes (permanently) the project from internal store and call external document service.
     * @projectId project
     */
    public async removeProject(projectId: string): Promise<void> {
        const pToDeleteCandidate = this._projectsFlat[projectId];

        if (pToDeleteCandidate == null) {
            throw new Error('Project with ID does not exist.');
        }

        try {
            await this.removeExistingProject(projectId);

            // remove project from list of projects
            if (pToDeleteCandidate.parentId != null) {
                delete Object.values(this._projects).find((x) => x.id == pToDeleteCandidate.parentId).subProjects[projectId];
            }
            else {
                delete this._projects[projectId];
            }

            delete this._projectsFlat[projectId];
            delete this.internalRawProject.projects[projectId];
        }
        catch (ex) {
            // catch exception code that happens when project cannot be deleted because a file in the project is locked, and fetch additional details from the document service to display to the user whitch document is locked and by whom.
            if (ex instanceof CantDeleteProjectsBecauseDocumentInUse) {
                const lockedFiles = await this.readLockedDesignsOfProject(projectId);
                ex.documentsInQuestion = lockedFiles;
            }

            throw ex;
        }
    }

    /**
     * Convert (permanently) the project to company (shared) project and call external document service.
     * @projectId project
     */
    public async convertProject(projectId: string): Promise<void> {
        const pToConvertCandidate = this._projectsFlat[projectId];
        if (pToConvertCandidate == null) {
            throw new Error('Project with ID does not exist.');
        }

        await this.convertExistingProject(projectId);

        // convert project from list of projects
        if (pToConvertCandidate.parentId != null) {
            Object.values(Object.values(this._projects).find((x) => x.id == pToConvertCandidate.parentId).subProjects).find((item) => item.id == projectId).isCompanyProject = true;
        }
        else if (pToConvertCandidate.subProjects != null && Object.values(pToConvertCandidate.subProjects).length > 0) {
            Object.values(this._projects).find((x) => x.id == pToConvertCandidate.id).subProjects[projectId].isCompanyProject = true;
            Object.values(this._projects).find((x) => x.id == pToConvertCandidate.id).isCompanyProject = true;
        }
        else {
            Object.values(this._projects).find((x) => x.id == pToConvertCandidate.id).isCompanyProject = true;
        }
    }

    public async getDocumentThumbnails(documentIds: string[]): Promise<Record<string, string>> {
        const url = `${environment.documentWebServiceUrl}documentcontent/GetMultipleDocumentThumbnail`;

        if (documentIds == null || documentIds.length == 0) {
            return this.documentThumbnails;
        }

        const missingImages: string[] = [];

        for (const id of documentIds) {
            if (this.documentThumbnails[id] == null && this.documentThumbnailPromise[id] == null) {
                missingImages.push(id);
            }
        }

        if (missingImages.length > 0) {
            const promise = this.apiService.request<unknown>(new HttpRequest('POST', url, missingImages), this.getApiOptions());
            for (const id of missingImages) {
                this.documentThumbnailPromise[id] = promise;
            }

            try {
                const response = await promise;
                const data: any = response.body;

                this.logServiceResponse('getDocumentThumbnails', data);

                for (const item of data) {
                    this.documentThumbnails[item.documentId] =
                        item.thumbnailImageContent != null && item.thumbnailImageContent != '' ? item.thumbnailImageContent : '';
                }

                return this.documentThumbnails;
            }
            finally {
                for (const id of missingImages) {
                    this.documentThumbnailPromise[id] = null;
                }
            }
        }
        else {
            return this.documentThumbnails;
        }
    }

    /**
     * Restores the project from internal store and call external document service.
     * @projectId project
     */
    public async restoreProject(projectId: string, projectLevelType: ModalDialogType): Promise<string[]> {
        const pToRestoreCandidate = Object.values(this._projectsArchive).find((item) => item.id == projectId);
        if (pToRestoreCandidate == null) {
            throw new Error('Project with ID does not exist.');
        }

        try {
            const x = await this.restoreExistingProject(projectId, projectLevelType);

            // Add restored project and it's documents to the project menu
            if (x != null && x.projects != null && x.documents != null) {
                for (const key in x.projects) {
                    this.internalRawProject.projects[key] = x.projects[key];
                }
                for (const key in x.documents) {
                    this.internalRawProject.documents[key] = x.documents[key];
                }

                this.getInternal(this.internalRawProject);
            }

            // Remove project and all sub-projects from archive list
            this._projectsArchive = this._projectsArchive.filter((x) => x.id != projectId && x.parentId != projectId);
            return Promise.resolve([]);
        }
        catch (ex) {
            // catch exception code that happens when project cannot be deleted because a file in the project is locked, and fetch additional details from the document service to display to the user whitch document is locked and by whom.
            if (ex instanceof CantRestoreProjectsBecauseDocumentInUse) {
                const lockedFiles = await this.readLockedDesignsOfProject(projectId);
                ex.documentsInQuestion = lockedFiles;
            }

            throw ex;
        }
    }

    public async restoreExistingDesign(documentId: string): Promise<void>{
        throw new Error('Method not implemented');
    }

    public async removeArchivedDesign(documentIds: string[]): Promise<void> {
        throw new Error('Method not implemented');
    }

    public initialize(data: DocumentGetResponse, dataArchive: ArchiveProjectResponseModel[]): void {
        this.internalRawProject = { projects: {}, documents: {} };
        this.getInternal(data);
        this.getArchiveInternal(dataArchive);
    }

    /*
    * Saves a new or existing project. Returns promise when the new id of the project is returned.
    */
    public async saveProject(project: Project, projectLevelType: ModalDialogType): Promise<void> {
        if (project.name.indexOf('#$') == 0) {
            this.modalService.openAlertWarning(
                this.localizationService.getString('Agito.Hilti.Profis3.DocumentService.Alerts.IllegalChar.Title'),
                this.localizationService.getString('Agito.Hilti.Profis3.DocumentService.Alerts.IllegalChar.Message')
            );

            throw new Error('invalid-name');
        }
        else {
            if (project.name.length > 250) {
                throw new Error('Max length for project name is 250 characters!');
            }

            if (this._projectsFlat[project.id] != null) {
                // call edit project on document service
                await this.updateExistingProject(project, projectLevelType);

                const internalProject = this.findProjectById(project.id);
                internalProject.name = project.name;
            }
            else {
                // call add new project on document service
                await this.addNewProject(project, projectLevelType);

                this.internalRawProject.projects[project.id] = {
                    id: project.id,
                    name: project.name,
                    dateChanged: new Date(),
                    dateCreated: new Date(),
                    parentId: project.parentId,
                    owner: project.owner,
                    isCompanyProject: project.isCompanyProject,
                    expanded: project.expanded,
                    readOnly: project.readOnly,
                    expandedState: project.expandedState
                };
                this._projectsFlat[project.id] = project;
                if (project.parentId != null) {
                    this._projects[project.parentId].subProjects[project.id] = project;
                }
                else {
                    this._projects[project.id] = project;
                }
            }
        }
    }

    /*
     * Toggles expanded state for project.
     */
    public async toggleExpandProject(project: Project): Promise<void> {
        if (this._projectsFlat[project.id] == null) {
            throw new Error('Project with ID does not exist.');
        }

        await this.toggleExpandExistingProject(project);

        const internalProject = this.findProjectById(project.id);
        internalProject.name = project.name;
    }

    /**
     * Remove the project from internal store and call external document service.
     * @projectId project
     */
    public async archiveProject(projectId: string): Promise<void> {
        const pToDeleteCandidate = this.findProjectById(projectId);

        if (pToDeleteCandidate == null) {
            throw new Error('Project with ID does not exist.');
        }
        if (pToDeleteCandidate.projectType != ProjectType.common) {
            throw new Error('Only common projects can be deleted.');
        }

        // delete parent
        try {
            const resp: any = await this.archiveExistingProject(projectId);
            const children: string[] = [];
            for (const index in resp) {
                const x = resp[index];
                const projId = x.projectid;
                this._projectsArchive.push({
                    archived: x.archived,
                    created: x.created,
                    designs: x.designs,
                    members: x.members,
                    id: x.projectid,
                    parentId: x.parentprojectid,
                    name: x.name
                });

                this.deleteProjectFunc(projId);
            }
        }
        catch (ex) {
            // catch exception code that happens when project cannot be deleted because a file in the project is locked, and fetch additional details from the document service to display to the user whitch document is locked and by whom.
            if (ex instanceof CantArchiveProjectsBecauseDocumentInUse) {
                const lockedFiles = await this.readLockedDesignsOfProject(projectId);
                ex.documentsInQuestion = lockedFiles;

                throw ex;
            }

            throw ex;
        }
    }

    public async openDesignExclusive<TProjectDesign>(design: IBaseDesign, adjustContent?: (content: TProjectDesign, designName: string, projectName: string) => TProjectDesign): Promise<TProjectDesign> {
        try {
            return await this.getDesignContentExclusive<TProjectDesign>(design.id, this.openNewSessionForDesign(design.id), adjustContent);
        }
        catch (response) {
            await this.catchDesignExclusive(response, design);

            throw response;
        }
    }


    /**
     * Takes the desing id file and returns JSON object, representing it.
     * In some cases object deriving from CantOpenDesignReason is returned.
     * @param design - Design
     */
    public async openDesignExclusivePe(design: IBaseDesign): Promise<ProjectDesignBaseEntity> {
        return this.openDesignExclusive<ProjectDesignBaseEntity>(design, (content, designName, projectName) => {
            content.DesignName = designName;
            content.ProjectName = projectName;
            return content;
        });
    }

    public async openDesignExclusiveC2C(design: IBaseDesign): Promise<ProjectDesignEntityC2C> {
        return this.openDesignExclusive<ProjectDesignEntityC2C>(design, (content, designName, projectName) => {
            content.designName = designName;
            content.projectName = projectName;
            return content;
        });
    }

    public async openDesignExclusiveDecking(design: IBaseDesign): Promise<ProjectDesignBaseEntity> {
        return this.openDesignExclusive<ProjectDesignBaseEntity>(design);
    }

    public async catchDesignExclusive(response: unknown, design: IBaseDesign): Promise<never> {
        // handle document locked error
        if (response instanceof HttpErrorResponse && response.status == 423) {
            // check the detail of the conflict
            const d = await this.getDesingBasicDocumentInformation(design.id);
            const ex = new CantOpenDesignBecauseLockedByOtherUser();
            ex.username = d.lockedUser;

            throw ex;
        }

        throw response;
    }

    public async openDesign(design: IBaseDesign): Promise<ProjectDesignBaseEntity> {
        const response = this.getDesignContent(design.id);
        this.openNewSessionForDesign(design.id);

        return response;
    }

    public async openDesignC2C(design: IBaseDesign): Promise<ProjectDesignEntityC2C> {
        const response = await this.getDesignContentC2C(design.id);
        this.openNewSessionForDesign(design.id);

        return response;
    }

    /**
     * Add a completely new design
     * @param projectId - To what project are we adding this design.
     * @param design - The new design entitiy.
     */
    public async addDesignCommon(projectId: string, design: DesignCommon, canGenerateUniqueName: boolean, ignoreConflict: boolean): Promise<IDesignListItemCommon> {
        // validate
        let project = this.findProjectById(projectId);
        if (project == null) {
            throw new Error('Invalid project ID.');
        }

        const designName = design.designName;
        if (designName.length > 250) {
            throw new Error('Max length for design name file is 250 !');
        }

        // make new meta-data entity
        const metaData: DesignExternalMetaDataCommon = design.metaData;
        // conversion
        const designAsBase64Endoded = this.browserService.toBase64(design.getDocumentDesign ? design.getDocumentDesign() : design.projectDesign);
        const document = await this.addNewFile(projectId, designName, designAsBase64Endoded, metaData, canGenerateUniqueName, ignoreConflict);   // call service to add new file into database

        // TODO: fix enter session key create new design request
        this.openNewSessionForDesign(document.documentid);

        // associate design to project
        project = this.findProjectById(projectId);
        if (project == null) {
            throw new Error('Invalid project ID.');
        }

        // add returned data to internal memory and return new design as promised
        const now: Date = new Date();
        const newDesign: IDesignListItemCommon = {
            id: document.documentid,
            changeDate: now,
            createDate: now,
            designName: document.name,
            locked: false,
            lockedUser: null,
            projectId,
            projectName: project.name,
            metaData,
            owner: true
        };
        this.addUpdateDesignInProject(newDesign);
        this.setDesignPropertiesCommon(design, newDesign);

        return newDesign;
    }

    public async addDesign(projectId: string, design: Design, canGenerateUniqueName: boolean, ignoreConflict: boolean, useDeckingDesign: boolean, deckingProject: BaseDesign): Promise<IDesignListItem> {

        // validate
        let project = this.findProjectById(projectId);
        if (project == null) {
            throw new Error('Invalid project ID.');
        }

        const designName = !useDeckingDesign ? design.designName : deckingProject.name;
        if (designName.length > 250) {
            throw new Error('Max length for design name file is 250 !');
        }

        // make new meta-data entity
        const metaData: DesignExternalMetaData = !useDeckingDesign ? DesignExternalMetaData.getMetaDataFromDesign(design)
            : DesignExternalMetaData.getMetaDataFromDeckingDesign(deckingProject);

        // conversion
        const designAsBase64Endoded = useDeckingDesign ? this.browserService.toBase64(deckingProject) : this.GetProjectDesignContent(design);
        const document = await this.addNewFile(projectId, designName, designAsBase64Endoded, metaData, canGenerateUniqueName, ignoreConflict);   // call service to add new file into database

        // TODO: fix enter session key create new design request
        this.openNewSessionForDesign(document.documentid);

        // associate design to project
        project = this.findProjectById(projectId);
        if (project == null) {
            throw new Error('Invalid project ID.');
        }

        // add returned data to internal memory and return new design as promised
        const now: Date = new Date();
        const newDesing: IDesignListItem = {
            id: document.documentid,
            changeDate: now,
            createDate: now,
            designName: document.name,
            locked: false,
            lockedUser: null,
            projectId,
            projectName: '',
            metaData,
            integrationDocument: (!useDeckingDesign) ? design.integrationDocument : null,
            owner: true
        };

        newDesing.projectName = project.name;

        this.addUpdateDesignInProject(newDesing);

        if (!useDeckingDesign) {
            if (design.isC2C) {
                this.setDesingPropertiesFromServiceRecordToWholeDesingEntitiyC2C(design, newDesing);
            }
            else {
                this.setDesingPropertiesFromServiceRecordToWholeDesingEntitiy(design, newDesing);
            }
        }
        return newDesing;
    }

    /**
     * Updates a design with the new project design.
     * @param design - The design entity.
     */
    public async updateDesign(design: Design): Promise<void> {
        const metaData: DesignExternalMetaData = DesignExternalMetaData.getMetaDataFromDesign(design);

        if (design) {
            if (design.isC2C) {
                await this.updateDesignWithNewContentC2C(design.id, design.projectId, design.designName, design.designData.projectDesignC2C, metaData, null, false, false, DocumentAccessMode.Update);
            }
            else {
                await this.updateDesignWithNewContent(design.id, design.projectId, design.designName, design.designData.projectDesign, metaData, null, false, false, DocumentAccessMode.Update);
            }
        }
    }

    /**
     * Updates a design with the new project design. Instead of using the ProjectDesignBaseEntity in design file use the ProjectDesignBaseEntity in the override parameter.
     * @param design - The design entity.
     */
    public async updateDesignWithNewContentCommon<TDisplayDesign>(
        design: DesignCommon,
        displayDesign: TDisplayDesign,
        unlock = false,
        exclusiveLock = false,
        documentAccessMode = DocumentAccessMode.Update): Promise<void> {

        // If there are no pending requests start normally
        if (this.defferedRequests[design.id] == null || (this.defferedRequests[design.id] != null && this.defferedRequests[design.id].running == null)) {
            this.defferedRequests[design.id] = {
                running: this.putSmallDesingChangePromiseCommon({
                    designId: design.id,
                    projectId: design.projectId,
                    designName: design.designName,
                    contentOverride: design.getDocumentDesign ? design.getDocumentDesign() : design.projectDesign,
                    metadataOverride: design.metaData,
                    unlock,
                    displayDesign,
                    exclusiveLock,
                    documentAccessMode
                }),
                defferedData: null,
                deffered: null
            };

            await this.defferedRequests[design.id].running;
        }
        else {
            // Else create deffer object save request data and return deffered promise
            if (this.defferedRequests[design.id].deffered == null) {
                this.defferedRequests[design.id].deffered = new Deferred();
            }

            this.defferedRequests[design.id].defferedData = {
                designId: design.id,
                projectId: design.projectId,
                designName: design.designName,
                contentOverride: design.getDocumentDesign ? design.getDocumentDesign() : design.projectDesign,
                metadataOverride: design.metaData,
                unlock,
                displayDesign,
                exclusiveLock,
                documentAccessMode
            };

            await this.defferedRequests[design.id].deffered.promise;
        }
    }

    public async updateDesignWithNewContent(
        designId: string,
        projectId: string,
        designName: string,
        contentOverride: ProjectDesignBaseEntity,
        metadataOverride: DesignExternalMetaData,
        displayDesign: IDetailedDisplayDesignPe,
        unlock = false,
        exclusiveLock = false,
        documentAccessMode = DocumentAccessMode.Update
    ): Promise<void> {
        // If there are no pending requests start normally
        if (this.defferedRequests[designId] == null || (this.defferedRequests[designId] != null && this.defferedRequests[designId].running == null)) {
            this.defferedRequests[designId] = {
                running: this.putSmallDesingChangePromise({
                    designId,
                    projectId,
                    designName,
                    contentOverride,
                    metadataOverride,
                    unlock,
                    displayDesign,
                    exclusiveLock,
                    documentAccessMode
                }),
                defferedData: null,
                deffered: null
            };

            await this.defferedRequests[designId].running;
        }
        else {
            // Else create deffer object save request data and return deffered promise
            if (this.defferedRequests[designId].deffered == null) {
                this.defferedRequests[designId].deffered = new Deferred();
            }

            this.defferedRequests[designId].defferedData = {
                designId,
                projectId,
                designName,
                contentOverride,
                metadataOverride,
                unlock,
                displayDesign,
                exclusiveLock,
                documentAccessMode
            };

            await this.defferedRequests[designId].deffered.promise;
        }
    }

    public async updateDesignWithNewContentC2C(
        designId: string,
        projectId: string,
        designName: string,
        contentOverride: ProjectDesignEntityC2C,
        metadataOverride: DesignExternalMetaData,
        displayDesign: IDetailedDisplayDesignC2C,
        unlock = false,
        exclusiveLock = false,
        documentAccessMode = DocumentAccessMode.Update
    ): Promise<void> {
        // If there are no pending requests start normally
        if (this.defferedRequests[designId] == null || (this.defferedRequests[designId] != null && this.defferedRequests[designId].running == null)) {
            this.defferedRequests[designId] = {
                running: this.putSmallDesingChangePromiseC2C({
                    designId,
                    projectId,
                    designName,
                    contentOverride,
                    metadataOverride,
                    unlock,
                    displayDesign,
                    exclusiveLock,
                    documentAccessMode
                }),
                defferedData: null,
                deffered: null
            };

            await this.defferedRequests[designId].running;
        }
        else {
            // Else create deffer object save request data and return deffered promise
            if (this.defferedRequests[designId].deffered == null) {
                this.defferedRequests[designId].deffered = new Deferred();
            }

            this.defferedRequests[designId].defferedData = {
                designId,
                projectId,
                designName,
                contentOverride,
                metadataOverride,
                unlock,
                displayDesign,
                exclusiveLock,
                documentAccessMode
            };

            await this.defferedRequests[designId].deffered.promise;
        }
    }

    public async smallDesignChangeCommon<TProjectDesign, TDetailedDisplayDesign>(
        internalDesign: IDesignListItemCommon,
        projectDesign: TProjectDesign,
        data: IDefferedDataCommon<TProjectDesign, TDetailedDisplayDesign>,
        metaData: DesignExternalMetaDataCommon
    ): Promise<void> {
        try {
            const designAsBase64Endoded = this.browserService.toBase64(projectDesign);

            await this.putSmallDesingChange(data.designId, data.projectId, data.designName, designAsBase64Endoded, this.getSessionKeyForDesign(data.designId), metaData, data.unlock, data.exclusiveLock, data.documentAccessMode);
            if (metaData != null) {
                // update meta-data in proxy store
                internalDesign.metaData = metaData;
            }

            internalDesign.designName = data.designName;

            const now: Date = new Date();
            const nowUtc: Date = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds()));
            internalDesign.changeDate = nowUtc;

            this.updateProject(data.designId, data.projectId);
        }
        finally {
            this.defferedRequests[data.designId].running = null;

            // start new request for deffered and save reference into runningDefferd to resolve it later
            // clear defferd so new requests can be added
            if (this.defferedRequests[data.designId].deffered != null) {
                const runningDeffered = this.defferedRequests[data.designId].deffered;
                this.defferedRequests[data.designId].running = runningDeffered.promise;

                this.putSmallDesingChangePromiseCommon(this.defferedRequests[data.designId].defferedData as IDefferedData)
                    .then(() => runningDeffered.resolve())
                    .catch((err) => runningDeffered.reject(err));

                this.defferedRequests[data.designId].deffered = null;
                this.defferedRequests[data.designId].defferedData = null;
            }
        }
    }

    public async smallDesignChange(
        internalDesign: IDesignListItem,
        projectDesign: ProjectDesignBaseEntity | ProjectDesignEntityC2C,
        data: IDefferedData | IDefferedDataC2C,
        metaData: DesignExternalMetaData
    ): Promise<void> {
        try {
            const designAsBase64Endoded = this.browserService.toBase64(
                "ProjectDesignType" in projectDesign && projectDesign.ProjectDesignType === DesignType.DiaphragmDesign ? projectDesign.DesignName : projectDesign
            );

            await this.putSmallDesingChange(data.designId, data.projectId, data.designName, designAsBase64Endoded, this.getSessionKeyForDesign(data.designId), metaData, data.unlock, data.exclusiveLock, data.documentAccessMode);
            if (metaData != null) {
                // update meta-data in proxy store
                internalDesign.metaData.productName = metaData.productName;
                internalDesign.metaData.designType = metaData.designType;
                internalDesign.metaData.name = metaData.name;
                internalDesign.metaData.region = metaData.region;
                internalDesign.metaData.standard = metaData.standard;
                internalDesign.metaData.designMethod = metaData.designMethod;
                internalDesign.metaData.approvalNumber = metaData.approvalNumber;
                internalDesign.metaData.calculationType = metaData.calculationType;
                internalDesign.metaData.handrailConnectionType = metaData.handrailConnectionType;
            }

            internalDesign.designName = data.designName;

            const now: Date = new Date();
            const nowUtc: Date = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds()));
            internalDesign.changeDate = nowUtc;

            this.updateProject(data.designId, data.projectId);
        }
        finally {
            this.defferedRequests[data.designId].running = null;

            // start new request for deffered and save reference into runningDefferd to resolve it later
            // clear defferd so new requests can be added
            if (this.defferedRequests[data.designId].deffered != null) {
                const runningDeffered = this.defferedRequests[data.designId].deffered;
                this.defferedRequests[data.designId].running = runningDeffered.promise;

                this.putSmallDesingChangePromise(this.defferedRequests[data.designId].defferedData as IDefferedData)
                    .then(() => runningDeffered.resolve())
                    .catch((err) => runningDeffered.reject(err));

                this.defferedRequests[data.designId].deffered = null;
                this.defferedRequests[data.designId].defferedData = null;
            }
        }
    }

    /**
     * Update design thumbnail image.
     * @param designId Design id
     * @param thumbnailContent Thumbnail image
     * @param respond If server should send response
     */
    public async updateDesignThumbnailImage(designId: string, thumbnailContent: string, respond: boolean): Promise<void> {
        // If there are no pending requests start normally
        if (this.defferedImageUpload[designId] == null || (this.defferedImageUpload[designId] != null && this.defferedImageUpload[designId].running == null)) {
            this.defferedImageUpload[designId] = {
                running: this.updateDesignThumbnailImagePromise({ designId, thumbnailContent, respond } as IDefferedImageData),
                defferedData: null,
                deffered: null
            };

            await this.defferedImageUpload[designId].running;
        }
        else {
            // Else create deffer object save request data and return deffered promise
            if (this.defferedImageUpload[designId].deffered == null) {
                this.defferedImageUpload[designId].deffered = new Deferred();
            }

            this.defferedImageUpload[designId].defferedData = {
                designId,
                thumbnailContent,
                respond,
                isTemplate: false
            };

            await this.defferedImageUpload[designId].deffered.promise;
        }
    }

    /**
     * Remove the design from internal store and call external document service.
     * @designId design
     */
    public async archiveDesign(designId: string): Promise<void> {

        const dToDeleteCandidate = this.findDesignById(designId);
        if (dToDeleteCandidate == null) {
            throw new Error('Design with ID does not exist.');
        }

        // delete design
        try {
            await this.archiveExistingDesign(designId);
            this.deleteDesignFunc(designId);
        }
        catch (ex) {
            // catch exception code that happens when design cannot be deleted because a file is locked, and fetch additional details from the document service to display to the user whitch document is locked and by whom.
            if (ex instanceof CantArchiveDesignBecauseLockedByOtherUser) {
                const d = await this.getDesingBasicDocumentInformation(designId);
                ex.username = d.lockedUser;
            }

            throw ex;
        }
    }

    /**
     * Deletes (permanently) the designs from internal store and call external document service.
     * @designIds list of designs
     */
    public async removeDesigns(designIds: string[]): Promise<void> {

        for (const designId of designIds) {
            const dToDeleteCandidate = this.findDesignById(designId);
            if (dToDeleteCandidate == null) {
                throw new Error(`Design with ID ${designId} does not exist.`);
            }
        }

        // delete designs
        await this.removeExistingDesigns(designIds);

        for (const designId of designIds) {
            this.deleteDesignFunc(designId);
        }
    }

    /**
     * Upload design printscreen image when a design report is ready for export
     * @param designId - desing id
     * @param imageContent - the design image content in base64 encoded xml format
     * @param base64ThumbnailXmlContent - the design thumbnail image content in base64 encoded xml format
     */
    public uploadDesignImage(designId: string, imageContent: string, thumbnailContent: string): Promise<void> {
        return this.uploadDesignImageInternal(designId, imageContent, thumbnailContent);
    }

    /**
     * Publishes the design.
     * @param id - The design id.
     */
    public async publish(id: string): Promise<void> {
        // TODO: should check session here

        if (!this.isCanceledSessionForDesign(id)) {
            await this.postUnlockDocument(id);
            this.closeSessionForDesing(id);
        }
    }

    /**
     * Returns users on the project
     * @param projectId - Project id
     */
    public async getUsersOnProjectById(projectId: string): Promise<ProjectUser[]> {
        const response = await this.getUsersOnProjectByIdInternal(projectId);

        return response.map((item) => {
            item.projectid = projectId;
            item.dateadded = item.dateadded != null ? new Date(item.dateadded as any) : item.dateadded;
            return item;
        });
    }

    /**
     * Adds user to the project
     * @param data - Project user data
     */
    public addUsersOnProjectById(data: ProjectUser): Promise<void> {
        return this.addUsersOnProjectByIdInternal(data);
    }

    /**
     * Removes user from the project
     * @param data - Project user data
     */
    public removeUsersOnProjectById(data: ProjectUser): Promise<void> {
        return this.removeUsersOnProjectByIdInternal(data);
    }

    public userHasAccessToProject(projectId: string): Promise<boolean> {
        return this.userHasAccessToProjectInternal(projectId);
    }

    public deleteProjectLocal(projectId: string): void {
        Object.values(this.internalRawProject.projects).forEach((proj) => {
            if (proj.parentId == projectId) {
                this.deleteProjectFunc(proj.id);
            }
        });

        this.deleteProjectFunc(projectId);
    }

    /**
     * Find design by name.
     * @param designName - Design name
     * @param projectId - Project id
     */
    public findDesignByName(designName: string, projectId: string, forceFromServer?: boolean): Promise<IDesignListItemCommon> {
        return this.findDesignByNameInternal(designName, projectId, forceFromServer);
    }

    private getContentFromResponse<TProjectDesign>(response: HttpResponse<unknown>, adjustContent?: ((conten: TProjectDesign, designName: string, projectName: string) => TProjectDesign) | undefined): TProjectDesign {
        this.logServiceResponse('getDesignContentExclusive', response);

        const body = (response.body as any);
        const projectDesignAsJsonString = this.browserService.fromBase64(body.filecontent);

        const project = this.findProjectById(body.projectid);

        let data: TProjectDesign;
        try {
            data = JSON.parse(projectDesignAsJsonString);
        }
        catch (error) {
            throw new Error('Invalid design on document service.');
        }
        return adjustContent?.(data, body.documentname, project.name) ?? data;
    }

    public async getDeckingDesignContent(designId: string, sessionId: string, documentId: string, isLock: boolean): Promise<any> {
        const url = `${environment.documentWebServiceUrl}documentContent/GetExclusive`;
        const rData = { documentid: designId, islock: isLock, key: sessionId };

        try {
            return await this.getDesignContentExclusiveInternal(url, rData);
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'get design content exclusive');
            // handle document locked error
            if (response instanceof HttpErrorResponse && response.status == 423) {
                // check the detail of the conflict
                const basicDocumentInfo = await this.getDesingBasicDocumentInformation(documentId);
                const alertTitle = 'Agito.Hilti.Profis3.ProjectAndDesing.Alerts.CannotOpenInUseBy.Title';
                const alertMessage = 'Agito.Hilti.Profis3.ProjectAndDesing.Alerts.CannotOpenInUseBy.Description';

                this.modalService.openAlertWarning(
                    this.localizationService.getString(alertTitle),
                    formatKeyValue(this.localizationService.getString(alertMessage), { user: basicDocumentInfo.lockedUser })
                );
            }

            throw response;
        }
    }

    public openNewSessionForDesign(designId: string): string {
        // just return new design if already exists, where we have certain methods where it is not certain if session is allready open
        if (this.designSessionMapping.some(m => m.designId == designId)) {
            return this.getSessionKeyForDesign(designId);
        }

        // create new session
        const key = this.guidService.new();

        this.designSessionMapping.push({ designId, sessionId: key, isCancelled: false });

        return key;
    }

    public getSessionKeyForDesign(designId: string): string {
        const mapping = this.designSessionMapping.find(x => x.designId == designId);
        if (mapping == null) {
            throw new Error('Session is not opened for the specified desing.');
        }

        return mapping.sessionId;
    }

    public getNumberOfDocumentsOwnedByUser(): number {
        return Object.values(this._desingsFlat).filter(x => x.owner).length;
    }

    public async updateDocumentDesignContent(document: IDesignListItem, base64Content: string,  unlock: boolean, exclusiveLock: boolean, documentAccessMode: DocumentAccessMode): Promise<void> {
        const url = `${environment.documentWebServiceUrl}documentcontent`;

        const rData = {
            key: this.getSessionKeyForDesign(document.id),
            documentid: document.id,
            projectid: document.projectId,
            name: document.designName,
            filecontent: base64Content,
            unlock,
            exclusiveLock,
            documentAccessMode
        };

        const request = new HttpRequest('PUT', url, rData, {
            responseType: 'json'
        });

        try {
            await this.apiService.request(request);
        }
        catch (response) {
            console.error(response);
        }
    }

    private mapArchiveProjectResponseEntityToIProjectArchive(row: ArchiveProjectResponseModel): IProjectArchive {
        return {
            archived: row.archived,
            created: row.created,
            id: row.projectid,
            designs: row.designs,
            members: row.members,
            name: row.name,
            parentId: row.parentprojectid
        };
    }

    private getArchiveInternal(dataArchive: ArchiveProjectResponseModel[]): void {
        this._projectsArchive = [];

        for (const item in dataArchive) {
            this._projectsArchive.push(this.mapArchiveProjectResponseEntityToIProjectArchive(dataArchive[item]));
        }
    }

    private getInternal(data: DocumentGetResponse): void {
        this.internalRawProject = data;
        this._desingsFlat = {};
        this._draftsProject = null;
        this._projectsFlat = {};
        this._projects = {};

        interface IResponse {
            projects: { [projectId: string]: IProject };
            documents: { [documentId: string]: IDocument };
        }

        interface IProject {
            id: string;
            name: string;
            parentId: string;
            dateChanged: string;
            dateCreated: string;
            owner: boolean;
            isCompanyProject: boolean;
            readOnly: boolean;
        }

        interface IDocument {
            id: string;
            name: string;
            projectId: string;
            dateChanged: string;
            dateCreated: string;
            locked: boolean;
            lockedUserName: string;
            type: number;
            metadata: Record<string, string>;
            owner: boolean;
        }

        let projects: { [projectId: string]: Project } = {};
        if (data != null && data.projects != null) {
            projects = Object.fromEntries(Object.entries(data.projects).map(([key, project]) => [key, new Project({
                id: project.id,
                name: this.getProjectName(project.name),
                parentId: project.parentId,
                projectType: this.getProjectType(project.name),
                designs: null,
                subProjects: null,
                changeDate: new Date(project.dateChanged.toString()),
                createDate: new Date(project.dateCreated.toString()),
                owner: project.owner,
                isCompanyProject: project.isCompanyProject,
                expanded: project.expanded,
                readOnly: project.readOnly
            })]));
        }

        let documents: { [documentId: string]: IDesignListItem } = {};
        if (data != null && data.documents != null) {
            documents = Object.fromEntries(Object.entries(data.documents).map(([key, document]): [string, IDesignListItem] => [key, {
                id: document.id,
                projectId: document.projectId,
                projectName: projects[document.projectId].name,
                changeDate: new Date(document.dateChanged.toString()),
                createDate: new Date(document.dateCreated.toString()),
                designName: document.name,
                locked: document.locked,
                lockedUser: document.lockedUserName,
                metaData: this.getDesignExternalMetadata(document.metadata),
                integrationDocument: undefined,
                owner: document.owner
            }]));
        }

        this._desingsFlat = documents;
        const allSubProjects = Object.values(projects).filter(projects => projects.parentId != null);
        const allParentProjects = Object.values(projects).filter(projects => projects.parentId == null);
        // add designs to projects
        for (const project of allParentProjects) {
            const designs: { [id: string]: IDesignListItem } = Object.fromEntries(Object.entries(documents).filter(([key, document]) => document.projectId == project.id));

            project.directChildDesigns = designs;
            project.designs = designs;

            this._projectsFlat[project.id] = project;
            this._projects[project.id] = project;

            // drafts project
            if (project.projectType == ProjectType.draft) {
                this._draftsProject = project;
            }
        }

        // add designs to subProjects
        for (const project of allSubProjects) {
            const designs: { [id: string]: IDesignListItem } = Object.fromEntries(Object.entries(documents).filter(([key, document]) => document.projectId == project.id));

            project.directChildDesigns = designs;
            project.designs = designs;

            this._projectsFlat[project.id] = project;
            if (this._projects[project.parentId] != null) {
                this._projects[project.parentId].subProjects[project.id] = project;

                Object.values(designs).forEach((design) => {
                    this._projects[project.parentId].designs[design.id] = design;
                });
            }
            else {
                this._projects[project.id] = project;
            }

        }

        // drafts project
        if (!Object.values(this._projectsFlat).some((project) => project.projectType == ProjectType.draft)) {
            const draftProject: Project = new Project({ name: '#$draft', projectType: ProjectType.draft });

            // call the actual service to add the project into store
            this.addNewProject(draftProject, ModalDialogType.project).then((project) => {
                this._projectsFlat[project.id] = project;
                this._projects[project.id] = project;
                this._draftsProject = project;
            });
        }
    }

    private getProjectType(projectName: string): ProjectType {
        if (projectName != null && projectName.indexOf('#$') == 0) {
            const projectType = projectName.substr(2);

            switch (projectType) {
                case 'draft':
                    return ProjectType.draft;
                case 'template':
                    return ProjectType.template;
            }
        }

        return ProjectType.common;
    }

    private getProjectName(projectName: string): string {
        if (projectName != null && projectName.indexOf('#$') == 0) {
            const projectType = projectName.substr(2);
            return this.localizationService.getLocalizedString(`Agito.Hilti.Profis3.Documents.SpecialProject.${projectType}`);
        }

        return projectName;
    }

    private getDesignExternalMetadata(metadata: Record<string, string>): DesignExternalMetaData {
        const designExternalMetadata = new DesignExternalMetaData();

        for (const key in metadata) {
            const value = metadata[key];

            switch (key) {
                case 'anchorName':
                case 'productName':
                    designExternalMetadata.productName = value;

                    break;
                case 'region':
                    if (value != null && value != '') {
                        designExternalMetadata.region = parseInt(value, 10);
                    }

                    break;
                case 'standard':
                    if (value != null && value != '') {
                        designExternalMetadata.standard = parseInt(value, 10);
                    }

                    break;
                case 'designMethod':
                    if (value != null && value != '') {
                        designExternalMetadata.designMethod = parseInt(value, 10);
                    }

                    break;
                case 'designType':
                    if (value != null && value != '') {
                        designExternalMetadata.designType = parseInt(value, 10);
                    }

                    break;
                case 'approvalNumber':
                    designExternalMetadata.approvalNumber = value;

                    break;
                case 'calculationType':
                    if (value != null && value != '') {
                        designExternalMetadata.calculationType = parseInt(value, 10);
                    }

                    break;
                case 'handrailConnectionType':
                    if (value != null && value != '') {
                        designExternalMetadata.handrailConnectionType = parseInt(value, 10);
                    }

                    break;

            }
        }

        return designExternalMetadata;
    }

    private GetProjectDesignContent(design: Design): string {
        if (design.isC2C) {
            const data = {...design.designData.projectDesignC2C};
            delete data.designName;
            delete data.projectName;
            return this.browserService.toBase64(data);
        }
        else {
            const data = {...design.designData.projectDesign};
            delete data.DesignName;
            delete data.ProjectName;
            return this.browserService.toBase64(data);
        }
    }

    /**
     * Creates promise to update design with new data.
     * @param data - The request data.
     */

    private async putSmallDesingChangePromiseCommon<TProjectDesign, TDetailedDisplayDesign>(data: IDefferedDataCommon<TProjectDesign, TDetailedDisplayDesign>): Promise<void> {
        // make new meta-data entity
        const internalDesign = this.findDesignById(data.designId);

        const projectDesign = cloneDeep(data.contentOverride);
        const metaData = cloneDeep(data.metadataOverride);

        await this.smallDesignChangeCommon<TProjectDesign, TDetailedDisplayDesign>(internalDesign, projectDesign, data, metaData);
    }

    private async putSmallDesingChangePromise(data: IDefferedData): Promise<void> {
        // make new meta-data entity
        const internalDesign = this.findDesignById(data.designId);

        const projectDesign = cloneDeep(data.contentOverride);
        if (projectDesign.ProjectDesignType !== DesignType.DiaphragmDesign) {
            delete projectDesign.DesignName;
            delete projectDesign.ProjectName;
        }
        const metaData = cloneDeep(data.metadataOverride);

        if (data.displayDesign != null && projectDesign.ProjectDesignType !== DesignType.DiaphragmDesign) {
            detailedDisplayDesignToProjectDesign(projectDesign, data.displayDesign);

            metaData.designMethod = data.displayDesign.designMethodGroup.id;
        }

        await this.smallDesignChange(internalDesign, projectDesign, data, metaData);
    }

    private async putSmallDesingChangePromiseC2C(data: IDefferedDataC2C): Promise<void> {
        // make new meta-data entity
        const internalDesign = this.findDesignById(data.designId);

        const projectDesign = cloneDeep(data.contentOverride);
        delete projectDesign.designName;
        delete projectDesign.projectName;
        const metaData = cloneDeep(data.metadataOverride);

        if (data.displayDesign != null) {
            detailedDisplayDesignToProjectDesignC2C(projectDesign, data.displayDesign);
        }

        await this.smallDesignChange(internalDesign, projectDesign, data, metaData);
    }

    /**
     * Creates promise to update thumbnail image.
     * @param data Request data
     */
    private async updateDesignThumbnailImagePromise(data: IDefferedImageData): Promise<void> {
        try {
            await this.uploadDesignImageInternal(data.designId, null, data.thumbnailContent, data.respond);
        }
        finally {
            this.defferedImageUpload[data.designId].running = null;

            // start new request for deffered and save reference into runningDefferd to resolve it later
            // clear defferd so new requests can be added
            if (this.defferedImageUpload[data.designId].deffered != null) {
                const runningDeffered = this.defferedImageUpload[data.designId].deffered;
                this.defferedImageUpload[data.designId].running = runningDeffered.promise;

                this.updateDesignThumbnailImagePromise(this.defferedImageUpload[data.designId].defferedData as IDefferedImageData)
                    .then(() => runningDeffered.resolve())
                    .catch((err) => runningDeffered.reject(err));

                this.defferedImageUpload[data.designId].deffered = null;
                this.defferedImageUpload[data.designId].defferedData = null;
            }
        }
    }

    private deleteProjectFunc(projId: string): void {
        delete this.internalRawProject.projects[projId];
        const deleteDocs = Object.keys(this.internalRawProject.documents).filter((it) => this.internalRawProject.documents[it].projectId == projId);
        deleteDocs.forEach((it) => this.deleteDesignFunc(it));

        // remove from project flat list
        delete this._projectsFlat[projId];

        // remove form hierarchical list - in line recursion
        delete this._projects[projId];

        const remove = (x: Project) => {
            delete x.subProjects[projId];

            for (const s in x.subProjects) remove(x.subProjects[s]);

        };
        for (const s in this._projects) remove(this._projects[s]);
    }

    private deleteDesignFunc(designId: string): void {
        const design = this.findDesignById(designId);
        const project = this.findProjectById(design.projectId);

        if (project == null) {
            throw new Error('Invalid project ID.');
        }

        const deleteDoc = Object.keys(this.internalRawProject.documents).find((it) => this.internalRawProject.documents[it].id == designId);
        delete this.internalRawProject.documents[deleteDoc];

        // remove from project
        delete project.directChildDesigns[designId];
        delete project.designs[designId];

        // remove from all parent projects
        let parentX: Project = this.findProjectById(project.parentId);
        while (parentX != undefined && parentX != null) {
            delete parentX.designs[designId];
            parentX = this.findProjectById(parentX.parentId);
        }

        // remove from flat design list
        delete this._desingsFlat[designId];
    }

    private updateProject(designId: string, projectId: string): void {
        const design = this.findDesignById(designId);
        if (design == null) {
            throw new Error('Invalid design id.');
        }

        const project = this.findProjectById(projectId);
        if (project == null) {
            throw new Error('Invalid project id.');
        }

        design.projectId = projectId;
        design.projectName = project.name;

        // remove design from project
        for (const pKey in this.projectsFlat) {
            const p = this.projectsFlat[pKey];
            delete p.directChildDesigns[design.id];
            delete p.designs[design.id];
        }

        // remove design from designs
        delete this._desingsFlat[design.id];

        // add design to project
        if (project.directChildDesigns == null) {
            project.directChildDesigns = {};
        }

        this.addUpdateDesignInProject(design);
    }

    // make any special stuff on special folders - for now only draft folder
    private setSpecialProjectProperties(project: Project): void {
        if (project.name.indexOf('#$') == 0) {
            const projectType = project.name.substr(2);
            switch (projectType) {
                case 'draft':
                    project.projectType = ProjectType.draft;
                    break;
                case 'template':
                    project.projectType = ProjectType.template;
                    break;
            }
            project.name = this.localizationService.getString(`Agito.Hilti.Profis3.Documents.SpecialProject.${projectType}`);

        }
    }

    /**
     * Parse raw data from document service to IDesignListItem.
     * @param data- document data
     */
    private toIDesignListItem(data: IGetDocumentResponse): IDesignListItem {
        const ret: IDesignListItem = {
            id: data.documentid,
            projectId: data.project.projectid,
            projectName: data.project.name,
            changeDate: data.lastchange != null ? new Date(data.lastchange * 1000) : null,
            createDate: data.created != null ? new Date(data.created * 1000) : null,
            designName: data.name,
            locked: data.locked,
            lockedUser: data.lockedby,
            metaData: this.fromServiceMetaData(data.metadata, data.documentid, data.name),
            integrationDocument: null,
            owner: data.owner
        };
        return ret;
    }

    /**
     * converts the metadata entitiy to a form appropriate for sending to the document service.
     * @param metaData - Design meta data
     */
    private toServiceMetaData(metaData: DesignExternalMetaDataCommon): any[] {
        if (metaData == null) {
            return null;
        }

        const ret: any[] = [];

        if (metaData != undefined && metaData != null) {
            (Object.keys(metaData) as (keyof typeof metaData)[]).forEach((key) => {
                const value = metaData[key]?.toString();

                if (value != null && value != '')
                    ret.push({ key: key, value: value });
            });
        }

        return ret;
    }

    /**
     * Parses some of the results of the read meta-data methods to the specified entity.
     * @param serviceMetaData - Service meta data
     * @param designId - Design id
     * @param designName - Design name
     */
    private fromServiceMetaData(serviceMetaData: { key: string, value: string }[], designId: string, designName: string): DesignExternalMetaData {
        const ret: DesignExternalMetaData = new DesignExternalMetaData();

        for (const rec of serviceMetaData) {
            switch (rec.key) {
                case 'anchorName':
                case 'productName':
                    ret.productName = rec.value;
                    break;
                case 'region':
                    if (rec.value != undefined && rec.value != null && rec.value != '') {
                        ret.region = parseInt(rec.value);
                    }
                    break;
                case 'standard':
                    if (rec.value != undefined && rec.value != null && rec.value != '') {
                        ret.standard = parseInt(rec.value);
                    }
                    break;
                case 'designMethod':
                    if (rec.value != undefined && rec.value != null && rec.value != '') {
                        ret.designMethod = parseInt(rec.value);
                    }
                    break;
                case 'designType':
                    if (rec.value != undefined && rec.value != null && rec.value != '') {
                        ret.designType = parseInt(rec.value);
                    }
                    break;
                case 'approvalNumber':
                    ret.approvalNumber = rec.value;
                    break;
                case 'calculationType':
                    if (rec.value != undefined && rec.value != null && rec.value != '') {
                        ret.calculationType = parseInt(rec.value);
                    }
                    break;
                case 'handrailConnectionType':
                    if (rec.value != undefined && rec.value != null && rec.value != '') {
                        ret.handrailConnectionType = parseInt(rec.value);
                    }
                    break;
            }
        }

        return ret;
    }

    /**
     * Returns users on the project
     * @param projectId The project id
     */
    private async getUsersOnProjectByIdInternal(projectId: string): Promise<ProjectUser[]> {
        const url = `${environment.documentWebServiceUrl}User?projectid=${projectId}`;

        try {
            return (await this.apiService.request<ProjectUser[]>(new HttpRequest('GET', url), this.getApiOptions())).body;
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'get users on project by id');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 404) {
                    this.ErrorHandlerService.showProjectArchivedModal(response, url);
                }
                else if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            }

            throw response;
        }
    }

    /**
     * Adds user to the project
     * @param data - Project user data
     */
    private async addUsersOnProjectByIdInternal(data: ProjectUser): Promise<void> {
        const url = `${environment.documentWebServiceUrl}User`;

        const sendData = {
            projectid: data.projectid,
            user: data.user
        };

        try {
            await this.apiService.request(new HttpRequest('POST', url, sendData), this.getApiOptions());
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'add user to the project by id');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 404) {
                    this.modalService.openAlertError(
                        this.localizationService.getString('Agito.Hilti.Profis3.ShareProject.UserNotAdded.Title'),
                        this.localizationService.getString('Agito.Hilti.Profis3.ShareProject.UserNotAdded.Message'),
                        {
                            response,
                            endPointUrl: url,
                            requestPayload: sendData
                        }
                    );
                }
                else {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url,
                        requestPayload: sendData
                    });
                }
            }

            throw response;
        }
    }

    /**
     * Removes user from the project
     * @param data - Project user data
     */
    private async removeUsersOnProjectByIdInternal(data: ProjectUser): Promise<void> {
        const url = `${environment.documentWebServiceUrl}User`;

        const sendData = {
            projectid: data.projectid,
            user: data.user
        };

        const request = new HttpRequest('DELETE', url, sendData, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        });

        try {
            await this.apiService.request(request, this.getApiOptions());
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'delete user from the project by id');

            if (response instanceof HttpErrorResponse) {
                if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url,
                        requestPayload: sendData
                    });
                }
            }

            throw response;
        }
    }

    private getApiOptions(): ApiOptions {
        if (!this.userService.isAuthenticated) {
            throw new Error('Unauthenticated');
        }

        return {
            supressErrorMessage: true,
        };
    }

    private async addNewProject(project: Project, projectLevelType: ModalDialogType): Promise<Project> {
        const url = `${environment.documentWebServiceUrl}project`;

        const rData = {
            name: project.name,
            parentprojectid: (project.parentId == null)
                ? null
                : project.parentId,
            isCompanyProject: project.isCompanyProject,
            expanded: project.expanded
        };
        this.logServiceRequest('addNewProject', rData, url);

        try {
            const response = await this.apiService.request<any>(new HttpRequest('POST', url, rData), this.getApiOptions());

            this.logServiceResponse('addNewProject', response);
            project.id = response.body.projectid as string;
            this.setSpecialProjectProperties(project);

            return project;
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'post project');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 409) {
                    this.ErrorHandlerService.showExistingProjectNameModal();
                }
                else if (response.status == 404) {
                    this.ErrorHandlerService.showProjectArchivedModal(response, url, rData);
                }
                else if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url,
                        requestPayload: rData
                    });
                }
            }

            throw response;
        }
    }

    private async updateExistingProject(project: Project, projectLevelType: ModalDialogType): Promise<void> {
        const url = `${environment.documentWebServiceUrl}project/`;

        const rData = {
            projectid: project.id,
            name: project.name,
            parentprojectid: project.parentId
        };
        this.logServiceRequest('updateExistingProject', rData, url);

        try {
            await this.apiService.request(new HttpRequest('PUT', url, rData), this.getApiOptions());
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'put project');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 409) { // conflict; duplicate name
                    this.ErrorHandlerService.showExistingProjectNameModal();
                }
                else if (response.status == 404) {
                    this.ErrorHandlerService.showProjectArchivedModal(response, url, rData);
                }
                else if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url,
                        requestPayload: rData
                    });
                }
            }

            throw response;
        }
    }

    private async toggleExpandExistingProject(project: Project): Promise<void> {
        project.expanded = !project.expanded;

        const url = `${environment.documentWebServiceUrl}project/setExpanded/${project.id}/${project.expanded}`;

        this.logServiceRequest('toggleExpandExistingProject', url);

        try {
            await this.apiService.request(new HttpRequest('PUT', url, null), this.getApiOptions());
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'put project setExpanded');

            if (response instanceof HttpErrorResponse) {
                if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            }

            throw response;
        }
    }

    private async archiveExistingProject(projectId: string): Promise<unknown> {
        const url = `${environment.documentWebServiceUrl}project/${projectId}`;

        this.logServiceRequest('archiveExistingProject', url);

        try {
            return (await this.apiService.request<unknown>(new HttpRequest('DELETE', url), this.getApiOptions())).body;
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'delete project');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 423) {
                    throw new CantArchiveProjectsBecauseDocumentInUse();
                }

                if (response.status == 404) {
                    this.ErrorHandlerService.showProjectArchivedModal(response, url);
                }
                else if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            }

            throw response;
        }
    }

    private async restoreExistingProject(projectId: string, projectLevelType: ModalDialogType): Promise<DocumentGetResponse> {
        const url = `${environment.documentWebServiceUrl}project/dearchive/${projectId}`;

        this.logServiceRequest('restoreExistingProject', url);

        try {
            const response = await this.apiService.request<any>(new HttpRequest('POST', url, {}), this.getApiOptions());
            this.logServiceResponse('restoreExistingProject', response);

            const data = response.body;
            const result: DocumentGetResponse = {
                projects: Object.fromEntries(Object.entries<any>(data.projects).map(([key, it]): [string, DocumentGetProject] => [key, {
                    dateChanged: it.dateChanged,
                    dateCreated: it.dateCreated,
                    id: it.id,
                    name: this.projectNameExists(it.name, it.parentId, it.id) ? this.createUniqueName(it.name, Object.values(this._projectsFlat).map(project => project.name)) : it.name,
                    parentId: it.parentId,
                    owner: it.owner,
                    isCompanyProject: it.isCompanyProject,
                    expanded: it.expanded,
                    readOnly: it.readOnly,
                    expandedState: it.expandedState
                }])),
                documents: Object.fromEntries(Object.entries<any>(data.documents).map(([key, it]): [string, DocumentGetDocument] => [key, {
                    dateChanged: it.dateChanged,
                    dateCreated: it.dateCreated,
                    id: it.id,
                    locked: it.locked,
                    metadata: it.metadata,
                    projectId: it.projectId,
                    lockedUserName: it.lockedUserName,
                    name: it.name,
                    type: it.type,
                    owner: it.owner
                }]))
            };

            return result;
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'restore project');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 423) {
                    throw new CantRestoreProjectsBecauseDocumentInUse();
                }

                if (response.status == 409) {
                    this.ErrorHandlerService.showExistingProjectNameModal();
                }
                else if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            }

            throw response;
        }
    }

    private async removeExistingArchivedProject(projectId: string): Promise<void> {
        const url = `${environment.documentWebServiceUrl}project/permanentArchived/${projectId}`;

        this.logServiceRequest('removeExistingProject', url);

        try {
            await this.apiService.request(new HttpRequest('DELETE', url), this.getApiOptions());
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'delete project');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 423) {
                    throw new CantDeleteProjectsBecauseDocumentInUse();
                }

                if (response.status == 404) {
                    this.ErrorHandlerService.showProjectArchivedModal(response, url);
                }
                else if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            }

            throw response;
        }
    }

    private async removeExistingProject(projectId: string): Promise<void> {
        const url = `${environment.documentWebServiceUrl}project/permanent/${projectId}`;

        this.logServiceRequest('removeExistingProject', url);

        try {
            await this.apiService.request(new HttpRequest('DELETE', url), this.getApiOptions());
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'delete project');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 423) {
                    throw new CantDeleteProjectsBecauseDocumentInUse();
                }

                if (response.status == 404) {
                    this.ErrorHandlerService.showProjectArchivedModal(response, url);
                }
                else if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            }

            throw response;
        }
    }

    private async convertExistingProject(projectId: string): Promise<void> {
        const url = `${environment.documentWebServiceUrl}project/convertProject/${projectId}`;

        this.logServiceRequest('convertExistingProject', url);

        try {
            await this.apiService.request(new HttpRequest('GET', url), this.getApiOptions());
        }
        catch (response) {
            if (response instanceof HttpErrorResponse) {
                if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            }

            throw response;
        }
    }

    private async getDocumentsByProjectId(projectId: string): Promise<unknown> {
        const url = `${environment.documentWebServiceUrl}document/get`;
        this.logServiceRequest('getDocumentsByProjectId', url);

        try {
            return (await this.apiService.request<unknown>(new HttpRequest('GET', url), this.getApiOptions())).body;
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'get project documents');

            if (response instanceof HttpErrorResponse) {
                if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            }

            throw response;
        }
    }

    private async findDesignByNameInternal(designName: string, projectId: string, forceFromServer: boolean): Promise<IDesignListItemCommon> {
        let project = this.findProjectById(projectId);

        if (project != null) {

            if (forceFromServer === true) {
                const data: any = await this.getDocumentsByProjectId(projectId);
                const result: DocumentGetResponse = {
                    projects: Object.fromEntries(Object.entries<any>(data.projects).map(([key, it]): [string, DocumentGetProject] => [key, {
                        dateChanged: it.dateChanged,
                        dateCreated: it.dateCreated,
                        id: it.id,
                        name: it.name,
                        parentId: it.parentId,
                        owner: it.owner,
                        isCompanyProject: it.isCompanyProject,
                        expanded: it.expanded,
                        readOnly: it.readOnly,
                        expandedState: it.expandedState
                    }])),
                    documents: Object.fromEntries(Object.entries<any>(data.documents).map(([key, it]): [string, DocumentGetDocument] => [key, {
                        dateChanged: it.dateChanged,
                        dateCreated: it.dateCreated,
                        id: it.id,
                        locked: it.locked,
                        metadata: it.metadata,
                        projectId: it.projectId,
                        lockedUserName: it.lockedUserName,
                        name: it.name,
                        type: it.type,
                        owner: it.owner
                    }]))
                };

                this.getInternal(result);
                project = this.findProjectById(projectId);

                const foundDesign = Object.values(project.designs).find((design) => design.designName.toLowerCase().trim() == designName.toLowerCase().trim());

                if (foundDesign != null) {
                    return foundDesign;
                }

                return null;
            }
            else {
                const foundDesign = Object.values(project.designs).find((design) => design.designName.toLowerCase().trim() == designName.toLowerCase().trim());

                if (foundDesign != null) {
                    return foundDesign;
                }

                return null;
            }

        }

        return null;
    }

    /**
     * Reads all the locked designs of a project.
     * @param projectId - Project id
     */
    private async readLockedDesignsOfProject(projectId: string): Promise<CantPerformActionReasonProblematicDocumentInformation[]> {
        interface IGetProjectResponse {
            documents: IGetProjectResponseDocument[];
        }

        interface IGetProjectResponseDocument {
            locked: boolean;
            documentid: string;
            name: string;
            lockedby: string;
        }

        const url: string = `${environment.documentWebServiceUrl}project/` + projectId;

        this.logServiceRequest('readDesignsOfProject', url);

        try {
            const response = await this.apiService.request<IGetProjectResponse>(new HttpRequest('GET', url), this.getApiOptions());

            this.logServiceResponse('readDesignsOfProject', response);
            const designs: CantPerformActionReasonProblematicDocumentInformation[] = [];
            if (response.body.documents instanceof Array) {
                for (const pRaw of response.body.documents) {
                    if (pRaw.locked != undefined && pRaw.locked != null && pRaw.locked == true) {
                        const p: CantPerformActionReasonProblematicDocumentInformation = new CantPerformActionReasonProblematicDocumentInformation();
                        p.designId = pRaw.documentid;
                        p.document = pRaw.name;
                        p.username = pRaw.lockedby;
                        designs.push(p);
                    }
                }
            }

            return designs;
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'documents');

            if (response instanceof HttpErrorResponse) {
                if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            }

            throw response;
        }
    }

    private async userHasAccessToProjectInternal(projectId: string): Promise<boolean> {
        const url = `${environment.documentWebServiceUrl}User/isuseronproject/${projectId}`;

        try {
            return (await this.apiService.request<boolean>(new HttpRequest('GET', url), this.getApiOptions())).body;
        }
        catch (response) {
            console.error(response);

            if (response instanceof HttpErrorResponse) {
                if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            }

            throw response;
        }
    }

    private async getDesingBasicDocumentInformation(id: string): Promise<IDesignListItem> {
        const url = `${environment.documentWebServiceUrl}document/${id}`;

        this.logServiceRequest('getDesingBasicDocumentInformation', url);

        try {
            const response = await this.apiService.request<IGetDocumentResponse>(new HttpRequest('GET', url), this.getApiOptions());
            this.logServiceResponse('getDesingBasicDocumentInformation', response);

            return this.toIDesignListItem(response.body);
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'read document');

            if (response instanceof HttpErrorResponse) {
                if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            }

            throw response;
        }
    }

    /**
     * Add new empty file and return document id when done.
     * @param projectId - Project id
     * @param designName - Design name
     * @param fileContent - File conctent
     * @param metaData - Meta data
     */
    private async addNewFile(
        projectId: string,
        designName: string,
        fileContent: string,
        metaData: DesignExternalMetaDataCommon,
        canGenerateUniqueName: boolean,
        ignoreConflict: boolean
    ): Promise<IDocumentServiceDesign> {
        const url = `${environment.documentWebServiceUrl}document`;

        const rData = { projectid: projectId, name: designName, filecontent: fileContent, type: 1, metadata: this.toServiceMetaData(metaData), adjustdocumentname: canGenerateUniqueName };
        this.logServiceRequest('addNewFile', rData, url);

        try {
            const response = await this.apiService.request<any>(new HttpRequest('POST', url, rData), this.getApiOptions());
            this.logServiceResponse('addNewFile', response);
            this.setNewSessionForNewlyCreatedDesign(response.body.documentid, response.body.sessionid);
            return response.body as IDocumentServiceDesign;
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'add new document');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 409 && response.error.content.conflictType == 0) {
                    if (ignoreConflict !== true) {
                        this.ErrorHandlerService.showExistingDesignNameModal(response, url, rData);
                    }
                }
                else if (response.status == 409 && response.error.content.conflictType == 1) {
                    this.modalService.openMaxDesignsLimitReached();
                }
                else if (response.status == 404) {
                    this.ErrorHandlerService.showProjectArchivedModal(response, url, rData);
                }
                else if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url,
                        requestPayload: rData
                    });
                }
            }

            throw response;
        }
    }

    private async getDesignContentExclusive<TProjectDesign>(designId: string, sessionId: string, adjustContent?: ((conten: TProjectDesign, designName: string, projectName: string) => TProjectDesign) | undefined): Promise<TProjectDesign> {
        const url = `${environment.documentWebServiceUrl}documentContent/GetExclusive`;
        const rData = { documentid: designId, islock: true, key: sessionId };

        try {
            const response = await this.getDesignContentExclusiveInternal(url, rData);
            return this.getContentFromResponse<TProjectDesign>(response, adjustContent);
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'get design content exclusive');

            if (response instanceof HttpErrorResponse) {
                // handle document locked error
                if (response.status == 423) {
                    // leave the upper level to deal with it
                    throw response;
                }

                if (response.status == 404) {
                    this.ErrorHandlerService.showDesignDoesNotExistModal(response, url, rData);
                }
                else if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url,
                        requestPayload: rData
                    });
                }
            }

            throw response;
        }
    }

    private async getDesignContentExclusiveInternal(url: string, rData: any): Promise<HttpResponse<unknown>> {
        this.logServiceRequest('getDesignContentExclusive', rData, url);

        return await this.apiService.request<unknown>(new HttpRequest('POST', url, rData), this.getApiOptions());
    }

    private getDesignContent(designId: string): Promise<ProjectDesignBaseEntity> {
        return this.getContent(designId);
    }

    private getDesignContentC2C(designId: string): Promise<ProjectDesignEntityC2C> {
        return this.getContent(designId);
    }

    private async getContent<T>(designId: string): Promise<T> {
        const url = `${environment.documentWebServiceUrl}documentContent/${designId}`;

        this.logServiceRequest('getDesignContent', url);

        try {
            const response = await this.apiService.request<any>(new HttpRequest('GET', url), this.getApiOptions());

            this.logServiceResponse('getDesignContent', response);

            const projectDesignAsJsonString = this.browserService.fromBase64(response.body.filecontent);

            try {
                return JSON.parse(projectDesignAsJsonString) as T;
            }
            catch (error) {
                throw new Error('Invalid design on document service.');
            }
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'get design content');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 404) {
                    this.ErrorHandlerService.showDesignDoesNotExistModal(response, url);
                }
                else if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            }

            throw response;
        }
    }

    /**
     * Make a small change to the allready exclusevly locked and open desing, without closing or releasing the content.
     * @param id - desing id
     * @param base64XmlContent - the design content in base64 encoded xml format
     * @param sessionId - id of the design change session
     * @param metaData -
     * @param openDesign - if it's called when you open design or close/save
     */
    private async putSmallDesingChange(
        designId: string,
        projectId: string,
        designName: string,
        base64XmlContent: string,
        sessionId: string,
        metaData: DesignExternalMetaDataCommon,
        unlock = false,
        exclusiveLock = false,
        documentAccessMode: DocumentAccessMode = DocumentAccessMode.Open
    ): Promise<void> {
        const url = `${environment.documentWebServiceUrl}documentcontent`;

        const rData = {
            unlock,
            key: sessionId,
            documentid: designId,
            projectid: projectId,
            name: designName,
            filecontent: base64XmlContent,
            metadata: this.toServiceMetaData(metaData),
            exclusiveLock,
            documentAccessMode
        };
        this.logServiceRequest('putSmallDesingChange', rData, url);

        const request = new HttpRequest('PUT', url, rData, {
            responseType: 'json'
        });

        try {
            await this.apiService.request(request, this.getApiOptions());
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'small design update failed');

            if (!this.handleSessionExpiredError(response, { designId }, [428])
                && !this.handleDesignFileLocked(response, { designId }, [423], documentAccessMode)
            ) {
                if (response instanceof HttpErrorResponse) {
                    if (response.status == 409) {
                        this.ErrorHandlerService.showExistingDesignNameModal(response, url, rData);
                    }
                    else if (response.status == 404) {
                        this.ErrorHandlerService.showProjectArchivedModal(response, url, rData);
                    }
                    else if (response.status != 401) {
                        this.modalService.openAlertServiceError({
                            response,
                            endPointUrl: url,
                            requestPayload: rData
                        });
                    }
                }
            }

            throw response;
        }
    }

    /**
     * Update design printscreen image when a design report is ready for export
     * @param id - desing id
     * @param base64XmlContent - the design image content in base64 encoded xml format
     * @param base64ThumbnailXmlContent - the design thumbnail image content in base64 encoded xml format
     */
    private async uploadDesignImageInternal(
        designId: string,
        base64XmlContent: string,
        base64ThumbnailXmlContent: string,
        respond = true
    ): Promise<void> {
        const url = `${environment.documentWebServiceUrl}documentcontent/UploadDocumentImage`;

        const sessionId = this.getSessionKeyForDesign(designId);

        const rData = {
            key: sessionId,
            documentid: designId,
            modelimage: base64XmlContent,
            thumbnail: base64ThumbnailXmlContent,
            respond
        } as any;

        this.logServiceRequest('uploadDocumentImage', rData, url);

        const request = new HttpRequest('POST', url, rData, {
            responseType: 'json'
        });

        try {
            const response = await this.apiService.request<any>(request, this.getApiOptions());

            if (respond) {
                this.logServiceResponse('uploadDocumentImage', response);
                const data = response.body;

                this.documentThumbnails[data.documentid] = data.thumbnailImageContent;
            }
            else {
                this.documentThumbnails[designId] = base64ThumbnailXmlContent;
            }
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'upload design image failed');

            if (!this.handleSessionExpiredError(response, { designId }, [428])) {
                if (typeof response != 'object' || !('status' in response) || response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url,
                        requestPayload: rData
                    });
                }
            }
        }
    }

    /**
     * Unlock the document on the document service.
     * @param id - design document id.
     */
    private async postUnlockDocument(id: string): Promise<void> {
        const sessionKey = this.getSessionKeyForDesign(id);
        const url = `${environment.documentWebServiceUrl}documentcontent/${id}/false/${sessionKey}`;
        this.logServiceRequest('postUnlockDocument', url);

        const request = new HttpRequest('POST', url, null, {
            responseType: 'json'
        });

        try {
            await this.apiService.request(request, this.getApiOptions());
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'design publish failed');

            if (response instanceof HttpErrorResponse) {
                if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            }

            throw response;
        }
    }

    private async archiveExistingDesign(designId: string): Promise<void> {
        const url = `${environment.documentWebServiceUrl}document/${designId}`;

        this.logServiceRequest('archiveExistingDesign', url);

        try {
            await this.apiService.request(new HttpRequest('DELETE', url), this.getApiOptions());
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'archive design');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 423) {
                    throw new CantArchiveDesignBecauseLockedByOtherUser();
                }

                if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            }

            throw response;
        }
    }

    private async removeExistingDesigns(designIds: string[]): Promise<void> {
        const url = `${environment.documentWebServiceUrl}document/permanentdelete`;

        this.logServiceRequest('removeExistingDesigns', url);

        const request = new HttpRequest('DELETE', url, designIds, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        });

        try {
            await this.apiService.request(request, this.getApiOptions());
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'remove designs');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 404) {
                    this.ErrorHandlerService.showProjectArchivedModal(response, url, designIds);
                }
                else if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url,
                        requestPayload: designIds
                    });
                }
            }

            throw response;
        }
    }

    private setNewSessionForNewlyCreatedDesign(design: string, session: string): void {
        this.designSessionMapping.push({ designId: design, sessionId: session, isCancelled: false });
    }

    private isCanceledSessionForDesign(designId: string): boolean {
        const mapping = this.designSessionMapping.find(x => x.designId == designId);
        if (mapping == null) {
            return true;
        }

        return mapping.isCancelled;
    }

    private closeSessionForDesing(designId: string): void {
        const mapping = this.designSessionMapping.find(x => x.designId == designId);
        if (mapping == null) {
            throw new Error('Session is not opened for the specified desing.');
        }

        this.designSessionMapping = this.designSessionMapping.filter(x => x.designId != designId);
    }

    private cancelSessionForDesign(designId: string): void {
        const mapping = this.designSessionMapping.find(x => x.designId == designId);
        if (mapping == null) {
            return;
        }

        mapping.isCancelled = true;
    }

    /**
     * the session in which the document was being edited and changed was overtaken.
     * @param response - the entire server error response.
     * @param additionalData - any additional data
     * @param errorsToHandle - what error code should this call handle.
     */
    private handleSessionExpiredError(response: HttpErrorResponse | unknown, additionalData: { designId: string } | unknown, errorsToHandle: number[]): boolean {
        let errorHandeled = false;

        if (errorsToHandle != null) {
            if (typeof response == 'object' && 'status' in response && response.status == 428 && errorsToHandle.includes(428)) {
                errorHandeled = true;

                this.modalService.openConfirmChange({
                    id: 'traSaveLock',
                    title: this.localizationService.getString('Agito.Hilti.Profis3.DocumentService.Alerts.SessionExpired.Title'),
                    message: this.localizationService.getString('Agito.Hilti.Profis3.DocumentService.Alerts.SessionExpired.Description'),
                    confirmButtonText: this.localizationService.getString('Agito.Hilti.Profis3.DocumentService.Alerts.SessionExpired.ConfirmButton'),
                    cancelButtonText: this.localizationService.getString('Agito.Hilti.Profis3.DocumentService.Alerts.SessionExpired.CancelButton'),
                    onConfirm: (modal) => {
                        if (typeof additionalData == 'object' && 'designId' in additionalData && typeof additionalData.designId == 'string') {
                            this.cancelSessionForDesign(additionalData.designId);
                        }

                        this.routingService.navigateToUrl(urlPath.projectAndDesign);
                        modal.close();
                    },
                    onCancel: (modal) => {
                        modal.close();
                    }
                });
            }
        }

        return errorHandeled;
    }

    private handleDesignFileLocked(response: HttpErrorResponse | unknown, additionalData: { designId: string } | unknown, errorsToHandle: number[], documentAccessMode: DocumentAccessMode = DocumentAccessMode.Open): boolean {
        let errorHandled = false;

        if (errorsToHandle != null &&
            typeof additionalData == 'object' && 'designId' in additionalData && typeof additionalData.designId == 'string') {

            if (typeof response == 'object' && 'status' in response && response.status == 423 && errorsToHandle.includes(423)) {
                errorHandled = true;

                this.getDesingBasicDocumentInformation(additionalData.designId)
                    .then(d => {
                        const ex: CantOpenDesignBecauseLockedByOtherUser = new CantOpenDesignBecauseLockedByOtherUser();
                        ex.username = d.lockedUser;
                        let alertTitle = '';
                        let alertMessage = '';

                        switch (documentAccessMode) {
                            case DocumentAccessMode.Open:
                                alertTitle = 'Agito.Hilti.Profis3.ProjectAndDesing.Alerts.CannotOpenInUseBy.Title';
                                alertMessage = 'Agito.Hilti.Profis3.ProjectAndDesing.Alerts.CannotOpenInUseBy.Description';
                                break;
                            case DocumentAccessMode.Update:
                                alertTitle = 'Agito.Hilti.Profis3.ProjectAndDesing.Alerts.CannotSaveInUseBy.Title';
                                alertMessage = 'Agito.Hilti.Profis3.ProjectAndDesing.Alerts.CannotSaveInUseBy.Description';
                                break;
                        }

                        this.modalService.openAlertWarning(
                            this.localizationService.getString(alertTitle),
                            formatKeyValue(this.localizationService.getString(alertMessage), { user: ex.username })
                        );
                    })
                    .catch(err => {
                        if (err instanceof Error) {
                            console.error(err);
                        }

                        errorHandled = false;
                    });
            }
        }

        return errorHandled;
    }

    public async moveDesign(documentId: string, projectId: string): Promise<void> {
        const url = `${environment.documentWebServiceUrl}document/move`;
        this.logServiceRequest('moveDesign', url);

        const data = {
            documentId,
            projectId
        };

        try {
            const response = await this.apiService.request<IGetDocumentResponse>(new HttpRequest('POST', url, data), this.getApiOptions());

            this.logServiceResponse('moveDesign', response);

            const design = this.toIDesignListItem(response.body);
            this.deleteDesignFunc(design.id);
            this.addUpdateDesignInProject(design);

        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'move design');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 409 && response.error.content.conflictType == 0) {
                    this.ErrorHandlerService.showExistingDesignNameModal(response, url, data);
                }
                else if (response.status == 409 && response.error.content.conflictType == 1) {
                    await this.modalService.openMaxDesignsLimitReached();
                }
                else if (response.status == 404) {
                    this.ErrorHandlerService.showProjectArchivedModal(response, url, data);
                }
                else if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url,
                        requestPayload: data
                    });
                }
            }

            throw response;
        }
    }

    public async copyDesign(documentId: string, documentName: string, projectId: string): Promise<void> {
        const url = `${environment.documentWebServiceUrl}document/copy`;
        this.logServiceRequest('copyDesign', url);

        const data = {
            documentId,
            documentName,
            projectId
        };

        try {
            const response = await this.apiService.request<IGetDocumentResponse>(new HttpRequest('POST', url, data), this.getApiOptions());

            this.logServiceResponse('copyDesign', response);

            const newDesign = this.toIDesignListItem(response.body);
            this.addUpdateDesignInProject(newDesign);

        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'copy design');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 409 && response.error.content.conflictType == 0) {
                    this.ErrorHandlerService.showExistingDesignNameModal(response, url, data);
                }
                else if (response.status == 409 && response.error.content.conflictType == 1) {
                    await this.modalService.openMaxDesignsLimitReached();
                }
                else if (response.status == 404) {
                    this.ErrorHandlerService.showProjectArchivedModal(response, url, data);
                }
                else if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url,
                        requestPayload: data
                    });
                }
            }

            throw response;
        }
    }

    private addUpdateDesignInProject(newDesign: IDesignListItemCommon) {
        const project = this.findProjectById(newDesign.projectId);
        if (project == null) {
            throw new Error('Invalid project ID.');
        }

        project.directChildDesigns[newDesign.id] = newDesign;
        project.designs[newDesign.id] = newDesign;
        // add design to all parent designs
        let parentX: Project = this.findProjectById(project.parentId);
        while (parentX != undefined && parentX != null) {
            parentX.designs[newDesign.id] = newDesign;
            parentX = this.findProjectById(parentX.parentId);
        }

        // add new design to designs in general
        this.internalRawProject.documents[newDesign.id] = {
            id: newDesign.id,
            name: newDesign.designName,
            projectId: newDesign.projectId,
            type: null,
            dateCreated: newDesign.createDate,
            dateChanged: newDesign.changeDate,
            locked: newDesign.locked,
            lockedUserName: newDesign.lockedUser,
            metadata: newDesign.metaData as any,
            owner: newDesign.owner
        };
        this._desingsFlat[newDesign.id] = newDesign;
    }
    public override findAllDesignsByProject(project: Project): { [id: string]: IDesignListItemCommon } {
        const parentProject = project.parentId ? this.findProjectById(project.parentId) : null;
        return parentProject == null ? null: parentProject.designs;
    }
}
