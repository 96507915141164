/**
 * Browser supports Fetch API.
 */
export const supportsFetchApi: boolean = fetch != null;


/**
 * Browser supports keepalive flag in requests.
 */
export let supportsReqKeepalive = false;
try {
    supportsReqKeepalive = 'keepalive' in new Request('');
}
catch (_err) {
    console.error(_err);
}
