import { Component, Input, OnChanges } from '@angular/core';
import { DropdownItem, DropdownProps } from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import { NumericTextBoxProps } from '@profis-engineering/pe-ui-common/components/numeric-text-box/numeric-text-box.common';
import {
    RadioButtonProps
} from '@profis-engineering/pe-ui-common/components/radio-button/radio-button.common';
import {
    Separator as SeparatorEntity
} from '@profis-engineering/pe-ui-common/entities/code-lists/separator';
import {
    getNumberDecimalSeparator, getNumberGroupSeparator
} from '@profis-engineering/pe-ui-common/helpers/localization-helper';
import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { ProjectCodeList } from '@profis-engineering/pe-ui-shared/enums/project-code-list';
import {
    DesignType as DesignTypeId, SteelGuideline
} from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import {
    IPropertyMetaData, PropertyMetaData
} from '@profis-engineering/pe-ui-shared/properties/properties';
import { IAdvancedCalculationData, IAdvancedCalculationInputs } from '../../helpers/app-settings-helper';

import { CodeListService } from '../../services/code-list.service';
import { LocalizationService } from '../../services/localization.service';
import { UserSettingsService } from '../../services/user-settings.service';

type InputsNumericTextBoxProps = Omit<NumericTextBoxProps, 'unit'>;

@Component({
    selector: 'app-advanced-calculation-inputs',
    templateUrl: './advanced-calculation-inputs.component.html',
    styleUrls: ['./advanced-calculation-inputs.component.scss']
})
export class AdvancedCalculationInputsComponent implements OnChanges {
    @Input()
    public advancedCalculationInputs?: IAdvancedCalculationInputs;

    @Input()
    public advancedCalculationData!: IAdvancedCalculationData;

    @Input()
    public selectedSteelGuideline?: SteelGuideline;

    @Input()
    public decimalSeparator?: SeparatorEntity;

    @Input()
    public groupSeparator?: SeparatorEntity;

    @Input()
    public submitted = false;


    public jointCoefficientBj!: InputsNumericTextBoxProps;
    public effectiveArea!: InputsNumericTextBoxProps;
    public effectiveAreaAISC!: InputsNumericTextBoxProps;
    public loadDistributionAngle!: InputsNumericTextBoxProps;
    public alphaCC!: InputsNumericTextBoxProps;
    public limitPlasticStrain!: InputsNumericTextBoxProps;
    public divisionOfSurfaceOfCHS!: InputsNumericTextBoxProps;
    public divisionOfArcsOfRHS!: InputsNumericTextBoxProps;
    public numberOfElementsOfEdge!: InputsNumericTextBoxProps;
    public numberOfAnalysisIterations!: InputsNumericTextBoxProps;
    public divergentIterationsCount!: InputsNumericTextBoxProps;
    public minimumSizeOfElement!: InputsNumericTextBoxProps;
    public maximumSizeOfElement!: InputsNumericTextBoxProps;
    public useULSStresses!: RadioButtonProps<boolean>;
    public concreteInCompressionMethod!: DropdownProps<number>;

    public componentInitialized = false;
    public isLoaded = false;

    constructor(
        private localization: LocalizationService,
        private userSettings: UserSettingsService,
        private codeList: CodeListService
    ) { }

    public get euBased() {
        return this.selectedSteelGuideline == SteelGuideline.EU;
    }

    public get stoBased() {
        return this.selectedSteelGuideline == SteelGuideline.STO;
    }

    public get auBased() {
        return this.selectedSteelGuideline == SteelGuideline.AU;
    }

    public get aciBased() {
        return this.selectedSteelGuideline == SteelGuideline.ACI;
    }

    public get inBased() {
        return this.selectedSteelGuideline == SteelGuideline.IN;
    }

    public get advancedCalculationDefaultValues() {
        return this.advancedCalculationInputs?.defaultAdvancedCalculationValues;
    }

    public get isConcreteBlockAvailable() {
        if (!this.euBased && !this.stoBased && !this.auBased && !this.inBased && !this.aciBased) {
            return false;

        }
        if (this.advancedCalculationInputs?.isApplicationSettings) {
            return true;
        }

        return this.advancedCalculationInputs?.designTypeId == DesignTypeId.Concrete || this.advancedCalculationInputs?.designTypeId == DesignTypeId.MetalDeck;
    }

    public get isAddEditDesign() {
        return !(this.advancedCalculationInputs?.isApplicationSettings ?? false);
    }

    private get numericTextBoxDecimalSeparator() {
        if (this.decimalSeparator == null) {
            return getNumberDecimalSeparator(this.localization.numberFormat(), this.userSettings);
        }

        return this.decimalSeparator.character;
    }

    private get numericTextBoxGroupSeparator() {
        if (this.groupSeparator == null) {
            return getNumberGroupSeparator(this.localization.numberFormat(), this.userSettings);
        }

        return this.groupSeparator.character;
    }

    public ngOnChanges(): void {
        if (!this.isLoaded) {
            this.initializeProps();
            this.isLoaded = true;
        }

        this.update();
    }

    private initializeProps() {
        this.jointCoefficientBj = this.createNumericTextBox('jointCoefficientBj', 'JointCoefficientBj',
            undefined,
            PropertyMetaData.AdvancedBaseplateCalculation_JointCoefficientBj);

        this.effectiveAreaAISC = this.createNumericTextBox('EffectiveAreaAISC', 'EffectiveAreaAISC',
            undefined,
            PropertyMetaData.AdvancedBaseplateCalculation_EffectiveAreaAISC);

        this.effectiveArea = this.createNumericTextBox('effectiveArea', 'EffectiveArea',
            undefined,
            PropertyMetaData.AdvancedBaseplateCalculation_EffectiveArea);

        this.loadDistributionAngle = this.createNumericTextBox('loadDistributionAngle', 'LoadDistributionAngle',
            undefined,
            PropertyMetaData.AdvancedBaseplateCalculation_LoadDistributionAngle);

        this.alphaCC = this.createNumericTextBox('alphaCC', 'AlphaCC',
            undefined,
            PropertyMetaData.AdvancedBaseplateCalculation_AlphaCC);

        this.limitPlasticStrain = this.createNumericTextBox('limitPlasticStrain', 'LimitPlasticStrain',
            undefined,
            PropertyMetaData.AdvancedBaseplateCalculation_LimitPlasticStrain);

        this.divisionOfSurfaceOfCHS = this.createNumericTextBox('divisionOfSurfaceOfCHS', 'DivisionOfSurfaceOfCHS',
            undefined,
            PropertyMetaData.AdvancedBaseplateCalculation_DivisionOfSurfaceOfCHS);

        this.divisionOfArcsOfRHS = this.createNumericTextBox('divisionOfArcsOfRHS', 'DivisionOfArcsOfRHS',
            undefined,
            PropertyMetaData.AdvancedBaseplateCalculation_DivisionOfArcsOfRHS);

        this.numberOfElementsOfEdge = this.createNumericTextBox('numberOfElementsOfEdge', 'NumberOfElementsOfEdge',
            undefined,
            PropertyMetaData.AdvancedBaseplateCalculation_NumberOfElementsOfEdge);

        this.numberOfAnalysisIterations = this.createNumericTextBox('numberOfAnalysisIterations', 'NumberOfAnalysisIterations',
            undefined,
            PropertyMetaData.AdvancedBaseplateCalculation_NumberOfAnalysisIterations);

        this.divergentIterationsCount = this.createNumericTextBox('divergentIterationsCount', 'DivergentIterationsCount',
            undefined,
            PropertyMetaData.AdvancedBaseplateCalculation_DivergentIterationsCount);

        this.minimumSizeOfElement = this.createNumericTextBox('minimumSizeOfElement', 'MinimumSizeOfElement',
            undefined,
            PropertyMetaData.AdvancedBaseplateCalculation_MinimumSizeOfElement);

        this.maximumSizeOfElement = this.createNumericTextBox('maximumSizeOfElement', 'MaximumSizeOfElement',
            undefined,
            PropertyMetaData.AdvancedBaseplateCalculation_MaximumSizeOfElement);

        this.useULSStresses = {
            id: 'advanced-calculation-useULSStresses',
            title: this.localization.getString('Agito.Hilti.Profis3.ApplicationSettings.AdvancedCalculation.UseULSStresses'),
            items: [
                {
                    id: 'advanced-calculation-useULSStressesULSStress',
                    text: this.localization.getString('Agito.Hilti.Profis3.ApplicationSettings.AdvancedCalculation.UseULSStresses.ULSStress'),
                    value: true,
                    disabled: this.submitted
                },
                {
                    id: 'advanced-calculation-useULSStressesMaximumStress',
                    text: this.localization.getString('Agito.Hilti.Profis3.ApplicationSettings.AdvancedCalculation.UseULSStresses.MaximumStress'),
                    value: false,
                    disabled: this.submitted
                }
            ]
        };

        this.concreteInCompressionMethod = this.createDropdownComponent('concreteInCompressionMethod', 'ConcreteInCompressionMethod',
            this.codeList.projectCodeLists[ProjectCodeList.ConcreteInCompressionMethod].map(item => ({
                value: item.id ?? 0,
                text: this.localization.getString(item.nameResourceKey ?? ''),
            })));

        // BUDQBP-11709: Disable for now. Delete when implemented correctly.
        // Fix in InputCalculator.cs as well. To be read from input.
        this.divisionOfSurfaceOfCHS.disabled = true;
        this.divisionOfSurfaceOfCHS.value = undefined;
    }

    private update() {
        this.alphaCC.placeholder = this.advancedCalculationDefaultValues?.alphaCC ?? PropertyMetaData.AdvancedBaseplateCalculation_AlphaCC.defaultValue;

        if (this.advancedCalculationData.concreteInCompressionMethod == null) {
            this.advancedCalculationData.concreteInCompressionMethod = this.advancedCalculationInputs?.defaultAdvancedCalculationValues?.concreteInCompressionMethod;
        }
    }

    public createNumericTextBox(id: string, translationKey: string, value: number | undefined, metaData: IPropertyMetaData, disabled = false) {
        const numericTextBox: InputsNumericTextBoxProps = {
            id: `advanced-calculation-${id}`,
            title: this.localization.getString(`Agito.Hilti.Profis3.ApplicationSettings.AdvancedCalculation.${translationKey}`),
            value,
            minValue: metaData.minValue,
            maxValue: metaData.maxValue,
            decimalSeparator: this.numericTextBoxDecimalSeparator,
            groupSeparator: this.numericTextBoxGroupSeparator,
            placeholder: metaData.defaultValue,
            disabled
        };

        return numericTextBox;
    }

    public createDropdownComponent<TValue>(id: string, translationKey: string, items: DropdownItem<TValue>[], selectedValue: TValue | undefined = undefined) {
        const dropdown: DropdownProps<TValue> = {
            id: `advanced-calculation-${id}`,
            title: this.localization.getString(`Agito.Hilti.Profis3.ApplicationSettings.AdvancedCalculation.${translationKey}`),
            items,
            selectedValue
        };

        return dropdown;
    }

    public getNumericTextBoxUnit(property: string) {
        switch (property) {
            case 'loadDistributionAngle':
                return Unit.degree;

            case 'limitPlasticStrain':
                return Unit.percent;

            case 'minimumSizeOfElement':
            case 'maximumSizeOfElement': {
                const selectedRegion = this.codeList.getCommonRegionById(this.advancedCalculationInputs?.selectedRegionId);
                return this.advancedCalculationInputs?.lengthUnit ?? selectedRegion.defaultUnitLength;
            }

            default:
                return Unit.None;
        }
    }
}
