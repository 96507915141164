import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonRegion } from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import { KnownRegion } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import { RegionMarketingCampaign } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.ProductInformationService.Shared.Entities';
import { stringNullOrEmpty } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { CommonCodeList } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import { ButtonEventType } from '@profis-engineering/pe-ui-common/services/common-tracking.common';
import { CollapsingControls } from '../../../entities/collapsing-controls';
import { CommonCodeListService } from '../../../services/common-code-list.service';
import { CommonTrackingService } from '../../../services/common-tracking.service';
import { ExternalAppsService, ProductInfoHiltiApplicationEntity } from '../../../services/external-apps.service';
import { LocalizationService } from '../../../services/localization.service';
import { OfflineService } from '../../../services/offline.service';
import { ProductInformationService } from '../../../services/product-information.service';
import { UserSettingsService } from '../../../services/user-settings.service';
import { ISectionsCollapsed } from '../home-page.common';

export interface ISectionCollapsed {
    control: CollapsingControls;
    collapsed: boolean;
}

@Component({
    selector: 'app-right-panel',
    templateUrl: './right-panel.component.html',
    styleUrls: ['./right-panel.component.scss']
})
export class RightPanelComponent implements OnInit, OnChanges {

    @Input()
    public virtaulTourInProgress: boolean;

    public isDownloadEnabled = false;
    public isAskHiltiEnabled = false;
    public isHiltiAcademyEnabled = false;
    public isApplicationImageAvailable = false;
    public AskHiltiLink = "";
    public LearningLink = "";
    public LearningImage = "";
    public isOffline = false;
    public inAppCampaigns: RegionMarketingCampaign[];
    public _hiltiApplications: ProductInfoHiltiApplicationEntity[];
    public sectionsCollapsed: ISectionsCollapsed = {
        inAppMarketing: {
            collapsed: false,
            control: CollapsingControls.InAppMarketing
        },
        hiltiApplications: {
            collapsed: false,
            control: CollapsingControls.HiltiAplications
        },
        learning: {
            collapsed: false,
            control: CollapsingControls.Learning
        }
    };

    constructor(
        private productInformationService: ProductInformationService,
        private localizationService: LocalizationService,
        private userSettingsService: UserSettingsService,
        private offlineService: OfflineService,
        private commonTrackingService: CommonTrackingService,
        public sanitizerService: DomSanitizer,
        public externalAppsService: ExternalAppsService,
        private codeListCommonService: CommonCodeListService,
    ) {
        this.userSettingsService.userSettingsSaved.subscribe(() => {
            setTimeout(() => {
                this.init();
            });
        });
    }

    public ngOnInit() {
       this.init();
    }

    ngOnChanges() {
        this.isDownloadEnabled = this.hiltiApplications == undefined ? false : this.hiltiApplications?.length != 0;
    }

    init() {
        if (!this.offlineService.isOffline) {
            this.sectionsCollapsed.inAppMarketing.collapsed = this.userSettingsService.isSectionCollapsed(this.sectionsCollapsed.inAppMarketing.control);
            this.sectionsCollapsed.hiltiApplications.collapsed = this.userSettingsService.isSectionCollapsed(this.sectionsCollapsed.hiltiApplications.control);
            this._hiltiApplications = this.externalAppsService.hiltiApplications;
            this.isDownloadEnabled = this.hiltiApplications?.length != 0;
            this.getMarketingCampaigns();
        }
        this.isOffline = this.offlineService.isOffline;
        this.sectionsCollapsed.learning.collapsed = this.userSettingsService.isSectionCollapsed(this.sectionsCollapsed.learning.control);
        this.getHiltiLinks();
    }

    public getHiltiLinks() {
        const regionalLinks = this.productInformationService.regionLinksForUser();
        if (regionalLinks) {
            if (regionalLinks.AskHiltiLink) {
                this.isAskHiltiEnabled = true;
                this.AskHiltiLink = regionalLinks.AskHiltiLink;
            }
            else {
                this.isAskHiltiEnabled = false;
            }

            if (regionalLinks.BetLearningLink) {
                this.isHiltiAcademyEnabled = true;
                this.LearningLink = regionalLinks.BetLearningLink;
                this.LearningImage = regionalLinks.LearningImage;
            }
            else {
                this.isHiltiAcademyEnabled = false;
            }
        }
        return regionalLinks;
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public onSectionCollapsedChange(section: ISectionCollapsed, collapsed: boolean) {
        section.collapsed = collapsed;
        this.userSettingsService.setSectionCollapsed(section.control, collapsed);
    }

    public campaignName(campaign: RegionMarketingCampaign): string {
        return `Marketing Campaign ${campaign.MarketingCampaignId}`;
    }

    public trackInAppCampaignClick(campaign: RegionMarketingCampaign) {
        this.commonTrackingService.trackPopupLinkClicked(campaign?.CampaignType, this.campaignName(campaign), campaign?.Link);
    }

    public async trackAllPopups() {
        const trackingPromises: Promise<any>[] = [];
        this.inAppCampaigns?.forEach(x => {
            trackingPromises.push(this.commonTrackingService.trackPopupShown(x.CampaignType, this.campaignName(x)));
        });
        await Promise.all(trackingPromises);
    }

    public setInAppMarketingCampaign(): void {
        this.inAppCampaigns = this.productInformationService.getInAppCampaigns();
        if (this.userSettingsService.settings.application.general.isNewUser.value) {
            this.inAppCampaigns.push(...this.productInformationService.getInAppCampaignsForNewUser());
            this.userSettingsService.settings.application.general.isNewUser.value = false;
            this.userSettingsService.save();
        }
        this.trackAllPopups();
    }
    public get hiltiApplications() {
        return this.virtaulTourInProgress ? this.virtualTourApplications : this._hiltiApplications;
    }

    onAskHiltiClick() {
        this.offlineService.nativeExternalLinkOpen(this.productInformationService.regionLinksForUser()?.AskHiltiLink ?? '');
        this.commonTrackingService.trackOnButtonClick(ButtonEventType.AskHiltiClicked);
    }

    onDownloadClick(application: ProductInfoHiltiApplicationEntity) {
        const url = this.externalAppsService.getHiltiApplicationLink(application);
        if (stringNullOrEmpty(url ?? '')) {
            return;
        }
        this.offlineService.nativeExternalLinkOpen(url);

        this.commonTrackingService.trackOnDownloadApplication(application.TrackingId);
    }

    onHiltiAcademyClick() {
        this.offlineService.nativeExternalLinkOpen(this.productInformationService.regionLinksForUser()?.BetLearningLink ?? '');
        const region = this.getRegionById(this.userSettingsService.settings.application.general.regionId.value);
        this.commonTrackingService.trackOnButtonClick(ButtonEventType.ELearnClicked, region.displayKey);
    }

    public onHiltiOnlineClick() {
        const selectedRegion = this.getRegionById(this.userSettingsService.settings.application.general.regionId.value);
        const hiltiOnlineUrl = selectedRegion.hiltiOnlineUrl ? this.getRegionById(KnownRegion.UnitedStates).hiltiOnlineUrl : selectedRegion.hiltiOnlineUrl;
        this.offlineService.nativeExternalLinkOpen(hiltiOnlineUrl);
    }

    private getMarketingCampaigns() {
        if (!this.offlineService.isOffline) {
            this.setInAppMarketingCampaign();
        }
    }

    private getRegionById(regionId: number) {
        const regions = this.codeListCommonService.commonCodeLists[CommonCodeList.Region] as CommonRegion[];
        return regions.find(region => region.id == regionId);
    }

    private get virtualTourApplications() {
        return [{
            DisplayKey: 'PEDesktop',
            SubtitleKey: 'PEDesktopSubtitle',
            Id: 1
        }] as ProductInfoHiltiApplicationEntity[];
    }
}
