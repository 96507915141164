import sortBy from 'lodash-es/sortBy';
import { Subscription } from 'rxjs';

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
    DropdownItem, DropdownProps
} from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import {
    CodeList, getCodeListTextDeps
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { Language } from '@profis-engineering/pe-ui-common/entities/code-lists/language';
import {
    Separator as SeparatorEntity
} from '@profis-engineering/pe-ui-common/entities/code-lists/separator';
import { Separator } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import {
    LanguageCulture
} from '@profis-engineering/pe-ui-common/services/localization.common';

import { CommonRegion } from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import { sortByUnicode } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { CommonCodeList } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import { environment } from '../../../environments/environment';
import { IAppSettingsRegionLanguageData } from '../../helpers/app-settings-helper';
import { CodeListService } from '../../services/code-list.service';
import { CommonCodeListService } from '../../services/common-code-list.service';
import { LocalizationService } from '../../services/localization.service';
import { NumberService } from '../../services/number.service';
import { UserSettingsService } from '../../services/user-settings.service';

@Component({
    selector: 'app-app-settings-region-language',
    templateUrl: './app-settings-region-language.component.html',
    styleUrls: ['../app-settings/app-settings-section.component.scss']
})
export class AppSettingsRegionLanguageComponent implements OnInit, OnDestroy {
    @Input()
    public submitted: boolean;

    @Input()
    public regionLanguageValues: IAppSettingsRegionLanguageData;

    @Output()
    public regionChange = new EventEmitter<CommonRegion>();

    public languageDropdown: DropdownProps<Language>;
    public regionDropdown: DropdownProps<CommonRegion>;
    public decimalSeparatorDropdown: DropdownProps<SeparatorEntity>;
    public thousandsSeparatorDropdown: DropdownProps<SeparatorEntity>;

    private localizationChangeSubscription: Subscription;

    constructor(
        private localization: LocalizationService,
        private userSettings: UserSettingsService,
        private codeList: CodeListService,
        private commonCodeList: CommonCodeListService,
        private numberService: NumberService
    ) { }

    ngOnInit(): void {
        const codeListDeps = getCodeListTextDeps(this.localization, this.numberService);

        // Languages
        const languageCodeList = this.getCommonCodeList<Language>(CommonCodeList.Language);
        let languageItems: DropdownItem<Language>[] = null;
        if (environment.translate) {
            const pseudoLanguageCodeList = languageCodeList.find(languageCodeListItem => languageCodeListItem.culture == LanguageCulture.pseudoLanguage);

            languageItems = [({
                value: pseudoLanguageCodeList,
                text: 'Pseudo'
            }) as DropdownItem<Language>];
        }
        else {
            const nonPseudoLanguageCodeList = languageCodeList.filter(languageCodeListItem => languageCodeListItem.culture != LanguageCulture.pseudoLanguage);
            languageItems = nonPseudoLanguageCodeList.map(languageCodeListItem => {
                return {
                    value: languageCodeListItem,
                    text: languageCodeListItem.getTranslatedNameText(codeListDeps)
                } as DropdownItem<Language>;
            });
        }

        // sort languages
        languageItems = sortBy(languageItems, languageItem => (languageItem.text || '').toLowerCase());
        this.languageDropdown = this.createDropdownComponent('language', 'Language', languageItems);


        // Region
        // sorting dropdown items on construction
        const regions = this.getCommonCodeList<CommonRegion>(CommonCodeList.Region);
        const regionCodeList = sortByUnicode(
            regions.map(
                (region) => {
                    return {
                        region,
                        text: region.getTranslatedNameText(codeListDeps)
                    };
                }
            ),
            'text'
        ).map(
            (o) => {
                return o.region;
            }
        );

        this.localizationChangeSubscription = this.localization.localizationChange.subscribe(() => {
            this.regionDropdown.items = sortByUnicode(this.regionDropdown.items, 'text');
        });

        this.regionDropdown = this.createDropdownComponent('region', 'Region',
            regionCodeList.map(region => ({
                value: region,
                text: this.localization.getString(region.nameResourceKey)
            }) as DropdownItem<CommonRegion>));

        // Decimal separator
        const decimalSeparatorItems = this.getCommonCodeList(CommonCodeList.DecimalSeparator).map(item => ({
            value: item,
            text: this.localization.getString(item.nameResourceKey)
        }) as DropdownItem<SeparatorEntity>);

        this.decimalSeparatorDropdown = this.createDropdownComponent(
            'decimalSeparator',
            'DecimalSeparator',
            decimalSeparatorItems);

        // Thousands separator
        const thousandSeparatorItems = this.getCommonCodeList(CommonCodeList.ThousandsSeparator).map(item => ({
            value: item,
            text: this.localization.getString(item.nameResourceKey)
        }) as DropdownItem<SeparatorEntity>);

        this.thousandsSeparatorDropdown = this.createDropdownComponent(
            'thousandSeparator',
            'ThousandsSeparator',
            thousandSeparatorItems);
    }

    ngOnDestroy(): void {
        // unsubscribe from this.localization.subject
        if (this.localization != null) {
            this.localizationChangeSubscription.unsubscribe();
        }
    }

    public onRegionDropdownChange(region: CommonRegion) {
        this.regionLanguageValues.region = region;
        this.regionChange.emit(region.cloneDeep());
    }

    public onDecimalSeparatorDropdownChange(value: SeparatorEntity) {
        this.regionLanguageValues.decimalSeparator = value;

        if (value.id == Separator.Default) {
            this.regionLanguageValues.thousandsSeparator = (this.thousandsSeparatorDropdown.items).find((item) => item.value.id == Separator.Default).value;
        }
        else if (this.regionLanguageValues.thousandsSeparator.id == value.id || this.regionLanguageValues.thousandsSeparator.id == Separator.Default) {
            this.regionLanguageValues.thousandsSeparator = (this.thousandsSeparatorDropdown.items).find((item) => item.value.id != Separator.Default && item.value.id != value.id).value;
        }
    }

    public onThousandsSeparatorDropdownChange(value: SeparatorEntity) {
        this.regionLanguageValues.thousandsSeparator = value;

        if (value.id == Separator.Default) {
            this.regionLanguageValues.decimalSeparator = this.decimalSeparatorDropdown.items.find((item) => item.value.id == Separator.Default).value;
        }
        else if (this.regionLanguageValues.decimalSeparator.id == value.id || this.regionLanguageValues.decimalSeparator.id == Separator.Default) {
            this.regionLanguageValues.decimalSeparator = this.decimalSeparatorDropdown.items.find((item) => item.value.id != Separator.Default && item.value.id != value.id).value;
        }
    }

    private getCommonCodeList<TEntity extends CodeList>(name: CommonCodeList): TEntity[] {
        return this.commonCodeList.commonCodeLists[name] as TEntity[];
    }

    private createDropdownComponent<TValue>(id: string, translationKey: string, items?: DropdownItem<TValue>[], selectedValue?: TValue) {
        const dropdown: DropdownProps<TValue> = {
            id: `application-settings-${id}`,
            title: translationKey ? this.localization.getString(`Agito.Hilti.Profis3.ApplicationSettings.${translationKey}`) : undefined,
            items,
            selectedValue
        };

        return dropdown;
    }
}
