import { Component, OnInit } from '@angular/core';
import {
    CheckboxButtonProps
} from '@profis-engineering/pe-ui-common/components/checkbox-button/checkbox-button.common';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import {
    SimpleCheckboxButtonHelper
} from '@profis-engineering/pe-ui-common/helpers/simple-checkbox-button-helper';
import {
    KnownRegion
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';

import { CommonRegion } from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import { RegionLanguage } from '@profis-engineering/pe-ui-common/entities/code-lists/region-language';
import { LocalizationService } from '../../services/localization.service';
import { UserSettingsService } from '../../services/user-settings.service';

@Component({
    selector: 'app-user-agreement',
    templateUrl: './user-agreement.component.html',
    styleUrls: ['./user-agreement.component.scss']
})
export class UserAgreementComponent implements OnInit {
    public endUserAgreementCheckbox: CheckboxButtonProps<boolean>;
    public hiltiDataPrivacyAgreementCheckbox: CheckboxButtonProps<boolean>;
    public customerAcceptanceCheckbox: CheckboxButtonProps<boolean>;
    public partyAcceptanceCheckbox: CheckboxButtonProps<boolean>;
    public userAgreementUrl: string;

    public pendingSave: boolean;

    private region: CommonRegion;
    private regionLanguage: RegionLanguage;
    private submitted: boolean;

    constructor(
        public localization: LocalizationService,
        private userSettings: UserSettingsService,
        private modalInstance: ModalInstance
    ) { }

    public get isCustomerAcceptanceEnabled() {
        return this.region.id == KnownRegion.Italy;
    }

    public get isContinueDisabled() {
        return this.pendingSave
            || this.isContinueDisabledInternal;
    }

    private get isContinueDisabledInternal() {
        // If the Customer Acceptance is disabled (e.g. selected region is something other than Italy), only privacy
        // and user agreement checkboxes are shown; otherwise there are four checkboxes to check

        const retVal = !SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.hiltiDataPrivacyAgreementCheckbox)
            || !SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.endUserAgreementCheckbox);

        if (retVal) {
            return true;
        }

        return this.isCustomerAcceptanceEnabled
            &&
            (
                !SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.customerAcceptanceCheckbox)
                || !SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.partyAcceptanceCheckbox)
            );
    }

    ngOnInit(): void {
        this.region = this.userSettings.getCommonRegionById(this.userSettings.settings.application.general.regionId.value);
        this.regionLanguage = this.userSettings.getRegionLanguage();

        this.userAgreementUrl = this.calculateUserAgreementUrl();

        this.endUserAgreementCheckbox = SimpleCheckboxButtonHelper.createSimpleCheckbox({
            itemText: this.localization.getString('Agito.Hilti.Profis3.UserAgreement.EndUserAgreement')
        });

        this.hiltiDataPrivacyAgreementCheckbox = SimpleCheckboxButtonHelper.createSimpleCheckbox({
            itemText: this.localization.getString('Agito.Hilti.Profis3.UserAgreement.DataPrivacyAgreement')
        });

        this.customerAcceptanceCheckbox = SimpleCheckboxButtonHelper.createSimpleCheckbox({
            itemText: this.localization.getString('Agito.Hilti.Profis3.UserAgreement.CustomerAcceptance')
        });

        this.partyAcceptanceCheckbox = SimpleCheckboxButtonHelper.createSimpleCheckbox({
            itemText: this.localization.getString('Agito.Hilti.Profis3.UserAgreement.PartyAcceptance')
        });

        this.modalInstance.setOnClosing((_) => {
            return this.submitted
                ? true
                : false;
        });
    }

    public continue() {
        if (this.isContinueDisabledInternal) {
            return;
        }

        this.pendingSave = true;

        this.userSettings.settings.application.general.userAgreement.value = true;
        this.userSettings.settings.application.general.isNewUser.value = true;

        this.userSettings.save()
            .finally(() => {
                this.pendingSave = false;
                this.submitted = true;
            })
            .then((_) => {
                this.close();
            });
    }

    public close() {
        this.modalInstance.close();
    }

    public openDataPrivacyLink() {
        const hiltiDataPrivacyUrl = this.regionLanguage.hiltiDataPrivacyUrl;
        window.open(hiltiDataPrivacyUrl, '_blank');
    }

    private calculateUserAgreementUrl() {
        if (this.regionLanguage == null) {
            return null;
        }

        return this.regionLanguage.userAgreement;
    }
}
