import cloneDeep from 'lodash-es/cloneDeep';
import { Subject } from 'rxjs';

import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LogType } from '@profis-engineering/pe-ui-common/services/logger.common';
import {
    IAuthentication, ProjectAndDesignView, UserServiceBase
} from '@profis-engineering/pe-ui-common/services/user.common';
import {
    OnProjectCloseRequest
} from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Tracking.Entities';

import { environment } from '../../environments/environment';
import { supportsFetchApi, supportsReqKeepalive } from '../browser';
import { Design } from '../entities/design';
import { Project } from '../entities/project';
import { ApiService } from './api.service';
import { AppStorageService } from './app-storage.service';
import { LicenseService } from './license.service';
import { LoggerService } from './logger.service';
import { ModalService } from './modal.service';
import { ModulesService } from './modules.service';
import { OfflineService } from './offline.service';
import { SessionStorageService } from './session-storage.service';
import {
    TrackDesignCloseRequest
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.CommonTrackingService.Shared.Entities';
import { stringNullOrEmpty } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { LeftNavigationPrimaryButtons, ILeftNavigationSelectedState } from '../components/home-page/home-page.common';
import { DesignTemplateFolderDetail } from '../components/home-page/template-folder';
import { isValidUrl } from '@profis-engineering/pe-ui-common/helpers/url-helper';

const sharedAuthenticationKey = 'sharedAuthentication';
const authenticationStorageKey = 'authentication';

export const offlineLoginDateStorageKey = 'offlineLoginDate';

@Injectable({
    providedIn: 'root'
})
export class UserService extends UserServiceBase<Design> {
    private _authenticated = new Subject<IAuthentication>();
    public authenticated = this._authenticated.asObservable();

    public fullName: string;
    public occupation: string;
    public projectAndDesignView = ProjectAndDesignView.allDesigns;
    public projectAndDesignViewV2: ILeftNavigationSelectedState = { primarySelection: LeftNavigationPrimaryButtons.AllDesigns };
    public renameFile: boolean;
    public openDesign: boolean;

    public openMarketingCampaign: boolean;
    public authentication: IAuthentication;
    public isAsadEnabled: boolean;
    public isAsadDebugEnabled: boolean;

    private _project: Project;
    private _design: Design;
    private _templateFolder?: DesignTemplateFolderDetail = null;
    private _isCreateTemplate?: boolean;

    constructor(
        private appStorage: AppStorageService,
        private sessionStorage: SessionStorageService,
        private logger: LoggerService,
        private offline: OfflineService,
        private license: LicenseService,
        private apiService: ApiService,
        private modalService: ModalService,
        private modulesService: ModulesService
    ) {
        super();
    }

    public get isAuthenticated() {
        return this.authentication != null &&
            typeof this.authentication == 'object' &&
            !stringNullOrEmpty(this.authentication.accessToken);
    }

    public get isExternalOnlineRussianUser() {
        return !this.offline.isOffline && environment.russianDataProtectionRegulationEnabled && !this.isInternalHiltiUser() && this.authentication?.subscription_info.CountryOfResidence == 'RU';
    }

    public get project() {
        return this._project;
    }

    public get design() {
        return this._design;
    }

    public get templateFolder() {
        return this._templateFolder;
    }

    public get hasFreeLicense() {
        return this.license.isFree();
    }

    public get hasfloatingLimitReached() {
        return this.license.floatingLimitReached;
    }

    public get isCreateTemplate() {
        return this._isCreateTemplate;
    }

    public get hasTrialLicense() {
        // user have trial license
        return this.license.isTrial();
    }

    public get hasOnlyBasic() {
        // user has only basic license (old LMv1 license model -> European regions)
        return this.license.isOnlyBasic();
    }

    /**
     * Users without customerOriginId are considered first time customers and should fill additional registration form.
     */
    public get isFirstTimeCustomer() {
        return !(this.authentication.customerOriginId != null && typeof this.authentication.customerOriginId == 'string' && this.authentication.customerOriginId.length > 0);
    }

    public get isInternalLicenseSwitchAvailable() {
        // Only available when design is not opened.
        return environment.switchInternalLicenseEnabled && this.isInternalHiltiUser() && this.design == null;
    }

    public getHeaders(
        url: string,
        forceIncludeAuthenticationHeaders: boolean
    ) {
        const headers = {} as Record<string, string>;

        // add authentication header if needed
        if (
            environment.authenticationRequired.some((authenticationRequiredUrl) => isValidUrl(authenticationRequiredUrl) && url.startsWith(authenticationRequiredUrl))
            ||
            this.modulesService.serviceRequiresAuthentication(url)
            ||
            forceIncludeAuthenticationHeaders
        ) {

            if (this.isAuthenticated) {

                if (this.authentication.accessToken != null && this.authentication.accessToken != '') {
                    headers['Authorization'] = `Bearer ${this.authentication.accessToken}`;
                }

                if (environment.includeHCHeaders) {
                    // only in development or offline mode
                    // on hilti environment this will be added automatically by OAG

                    // TODO: if external service is called in offline application below headers must not be sent to service
                    // (Sync with user settings service), HOW to test in Agitavit environment
                    if (this.authentication.userId != null &&
                        this.authentication.userId != '') {
                        headers['HC-UID'] = this.authentication.userId;
                    }

                    if (this.authentication.userName != null &&
                        this.authentication.userName != '') {
                        headers['HC-User'] = this.authentication.userName;
                    }

                    if (this.authentication.license != null &&
                        this.authentication.license != '') {
                        headers['HC-License'] = this.authentication.license;
                    }

                    if (this.authentication.customerId != null &&
                        this.authentication.customerId != '') {
                        headers['HC-CustomerID'] = this.authentication.customerId;
                    }

                    if (this.authentication.customerOriginId != null &&
                        this.authentication.customerOriginId != '') {
                        headers['HC-OriginCustomerID'] = this.authentication.customerOriginId;
                    }

                    if (this.authentication.country != null &&
                        this.authentication.country != '') {
                        headers['HC-Country'] = this.authentication.country;
                    }

                    if (this.offline.isOffline) {
                        // only for offline
                        if (this.authentication.externalUserId != null &&
                            this.authentication.externalUserId != '') {
                            headers['HC-ExternalUID'] = this.authentication.externalUserId;
                        }

                        if (this.authentication.externalUserName != null &&
                            this.authentication.externalUserName != '') {
                            headers['HC-ExternalUser'] = this.authentication.externalUserName;
                        }

                        // uri encode username, windows account can contain non-ascii characters
                        if (headers['HC-User'] != null) {
                            headers['HC-User'] = encodeURI(headers['HC-User'] as string);
                        }
                        // uri encode external username, it can contain non ascii characters
                        if (headers['HC-ExternalUser'] != null) {
                            headers['HC-ExternalUser'] = encodeURI(headers['HC-ExternalUser'] as string);
                        }
                    }

                    headers['HC-TransactionId'] = 'Id-a85ce45f2507d3daf2bb5993';
                }
            }
            else {
                return null;
            }
        }

        return headers;
    }

    public changeDesign(project?: Project, design?: Design) {
        if (project !== undefined) {
            this._project = project;
        }

        if (design !== undefined) {
            if (this._design != null && this._design !== design) {
                this._design.dispose();
            }

            this._design = design;
        }
    }

    public unsetProject() {
        this._project = undefined;
    }

    public setIsCreateTemplate(value?: boolean) {
        this._isCreateTemplate = value;
    }

    public setTemplateFolder(templateFolder?: DesignTemplateFolderDetail) {
        this._templateFolder = templateFolder;
    }

    public setAuthenticated(authentication: IAuthentication, remember?: boolean) {
        this.authentication = cloneDeep(authentication);

        // save to session
        this.sessionStorage.set(authenticationStorageKey, authentication);

        if (remember) {
            this.appStorage.set(authenticationStorageKey, authentication);
        }
        else {
            this.appStorage.remove(authenticationStorageKey);
        }

        this.appStorage.set(sharedAuthenticationKey, authentication);
        localStorage.removeItem(sharedAuthenticationKey);

        // trigger authenticated event
        this._authenticated.next(this.authentication);
    }

    public invalidateLocalSessionStorage() {
        this.sessionStorage.remove(authenticationStorageKey);
        this.appStorage.remove(authenticationStorageKey);
    }

    public invalidateAuthentication() {
        this.authentication = null;
        this.invalidateLocalSessionStorage();
    }

    public authenticatedFromStorage() {
        this.authentication =
            this.sessionStorage.get<IAuthentication>(authenticationStorageKey) ||
            this.appStorage.get<IAuthentication>(authenticationStorageKey);

        // Check if offline login is still valid
        if (this.isAuthenticated && this.offline.isOffline) {
            const loginDate = new Date(this.appStorage.get<Date>(offlineLoginDateStorageKey));
            const sixMonthsExpirationDate = new Date(loginDate).setMonth((loginDate.getMonth() + 6));
            const currentDate = new Date();

            // Check if login expired already (6 months)
            if (currentDate.valueOf() > sixMonthsExpirationDate.valueOf()) {
                this.authentication = null;
            }
            // Check if login expires in a month
            else if (new Date(currentDate).setMonth(currentDate.getMonth() + 1).valueOf() > sixMonthsExpirationDate.valueOf()) {
                this.modalService.openDesktopLicenseWarning(true, this.authentication.userName);
            }
        }

        if (!this.isAuthenticated) {
            this.invalidateAuthentication();
        }
        else {
            // trigger authenticated event
            this._authenticated.next(this.authentication);
        }
    }

    public getUserName() {
        return this.authentication?.userName;
    }

    public isInternalHiltiUser() {
        const userName = this.getUserName()?.toLowerCase();

        // that's how hilti cloud filters them
        return userName?.indexOf('@hilti.com') > 0;
    }

    public releaseAllFloatingLicenses(ignoreErrors: boolean) {
        const url = `${environment.peCommonServiceUrl}License/ReleaseAllFloatingLicenses`;
        const request = new HttpRequest('POST', url, null, {
            responseType: 'json'
        });

        return this.apiService.request(request, { supressErrorMessage: true })
            .then(() => { return; })
            .catch((response) => {
                if (!ignoreErrors) {
                    // ignore errors if triggered on browser unload, because ajax might be interrupted
                    // and some other magical stuff might happen - depends on browser
                    this.logger.logServiceError(response, 'LicenseService', 'releaseAllFloatingLicenses');
                }
            });
    }

    public async onProjectCloseBrowserUnload(data: OnProjectCloseRequest) {
        const url = `${environment.baseplateApplicationWebServiceUiApiUrl}OnProjectCloseSync`;
        const jsonData = data != null
            ? JSON.stringify(data)
            : null;

        await this.collectHeadersAndCallProjectCloseBrowserUnload(url, jsonData);
    }

    public async onProjectCloseBrowserUnloadC2C(data: TrackDesignCloseRequest) {
        const url = `${environment.peTrackingServiceUrl}Tracking/TrackOnProjectDesignCloseSync`;
        const jsonData = data != null
            ? JSON.stringify(data)
            : null;

        await this.collectHeadersAndCallProjectCloseBrowserUnload(url, jsonData);
    }

    public async collectHeadersAndCallProjectCloseBrowserUnload(url: string, jsonData: string) {
        const httpHeaders = this.getHeaders(url, true);
        const headers: [string, string][] = [];
        Object.entries(httpHeaders).forEach(([key, value]) => {
            headers.push([key, value]);
        });

        headers.push(['Accept', 'application/json;odata=verbose']);
        headers.push(['Content-Type', 'application/json;charset=UTF-8']);

        // Unloading design
        this.logger.log(`Browser: Fetch API supported: ${supportsFetchApi}, keepalive supported: ${supportsReqKeepalive}`, LogType.debug);

        if (supportsFetchApi && supportsReqKeepalive) {
            // Browser supports Fetch API and keepalive
            await this.onProjectCloseBrowserUnloadFetch(url, jsonData, headers);
        }
        else {
            await this.onProjectCloseBrowserUnloadSync(url, jsonData, headers);
        }
    }

    private async onProjectCloseBrowserUnloadFetch(url: string, jsonData: string, headers: [string, string][]) {
        this.logger.log('Using Fetch API.', LogType.debug);

        const req: RequestInit = {
            method: 'POST',
            headers,
            body: jsonData,
            cache: 'no-cache',
            redirect: 'follow',
            credentials: 'include',

            keepalive: true,
            mode: 'cors',
        };

        try {
            const response = await fetch(url, req);
            if (response.ok !== true) {
                throw response;
            }
        }
        catch (error) {
            if (typeof error == 'object' && 'message' in error && error.message == 'Failed to fetch') {
                console.error(error);

                // Some browsers (Chrome, Chromium?) do not support combining
                // CORS with keepalive. Fallback to the XHR method.
                await this.onProjectCloseBrowserUnloadSync(url, jsonData, headers);
            }
            else {
                throw error;
            }
        }
    }

    private async onProjectCloseBrowserUnloadSync(url: string, jsonData: string, headers: string[][]) {
        this.logger.log('Using Sync XHR.', LogType.debug);

        const xhr = new XMLHttpRequest();
        xhr.open('POST', url, false);
        headers.forEach(([key, value]) => xhr.setRequestHeader(key, value));

        if (jsonData != null) {
            xhr.send(jsonData);
        }
        else {
            xhr.send();
        }

        if (xhr.readyState != 4 || xhr.status >= 400) {
            throw xhr;
        }
    }
}
