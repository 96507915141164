import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { ApiService } from './api.service';
import { ApplicationVersionsService } from './application-versions.service';

export interface IModuleVersionData {
    /** Module name */
    moduleName: string;

    /** Module version */
    moduleVersion: string;

    /** Build version */
    version?: string;
}

export interface IWindowsModuleDefinition extends Window {
    environmentCommon: IModuleVersionData;
    environmentC2C: IModuleVersionData;
    environmentPe: IModuleVersionData;
    environmentDecking: IModuleVersionData;
    environmentCW: IModuleVersionData;
    environmentSP: IModuleVersionData;
    environmentGlass: IModuleVersionData;
    environmentMasonryRnf: IModuleVersionData;
}

export interface IServiceVersionDetails {
    name: string;
    description: string;
    version: string;
}

export interface IModuleVersionDetails extends IServiceVersionDetails {
    cluster: string;
    buildVersion: string;
}

export interface IVersionDetailsService {
    getClusterDetails(): Promise<IClusterDetails[]>;
    getModulesData(): Promise<IModuleVersionDetails[]>;
    getCommonServicesData(): Promise<IServiceVersionDetails[]>;
}

export interface IVersionApiUrl {
    version: string;
}

interface IClusterDetails {
    moduleName: string;
    cluster: string;
}

interface IModuleInfoConfig {
    name: string;
    description: string;
    getModuleVersionData: (data: IWindowsModuleDefinition) => Promise<IModuleVersionData>;
    getModuleClusterData: (data: IClusterDetails[]) => IClusterDetails;
}

interface ICommonServiceUrlConfig {
    url: string;
    serviceName: string;
    serviceDescription: string;
}

@Injectable({
    providedIn: 'root'
})
// This class reads the versions of all the modules and common services as well as cluster info.
// To add a module:
// - add a new entry to createModuleInfoConfigList() method
// To add a common service:
// - add a new entry to createUrlList() method
export class VersionDetailsService implements IVersionDetailsService {
    private pattern = /\/+$/;


    constructor(
        private apiService: ApiService,
        private applicationVersionsService: ApplicationVersionsService
    ) { }


    public async getClusterDetails(): Promise<IClusterDetails[]> {
        const options = { supressErrorMessage: true };

        try {
            const result = await this.apiService.request<IClusterDetails[]>(new HttpRequest('GET', `${environment.baseUrl}/api/cluster`), options);
            if (result?.body != null) {
                return result.body;
            }
        } catch (error) {
            console.error(error);
        }

        return Promise.resolve([]);
    }

    public async getModulesData(): Promise<IModuleVersionDetails[]> {
        const versionList: IModuleVersionDetails[] = [];

        const clusterDetails = await this.getClusterDetails();
        const wdw = window as unknown as IWindowsModuleDefinition;

        const modules = this.createModuleInfoConfigList();
        for (const module of modules) {
            this.addModuleInformation(
                versionList,
                await module.getModuleVersionData(wdw) ?? undefined,
                module.name,
                module.description,
                module.getModuleClusterData(clusterDetails)?.cluster
            );
        }

        versionList.sort((n1, n2) => {
            if (n1.name > n2.name) {
                return 1;
            }

            if (n1.name < n2.name) {
                return -1;
            }

            return 0;
        });
        return versionList;
    }

    public async getCommonServicesData(): Promise<IServiceVersionDetails[]> {
        const versionList: IServiceVersionDetails[] = [];
        const urlList = this.createCommonServiceUrlList();

        await Promise.all(urlList.map(async (urlDetail) => {
            let result: IVersionApiUrl | undefined = undefined;

            try {
                result = await this.getVersionFromService(urlDetail.url);
            } catch (error) {
                console.warn(error);
            }

            if (result != null){
                versionList.push({
                    name: urlDetail.serviceName,
                    description: urlDetail.serviceDescription,
                    version: result.version
                });
            }
        }));

        versionList.sort((n1, n2) => {
            if (n1.name > n2.name) {
                return 1;
            }

            if (n1.name < n2.name) {
                return -1;
            }

            return 0;
        });
        return versionList;
    }


    private createModuleInfoConfigList() {
        const moduleList: IModuleInfoConfig[] = [];

        // Common
        moduleList.push({
            name: 'Common',
            description: 'Common services',
            getModuleVersionData: async (data: IWindowsModuleDefinition) => {
                const retVal: IModuleVersionData = data.environmentCommon;
                const commonModuleVersion = await this.applicationVersionsService.getCommonModuleVersionFromService();
                if (retVal && commonModuleVersion) {
                    retVal.moduleVersion = commonModuleVersion
                }
                return retVal;
            },
            getModuleClusterData: (data: IClusterDetails[]) => data.find(c => c.moduleName.toLowerCase() == 'common')
        });

        // PE
        moduleList.push({
            name: 'PE',
            description: 'PE',
            getModuleVersionData: (data: IWindowsModuleDefinition) => Promise.resolve(data.environmentPe),
            getModuleClusterData: (data: IClusterDetails[]) => data.find(c => c.moduleName.toLowerCase() == 'pe')
        });

        // C2C
        moduleList.push({
            name: 'C2C',
            description: 'Concrete-to-concrete',
            getModuleVersionData: (data: IWindowsModuleDefinition) => Promise.resolve(data.environmentC2C),
            getModuleClusterData: (data: IClusterDetails[]) => data.find(c => c.moduleName.toLowerCase() == 'c2c')
        });

        // CW
        moduleList.push({
            name: 'CW',
            description: 'Curtain Wall',
            getModuleVersionData: (data: IWindowsModuleDefinition) => Promise.resolve(data.environmentCW),
            getModuleClusterData: (data: IClusterDetails[]) => data.find(c => c.moduleName.toLowerCase() == 'cw')
        });

        // Decking
        moduleList.push({
            name: 'Decking',
            description: 'Decking',
            getModuleVersionData: (data: IWindowsModuleDefinition) => Promise.resolve(data.environmentDecking),
            getModuleClusterData: (data: IClusterDetails[]) => data.find(c => c.moduleName.toLowerCase() == 'decking')
        });

        // S&P
        moduleList.push({
            name: 'S&P',
            description: 'Shear & Punching',
            getModuleVersionData: (data: IWindowsModuleDefinition) => Promise.resolve(data.environmentSP),
            getModuleClusterData: (data: IClusterDetails[]) => data.find(c => c.moduleName.toLowerCase() == 'sp')
        });

        // Glass
        moduleList.push({
            name: 'Glass',
            description: 'Glass',
            getModuleVersionData: (data: IWindowsModuleDefinition) => Promise.resolve(data.environmentGlass),
            getModuleClusterData: (data: IClusterDetails[]) => data.find(c => c.moduleName.toLowerCase() == 'glass')
        });

        // Masonry reinforcement
        moduleList.push({
            name: 'MasonryRnf',
            description: 'Masonry wall reinforcement',
            getModuleVersionData: (data: IWindowsModuleDefinition) => Promise.resolve(data.environmentMasonryRnf),
            getModuleClusterData: (data: IClusterDetails[]) => data.find(c => c.moduleName.toLowerCase() == 'masonryrnf')
        });

        return moduleList;
    }

    private createCommonServiceUrlList(){
        const urlList: ICommonServiceUrlConfig[] = [];

        this.addCommonServiceUrlConfig(urlList, this.getCommonServiceMainUrl(environment.peCommonServiceUrl), 'Common Service', 'pe-common');
        this.addCommonServiceUrlConfig(urlList, this.getCommonServiceMainUrl(environment.documentWebServiceRootUrl), 'Document Service', 'document-service-legacy');
        this.addCommonServiceUrlConfig(
            urlList,
            environment.npsSurveyEnabled ? this.getCommonServiceMainUrl(environment.npsSurveyWebServiceRootUrl) : undefined,
            'NPS Survey Service',
            'nps-service'
        );
        this.addCommonServiceUrlConfig(urlList, this.getCommonServiceMainUrl(environment.peTrackingServiceUrl), 'PE Tracking Service', 'pe-tracking');
        this.addCommonServiceUrlConfig(urlList, this.getCommonServiceMainUrl(environment.productInformationServiceRootUrl), 'Product Information Service', 'product-information-service');
        this.addCommonServiceUrlConfig(urlList, this.getCommonServiceMainUrl(environment.supportServiceRootUrl), 'Support Service', 'support-service');
        this.addCommonServiceUrlConfig(urlList, this.getCommonServiceMainUrl(environment.translationsWebServiceRootUrl), 'Translation Service', 'translation-service');
        this.addCommonServiceUrlConfig(
            urlList,
            environment.trimbleConnectEnabled ? this.getCommonServiceMainUrl(environment.trimbleConnectWebServiceUrl) : undefined,
            'Trimble Connect Service',
            'trimble-connect-service'
        );
        this.addCommonServiceUrlConfig(urlList, this.getCommonServiceMainUrl(environment.userSettingsWebServiceRootUrl), 'User Settings Service', 'user-settings');

        return urlList;
    }


    private addModuleInformation(
        moduleVersionDetailsList: IModuleVersionDetails[],
        module: IModuleVersionData,
        moduleName: string,
        moduleDescription: string,
        clusterName: string
    ){
        if (module != null){
            moduleVersionDetailsList.push({
                name: module?.moduleName ?? moduleName,
                description: moduleDescription ?? '',
                version: module?.moduleVersion ?? 'N/A',
                buildVersion: module?.version ?? '',
                cluster: clusterName ?? 'N/A'
            });
        }
    }

    private getCommonServiceMainUrl(url: string): string {
        const result = url.replace(this.pattern, '');
        return result ?? undefined;
    }

    private addCommonServiceUrlConfig(urlList: ICommonServiceUrlConfig[], url: string, serviceName: string, serviceDescription: string){
        if (url) {
            urlList.push({
                url: `${url}/api/Version`,
                serviceName,
                serviceDescription
            });
        }
    }

    private async getVersionFromService(url: string): Promise<IVersionApiUrl> {
        const options = { supressErrorMessage: true };

        try {
            const result = await this.apiService.request<IVersionApiUrl>(new HttpRequest('GET', url), options);
            if (result?.body != null) {
                return result.body;
            }
        } catch (error) {
            console.error(error);
        }

        return {
            version: 'N/A'
        };
    }
}
