<div class="modal-header">
    <h3 class="modal-title">{{title}}</h3>
    <button id="import-from-checkbot-close-button" type="button" class="close" (click)="close()">
        <span>&times;</span>
    </button>
</div>

<div class="modal-body">
    <div class="content">
        <div class="category-container" *ngFor="let item of checkbotDisplayInfo">
            <div class="sprite sprite-checkbot-{{item.icon}}"></div>
            <div class="text-wrapper">
                <div [innerHTML]="item.title" class="category-title"></div>
                <div [innerHTML]="item.message" class="category-message"></div>
            </div>
            <div class="category-status sprite sprite-mapping-{{item.mappingStatus}}"></div>
        </div>
        <div [innerHTML]="this.reviewMessage" class="status-message" [ngClass]="{'error': !allowDesignCreation}">
        </div>
    </div>
</div>

<div class="modal-footer">
    <button *ngIf="!allowDesignCreation" type="button" id="import-from-checkbot-cancel-button"
        class="modal-button button button-CTA" l10n="Agito.Hilti.Profis3.HomePage.Popup.Cancel" (click)="close()"
        [disabled]="submitted">
    </button>

    <button *ngIf="allowDesignCreation" type="submit" id="import-from-checkbot-continue-button"
        class="modal-button button button-CTA" [disabled]="submitted" (click)="continue(false)">
        <pe-loading-button [loading]="submitted"
            [text]="'Agito.Hilti.Profis3.Integrations.Checkbot.Mapping.Modal.Continue' | l10n:localization.selectedLanguage">
        </pe-loading-button>
    </button>
    <button *ngIf="allowDesignCreation && complexDesignsAvailable" type="submit" id="import-from-checkbot-continue-button"
        class="modal-button button button-CTA" [disabled]="submitted" (click)="continue(true)">
        <pe-loading-button [loading]="submitted"
            [text]="'Agito.Hilti.Profis3.Integrations.Checkbot.Mapping.Modal.ContinueComplexDesigns' | l10n:localization.selectedLanguage">
        </pe-loading-button>
    </button>
</div>