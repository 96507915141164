import { HttpHeaders, HttpRequest } from '@angular/common/http';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, TrackByFunction } from '@angular/core';
import { IMenuItem } from '@profis-engineering/pe-ui-common/components/context-menu/context-menu.common';
import { DropdownItem, DropdownProps } from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import { IProjectAndDesignId } from '@profis-engineering/pe-ui-common/entities/design';
import { IDetailedDisplayDesign as IDetailedDisplayDesignCommon } from '@profis-engineering/pe-ui-common/entities/display-design';
import { Feature } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import { DesignTemplateEntity } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.Entities.DesignTemplate';
import { SafeFunctionInvokerHelper } from '@profis-engineering/pe-ui-common/helpers/safe-function-invoker-helper';
import { formatKeyValue } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { CantOpenDesignBecauseLockedByOtherUser } from '@profis-engineering/pe-ui-common/services/document.common';
import { ProjectAndDesignDesignGroup as DesignGroup } from '@profis-engineering/pe-ui-common/services/user.common';
import { DesignType } from '@profis-engineering/pe-ui-shared/entities/code-lists/design-type';
import { DesignType as DesignTypeEnum } from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import sortBy from 'lodash-es/sortBy';
import trim from 'lodash-es/trim';
import { Subscription } from 'rxjs/internal/Subscription';
import { environment } from '../../../../environments/environment';
import { CollapsingControls } from '../../../entities/collapsing-controls';
import { IDisplayDesign } from '../../../entities/display-design';
import { Document } from '../../../entities/document';
import { Project } from '../../../entities/project';
import { Template } from '../../../entities/template';
import { ApiService } from '../../../services/api.service';
import { BrowserService } from '../../../services/browser.service';
import { DesignTemplateService } from '../../../services/design-template.service';
import { DocumentService, IDesignListItem } from '../../../services/document.service';
import { ErrorHandlerService } from '../../../services/error-handler.service';
import { FeatureVisibilityService } from '../../../services/feature-visibility.service';
import { FeaturesVisibilityInfoService } from '../../../services/features-visibility-info.service';
import { LocalizationService } from '../../../services/localization.service';
import { ModalService } from '../../../services/modal.service';
import { DesignTypeId, ModulesService } from '../../../services/modules.service';
import { NumberService } from '../../../services/number.service';
import { PendingActionService } from '../../../services/pending-action.service';
import { UserSettingsService } from '../../../services/user-settings.service';
import { UserService } from '../../../services/user.service';
import { DesignSort, DisplayDesignType, getCodeListTextDeps, IDisplayDesignChangeOutput, IDisplayDesignInput, IDisplayProject, IGroupedDisplayDesign, ISectionCollapsed, ISectionsCollapsed, LeftNavigationPrimaryButtons, LeftNavigationSecondaryButtons, MenuOptions, PendingAction } from '../home-page.common';
import { DesignTemplateFolderDetail, TemplateDocumentRequestModel } from '../template-folder';
import { virtualTourGroupedDesigns, virtualTourSelectedDesigns } from './design-view.helper';

@Component({
  selector: 'app-design-view',
  templateUrl: './design-view.component.html',
  styleUrls: ['./design-view.component.scss']
})

export class DesignViewComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @Input()
  displayDesignInput: IDisplayDesignInput = {
    leftNavigationSelectedState: { primarySelection: LeftNavigationPrimaryButtons.AllDesigns }
  };

  @Input()
  virtualTourInProgress = false;

  @Output()
  public designsStateChanged = new EventEmitter<IDisplayDesignChangeOutput>();

  @Output()
  public selectedProjectChanged = new EventEmitter<IDisplayProject>();

  @Output()
  public markAsFavoriteClicked = new EventEmitter();
  showDetails = false;
  public readonly PE_LOADING_COLOR = '#524f53'; // $color-iron
  public sortDesignsDropdown: Pick<DropdownProps<DesignSort>, 'items' | 'selectedValue'>;
  public _designSearchValue: string;
  public designDragDisabled = false;
  public deckingEnabled = false;
  public pendingActionDesign: IProjectAndDesignId;
  public pendingAction: PendingAction;
  public pendingActionSubscription: Subscription;
  public groupedDesignsLimitStep = 12;
  public groupedDesignsLimit: { [key: number]: number } = {};
  public readonly typeDesignsMaxHeight = 515;
  private _selectedDesigns = new Array<IDisplayDesign>();
  public groupedDesigns: IGroupedDisplayDesign[];
  public selectedDocument: Document;
  public bulkDesignsMenu = {
    menu: [] as IMenuItem<string>[],
    visibleMenu: [] as IMenuItem<string>[],
    contextMenu: [] as IMenuItem<string>[]
  }
  bulkMenuOptionsList: { [key: number]: IMenuItem<string> } = {};
  public DesignGroup = DesignGroup;
  private designTypes: { [id: string]: DesignType; };
  private designListInfoProvidedSubscription: Subscription;
  menuOptionsList: { [key: number]: IMenuItem<string> } = {};
  public sectionsCollapsed: ISectionsCollapsed = {
    designsView: {
      collapsed: false,
      control: CollapsingControls.DesignsView
    }
  };
  currentOpenMenu: string;
  inprogressList: string[];

  constructor(
    public localization: LocalizationService,
    private numberService: NumberService,
    private featureVisibilityService: FeatureVisibilityService,
    private featuresVisibilityInfo: FeaturesVisibilityInfoService,
    private modulesService: ModulesService,
    public userSettingsService: UserSettingsService,
    private documentService: DocumentService,
    private modal: ModalService,
    private user: UserService,
    private apiService: ApiService,
    private browser: BrowserService,
    private onServiceErrorHandler: ErrorHandlerService,
    private templateService: DesignTemplateService,
    private pendingActionService: PendingActionService
  ) {
    this.designTypes = this.normalizeArray(this.modulesService.designTypes as DesignType[], 'id');

    this.sortDesignsDropdown = {
      selectedValue: this.designSort,
      items: this.initSortDropdownItems()
    };
    this.setMenuOptions();
    this.setbulkMenuOptions();
    this.setBulkDesignSelectMenu();
  }

  openMenu(id: string, show: boolean){
    this.currentOpenMenu = id;
    this.showDetails = show;
  }

  ngOnInit(): void {
    this.deckingEnabled =  this.featureVisibilityService.isFeatureEnabled('Decking_Global') || environment.deckingEnabled;
    this.pendingActionSubscription = this.pendingActionService.pendingActions?.subscribe(value => {
      this.pendingAction = value;
    });

    this.designListInfoProvidedSubscription = this.modulesService.designListInfoProvided.subscribe(() => {
      this.designTypes = this.normalizeArray(this.modulesService.designTypes as DesignType[], 'id');
      this.resetGroupedDesignsLimit();
      this.refreshDesigns();
    });

    this.sectionsCollapsed.designsView.collapsed = this.userSettingsService.isSectionCollapsed(this.sectionsCollapsed.designsView.control);

    this.resize = this.resize.bind(this);
    window.addEventListener('resize', this.resize, false);

    this.localization.localizationChange.subscribe(() => {
      this.sortDesignsDropdown.items = this.initSortDropdownItems();
      this.setMenuOptions();
      this.setbulkMenuOptions();
      this.setBulkDesignSelectMenu();
      this.refreshDesigns();
    });
  }

  ngOnChanges(): void {
    this.designDragDisabled = this.displayDesignInput.leftNavigationSelectedState.primarySelection === LeftNavigationPrimaryButtons.Templates;
    this.refreshDesigns();
    this.setBulkDesignSelectMenu();
    this._selectedDesigns = [];
    if(this.virtualTourInProgress)
      setTimeout(() => {
        this.sectionsCollapsed.designsView.collapsed = false;
    }, 1000);

  }

  ngAfterViewInit(): void {
    setTimeout(() => this.viewContentLoaded());
  }

  ngOnDestroy(): void {
    // Remove subscriptions
    this.designListInfoProvidedSubscription?.unsubscribe();
    this.pendingActionSubscription?.unsubscribe();

    // Remove event listeners
    window.removeEventListener('resize', this.resize, false);
  }

  public onDesignClick(displayDesign: IDisplayDesign) {

    if (this.selectedDesigns?.length) {
      this.selectDesign(displayDesign);
      return;
    }

    if (this.pendingAction != null) {
      return;
    }

    switch (displayDesign.displayDesignType) {
      case DisplayDesignType.design:
        this.openDesign(displayDesign);
        break;
      case DisplayDesignType.template:
        this.newDesignFromTemplate(displayDesign);
        break;
    }
  }

  public async newDesignFromTemplate(displayDesign: IDisplayDesign) {
    if (this.pendingAction != null) {
      return;
    }

    const designsInfo = this.modulesService.getDesignListInfoByDesignType(displayDesign.designType);
    if (designsInfo.newDesignFromTemplate != null) {
      this.pendingActionService.setPendingAction(PendingAction.createDesign);
      this.pendingActionDesign = ({ designId: displayDesign.id, projectId: displayDesign.projectId } as IProjectAndDesignId);
      try {
        await designsInfo.newDesignFromTemplate(displayDesign.id)
            .catch(() => this.clearPendingAction())
            .finally(() => this.clearPendingAction());
      }
      catch (err) {
        console.error(err);
        this.clearPendingAction();
      }
    }
  }

  public async openDesign(displayDesign: IDisplayDesign) {
    this.pendingActionDesign = { designId: displayDesign.id, projectId: displayDesign.projectId };
    this.pendingActionService.setPendingAction(PendingAction.openDesign);
    const documentDesign = this.documentService.findDesignById(displayDesign.id);

    const designsInfo = this.modulesService.getDesignListInfoByDesignType(documentDesign.metaData.designType);

    if (designsInfo?.openFromDocumentDesign != null) {
        try {
            await designsInfo.openFromDocumentDesign(documentDesign).catch((response) => {
                if (response instanceof CantOpenDesignBecauseLockedByOtherUser) {
                  const lockedEx: CantOpenDesignBecauseLockedByOtherUser = response;
                  this.modal.openAlertWarning(
                    this.localization.getString('Agito.Hilti.Profis3.ProjectAndDesing.Alerts.CannotOpenInUseBy.Title'),
                    formatKeyValue(
                      this.localization.getString('Agito.Hilti.Profis3.ProjectAndDesing.Alerts.CannotOpenInUseBy.Description'),
                      {
                        user: lockedEx.username
                      }
                    )
                  );
                }
                this.clearPendingAction(PendingAction.openDesign);
                throw response;
              })
                .finally(() => {
                  this.clearPendingAction(PendingAction.openDesign)
                });
        }
        catch (err) {
            console.error(err);
            this.clearPendingAction(PendingAction.openDesign);
        }
    }
    else {
      throw Error(`Open design not implemented for design type ${documentDesign.metaData.designType}`);
    }
  }

  public getProjectDesignInfoTooltip(design: IDisplayDesign): string {
    if (this.designFavoriteDisabled) {
      return null;
    }
    let projectDesignInfoName = design.rawProject != null ? design.rawProject.getDisplayName(this.localization) : (design.projectName ?? this.localization.getString('Agito.Hilti.Profis3.HomePage.Shared.SharedWithMe'));
    projectDesignInfoName += ' / ' + design.name + ' / ' + design.regionDesignStandardApprovalNumber;
    return projectDesignInfoName;
  }

  private getGroupedDesigns(designView: LeftNavigationPrimaryButtons, designSearchValue: string, sort: DesignSort) {
    const designs = this.getDesigns(designView, designSearchValue, sort);
    let groupedDesigns: IGroupedDisplayDesign[] = [];
    const codeListDeps = getCodeListTextDeps(this.localization, this.numberService);

    // group
    for (const design of designs) {
      const designTypeCodeListItem = design.designType != null ? this.designTypes[design.designType] : null;

      // after modularization will be done, remove this and in case of c2c is not enabled or decking is not enabled (and other design types) handle this in application-provider.service and by setting isEnabled property
      //       and filter out designs here
      if ((((!environment.c2cEnabled && !environment.c2cOverlayDemoEnabled) && design.designType != DesignTypeId.Concrete2Concrete) || (environment.c2cEnabled || environment.c2cOverlayDemoEnabled))
        && (this.deckingEnabled || design.designType !== DesignTypeEnum.DiaphragmDesign)
      ) {
        // no design type
        if (designTypeCodeListItem == null) {
          let group = groupedDesigns.find((groupedDesign) => groupedDesign.designType == null);

          // create container if not created
          if (group == null) {
            groupedDesigns.push(group = {
              designType: null,
              designTypeName: this.translate('Agito.Hilti.Profis3.HomePage.Main.DesignType.Unknown'),
              designs: [],
              designsCount: 0
            });
          }

          group.designsCount++;

          if (group.designs.length < this.groupedDesignsLimit[DesignTypeId.Unknown]) {
            design.contextMenu = this.getContextMenu(design.id);
            group.designs.push(design);
          }
        }
        else {
          let group = groupedDesigns.find((groupedDesign) => groupedDesign.designType != null && groupedDesign.designType == design.designType);

          // create container if not created
          if (group == null) {
            groupedDesigns.push(group = {
              designType: designTypeCodeListItem.id,
              designTypeName: designTypeCodeListItem.getTranslatedNameText(codeListDeps),
              designs: [],
              designsCount: 0
            });
          }

          group.designsCount++;

          if (group.designs.length < this.groupedDesignsLimit[group.designType]) {
            if (this.displayDesignInput.leftNavigationSelectedState.primarySelection == LeftNavigationPrimaryButtons.Templates) {
              design.contextMenu = this.getTemplateContextMenu(design.id);
            } else {
              design.contextMenu = this.getContextMenu(design.id);
            }
            group.designs.push(design);
          }
        }
      }
    }

    // sort based on design type / sort data
    groupedDesigns = this.sortGroupedDesigns(groupedDesigns);

    return groupedDesigns;
  }

  private refreshDesigns() {
    this.groupedDesigns = this.getGroupedDesigns(this.displayDesignInput.leftNavigationSelectedState.primarySelection, this.designSearchValue, this.sortDesignsDropdown.selectedValue);
    const groupedDesignIds = this.groupedDesigns.flatMap(groupedDesign => groupedDesign.designs.map(design => design.id));
    this.inprogressList = this.documentService.getMissingThumbnailList(groupedDesignIds);

    if (this.displayDesignInput.leftNavigationSelectedState.primarySelection === LeftNavigationPrimaryButtons.Templates) {
      this.templateService.getDocumentThumbnails(groupedDesignIds)
        .then(thumbnails => {
          if (thumbnails != null) {
            for (const groupedDesign of this.groupedDesigns) {
              groupedDesign.designs.forEach(design => {
                if (thumbnails[design.id] != null && thumbnails[design.id] != '') {
                  design.thumbnail = thumbnails[design.id];
                }
                this.inprogressList.splice(this.inprogressList.indexOf(design.id), 1);
              });
            }
          }
        });
    }
    else {

      this.documentService.getDocumentThumbnails(groupedDesignIds)
        .then(thumbnails => {
          if (thumbnails != null) {
            for (const groupedDesign of this.groupedDesigns) {
              groupedDesign.designs.forEach(design => {
                if (thumbnails[design.id] != null && thumbnails[design.id] != '') {
                  design.thumbnail = thumbnails[design.id];
                }
                this.inprogressList.splice(this.inprogressList.indexOf(design.id), 1);
              });
            }
          }
        });
    }
  }

  /* #region Get selected navigation designs */
  private getDesigns(designView: LeftNavigationPrimaryButtons, designSearchValue: string, sort: DesignSort) {
    switch (designView) {
      case LeftNavigationPrimaryButtons.AllDesigns:
        return this.filterAndSortDesigns(this.getAllDesigns(), designSearchValue, sort);
      case LeftNavigationPrimaryButtons.MyProjects:
        return this.filterAndSortDesigns(this.getMyProjectDesigns(this.displayDesignInput.selectedProject), designSearchValue, sort);
      case LeftNavigationPrimaryButtons.Drafts:
        return this.filterAndSortDesigns(this.getDrafts(), designSearchValue, sort);
      case LeftNavigationPrimaryButtons.CompanyProjects:
        return this.filterAndSortDesigns(this.getCompanyProjectDesigns(this.displayDesignInput.selectedProject), designSearchValue, sort);
      case LeftNavigationPrimaryButtons.Favourites:
        return this.filterAndSortDesigns(this.getFavoriteDesigns(this.displayDesignInput.selectedProject), designSearchValue, sort);
      case LeftNavigationPrimaryButtons.Shared:
        return this.filterAndSortDesigns(this.getSharedProjectDesigns(this.displayDesignInput.leftNavigationSelectedState.secondarySelection, this.displayDesignInput.selectedProject), designSearchValue, sort);
      case LeftNavigationPrimaryButtons.Templates:
        return this.filterAndSortDesigns(this.getTemplateDesigns(this.displayDesignInput.leftNavigationSelectedState.secondarySelection, this.displayDesignInput.selectedTemplateFolder), designSearchValue, sort);
      default:
        throw new Error('Unknown DesignView.');
    }
  }

  private getAllDesigns() {
    const projects = Object.values(this.documentService.projects);
    const allDesigns = [...this.toDisplayProjects(projects)].flatMap((project) => project.designs);

    return [...allDesigns];
  }

  private getDrafts() {
    return [this.toDisplayProject(this.documentService.draftsProject)].flatMap((project) => project.designs);
  }

  private getMyProjectDesigns(project?: IDisplayProject) {
    if (project) {
      const projects = this.documentService.findProject(project.id, this.documentService.projects);
      return [...this.toDisplayProjects([projects])].flatMap((project) => project.designs);
    }

    // Display All my project
    const projects = Object.values(this.documentService.projects).filter((project) =>
      !project.isCompanyProject && project.id != this.documentService.draftsProject.id && project.owner);

    return [...this.toDisplayProjects(projects)].flatMap((project) => project.designs);
  }

  private getSharedProjectDesigns(subLeftNavigationButton?: LeftNavigationSecondaryButtons, project?: IDisplayProject) {

    if (subLeftNavigationButton) {
      if (subLeftNavigationButton == LeftNavigationSecondaryButtons.SharedByMe) {
        return this.getSharedByMeProjectDesigns(project);
      }

      if (subLeftNavigationButton == LeftNavigationSecondaryButtons.SharedWithMe) {
        return this.getSharedWithMeProjectDesigns(project);
      }
    }

    return [...this.getSharedByMeProjectDesigns(), ...this.getSharedWithMeProjectDesigns()];
  }

  private getSharedByMeProjectDesigns(project?: IDisplayProject) {
    if (project) {
      const projects = this.documentService.findProject(project.id, this.documentService.projects);
      return [...this.toDisplayProjects([projects])].flatMap((project) => project.designs.filter(d => d.isSharedByMe));
    }

    // Display all shared by me
    const allSharedByMeProjectDesigns = Object.values(this.documentService.designsFlat)
      .filter((design) => design.isSharedByMe);

    return this.getDisplayDesigns(allSharedByMeProjectDesigns);
  }

  private getSharedWithMeProjectDesigns(project?: IDisplayProject) {
    if (project) {
      const projects = this.documentService.findProject(project.id, this.documentService.projects);
      return [...this.toDisplayProjects([projects])].flatMap((project) => project.designs);
    }

    // Display all shared with me designs
    const allSharedProjectDesigns = Object.values(this.documentService.designsFlat)
      .filter((design) =>
        (Object.values(this.documentService.projectsFlat).filter(x => !x.owner)).map(x => x.id).includes(design.projectId));

    return this.getDisplayDesigns(allSharedProjectDesigns);
  }

  private getCompanyProjectDesigns(displayProject?: IDisplayProject) {
    if (displayProject?.isCompanyProject) {
      const project = this.documentService.findProject(displayProject.id, this.documentService.projects);
      return [...this.toDisplayProjects([project])].flatMap((project) => project.designs);
    }

    // Display All company projects
    const projects = Object.values(this.documentService.projects).filter((project) => project.isCompanyProject && project.id != this.documentService.draftsProject.id);
    return [...this.toDisplayProjects(projects)].flatMap((project) => project.designs);
  }

  private getTemplateDesigns(subLeftNavigationButton?: LeftNavigationSecondaryButtons, selectedTemplateFolder?: DesignTemplateFolderDetail) {

    if (subLeftNavigationButton) {
      if (subLeftNavigationButton == LeftNavigationSecondaryButtons.TemplatesSharedByMe) {
        return this.getSharedByMeTemplateDesigns(selectedTemplateFolder);
      }

      if (subLeftNavigationButton == LeftNavigationSecondaryButtons.TemplatesSharedWithMe) {
        return this.getSharedWithMeTemplateDesigns(selectedTemplateFolder);
      }
    }

    if (selectedTemplateFolder) {
      const templates: DesignTemplateEntity[] = Object.values(this.templateService.findTemplateFolderById(selectedTemplateFolder.templateFolderId)?.templates);
      return templates.map(template => this.toDisplayDesignTemplate(template)).filter(template => template != null);
    }

    const templates: DesignTemplateEntity[] = Object.values(this.templateService.templateV2);
    return templates.map(template => this.toDisplayDesignTemplate(template)).filter(template => template != null);
  }

  private getSharedByMeTemplateDesigns(selectedTemplateFolder?: DesignTemplateFolderDetail) {
    if (selectedTemplateFolder) {
      const templates: DesignTemplateEntity[] = Object.values(this.templateService.findTemplateFolderById(selectedTemplateFolder.templateFolderId)?.templates);
      return templates.map(template => this.toDisplayDesignTemplate(template)).filter(template => template != null && template.isSharedByMe);
    }

    // Display all shared by me
    const templates: DesignTemplateEntity[] = Object.values(this.templateService.templateV2);
    return templates.map(template => this.toDisplayDesignTemplate(template)).filter(template => template != null && template.isSharedByMe);
  }

  private getSharedWithMeTemplateDesigns(selectedTemplateFolder?: DesignTemplateFolderDetail) {
    if (selectedTemplateFolder) {
      const templates: DesignTemplateEntity[] = Object.values(this.templateService.findTemplateFolderById(selectedTemplateFolder.templateFolderId)?.templates);
      return templates.map(template => this.toDisplayDesignTemplate(template)).filter(template => template != null);
    }

    // Display all shared with me
    const allSharedwithMeTemplates = Object.values(this.templateService.templateV2)
      .filter((template) =>
        (Object.values(this.templateService.templatesFoldersFlat).filter(x => !x.owner)).map(x => x.templateFolderId).includes(template.templateFolderId));

    return allSharedwithMeTemplates.map(template => this.toDisplayDesignTemplate(template)).filter(template => template != null);
  }

  private getFavoriteDesigns(project?: IDisplayProject) {
    if (project) {
      const projects = this.documentService.findProject(project.id, this.documentService.projects);
      // Filter added to set only direct designs
      return this.toDisplayProject(projects).designs.filter(x => x.projectId == project.id);
    }

    return this.getAllDesigns();
  }
  /* #endregion Get selected navigation designs */

  /* #region Template Context menu */
  getTemplateContextMenu(templateId: string): IMenuItem<string>[] {
    const design = this.templateService.findById(templateId);

    const menu: IMenuItem<string>[] = [];
    const createDesign = () => {
      this.modal.openDesignFromTemplate({ template: design }).result.then(() => { this.designsStateChanged.emit({ action: PendingAction.createDesign } as IDisplayDesignChangeOutput) });
    }
    const openTemplateSettings = (item: IMenuItem<string>) => {
      this.openTemplateSettingsClick(item.details);
    };
    const editTemplate = (item: IMenuItem<string>) => {
      this.editTemplatesClick(item.details);
    }
    const downloadTemplate = (item: IMenuItem<string>) => {
      this.downloadTemplateClick(item.details);
    }
    const archiveTemplate = (item: IMenuItem<string>) => {
      this.archiveTemplateClick(item.details);
    }
    const shareTemplate = (item: IMenuItem<string>) => {
      this.shareTemplateClick(item.details);
    }


    menu.push({ onClick: createDesign, details: design.DesignTemplateDocumentId, ...this.menuOptionsList[MenuOptions.CreateDesign] });
    menu.push({ onClick: openTemplateSettings, details: design.DesignTemplateDocumentId, ...this.menuOptionsList[MenuOptions.TemplateSetting] });
    menu.push({ onClick: downloadTemplate, details: design.DesignTemplateDocumentId, ...this.menuOptionsList[MenuOptions.Download] });

    if (this.templateService.templatesFoldersFlat[design.templateFolderId]?.owner || !design.templateFolderId) {
      menu.push({ onClick: editTemplate, details: design.DesignTemplateDocumentId, ...this.menuOptionsList[MenuOptions.EditTemplate] });
      menu.push({ onClick: shareTemplate, details: design.DesignTemplateDocumentId, disabled: !design.templateFolderId, toolTip: this.shareTemplateTooltip(design), ...this.menuOptionsList[MenuOptions.Share] });
      menu.push({ onClick: archiveTemplate, details: design.DesignTemplateDocumentId, ...this.menuOptionsList[MenuOptions.Archive] });
    }

    return menu;
  }

  public shareTemplateTooltip(template: DesignTemplateEntity) {
    if (!template.templateFolderId) {
      return this.translate('Agito.Hilti.Profis3.HomePage.Menu.Sharing.DisabledForDrafts.ToolTip');
    }

    return null;
  }

  shareTemplateClick(templateId: string) {
    const template = this.templateService.findById(templateId);
    const selectedTemplate = new Template();
    selectedTemplate.id = template.DesignTemplateDocumentId;
    selectedTemplate.name = template.DesignTemplateName;
    selectedTemplate.templateFolderId = template.templateFolderId;
    selectedTemplate.loadUsers(this.templateService)
      .then(() => this.modal.openShareView({
        selectedTemplate,
        onAddOrRemoveUser: (res: any) => {
          if (res == true) {
            const designDisplay = this.toDisplayDesignTemplate(template);
            this.designsStateChanged.emit({
              action: PendingAction.shareDesign,
              displayDesigns: new Array(designDisplay)
            } as IDisplayDesignChangeOutput);
          }
        },
        isTemplate: true
      }));
  }

  async openTemplateSettingsClick(templateId: string) {
    this.pendingActionService.setPendingAction(PendingAction.loadTemplate);
    const template = this.templateService.findById(templateId);
    this.pendingActionDesign = { designId: template.DesignTemplateDocumentId, projectId: template.templateFolderId };
    const designsInfo = this.modulesService.getDesignListInfoByDesignType(template.DesignTypeId);

    if (designsInfo?.openTemplateSettings) {
      try {
        await designsInfo.openTemplateSettings(
          template.DesignTemplateDocumentId, this.onTemplateSettingsSave.bind(this)
        ).catch(() => {
          this.clearPendingAction(PendingAction.loadTemplate)
        });
      }
      catch (err) {
        console.error(err);
        this.clearPendingAction(PendingAction.loadTemplate);
      }
    }
    else {
      throw Error(`Open Template settings not implemented for template type ${template.DesignTypeId}`);
    }
  }

  async editTemplatesClick(templateId: string) {
    this.pendingActionService.setPendingAction(PendingAction.loadTemplate);
    const template = this.templateService.findById(templateId);
    this.pendingActionDesign = { designId: template.DesignTemplateDocumentId, projectId: template.templateFolderId };
    const designsInfo = this.modulesService.getDesignListInfoByDesignType(template.DesignTypeId);

    if (designsInfo?.openTemplate) {
      try {
        await designsInfo.openTemplate(
          template.DesignTemplateDocumentId
        ).catch(() => {
          this.clearPendingAction(PendingAction.loadTemplate)
        });
      }
      catch (err) {
        console.error(err);
        this.clearPendingAction(PendingAction.loadTemplate);
      }
    }
    else {
      throw Error(`Open Template settings not implemented for template type ${template.DesignTypeId}`);
    }
  }

  private onTemplateSettingsSave() {
    this.clearPendingAction(PendingAction.loadTemplate);
    this.refreshDesigns();
    // This will trigger tree refresh on homepage
    this.designsStateChanged.emit({ action: PendingAction.editTemplate } as IDisplayDesignChangeOutput);
  }

  private downloadTemplateClick(templateId: string) {
    const template = this.templateService.findById(templateId);
    this.downloadTemplate([template]);
  }

  private downloadTemplate(templates: DesignTemplateEntity[]) {
    if (this.pendingAction) {
      return;
    }

    if (!templates || templates.length == 0) {
      return;
    }

    const url = `${environment.baseplateApplicationWebServiceUrl}GetTemplatesDocumentsZip`;

    this.pendingActionService.setPendingAction(PendingAction.downloadTemplateFolder)

    const downloadBody = templates;
    const body = downloadBody;
    const request = new HttpRequest('POST', url, body, {
      headers: new HttpHeaders({
        Accept: 'application/zip'
      }),
      responseType: 'blob'
    });

    this.apiService.request<Blob>(request, { supressErrorMessage: true })
      .then(response => {
        const zip = response.body;

        this.browser.downloadBlob(zip, 'Templates' + '.zip', false, false);
      })
      .catch(response => {
        if (response instanceof Error) {
          console.error(response);
        }

        if (response.status == 404) {
          this.onServiceErrorHandler.showProjectArchivedModal(response, url);
        }
        else if (response.status != 401) {
          this.modal.openAlertServiceError({
            response,
            endPointUrl: url
          });
        }
      })
      .finally(() => {
        this.clearSelectedDesigns();
        this.clearPendingAction(PendingAction.downloadTemplateFolder);
      });
  }

  private archiveTemplateClick(templateId: string) {
    const templateDocumentRequest = this.getDesignTemplateDocument([templateId]);
    this.templateService.archiveExistingTemplate(templateDocumentRequest)?.then(
      () => {
        this.refreshDesigns();
        // Trigger tree refresh
        this.designsStateChanged.emit({ action: PendingAction.deleteTemplate } as IDisplayDesignChangeOutput);
      });
  }

  private getDesignTemplateDocument(templateId: string[]): TemplateDocumentRequestModel {
    return {
      designTemplateDocumentIds: templateId
    };
  }
  /* #endregion Template Context menu */

  /* #region Design Context menu */
  public isShareDesignHidden() {
    return this.featuresVisibilityInfo.isHidden(Feature.Application_FileSharing, this.globalRegion.id);
  }

  public isShareDesignDisabled(projectId?: string) {
    return this.featuresVisibilityInfo.isDisabled(Feature.Application_FileSharing, this.globalRegion.id) ||
      this.documentService.draftsProject.id === projectId;
  }

  public shareDesignTooltip(projectId?: string) {
    if (this.featuresVisibilityInfo.isDisabled(Feature.Application_FileSharing, this.globalRegion.id)) {
      return this.featuresVisibilityInfo.tooltip(Feature.Application_FileSharing);
    }

    else if (this.documentService.draftsProject.id === projectId) {
      return this.translate('Agito.Hilti.Profis3.HomePage.Menu.Sharing.DisabledForDrafts.ToolTip');
    }

    return null;
  }

  getContextMenu(documentId: string) {
    const design = this.documentService.findDesignById(documentId);
    const project = this.documentService.findProjectById(design.projectId);
    const shareDocument = (item: IMenuItem<string>) => {
      this.shareDocumentClick(item.details);
    };

    const duplicateDocument = (item: IMenuItem<string>) => {
      this.duplicateDocumentClick(item.details);
    };

    const openDesignSettings = (item: IMenuItem<string>) => {
      this.openDesignSettingsClick(item.details);
    };

    const archiveDocument = (item: IMenuItem<string>) => {
      this.archiveDocumentClick(item.details);
    };

    const downloadDesigns = (item: IMenuItem<string>) => {
      this.downloadDesigns([item.details]);
    }

    const menu: IMenuItem<string>[] = [];
    menu.push({ onClick: openDesignSettings, details: design.id, ...this.menuOptionsList[MenuOptions.DesignSettings] });
    if (((design.owner && project.owner) || project.isCompanyProject) && !this.isShareDesignHidden()) {
      menu.push({ onClick: shareDocument, disabled: this.isShareDesignDisabled(project?.id), details: design.id, toolTip: this.shareDesignTooltip(project?.id), ...this.menuOptionsList[MenuOptions.Sharing] });
    }
    menu.push({ onClick: duplicateDocument, details: design.id, ...this.menuOptionsList[MenuOptions.Duplicate] });

    if (design.owner) {
      menu.push({ onClick: archiveDocument, details: design.id, ...this.menuOptionsList[MenuOptions.Archive] });
    }

    menu.push({ onClick: downloadDesigns, details: design.id, ...this.bulkMenuOptionsList[MenuOptions.Download] });

    return menu;
  }

  shareDocumentClick(documentId: string) {
    const design = this.documentService.findDesignById(documentId);
    this.selectedDocument = new Document();
    this.selectedDocument.id = design.id;
    this.selectedDocument.name = design.designName;
    this.selectedDocument.owner = design.owner;
    this.selectedDocument.projectId = design.projectId;
    const selectedDocument = this.selectedDocument;
    selectedDocument.loadUsers(this.documentService)
      .then(() => this.modal.openShareView({
        selectedDocument,
        onAddOrRemoveUser: (res: any) => {
          if (res == true) {
            const designDisplay = this.toDisplayDesign(design);
            this.designsStateChanged.emit({
              action: PendingAction.shareDesign,
              displayDesigns: new Array(designDisplay)
            } as IDisplayDesignChangeOutput);
          }
        },
      }));
  }

  duplicateDocumentClick(documentId: string) {
    const design = this.documentService.findDesignById(documentId);
    const designDisplay = this.toDisplayDesign(design);
    this.copyDesignClick(designDisplay);
  }

  archiveDocumentClick(documentId: string) {
    this.documentService.archiveDocument(new Array(documentId))?.then(
      () => {
        this.refreshDesigns();
        // Trigger tree refresh
        this.designsStateChanged.emit();
      });
  }

  copyDesignClick(displayDesign: IDisplayDesign | IDetailedDisplayDesignCommon) {
    if (this.pendingAction != null) {
      return;
    }

    this.pendingActionService.setPendingAction(PendingAction.copyDesign);
    this.pendingActionDesign = ({ designId: displayDesign.id, projectId: displayDesign.projectId } as IProjectAndDesignId);

    if (displayDesign.designType === DesignTypeId.DiaphragmDesign) {
      displayDesign = this.createDiaphragmModalDesign(displayDesign);
    }

    this.modal.openCopyDesign({
      design: displayDesign,
      onDesignCopied: (project: Project) => {
        this.selectedProjectChanged.emit(this.toDisplayProject(project));
        return true;
      },
      onOffline: (name: string, designId: string) => this.modulesService.downloadDesign(name, designId, displayDesign.designType)
    }).closed
      .finally(() => {
        this.refreshDesigns();
      });

    this.clearPendingAction(PendingAction.copyDesign);
  }

  private createDiaphragmModalDesign(displayDesign: IDisplayDesign | IDetailedDisplayDesignCommon): IDetailedDisplayDesignCommon {
    return {
      id: displayDesign.id,
      name: displayDesign.name,
      projectId: displayDesign.projectId,
      projectName: displayDesign.projectName,
      region: null,
      designType: displayDesign.designType,
      displayDesignType: DisplayDesignType.design,
      unitLength: null,
      unitLengthLarge: null,
      unitArea: null,
      unitStress: null,
      unitStressSmall: null,
      unitForce: null,
      unitMoment: null,
      unitTemperature: null,
      unitForcePerLength: null,
      unitAreaPerLength: null,
      unitMomentPerLength: null,
      unitDensity: null
    };
  }

  async openDesignSettingsClick(documentId: string) {
    this.pendingActionService.setPendingAction(PendingAction.openDesign);
    const documentDesign = this.documentService.findDesignById(documentId);
    this.pendingActionDesign = { designId: documentDesign.id, projectId: documentDesign.projectId };
    const designsInfo = this.modulesService.getDesignListInfoByDesignType(documentDesign.metaData.designType);

    if (designsInfo?.openDesignSettings) {
        try {
            await designsInfo.openDesignSettings(
                documentDesign.id, documentDesign.designName, documentDesign.metaData.region, this.onDesignSettingsSave.bind(this)
              ).catch(() => {
                this.clearPendingAction(PendingAction.openDesign);
              });
        }
        catch (err) {
            console.error(err);
            this.clearPendingAction(PendingAction.openDesign);
        }
    }
    else {
      throw Error(`Open design settings not implemented for design type ${documentDesign.metaData.designType}`);
    }
  }

  private onDesignSettingsSave() {
    this.user.changeDesign(undefined, null);
    this.clearPendingAction(PendingAction.openDesign);
    this.refreshDesigns();
    // This will trigger tree refresh on homepage
    this.designsStateChanged.emit();
  }

  /* #endregion Design Context menu */

  /* #region Design view limit handler */

  public resize() {
    if (this.designGroup == DesignGroup.none) {
      this.setGroupedDesignsDisplayedElements();
    }

    this.setBulkDesignMenuDisplay();
  }

  public showMoreDesigns(designType: number) {
    designType = designType || DesignTypeId.Unknown;
    this.groupedDesignsLimit[designType] += this.groupedDesignsLimitStep;
    this.refreshDesigns();
  }

  private setGroupedDesignsDisplayedElements() {
    const designContainer = document.querySelector('.design-group-none');

    if (designContainer != null) {
      if (designContainer.clientWidth < 832) {
        this.groupedDesignsLimitStep = 6;
      }
      else if (designContainer.clientWidth >= 832 && designContainer.clientWidth < 1052) {
        this.groupedDesignsLimitStep = 8;
      }
      else if (designContainer.clientWidth >= 1052 && designContainer.clientWidth < 1248) {
        this.groupedDesignsLimitStep = 10;
      }
      else {
        this.groupedDesignsLimitStep = 12;
      }
    }

    this.resetGroupedDesignsLimit();
    this.refreshDesigns();
  }

  private viewContentLoaded() {
    this.setBulkDesignMenuDisplay();
    this.setGroupedDesignsDisplayedElements();
  }

  private resetGroupedDesignsLimit() {
    const designTypes = this.modulesService.designTypes;
    for (const designType of designTypes) {
      this.groupedDesignsLimit[designType.id] = this.groupedDesignsLimitStep;
    }

    const maxHeightStyle = this.typeDesignsMaxHeight + 'px';
    document.querySelectorAll('.design-type-designs').forEach(e => {
      (e as HTMLElement).style.maxHeight = maxHeightStyle;
    });
  }

  /* #endregion Design view limit handler */

  /* #region Sorting/Filtering */

  private sortGroupedDesigns(groupedDesigns: IGroupedDisplayDesign[]): IGroupedDisplayDesign[] {
    return sortBy(groupedDesigns, (group: IGroupedDisplayDesign) => {
      const designsInfo = this.modulesService.getDesignListInfoByDesignType(group.designType);
      if (designsInfo) {
        return designsInfo.order;
      }

      if (group.designType == DesignTypeId.DiaphragmDesign) {
        return 106;
      }

      return Number.MAX_SAFE_INTEGER;
    });
  }

  private filterAndSortDesigns(designs: IDisplayDesign[], designSearchValue: string, sort: DesignSort) {
    return this.sortDesigns(this.filterDesigns(designs, designSearchValue), sort);
  }

  private filterDesigns(designs: IDisplayDesign[], filterText: string) {
    if (designs == null || !designs.length) {
      return designs;
    }

    // Filter designs by supported design type
    designs = designs.filter((design) => {
      const designsInfo = this.modulesService.getDesignListInfoByDesignType(design.designType);
      if (designsInfo != null) {
        return designsInfo.designTypeId == design.designType && SafeFunctionInvokerHelper.safeInvoke(designsInfo.isEnabled, false);
      }

      if (design.designType == DesignTypeId.DiaphragmDesign) {
        return this.deckingEnabled;
      }

      return false;
    });

    filterText = (filterText || '').trim().toLowerCase();
    if (filterText == '') {
      return designs;
    }

    // Filter remaining designs by properties
    return designs.filter((design) => (design.name || '').toLowerCase().indexOf(filterText) > -1 || ((this.projectOrTemplateFolderName(design) || '').toLowerCase().indexOf(filterText) > -1));
  }

  private projectOrTemplateFolderName(design: IDisplayDesign) {
    if (this.displayDesignInput.leftNavigationSelectedState.primarySelection == LeftNavigationPrimaryButtons.Templates) {
      return this.getTemplateName(design);
    }

    return design?.rawProject?.getDisplayName(this.localization) ?? design.projectName ?? '';
  }

  private sortDesigns(designs: IDisplayDesign[], sort: DesignSort) {
    switch (sort) {
      // newest
      case DesignSort.Newest:
        return sortBy(designs, (design: IDisplayDesign) => design.created.getTime()).reverse();

      // oldest
      case DesignSort.Oldest:
        return sortBy(designs, (design: IDisplayDesign) => design.created.getTime());

      // A to Z
      case DesignSort.AtoZ:
        return sortBy(designs, (design: IDisplayDesign) => (design.name || '').toLowerCase(), (design: IDisplayDesign) => design.displayDesignType == DisplayDesignType.design ? (design.rawProject?.getDisplayName(this.localization) || '').toLowerCase() : undefined);

      // Z to A
      case DesignSort.ZtoA:
        return sortBy(designs, (design: IDisplayDesign) => (design.name || '').toLowerCase(), (design: IDisplayDesign) => design.displayDesignType == DisplayDesignType.design ? (design.rawProject.getDisplayName(this.localization) || '').toLowerCase() : undefined).reverse();

      default:
        throw new Error('Unknown DesignSort');
    }
  }

  public searchInputBlur() {
    if (this.designSearchValue != null) {
      this.designSearchValue = this.designSearchValue.trim();
    }
  }

  /* #endregion Sorting/Filtering */

  /* #region Design selection */

  public markAndUnmarkFavoriteDesign(design: IDisplayDesign) {
    if(design.isFavorite){
      this.removeFromFavorite(design)
    }
    else{
      this.addToFavorite(design);
      this.markAsFavoriteClicked.emit()
    }
  }

  public selectDesign(design: IDisplayDesign) {
    if (this.isDesignSelected(design))
      this._selectedDesigns = this._selectedDesigns.filter(x => x.id != design.id);
    else
      this._selectedDesigns.push(design);

    this.setBulkDesignSelectMenu();
  }

  public isDesignSelected(design: IDisplayDesign): boolean {
    return this.selectedDesigns.some(x => x.id == design.id);
  }
  /* #endregion  Design selection */

  /* #region Getters / Setters */
  public get selectedDesigns() {
    return this.virtualTourInProgress ? virtualTourSelectedDesigns() : this._selectedDesigns;
  }

  public get allGrupedDesigns() {
    return this.virtualTourInProgress ? virtualTourGroupedDesigns() : this.groupedDesigns;
  }

  public get designFavoriteDisabled() {
    return this.user.hasFreeLicense || this.user.hasfloatingLimitReached;
  }

  public get getDesignFavoriteDisabledTooltip() {
    if (!this.designFavoriteDisabled) {
      return null;
    }
    return this.localization.getString('Agito.Hilti.Profis3.Features.Menu.Favorites.Tooltip');
  }

  public get getDesignFavoriteDisabledTooltipForListView() {
    if (!this.designFavoriteDisabled) {
      return this.localization.getString('Agito.Hilti.C2C.Main.Region.AddRemoveFromFavorites');
    }
    return this.localization.getString('Agito.Hilti.Profis3.Features.Menu.Favorites.Tooltip');
  }

  public get globalRegion() {
    return this.userSettingsService.getCommonRegionById(this.userSettingsService.settings.application.general.regionId.value);
  }

  public get designGroup() {
    return this.userSettingsService.settings.designGroup.value ?? DesignGroup.none;
  }

  public set designGroup(value) {
    this.userSettingsService.settings.designGroup.value = value;
    this.userSettingsService.save();
  }

  get boxSectionTitle() {
    switch (this.displayDesignInput.leftNavigationSelectedState.primarySelection) {
      case LeftNavigationPrimaryButtons.AllDesigns:
        return this.translate('Agito.Hilti.Profis3.HomePage.Navigation.Designs.AllDesigns');

      case LeftNavigationPrimaryButtons.Drafts:
        return this.translate('Agito.Hilti.Profis3.HomePage.Navigation.Designs.Drafts');

      case LeftNavigationPrimaryButtons.Favourites:
        if (this.displayDesignInput.selectedProject) {
          return this.translate('Agito.Hilti.Profis3.HomePage.Navigation.Designs.Prefix') + ' ' + this.displayDesignInput.selectedProject.name;
        }
        return this.translate('Agito.Hilti.Profis3.HomePage.Navigation.Designs.Favorites');

      case LeftNavigationPrimaryButtons.MyProjects:
        if (this.displayDesignInput.selectedProject) {
          return this.translate('Agito.Hilti.Profis3.HomePage.Navigation.Designs.Prefix') + ' ' + this.displayDesignInput.selectedProject.name;
        }
        return this.translate('Agito.Hilti.Profis3.HomePage.Navigation.Designs.MyProjects');

      case LeftNavigationPrimaryButtons.Shared:
        if (this.displayDesignInput.selectedProject && this.displayDesignInput.selectedProject?.name != this.translate('Agito.Hilti.Profis3.HomePage.Navigation.Designs.Drafts')) {
          return this.translate('Agito.Hilti.Profis3.HomePage.Navigation.Designs.Prefix') + ' ' + this.displayDesignInput.selectedProject.name;
        }

        if (this.displayDesignInput.leftNavigationSelectedState.secondarySelection) {
          switch (this.displayDesignInput.leftNavigationSelectedState.secondarySelection) {
            case LeftNavigationSecondaryButtons.SharedByMe:
              return this.translate('Agito.Hilti.Profis3.HomePage.Shared.SharedByMe');
            case LeftNavigationSecondaryButtons.SharedWithMe:
              return this.translate('Agito.Hilti.Profis3.HomePage.Shared.SharedWithMe')
          }
        }

        return this.translate('Agito.Hilti.Profis3.HomePage.Shared');

      case LeftNavigationPrimaryButtons.CompanyProjects:
        if (this.displayDesignInput.selectedProject && this.displayDesignInput.selectedProject?.name != this.translate('Agito.Hilti.Profis3.HomePage.Navigation.Designs.Drafts')) {
          return this.translate('Agito.Hilti.Profis3.HomePage.Navigation.Designs.Prefix') + ' ' + this.displayDesignInput.selectedProject.name;
        }
        return this.translate('Agito.Hilti.Profis3.HomePage.Navigation.Designs.CompanyProjects');

      case LeftNavigationPrimaryButtons.Templates:
        if (this.displayDesignInput.selectedTemplateFolder) {
          return this.translate('Agito.Hilti.Profis3.HomePage.Navigation.Templates.Prefix') + ' ' + this.displayDesignInput.selectedTemplateFolder.name;
        }

        if (this.displayDesignInput.leftNavigationSelectedState.secondarySelection) {
          switch (this.displayDesignInput.leftNavigationSelectedState.secondarySelection) {
            case LeftNavigationSecondaryButtons.TemplatesSharedByMe:
              return this.translate('Agito.Hilti.Profis3.HomePage.Shared.TemplatesSharedByMe');
            case LeftNavigationSecondaryButtons.TemplatesSharedWithMe:
              return this.translate('Agito.Hilti.Profis3.HomePage.Shared.TemplatesSharedWithMe')
          }
        }

        return this.translate('Agito.Hilti.Profis3.HomePage.Templates');
      default:
        return 'Unknown';
    }
  }

  public get designSearchValue(): string {
    return this._designSearchValue;
  }

  public set designSearchValue(value: string) {
    if (value !== this._designSearchValue) {
      this._designSearchValue = value;
      this.refreshDesigns();
    }
  }

  public get sortDesignsSelectedValue(): DesignSort {
    return this.sortDesignsDropdown.selectedValue;
  }

  public set sortDesignsSelectedValue(value: DesignSort) {
    if (this.sortDesignsDropdown.selectedValue !== value) {
      this.sortDesignsDropdown.selectedValue = value;
      this.onDesignSortChange(value);
    }
  }

  public get designSort() {
    return this.userSettingsService.settings.designSort.value ?? DesignSort.Newest;
  }

  public set designSort(value) {
    this.userSettingsService.settings.designSort.value = value;
    this.userSettingsService.save();
  }

  /* #endregion Getters / Setters */

  /* #region Helpers */
  private toDisplayProjects(projects: Project[]) {
    const displayProjects = projects.map((project) => this.toDisplayProject(project));
    return displayProjects;
  }

  private getDesignThumbnail(designId: string) {
    if (this.groupedDesigns == null) {
      return undefined;
    }

    const design = this.groupedDesigns
      .map((it) => it.designs)
      .flat()
      .find(dsg => dsg.id == designId);

    return design?.thumbnail;
  }

  private toDisplayProject(project: Project) {
    if (project == null) {
      return null;
    }

    const displayProject: IDisplayProject = {
      id: project.id,
      name: project.name,
      parentId: project.parentId,
      rawProject: project,
      subProjects: Object.values(project.subProjects).map((subProject) => this.toDisplayProject(subProject)),
      designs: this.getDisplayDesigns(Object.values(project.designs), this.displayDesignInput.leftNavigationSelectedState.primarySelection),
      created: project.createDate,
      owner: project.owner,
      isCompanyProject: project.isCompanyProject,
      expanded: project.expanded,
      readOnly: project.readOnly,
      isSharedByMe: project.isSharedByMe
    };

    return displayProject;
  }

  private toDisplayDesign(design: IDesignListItem): IDisplayDesign {
    const designInfo = this.modulesService.getDesignListInfo().find(x => x.designTypeId == design?.metaData?.designType);
    if (designInfo?.toDisplayDesign) {
        try {
            return designInfo.toDisplayDesign(design, this.getDesignThumbnail.bind(this)) as IDisplayDesign;
        }
        catch (err) {
            console.error(err);
        }
    }

    return undefined;
  }

  getDisplayDesigns(designs: any[], selectedLeftNavigation?: LeftNavigationPrimaryButtons) {
    let filteredDesigns = [];

    switch (selectedLeftNavigation) {
      case LeftNavigationPrimaryButtons.MyProjects:
        filteredDesigns = designs.filter(x => !this.documentService.projectsFlat[x.projectId].isCompanyProject);
        break;
      case LeftNavigationPrimaryButtons.CompanyProjects:
        filteredDesigns = designs.filter(x => this.documentService.projectsFlat[x.projectId].isCompanyProject);
        break;
      case LeftNavigationPrimaryButtons.Favourites:
        filteredDesigns = designs.filter(design => design?.isFavorite);
        break;
      default:
        filteredDesigns = designs;
        break;
    }

    return filteredDesigns?.map(x => this.toDisplayDesign(x as IDesignListItem)).filter(design => design != undefined);
  }

  private toDisplayDesignTemplate(template: DesignTemplateEntity): IDisplayDesign {

    const designInfo = this.modulesService.getDesignListInfo().find(x => x.designTypeId == template?.DesignTypeId);
    if (designInfo?.toDisplayDesignTemplate) {
      try {
        const displayDesign = designInfo.toDisplayDesignTemplate(template, this.getDesignThumbnail.bind(this)) as IDisplayDesign;
        // TODO: Move to specific module
        displayDesign.projectId = template.templateFolderId;
        return displayDesign;
      }
      catch (err) {
        console.error(err);
      }
    }

    return undefined;
  }

  public onSectionCollapsedChange(section: ISectionCollapsed, collapsed: boolean) {
    section.collapsed = collapsed;
    this.userSettingsService.setSectionCollapsed(section.control, collapsed);
  }

  public trackGroupByDesignType: TrackByFunction<IGroupedDisplayDesign> = (_: number, group: IGroupedDisplayDesign) => {
    return group.designType;
  }

  public trackDesignTypeById: TrackByFunction<IDisplayDesign> = (_: number, designType: IDisplayDesign) => {
    return designType.id;
  }

  public onDesignSortChange(newVal: DesignSort) {
    this.designSort = newVal;
    this.resetGroupedDesignsLimit();
    this.refreshDesigns();
  }

  public isDesignLoading(design: IDisplayDesign) {
    const loading = this.pendingAction == PendingAction.openDesign && this.pendingActionDesign.designId == design.id
      || this.pendingAction == PendingAction.copyDesign && this.pendingActionDesign.designId == design.id
      || this.pendingAction == PendingAction.deleteTemplate && this.pendingActionDesign.designId == design.id
      || this.pendingAction == PendingAction.renameDesign && this.pendingActionDesign.designId == design.id
      || this.pendingAction == PendingAction.loadTemplate && this.pendingActionDesign.designId == design.id
      || this.pendingAction == PendingAction.createDesign && this.pendingActionDesign.designId == design.id
      || this.pendingAction == PendingAction.assignProject && this.pendingActionDesign.designId == design.id
      || this.pendingAction == PendingAction.deleteDesign && this.pendingActionDesign.designId == design.id;

    return loading;
  }

  isDesignInProgress(id: string){
    return this.inprogressList.includes(id);
  }
  private clearPendingAction(pendingAction?: PendingAction) {
    this.pendingActionDesign = null;

    if (this.pendingAction == pendingAction) {
      this.pendingActionService.setPendingAction(null);
    }
  }

  public translate(key: string) {
    return this.localization.getString(key);
  }

  public isCalculationTypeTextAvailable(design: IDisplayDesign): boolean {
    return environment.useDevFeatures && trim(design.calculationTypeText) != '';
  }

  public getProjectName(design: IDisplayDesign): string {
    if (this.displayDesignInput.leftNavigationSelectedState.primarySelection == LeftNavigationPrimaryButtons.Templates) {
      return this.getTemplateName(design);
    } else {
      if (design.rawProject != null) {
        return design.rawProject.getDisplayName(this.localization);
      }

      return design.projectName ?? this.localization.getString('Agito.Hilti.Profis3.HomePage.Shared.SharedWithMe');
    }
  }

  private getTemplateName(design: IDisplayDesign) {
    const template = this.templateService.findById(design.id);
    if (template?.templateFolderId) {
      const templateFolder = this.templateService.findTemplateFolderById(template?.templateFolderId);
      return templateFolder?.name;
    }
    return null;
  }

  public getRegionAndDesignCode(design: IDisplayDesign): string {
    return design.regionText + ', ' + design.designStandardTextApprovalNumberText;
  }

  private normalizeArray<T>(array: T[], indexKey: keyof T) {

    const normalizedObject: any = {};
    array.forEach(element => {
      const key = element[indexKey];
      normalizedObject[key] = element;
    });

    return normalizedObject as { [key: string]: T };
  }

  private initSortDropdownItems(): DropdownItem<DesignSort>[] {
    return [
      {
        value: DesignSort.Newest,
        text: this.translate('Agito.Hilti.Profis3.HomePage.Designs.SortBy.Newest')
      },
      {
        value: DesignSort.Oldest,
        text: this.translate('Agito.Hilti.Profis3.HomePage.Designs.SortBy.Oldest')
      },
      {
        value: DesignSort.AtoZ,
        text: this.translate('Agito.Hilti.Profis3.HomePage.Designs.SortBy.AtoZ')
      },
      {
        value: DesignSort.ZtoA,
        text: this.translate('Agito.Hilti.Profis3.HomePage.Designs.SortBy.ZtoA')
      }
    ];
  }

  /* #endregion Helpers */

  /* #region Bulk Menu Options */

  setMenuOptions() {
    this.menuOptionsList = {
      [MenuOptions.DesignSettings]: { label: this.translate('Agito.Hilti.Profis3.HomePage.Menu.DesignSettings'), icon: 'design-setting-icon' },
      [MenuOptions.Sharing]: { label: this.translate('Agito.Hilti.Profis3.HomePage.Menu.Sharing'), icon: 'share-icon' },
      [MenuOptions.Duplicate]: { label: this.translate('Agito.Hilti.Profis3.HomePage.Menu.Duplicate'), icon: 'duplicate-icon' },
      [MenuOptions.Archive]: { label: this.translate('Agito.Hilti.Profis3.HomePage.Menu.Archive'), icon: 'archive' },
      [MenuOptions.EditTemplate]: { label: this.translate('Agito.Hilti.Profis3.HomePage.Template.Menu.EditTemplate'), icon: 'template-edit' },
      [MenuOptions.TemplateSetting]: { label: this.translate('Agito.Hilti.Profis3.HomePage.Template.Menu.TemplateSettings'), icon: 'template-settings' },
      [MenuOptions.CreateDesign]: { label: this.translate('Agito.Hilti.Profis3.HomePage.Menu.CreateDesign'), icon: 'add-document' },
      [MenuOptions.Share]: { label: this.translate('Agito.Hilti.Profis3.HomePage.Menu.Share'), icon: 'share-icon' },
      [MenuOptions.Rename]: { label: this.translate('Agito.Hilti.Profis3.HomePage.Menu.Rename'), icon: 'edit-pencil' },
      [MenuOptions.Download]: { label: this.translate('Agito.Hilti.Profis3.HomePage.Menu.Download'), icon: 'download' },
    };
  }

  private setBulkDesignMenuDisplay() {
    // minus 200 for margins
    const designContainerWidth = document.querySelector('.container-content')?.clientWidth - 200;
    // max-width of button is 150
    const limit = Math.min((designContainerWidth / 180), this.bulkDesignsMenu.menu.length);

    this.bulkDesignsMenu.visibleMenu = this.bulkDesignsMenu.menu.slice(0, limit);
    this.bulkDesignsMenu.contextMenu = this.bulkDesignsMenu.menu.slice(limit, this.bulkDesignsMenu.menu.length);
  }

  private setBulkDesignSelectMenu() {
    this.bulkDesignsMenu.menu = [];

    this.bulkDesignsMenu.menu.push({ onClick: this.clearSelectedDesigns, ...this.bulkMenuOptionsList[MenuOptions.ClearSelectedDesigns] });

    if (this.displayDesignInput.leftNavigationSelectedState.primarySelection == LeftNavigationPrimaryButtons.Templates) {
      this.setBulkTemplateMenu();
    }
    else
      this.setBulkDesignMenu();

    this.setBulkDesignMenuDisplay();
  }

  private setBulkDesignMenu() {
    if (this.isBulkReportAvailable(this.selectedDesigns)) {
      this.bulkDesignsMenu.menu.push({ onClick: this.generateBulkReport, ...this.bulkMenuOptionsList[MenuOptions.GenerateBulkReports] });
    }

    const downloadDesigns = () => {
      this.downloadDesigns(this.selectedDesigns.map(x => x.id));
    }

    this.bulkDesignsMenu.menu.push({ onClick: downloadDesigns, ...this.bulkMenuOptionsList[MenuOptions.Download] });

    if (this.virtualTourInProgress || !this.selectedDesigns.some(d => !this.documentService.designsFlat[d.id]?.owner)) {
      this.bulkDesignsMenu.menu.push({ onClick: this.archiveDesigns, ...this.bulkMenuOptionsList[MenuOptions.Archive] });
    }
    if (this.virtualTourInProgress ||  this.selectedDesigns.some(x => !this.documentService.designsFlat[x.id]?.isFavorite)) {
      this.bulkDesignsMenu.menu.push({ onClick: this.addToFavoriteMultiple, disabled: this.designFavoriteDisabled, ...this.bulkMenuOptionsList[MenuOptions.AddToFavorite], toolTip: this.getDesignFavoriteDisabledTooltip });
    }

    if (this.virtualTourInProgress ||  this.selectedDesigns.some(x => this.documentService.designsFlat[x.id]?.isFavorite)) {
      this.bulkDesignsMenu.menu.push({ onClick: this.removeFromFavoriteMultiple, disabled: this.designFavoriteDisabled, ...this.bulkMenuOptionsList[MenuOptions.RemoveFromFavorite], toolTip: this.getDesignFavoriteDisabledTooltip });
    }
  }

  private setBulkTemplateMenu() {
    const downloadTemplates = () => {
      this.downloadTemplatesClick();
    }

    const archiveTemplates = () => {
      this.archiveTemplatesClick();
    }

    this.bulkDesignsMenu.menu.push({ onClick: downloadTemplates, ...this.bulkMenuOptionsList[MenuOptions.Download] });
    if (!this.selectedDesigns.some(d => d.projectId != null && !this.templateService.templatesFoldersFlat[d.projectId]?.owner)) {
      this.bulkDesignsMenu.menu.push({ onClick: archiveTemplates, ...this.bulkMenuOptionsList[MenuOptions.Archive] });
    }
  }

  private isActionAllowed = () => { return (this.selectedDesigns.length || !this.pendingAction) }

  private clearSelectedDesigns = () => { this._selectedDesigns = [] };

  private generateBulkReport = () => {
    if (!this.isActionAllowed()) { return; }

    if (environment.useDevFeatures) {
      this.modal.openExportReports(this.selectedDesigns);
    }

    this.clearSelectedDesigns();
  };

  private downloadDesigns = (documentIds: string[]) => {
    if (!this.isActionAllowed) {
      return;
    }

    this.pendingActionService.setPendingAction(PendingAction.downloadProject);
    const url = `${environment.baseplateApplicationWebServiceUrl}DownloadDocumentsZip`;
    const body = { documentIds: documentIds };

    const request = new HttpRequest('POST', url, body, {
      headers: new HttpHeaders({ Accept: 'application/zip' }),
      responseType: 'blob'
    });

    this.apiService.request<Blob>(request, { supressErrorMessage: true })
      .then(response => {
        const zip = response.body;
        this.browser.downloadBlob(zip, 'PEdesigns' + '.zip', false, false);
      })
      .catch(response => {
        if (response instanceof Error) {
          console.error(response);
        }
        if (response.status == 404) {
          this.onServiceErrorHandler.showDesignDoesNotExistModal(response, url);
        }
        else if (response.status != 401) {
          this.modal.openAlertServiceError({
            response,
            endPointUrl: url
          });
        }
      })
      .finally(() => {
        this.clearPendingAction(PendingAction.downloadProject);
        this.clearSelectedDesigns();
      });
  };

  private archiveDesigns = () => {
    this.pendingActionService.setPendingAction(PendingAction.archiveProject);
    this.documentService.archiveDocument(this.selectedDesigns.map(x => x.id))?.then(() => {
      this.refreshDesigns();
       // Trigger tree refresh
       this.designsStateChanged.emit();
    }).finally(() => {
      this.clearPendingAction(PendingAction.archiveProject);
      this.clearSelectedDesigns();
    });
  };

  private addToFavoriteMultiple = () => {
    this.addToFavorite();
  }

  private addToFavorite(design?: IDisplayDesign) {
    if (!this.isActionAllowed())
      return;

    this.pendingActionService.setPendingAction(PendingAction.addToFavorite)
    const designsToBeMarkedAsFavorite = design ? new Array(design) : this.selectedDesigns.filter(x => !x.isFavorite);
    const documentIds = designsToBeMarkedAsFavorite.map(x => x.id);
    designsToBeMarkedAsFavorite.forEach(x => x.isFavorite = true);

    this.documentService.addToFavorite(documentIds)
      .then(() => {
        this.designsStateChanged.emit({
          action: PendingAction.addToFavorite,
          displayDesigns: designsToBeMarkedAsFavorite
        } as IDisplayDesignChangeOutput);
      })
      .catch(response => {
        designsToBeMarkedAsFavorite.forEach(x => x.isFavorite = false);
        if (response instanceof Error) {
          console.error(response);
        }
      })
      .finally(() => {
        this.clearPendingAction(PendingAction.addToFavorite);
        if (design == undefined) {
          this.clearSelectedDesigns();
        }
      });
  }

  private removeFromFavoriteMultiple = () => {
    this.removeFromFavorite();
  }

  private removeFromFavorite = (design?: IDisplayDesign) => {
    if (!this.isActionAllowed())
      return;

    this.pendingActionService.setPendingAction(PendingAction.removeFromFavorite);
    const designsToBeRemovedFromFavorites = design ? new Array(design) : this.selectedDesigns.filter(x => x.isFavorite);
    const documentIds = designsToBeRemovedFromFavorites.map(x => x.id);
    designsToBeRemovedFromFavorites.forEach(x => x.isFavorite = false);

    this.documentService.removeFromFavorite(documentIds)
      .then(() => {
        this.designsStateChanged.emit({
          action: PendingAction.removeFromFavorite,
          displayDesigns: designsToBeRemovedFromFavorites
        } as IDisplayDesignChangeOutput);
      })
      .catch(response => {
        designsToBeRemovedFromFavorites.forEach(x => x.isFavorite = true);
        if (response instanceof Error) {
          console.error(response);
        }
      })
      .finally(() => {
        this.clearPendingAction(PendingAction.removeFromFavorite);
        if (design == undefined) {
          this.clearSelectedDesigns();
        }
      });
  };

  private isBulkReportAvailable(designs: IDisplayDesign[]): boolean {

    // Bulk report is not yet developed for every module
    return false;

    // let isAvailable = false;
    // designs.forEach(design => {
    //   const designsInfo = this.modulesService.getDesignListInfoByDesignType(design.designType);

    //   if (designsInfo?.isBulkReportEnabled != null) {
    //     isAvailable = SafeFunctionInvokerHelper.safeInvoke(designsInfo.isBulkReportEnabled, false);
    //   }
    //   else {
    //     isAvailable = design.designType != DesignTypeId.CurtainWall && this.featureVisibilityService.isFeatureEnabled('BulkReport') && design.displayDesignType != DisplayDesignType.template;
    //   }
    // });

    // return isAvailable;
  }

  private downloadTemplatesClick() {
    const templates: DesignTemplateEntity[] = [];
    this.selectedDesigns.forEach(x => {
      const template = this.templateService.findById(x.id);
      templates.push(template);
    })
    this.downloadTemplate(templates);
  }

  private archiveTemplatesClick() {
    const templateId: string[] = [];
    this.selectedDesigns.forEach(x => {
      templateId.push(x.id);
    })
    const templateDocumentRequest = this.getDesignTemplateDocument(templateId);
    this.templateService.archiveExistingTemplate(templateDocumentRequest)?.then(
      () => {
        this.refreshDesigns();
        this.clearSelectedDesigns();
        // Trigger tree refresh
        this.designsStateChanged.emit({ action: PendingAction.deleteTemplate } as IDisplayDesignChangeOutput);
      });
  }

  private setbulkMenuOptions() {
    this.bulkMenuOptionsList = {
      [MenuOptions.ClearSelectedDesigns]: { label: this.translate('Agito.Hilti.Profis3.HomePage.Menu.ClearSelectedDesigns'), icon: 'cross' },
      [MenuOptions.GenerateBulkReports]: { label: this.translate('Agito.Hilti.Profis3.HomePage.Menu.GenerateBulkReports'), icon: 'printer' },
      [MenuOptions.Download]: { label: this.translate('Agito.Hilti.Profis3.HomePage.Menu.Download'), icon: 'download' },
      [MenuOptions.Archive]: { label: this.translate('Agito.Hilti.Profis3.HomePage.Menu.Archive'), icon: 'archive' },
      [MenuOptions.AddToFavorite]: { label: this.translate('Agito.Hilti.Profis3.HomePage.Menu.AddToFavourite'), icon: 'favorite' },
      [MenuOptions.RemoveFromFavorite]: { label: this.translate('Agito.Hilti.Profis3.HomePage.Menu.RemoveFromFavourites'), icon: 'favorite' },
    };
  }

  /* #endregion Bulk Menu Options */
}
