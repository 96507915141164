import { Component, OnInit } from '@angular/core';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';

import { DateTimeService } from '../../services/date-time.service';
import { DocumentService } from '../../services/document.service';
import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { ModalDialogType } from '../add-edit-design/add-edit-design-models';

interface Project {
    projectId: string;
    projectName: string;
    designs: number;
    members: number;
    created: Date;
    archived: Date;
    parentProjectId: string;
}

export interface IArchiveComponentInput {
    onRestore?: () => void;
}

@Component({
    selector: 'app-archive',
    templateUrl: './archive.component.html',
    styleUrls: ['./archive.component.scss']
})
export class ArchiveComponent implements OnInit {
    public submitted: boolean;
    public projects: Project[];

    public projectHeaderHeight = 39;    // must be the same value as in css
    public projectRowHeight = 35;       // must be the same value as in css
    public scrollElement: Element;

    private pendingSave: boolean;

    constructor(
        public localization: LocalizationService,
        public dateTime: DateTimeService,
        private documentService: DocumentService,
        private modal: ModalService,
        private modalInstance: ModalInstance<IArchiveComponentInput>
    ) { }

    ngOnInit(): void {
        this.modalInstance.setOnClosing((_) => {
            return this.pendingSave
                ? false
                : true;
        });

        this.scrollElement = document.querySelector('.modal') as Element;

        this.initProjects();
    }

    public close() {
        this.modalInstance.close();
    }

    public restoreProject(projectId: string) {
        if (this.submitted || this.pendingSave) {
            return;
        }

        this.submitted = this.pendingSave = true;
        const projectLevelType = this.projects.find((item) => item.projectId == projectId).parentProjectId == null
            ? ModalDialogType.project
            : ModalDialogType.subproject;

        this.documentService.restoreProject(projectId, projectLevelType).then(() => {
            this.projects = this.projects.filter((item) => item.projectId != projectId && item.parentProjectId != projectId);

            // invoke onRestore
            if (this.modalInstance.input?.onRestore != null) {
                this.modalInstance.input.onRestore();
            }
        }).finally(() => {
            this.submitted = this.pendingSave = false;
        });
    }

    public deleteArchivedProject(projectId: string) {
        if (this.submitted || this.pendingSave) {
            return;
        }

        const opened = this.modal.openConfirmChange({
            id: 'confirm-delete-project-permanently',
            title: this.localization.getString('Agito.Hilti.Profis3.Archive.ConfirmDelete.Title'),
            message: this.localization.getString('Agito.Hilti.Profis3.Archive.ConfirmDelete.Message'),
            confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.Archive.ConfirmDelete.Ok'),
            cancelButtonText: this.localization.getString('Agito.Hilti.Profis3.Archive.ConfirmDelete.Cancel'),
            onConfirm: (modal) => {
                modal.close(true);
            },
            onCancel: (modal) => {
                modal.close(false);
            }
        }).result;

        this.submitted = this.pendingSave = true;

        opened
            .then((result: any) => {
                // Modal closed
                const promise = new Promise<void>((resolve, reject) => {
                    if (result == true) {
                        this.documentService.removeArchivedProject(projectId)
                            .then(() => {
                                this.projects = this.projects.filter((item) => item.projectId != projectId);
                                resolve();
                            })
                            .catch(() => {
                                reject();
                            });
                        return;
                    }

                    resolve();
                });

                promise.finally(() => {
                    this.submitted = this.pendingSave = false;
                });
            })
            .catch(() => {
                // Modal dismissed
                this.submitted = this.pendingSave = false;
            });
    }

    private initProjects() {
        this.projects = [];
        for (const idx in this.documentService.projectsArchive) {
            const item = this.documentService.projectsArchive[idx];
            this.projects.push({
                projectId: item.id,
                projectName: item.name,
                members: item.members,
                designs: item.designs,
                created: item.created,
                archived: item.archived,
                parentProjectId: item.parentId
            } as Project);
        }

        this.projects.sort((a, b) => {
            return a.projectName.localeCompare(b.projectName);
        });
    }
}
