<div class="box-section box-section-white designs main-container">
    <div class="box-section-header">
        <div class="box-section-title container-header"><span class="title">{{boxSectionTitle}}</span>
        </div>
        <pe-collapse [container]="'box-section-content'" [collapsed]="sectionsCollapsed.designsView.collapsed"
            (collapsedChange)="onSectionCollapsedChange(sectionsCollapsed.designsView, $any($event).detail)">
        </pe-collapse>
    </div>
    <div class="box-section-content">
        <div class="container-content design-view-box-container">
            <div class="top-container">
                <div *ngIf="!selectedDesigns.length" class="search-container">
                    <div class="search-image-container">
                        <span class="search-image sprite sprite-search-red"></span>
                    </div>
                    <input id="project-and-design-main-search-textfield" class="search-input"
                        [(ngModel)]="designSearchValue" name="project-and-design-main-search-textfield"
                        (blur)="searchInputBlur()"
                        placeholder="{{translate('Agito.Hilti.Profis3.HomePage.Designs.SearchPlaceholder')}}"
                        type="text" />
                    <button id="project-and-design-main-search-clear-button" class="clear-search-button"
                        *ngIf="designSearchValue?.trim().length > 0" (click)="designSearchValue = null" type="button">
                        <span class="sprite sprite-cross-red"></span>
                    </button>
                </div>

                <div *ngIf="selectedDesigns.length" class="menu-container">
                    <div class="design-actions">
                        <button *ngFor="let button of bulkDesignsMenu.visibleMenu; index as i"
                            class="bulk-action-button" (click)="$event.stopPropagation(); button.onClick()"
                            [id]="'bulk-action-option-'+i" [attr.data-id]="'bulk-action-option-'+i" tabindex="0"
                            [disabled]="button.disabled || this.pendingAction != null"
                            [ngClass]="{'disabled':button.disabled || this.pendingAction != null}">
                            <div class="icon">
                                <span [class]="'sprite sprite-'+button.icon"></span>
                            </div>
                            <span class="label">
                                <span *ngIf="i==0">{{this.selectedDesigns.length}}</span>
                                {{button.label}}
                            </span>
                        </button>
                    </div>
                    <span *ngIf="bulkDesignsMenu.contextMenu?.length" class="context-menu-trigger"
                        (click)="$event.stopPropagation()">
                        <pe-context-menu [disabled]="this.pendingAction != null"
                            [icon]="'sprite sprite-ellipsis-vertical'"
                            [label]="translate('Agito.Hilti.Profis3.HomePage.Menu.MoreOptions')"
                            [menu]="bulkDesignsMenu.contextMenu">
                        </pe-context-menu>
                    </span>
                </div>

                <div class="sort-designs">
                    <pe-control-title class="sort-designs-title" [focusControlId]="'project-and-design-sort-dropdown'"
                        [title]="localization.getLocalizedString('Agito.Hilti.Profis3.HomePage.Designs.SortBy')">
                    </pe-control-title>

                    <div class="sort-designs-dropdown-wrapper">
                        <pe-dropdown class="sort-designs-dropdown" [id]="'project-and-design-sort-dropdown'"
                            [items]="sortDesignsDropdown.items" [selectedValue]="sortDesignsSelectedValue"
                            (selectedValueChange)="sortDesignsSelectedValue = $any($event).detail">
                        </pe-dropdown>
                    </div>
                </div>

                <div class="design-view-toggle group-container btn-group">
                    <div class="btn-group-toggle">
                        <input id="project-and-design-main-design-group-button-none" class="btn-check"
                            [value]="DesignGroup.none" type="radio" name="designGroup" [(ngModel)]="designGroup">
                        <label class="group-button design-group-none-button btn btn-default"
                            for="project-and-design-main-design-group-button-none"
                            [ngbTooltip]="translate('Agito.Hilti.Profis3.HomePage.Designs.View.GridView')">
                            <span class="sprite sprite-group-square"></span>
                        </label>
                        <input id="project-and-design-main-design-group-button-list" class="btn-check"
                            [value]="DesignGroup.list" type="radio" name="designGroup" [(ngModel)]="designGroup">
                        <label class="group-button design-group-list-button btn btn-default"
                            for="project-and-design-main-design-group-button-list"
                            [ngbTooltip]="translate('Agito.Hilti.Profis3.HomePage.Designs.View.ListView')">
                            <span class="sprite sprite-group-list"></span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="design-group-none" *ngIf="designGroup == DesignGroup.none">
                <div class="design-type-container"
                    *ngFor="let group of allGrupedDesigns; trackBy : trackGroupByDesignType">
                    <p class="design-type-name">{{group.designTypeName}}</p>
                    <div class="design-type-designs"
                        [ngStyle]="{'max-height.px': typeDesignsMaxHeight * (groupedDesignsLimit[group.designType] / groupedDesignsLimitStep) }">
                        <div class="design" [attr.data-design-id]="design.id" (click)="onDesignClick(design)"
                            id="design-{{design.id}}" tabindex="0"
                            *ngFor="let design of group.designs; trackBy : trackDesignTypeById"
                            [ngClass]="{ 'disabled': pendingAction != null }">

                            <div class="image-container"  [class.show-loading]="isDesignLoading(design) || isDesignInProgress(design.id)">
                                <span class="model3d-image thumbnail sprite"
                                    *ngIf="design.thumbnail == null && design.image != null && !isDesignInProgress(design.id)"
                                    [ngStyle]="design.image"></span>
                                <img class="model3d-image thumbnail" *ngIf="design.thumbnail != null"
                                    [src]="design.thumbnail" alt="3D model thumbnail" />
                                <span class="calculation-type"
                                    *ngIf="isCalculationTypeTextAvailable(design)">{{design.calculationTypeText}}</span>
                                <div class="loading-container" *ngIf="isDesignLoading(design) || isDesignInProgress(design.id)">
                                    <pe-loading [color]="'#524f53'"></pe-loading>
                                </div>
                            </div>
                            <div class="design-content-container" [ngClass]="{'selected' :isDesignSelected(design)}"
                                [ngbTooltip]="getProjectDesignInfoTooltip(design)">
                                <span class="name project-name">{{getProjectName(design)}}</span>
                                <span class="name design-name">{{design.name}}</span>
                                <span class="created">{{design.createdDisplay()}}
                                </span>
                                <span appClickStopPropagation class="select-design-checkbox"
                                    [class.hide]="!selectedDesigns.length"
                                    (click)="pendingAction != null ? null : selectDesign(design)">
                                    <input class="checkbox-input" id="check-design-{{design.id}}"
                                        [checked]="isDesignSelected(design)" [disabled]="pendingAction != null"
                                        [attr.checked]="isDesignSelected(design)" type="checkbox">
                                    <span class="checkbox-button sprite"
                                        [class.sprite-checkbox]="isDesignSelected(design)"></span>
                                </span>
                                <span appClickStopPropagation class="design-favorite-container"
                                    *ngIf="design.displayDesignType != 1">
                                    <span [ngbTooltip]="getDesignFavoriteDisabledTooltip"
                                        [ngClass]="design.isFavorite ?
                                              (this.selectedDesigns?.length || designFavoriteDisabled  ? 'sprite-star-icon-selected disableFavorite' : 'sprite-star-icon-selected')
                                            : (this.selectedDesigns?.length || designFavoriteDisabled ? 'sprite-star-icon disableFavorite' : 'sprite-star-icon')"
                                        class="sprite"
                                        (click)="pendingAction != null || this.selectedDesigns?.length || designFavoriteDisabled ? null : markAndUnmarkFavoriteDesign(design);"></span>
                                </span>
                                <span [class.disabled]="this.selectedDesigns?.length" class="design-dummy-dot-container" (click)="$event.stopPropagation()" *ngIf="(!currentOpenMenu || currentOpenMenu != design.id)" (mouseenter)="openMenu(design.id, true)">
                                    <span class="sprite sprite-ellipsis-vertical"></span>
                                </span>
                                <span [class.disabled]="this.selectedDesigns?.length" class="context-menu-trigger" *ngIf="showDetails && currentOpenMenu == design.id"
                                    (click)="$event.stopPropagation()">
                                    <pe-context-menu
                                        [disabled]="this.pendingAction != null || this.selectedDesigns?.length"
                                        [icon]="'sprite sprite-ellipsis-vertical'"
                                        [menu]="design.contextMenu"></pe-context-menu>
                                </span>
                            </div>

                        </div>
                    </div>

                    <div class="show-more-designs-container" *ngIf="allGrupedDesigns && allGrupedDesigns[0] && allGrupedDesigns[0].designs && allGrupedDesigns[0].designs.length > 0">
                        <button class="show-more-designs button button-default"
                            (click)="showMoreDesigns(group.designType)"
                            *ngIf="group.designsCount > group.designs.length"
                            l10n="Agito.Hilti.Profis3.HomePage.Designs.ShowMoreDesigns" type="button">
                        </button>
                    </div>
                </div>
            </div>

            <div class="designs-list design-group-list" *ngIf="designGroup == DesignGroup.list">
                <div class="design-type-grid" *ngFor="let group of allGrupedDesigns; trackBy : trackGroupByDesignType">
                    <div class="grid-header">
                        <span>{{group.designTypeName}}</span>
                    </div>
                    <div class="grid-body">
                        <ng-container *ngTemplateOutlet="headerRow"></ng-container>
                        <div class="grid-row"
                            [ngClass]="{ 'disabled': pendingAction != null, 'selected': isDesignSelected(design)}"
                            [attr.data-design-id]="design.id"
                            *ngFor="let design of group.designs; trackBy : trackDesignTypeById">
                            <div class="row-wrapper" tabindex="0" (click)="onDesignClick(design)">

                                <div class="grid-cell grid-cell-selection">
                                    <div class="selection-container">
                                        <span appClickStopPropagation class="selection select-design-checkbox"
                                            [class.hide]="!selectedDesigns.length"
                                            (click)="pendingAction != null ? null : selectDesign(design)">
                                            <input class="checkbox-input" id="check-design-{{design.id}}"
                                                [checked]="isDesignSelected(design)" [disabled]="pendingAction != null"
                                                [attr.checked]="isDesignSelected(design)" type="checkbox">
                                            <span class="checkbox-button sprite"
                                                [class.sprite-checkbox]="isDesignSelected(design)"></span>
                                        </span>

                                        <div class="loading-container" *ngIf="isDesignLoading(design)">
                                            <pe-loading [width]="28" [height]="7"
                                                [color]="PE_LOADING_COLOR"></pe-loading>
                                        </div>
                                    </div>
                                </div>

                                <div class="grid-cell grid-cell-name">
                                    <span class="text" [ngbTooltip]="design.name">{{design.name}}</span>
                                </div>

                                <div class="grid-cell grid-cell-project">
                                    <span class="text"
                                        [ngbTooltip]="getProjectName(design)">{{getProjectName(design)}}</span>
                                </div>

                                <div class="grid-cell grid-cell-region-code">
                                    <span class="text"
                                        [ngbTooltip]="getRegionAndDesignCode(design)">{{getRegionAndDesignCode(design)}}</span>
                                </div>

                                <div class="grid-cell grid-cell-anchor">
                                    <span class="text" [ngbTooltip]="design.productName">{{design.productName}}</span>
                                </div>

                                <div class="grid-cell grid-cell-date">
                                    <span class="text"
                                        [ngbTooltip]="design.createdDisplay()">{{design.createdDisplay()}}</span>
                                </div>

                                <div class="grid-cell grid-cell-favorite">
                                    <span [ngbTooltip]="getDesignFavoriteDisabledTooltipForListView"
                                        *ngIf="design.displayDesignType != 1"
                                        [ngClass]="design.isFavorite ?
                                      (this.selectedDesigns?.length || designFavoriteDisabled  ? 'sprite-star-icon-selected disableFavorite' : 'sprite-star-icon-selected')
                                    : (this.selectedDesigns?.length || designFavoriteDisabled ? 'sprite-star-icon disableFavorite' : 'sprite-star-icon')"
                                        class="sprite"
                                        (click)="pendingAction != null || this.selectedDesigns?.length || designFavoriteDisabled ? null : markAndUnmarkFavoriteDesign(design);$event.stopPropagation();"></span>
                                </div>
                                <span [class.disabled]="this.selectedDesigns?.length" class="grid-cell grid-cell-dummy-dot" (click)="$event.stopPropagation()" *ngIf="(!currentOpenMenu || currentOpenMenu != design.id)" (mouseenter)="openMenu(design.id, true)">
                                    <span class="sprite sprite-ellipsis-vertical"></span>
                                </span>
                                <div [class.disabled]="this.selectedDesigns?.length"
                                    class="grid-cell context-menu-trigger" (click)="$event.stopPropagation()"  *ngIf="showDetails && currentOpenMenu == design.id">
                                    <pe-context-menu
                                        [disabled]="this.pendingAction != null || this.selectedDesigns?.length"
                                        [icon]="'sprite sprite-ellipsis-vertical'"
                                        [menu]="design.contextMenu"></pe-context-menu>
                                </div>


                            </div>
                        </div>
                    </div>
                    <div class="grid-footer" *ngIf="allGrupedDesigns && allGrupedDesigns[0] && allGrupedDesigns[0].designs && allGrupedDesigns[0].designs.length > 0">
                        <button class="show-more-designs button button-default"
                            (click)="showMoreDesigns(group.designType)"
                            *ngIf="group.designsCount > group.designs.length"
                            l10n="Agito.Hilti.Profis3.HomePage.Designs.ShowMoreDesigns" type="button"></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #headerRow>
    <div class="grid-row">
        <div class="row-wrapper">
            <div class="grid-cell grid-cell-selection">
            </div>
            <div class="grid-cell grid-cell-name">
                <span class="text">
                    {{translate('Agito.Hilti.Profis3.HomePage.Designs.View.ListView.Column.FileName')}}
                </span>
            </div>
            <div class="grid-cell grid-cell-project">
                <span class="text">
                    {{translate('Agito.Hilti.Profis3.HomePage.Designs.View.ListView.Column.Folder')}}
                </span>
            </div>
            <div class="grid-cell grid-cell-region-code">
                <span class="text">
                    {{translate('Agito.Hilti.Profis3.HomePage.Designs.View.ListView.Column.RegionAndCode')}}
                </span>
            </div>
            <div class="grid-cell grid-cell-anchor">
                <span class="text">
                    {{translate('Agito.Hilti.Profis3.HomePage.Designs.View.ListView.Column.Anchor')}}
                </span>
            </div>
            <div class="grid-cell grid-cell-date">
                <span class="text">
                    {{translate('Agito.Hilti.Profis3.HomePage.Designs.View.ListView.Column.DateCreated')}}
                </span>
            </div>
            <div class="grid-cell grid-cell-favorite">
            </div>
            <div class="grid-cell context-menu-trigger">
            </div>
        </div>
    </div>
</ng-template>