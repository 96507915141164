import cloneDeep from 'lodash-es/cloneDeep';
import { HttpErrorResponse, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    ProjectDesignEntityC2C
} from '@profis-engineering/pe-ui-c2c/generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import { ProjectType, ProjectUser } from '@profis-engineering/pe-ui-common/entities/project';
import {
    DocumentGetResponse, ArchiveDocumentResponseModel, DocumentGetProject, DocumentGetDocument
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.Entities.Documents';
import {
    ArchiveProjectResponseModel, ProjectExpandedState
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.Entities.Projects';
import { ApiOptions } from '@profis-engineering/pe-ui-common/services/api.common';
import {
    CantArchiveProjectsBecauseDocumentInUse,
    CantDeleteProjectsBecauseDocumentInUse,
    CantOpenDesignBecauseLockedByOtherUser,
    CantPerformActionReasonProblematicDocumentInformation,
    CantRestoreProjectsBecauseDocumentInUse,
    DocumentAccessMode, DocumentServiceBase
} from '@profis-engineering/pe-ui-common/services/document.common';
import {
    ProjectDesignBaseEntity
} from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign';
import { environment } from '../../environments/environment';

import { ModalDialogType } from '../components/add-edit-design/add-edit-design-models';
import { BaseDesign } from '@profis-engineering/pe-ui-decking/src/decking/entities/decking-design/base-design';
import { Design, IBaseDesign } from '../entities/design';
import { DesignExternalMetaData } from '../entities/design-external-meta-data';
import { IProjectArchive, Project } from '../entities/project';
import { ApiService } from './api.service';
import { BrowserService } from './browser.service';
import { DocumentService, IDesignListItem } from './document.service';
import { ErrorHandlerService } from './error-handler.service';
import { GuidService } from './guid.service';
import { LocalizationService } from './localization.service';
import { LoggerService } from './logger.service';
import { ModalService } from './modal.service';
import { RoutingService } from './routing.service';
import { UserService } from './user.service';
import {
    detailedDisplayDesignToProjectDesign, IDetailedDisplayDesign as IDetailedDisplayDesignPe
} from '@profis-engineering/pe-ui-shared/entities/display-design';
import {
    detailedDisplayDesignToProjectDesignC2C, IDetailedDisplayDesign as IDetailedDisplayDesignC2C
} from '@profis-engineering/pe-ui-c2c/entities/display-design';
import { Design as DesignCommon } from '@profis-engineering/pe-ui-common/entities/design';
import {
    DesignExternalMetaData as DesignExternalMetaDataCommon,
    IDefferedData as IDefferedDataCommon, IDesignListItem as IDesignListItemCommon,
    IDocumentServiceDesign
} from '@profis-engineering/pe-ui-common/services/document.common';
import { IDefferedData, IDefferedDataC2C, IDefferedImageData, IDefferedRequests } from '../helpers/document-helper';
import { Deferred } from '@profis-engineering/pe-ui-common/helpers/deferred';
import { DesignType } from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { urlPath } from '../module-constants';
import { formatKeyValue } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { DocumentUser, IDocumentArchive, SharedDocumentUser } from '../entities/document';
import { PendingActionService } from './pending-action.service';
import { PendingAction } from '../components/home-page/home-page.common';


interface IGetDocumentResponse {
    documentid: string;
    project: IGetDocumentResponseProject;
    name: string;
    lastchange: number;
    created: number;
    locked: boolean;
    lockedby: string;
    metadata: { key: string, value: string }[];
    owner: boolean;
}

interface IGetDocumentResponseProject {
    projectid: string;
    parentProjectId: string;
    name: string;
}

class DesingSessionPair {
    /*
    * design Id
    */
    public designId: string;

    /*
    * Session id of the design.
    */
    public sessionId: string;

    /*
    * This session was explicitly cancelled.
    */
    public isCancelled = false;
}

@Injectable({
    providedIn: 'root'
})
export class DocumentWebV2Service extends DocumentService implements DocumentServiceBase {
    private internalRawProject: DocumentGetResponse;
    private designSessionMapping: DesingSessionPair[] = [];
    private defferedRequests: IDefferedRequests<any, any> = {};
    private defferedImageUpload: IDefferedRequests<any, any> = {};
    private documentThumbnails: Record<string, string> = {};
    private documentThumbnailPromise: Record<string, Promise<unknown>> = {};

    constructor(
        loggerService: LoggerService,
        private routingService: RoutingService,
        private apiService: ApiService,
        private browserService: BrowserService,
        private modalService: ModalService,
        private localizationService: LocalizationService,
        private guidService: GuidService,
        private userService: UserService,
        private errorHandlerService: ErrorHandlerService,
        private pendingActionService: PendingActionService
    ) {
        super(
            'DocumentService',
            loggerService
        );

        this.findDesignById = this.findDesignById.bind(this);
    }

    /**
     * Creates a unique file name from a given name
     */
    public createUniqueName(oldName: string, usedNames: string[]): string {
        let newName = oldName;
        let index = 0;

        while (usedNames.find((item) => item == newName) != null) {
            index++;
            newName = oldName + ' (' + index + ')';
        }

        return newName;
    }

    public async findDesignByName(designName: string, projectId: string, forceFromServer?: boolean): Promise<IDesignListItemCommon> {
        return this.findDesignByNameInternal(designName, projectId, forceFromServer);
    }

    private async findDesignByNameInternal(designName: string, projectId: string, forceFromServer: boolean): Promise<IDesignListItemCommon> {
        let project = this.findProjectById(projectId);

        if (project != null) {

            if (forceFromServer === true) {
                const data: any = await this.getDocumentsByProjectId(projectId);
                const result: DocumentGetResponse = {
                    projects: Object.fromEntries(Object.entries<any>(data.projects).map(([key, it]): [string, DocumentGetProject] => [key, {
                        dateChanged: it.dateChanged,
                        dateCreated: it.dateCreated,
                        id: it.id,
                        name: it.name,
                        parentId: it.parentId,
                        owner: it.owner,
                        isCompanyProject: it.isCompanyProject,
                        expanded: it.expanded,
                        readOnly: it.readOnly,
                        expandedState: it.expandedState
                    }])),
                    documents: Object.fromEntries(Object.entries<any>(data.documents).map(([key, it]): [string, DocumentGetDocument] => [key, {
                        dateChanged: it.dateChanged,
                        dateCreated: it.dateCreated,
                        id: it.id,
                        locked: it.locked,
                        metadata: it.metadata,
                        projectId: it.projectId,
                        lockedUserName: it.lockedUserName,
                        name: it.name,
                        type: it.type,
                        owner: it.owner
                    }]))
                };

                this.getInternal(result);
                project = this.findProjectById(projectId);

                const foundDesign = Object.values(project.designs).find((design) => design.designName.toLowerCase().trim() == designName.toLowerCase().trim());

                if (foundDesign != null) {
                    return foundDesign;
                }

                return null;
            }
            else {
                const foundDesign = Object.values(project.designs).find((design) => design.designName.toLowerCase().trim() == designName.toLowerCase().trim());

                if (foundDesign != null) {
                    return foundDesign;
                }

                return null;
            }

        }

        return null;
    }

    private async getDocumentsByProjectId(projectId: string): Promise<unknown> {
        const url = `${environment.documentWebServiceUrl}document/get`;
        this.logServiceRequest('getDocumentsByProjectId', url);

        try {
            return (await this.apiService.request<unknown>(new HttpRequest('GET', url), this.getApiOptions())).body;
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'get project documents');

            if (response instanceof HttpErrorResponse) {
                if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            }

            throw response;
        }
    }

    public initialize(data: DocumentGetResponse, dataArchive: ArchiveProjectResponseModel[], dataDocumentArchive?: ArchiveDocumentResponseModel[]): void {
        this.internalRawProject = { projects: {}, documents: {} };
        this.getInternal(data);
        this.getArchiveInternal(dataArchive);
        this.getArchiveDocumentsInternal(dataDocumentArchive);
    }
    /*
    * Saves a new or existing project. Returns promise when the new id of the project is returned.
    */
    public async saveProject(project: Project, projectLevelType: ModalDialogType): Promise<void> {
        if (project.name.indexOf('#$') == 0) {
            this.modalService.openAlertWarning(
                this.localizationService.getString('Agito.Hilti.Profis3.DocumentService.Alerts.IllegalChar.Title'),
                this.localizationService.getString('Agito.Hilti.Profis3.DocumentService.Alerts.IllegalChar.Message')
            );

            throw new Error('invalid-name');
        }
        else {
            if (project.name.length > 250) {
                throw new Error('Max length for project name is 250 characters!');
            }

            if (this._projectsFlat[project.id] != null) {
                // call edit project on document service
                await this.updateExistingProject(project, projectLevelType);

                const internalProject = this.findProjectById(project.id);
                internalProject.name = project.name;
                internalProject.changeDate = new Date();
                internalProject.createDate = new Date();
            }
            else {
                // call add new project on document service
                await this.addNewProject(project, projectLevelType);

                //update raw data
                this.internalRawProject.projects[project.id] = {
                    id: project.id,
                    name: project.name,
                    dateChanged: new Date(),
                    dateCreated: new Date(),
                    parentId: project.parentId,
                    owner: project.owner,
                    isCompanyProject: project.isCompanyProject,
                    expanded: project.expanded,
                    readOnly: project.readOnly,
                    expandedState: project.expandedState
                };
                this._projectsFlat[project.id] = project;

                if (project.parentId) {
                    const parentItem = this.findProject(project.parentId, this.projects);
                    parentItem.subProjects[project.id] = project;
                    project.isSharedByMe = parentItem.isSharedByMe;
                } else {
                    this.projects[project.id] = project;
                }
            }
        }
    }
    public toggleExpandProject(project: Project): Promise<void> {
        throw new Error('Method not implemented.');
    }

    public async removeArchivedProject(projectId: string): Promise<void> {
        const pToDeleteCandidate = Object.values(this._projectsArchive).find((item) => item.id == projectId);
        if (pToDeleteCandidate == null) {
            throw new Error('Project with ID does not exist.');
        }

        try {
            await this.removeExistingArchivedProject(projectId);

            // remove from archived project and documentslist
            this._projectsArchive = this._projectsArchive.filter((x) => x.id != projectId);
            this._documentsArchive = this._documentsArchive.filter((x) => x.parentProjectId != projectId);
        }
        catch (ex) {
            // catch exception code that happens when project cannot be deleted because a file in the project is locked, and fetch additional details from the document service to display to the user whitch document is locked and by whom.
            if (ex instanceof CantDeleteProjectsBecauseDocumentInUse) {
                const lockedFiles = await this.readLockedDesignsOfProject(projectId);
                ex.documentsInQuestion = lockedFiles;
            }

            throw ex;
        }
    }

    private async removeExistingArchivedProject(projectId: string): Promise<void> {
        const url = `${environment.documentWebServiceUrl}project/permanentArchived/${projectId}`;

        this.logServiceRequest('removeExistingProject', url);

        try {
            await this.apiService.request(new HttpRequest('DELETE', url), this.getApiOptions());
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'delete project');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 423) {
                    throw new CantDeleteProjectsBecauseDocumentInUse();
                }

                if (response.status == 404) {
                    this.errorHandlerService.showProjectArchivedModal(response, url);
                }
                else if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            }

            throw response;
        }
    }

    public async removeArchivedDesign(documentIds: string[]): Promise<void> {
        if (documentIds == null)
            throw new Error('Document ID does not exist.');

        // eslint-disable-next-line no-useless-catch
        try {
            await this.removeExistingArchivedDesign(documentIds);

            documentIds.forEach((id) => {
                const projId = this._documentsArchive.find(x => x.id == id)?.parentProjectId;
                if (this._projectsArchive.find((project) => project.id == projId))
                    this._projectsArchive.find((project) => project.id == projId).designs--;
            })
            this._documentsArchive = this._documentsArchive.filter((x) => !documentIds.includes(x.id));
        }
        catch (ex) {
            throw ex;
        }

    }

    private async removeExistingArchivedDesign(documentIds: string[]): Promise<void> {
        const url = `${environment.documentWebServiceUrl}Document/permanentdelete`;

        this.logServiceRequest('removeExistingArchivedDesign', url);

        try {
            await this.apiService.request(new HttpRequest('DELETE', url, documentIds, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                }
                )
            }));
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'delete design');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 423) {
                    //throw new CantDeleteProjectsBecauseDocumentInUse();
                }

                if (response.status == 404) {
                    this.errorHandlerService.showDesignDoesNotExistModal(response, url);
                }
                else if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            }

            throw response;
        }
    }

    public removeProject(projectId: string): Promise<void> {
        throw new Error('Method not implemented.');
    }
    /**
     * Convert (permanently) the project to company (shared) project and call external document service.
     * @projectId project
     */
    public async convertProject(projectId: string): Promise<void> {
        let matchedProject = this.findProject(projectId, this.projects);
        await this.convertExistingProject(projectId);
        // Update all the projects with companyproject=true under given project
        if (matchedProject) {
            const designList = cloneDeep(Object.keys(matchedProject.designs));
            matchedProject = this.updateCompanyProject(matchedProject, true);
            //remove designs of converted projects from parent projects
            this.removeSubChildDesigns(projectId, this.projects, designList);
            //remove converted projects from the parent projects
            this.removeSubProject(matchedProject.parentId, this.projects, matchedProject.id);
            matchedProject.parentId = null;
            this._projectsFlat[matchedProject.id].parentId = null;
            // move the converted sub projects to root
            this._projects[matchedProject.id] = matchedProject;
        }

        // flatten project to store back into _projectFlat
        // const flattenProjects = this.flattenProjects({ [matchedProject.id]: matchedProject });

        // for (const projectId in flattenProjects) {
        //     this._projectsFlat[projectId] = flattenProjects[projectId];
        // }
    }

    /**
     * Remove the project from internal store and call external document service.
     * @projectId project
     */
    public async archiveProject(projectId: string): Promise<void> {
        const pToDeleteCandidate = this.findProjectById(projectId);

        if (pToDeleteCandidate == null) {
            throw new Error('Project with ID does not exist.');
        }
        if (pToDeleteCandidate.projectType != ProjectType.common) {
            throw new Error('Only common projects can be deleted.');
        }

        // delete parent
        try {
            const resp: any = await this.archiveExistingProject(projectId);
            for (const index in resp) {
                const x = resp[index];
                const projId = x.projectid;
                if (!this._projectsArchive.find(x => x.id === projId)) {
                    this._projectsArchive.push({
                        archived: x.archived,
                        created: x.created,
                        designs: x.designs,
                        members: x.members,
                        id: x.projectid,
                        parentId: x.parentprojectid,
                        name: x.name
                    });
                }

                this.getDesignIdsByProjectId(projId).forEach((designId) => {
                    const design = this.findDesignById(designId);
                    if (projId === design.projectId) {
                        this._documentsArchive.unshift({
                            archived: x.archived,
                            created: design.createDate,
                            id: designId,
                            designs: x.designs,
                            members: x.members,
                            name: design.designName,
                            parentProjectId: design.projectId,
                            projectname: x.name
                        });
                    }
                });
                this.deleteProjectFunc(projId);
            }
            // set issharedby me for project and child project to false
            const foundedProject = this.findProject(projectId, this.projects);
            this.setSharedByMeTillChildNode(foundedProject, false);
            this.setSharedByMeTillParentNode(foundedProject, false);
            for (const index in resp) {
                const x = resp[index];
                const projId = x.projectid;

                // remove from project flat list
                delete this._projectsFlat[projId];

                // remove form hierarchical list - in line recursion
                this.deleteProject(projId, this._projects);
            }

        }
        catch (ex) {
            // catch exception code that happens when project cannot be deleted because a file in the project is locked, and fetch additional details from the document service to display to the user whitch document is locked and by whom.
            if (ex instanceof CantArchiveProjectsBecauseDocumentInUse) {
                const lockedFiles = await this.readLockedDesignsOfProject(projectId);
                ex.documentsInQuestion = lockedFiles;

                throw ex;
            }

            throw ex;
        }
    }

    private deleteProjectFunc(projId: string): void {
        delete this.internalRawProject.projects[projId];
        const deleteDocs = Object.keys(this.internalRawProject.documents).filter((it) => this.internalRawProject.documents[it].projectId == projId);
        deleteDocs.forEach((it) => this.deleteDesignFunc(it));
    }

    private deleteProject(id: string, projects: { [id: string]: Project }): void {
        if (projects[id]) {
            delete projects[id];
        }
        for (const item in projects) {
            const project = projects[item];
            this.deleteProject(id, project.subProjects);
        }
    }

    private deleteDesignFunc(designId: string): void {
        const design = this.findDesignById(designId);
        const project = this.findProjectById(design?.projectId);

        if (project == null) {
            throw new Error('Invalid project ID.');
        }

        const deleteDoc = Object.keys(this.internalRawProject.documents).find((it) => this.internalRawProject.documents[it].id == designId);
        delete this.internalRawProject.documents[deleteDoc];

        // remove from project
        delete project.directChildDesigns[designId];
        delete project.designs[designId];

        // remove from all parent projects
        let parentX: Project = this.findProjectById(project.parentId);
        while (parentX != undefined && parentX != null) {
            delete parentX.designs[designId];
            parentX = this.findProjectById(parentX.parentId);
        }

        // remove from flat design list
        delete this._desingsFlat[designId];

        // remove from project object
        const proj: Project = this.findProject(project.id, this._projects);
        delete proj.directChildDesigns[designId];
        delete proj.designs[designId];

        let parentProj: Project = this.findProject(project.parentId, this._projects);
        while (parentProj) {
            delete parentProj.designs[designId];
            delete parentProj.directChildDesigns[designId];
            parentProj = this.findProject(parentProj.parentId, this._projects);
        }
    }

    private findDesignByIdFromProjectFlat(projects: { [id: string]: Project; }, designId: string): IDesignListItem {
        if (projects == null) {
            return null;
        }

        for (const pKey in projects) {
            const foundDesign = Object.values(projects[pKey].directChildDesigns).find((design) => design.id == designId);

            if (foundDesign != null) {
                return foundDesign as IDesignListItem;
            }
        }

        return null;
    }

    /**
   * Reads all the locked designs of a project.
   * @param projectId - Project id
   */
    private async readLockedDesignsOfProject(projectId: string): Promise<CantPerformActionReasonProblematicDocumentInformation[]> {
        interface IGetProjectResponse {
            documents: IGetProjectResponseDocument[];
        }

        interface IGetProjectResponseDocument {
            locked: boolean;
            documentid: string;
            name: string;
            lockedby: string;
        }

        const url: string = `${environment.documentWebServiceUrl}project/` + projectId;

        this.logServiceRequest('readDesignsOfProject', url);

        try {
            const response = await this.apiService.request<IGetProjectResponse>(new HttpRequest('GET', url), this.getApiOptions());

            this.logServiceResponse('readDesignsOfProject', response);
            const designs: CantPerformActionReasonProblematicDocumentInformation[] = [];
            if (response.body.documents instanceof Array) {
                for (const pRaw of response.body.documents) {
                    if (pRaw.locked) {
                        const p: CantPerformActionReasonProblematicDocumentInformation = new CantPerformActionReasonProblematicDocumentInformation();
                        p.designId = pRaw.documentid;
                        p.document = pRaw.name;
                        p.username = pRaw.lockedby;
                        designs.push(p);
                    }
                }
            }

            return designs;
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'documents');

            if (response instanceof HttpErrorResponse) {
                if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            }

            throw response;
        }
    }

    private async archiveExistingProject(projectId: string): Promise<unknown> {
        const url = `${environment.documentWebServiceUrl}project/${projectId}`;

        this.logServiceRequest('archiveExistingProject', url);

        try {
            return (await this.apiService.request<unknown>(new HttpRequest('DELETE', url), this.getApiOptions())).body;
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'delete project');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 423) {
                    throw new CantArchiveProjectsBecauseDocumentInUse();
                }

                if (response.status == 404) {
                    this.errorHandlerService.showProjectArchivedModal(response, url);
                }
                else if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            }

            throw response;
        }
    }

    public async restoreProject(projectId: string, projectLevelType: ModalDialogType): Promise<string[]> {
        const pToRestoreCandidate = Object.values(this._projectsArchive).find((item) => item.id == projectId);
        if (pToRestoreCandidate == null) {
            throw new Error('Project with ID does not exist.');
        }
        if (this.projectNameExists(pToRestoreCandidate.name, pToRestoreCandidate.parentId)) {
            this.errorHandlerService.showExistingProjectNameModal();
            return Promise.resolve([]);
        }
        try {
            const x = await this.restoreExistingProject(projectId, projectLevelType);

            // Add restored project and it's documents to the project menu
            if (x) {
                const { projects, documents } = x;

                Object.assign(this.internalRawProject.projects, projects);
                Object.assign(this.internalRawProject.documents, documents);

                this.getInternal(this.internalRawProject);
            }
            //Remove project from archived project list
            const projectIdsToRemove = Object.values(x.projects).map((project) => project.id);
            this._projectsArchive = this._projectsArchive.filter((x) => !projectIdsToRemove.includes(x.id));
            //Remove project designs from archived designs list
            const documentsIdsToRemove = Object.values(x.documents).map((document) => document.id);
            this._documentsArchive = this._documentsArchive.filter((x) => !documentsIdsToRemove.includes(x.id));
            return Promise.resolve(projectIdsToRemove);
        }
        catch (ex) {
            // catch exception code that happens when project cannot be deleted because a file in the project is locked, and fetch additional details from the document service to display to the user whitch document is locked and by whom.
            if (ex instanceof CantRestoreProjectsBecauseDocumentInUse) {
                const lockedFiles = await this.readLockedDesignsOfProject(projectId);
                ex.documentsInQuestion = lockedFiles;
            }

            throw ex;
        }
    }

    private async restoreExistingProject(projectId: string, projectLevelType: ModalDialogType): Promise<DocumentGetResponse> {
        const url = `${environment.documentWebServiceUrl}project/dearchive/${projectId}`;

        this.logServiceRequest('restoreExistingProject', url);

        try {
            const response = await this.apiService.request<any>(new HttpRequest('POST', url, {}), this.getApiOptions());
            this.logServiceResponse('restoreExistingProject', response);

            const data = response.body;
            const result: DocumentGetResponse = {
                projects: Object.fromEntries(Object.entries<any>(data.projects).map(([key, it]): [string, DocumentGetProject] => [key, {
                    dateChanged: it.dateChanged,
                    dateCreated: it.dateCreated,
                    id: it.id,
                    name: this.projectNameExists(it.name, it.parentId, it.id) ? this.createUniqueName(it.name, Object.values(this._projectsFlat).map(project => project.name)) : it.name,
                    parentId: it.parentId,
                    owner: it.owner,
                    isCompanyProject: it.isCompanyProject,
                    expanded: it.expanded,
                    readOnly: it.readOnly,
                    expandedState: it.expandedState
                }])),
                documents: Object.fromEntries(Object.entries<any>(data.documents).map(([key, it]): [string, DocumentGetDocument] => [key, {
                    dateChanged: it.dateChanged,
                    dateCreated: it.dateCreated,
                    id: it.id,
                    locked: it.locked,
                    metadata: it.metadata,
                    projectId: it.projectId,
                    lockedUserName: it.lockedUserName,
                    name: it.name,
                    type: it.type,
                    owner: it.owner
                }]))
            };

            return result;
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'restore project');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 423) {
                    throw new CantRestoreProjectsBecauseDocumentInUse();
                }

                if (response.status == 409) {
                    this.errorHandlerService.showExistingProjectNameModal();
                }
                else if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            }

            throw response;
        }
    }

    public async restoreExistingDesign(documentId: string): Promise<void> {
        if (documentId == null) {
            throw new Error('Document id doesnot exist');
        }
        const url = `${environment.documentWebServiceUrl}Document/dearchive`;
        const data = { documentIds: [documentId] };
        try {
            const response = await this.apiService.request<any>(new HttpRequest('PATCH', url, data), this.getApiOptions());
            this.logServiceResponse('restoreExistingDesign', response);

            const body = response.body;

            const documents =
                Object.fromEntries(Object.entries<any>(body).map(([key, it]): [string, DocumentGetDocument] => [it.documentid, {
                    dateChanged: it.lastchange != null ? new Date(it.lastchange * 1000) : null,
                    dateCreated: it.created != null ? this.getDocumentCreatedDate(it.documentid) : null,
                    id: it.documentid,
                    locked: it.locked,
                    metadata: this.getDesignMetaData(it.metadata),
                    projectId: it.project.projectid,
                    lockedUserName: it.lockedby,
                    name: it.name,
                    type: it.type,
                    owner: it.owner
                }]));

            Object.assign(this.internalRawProject.documents, documents);
            this.getInternal(this.internalRawProject);
            //Remove design from archived project list and document list
            const documentProjId = this._documentsArchive.find((x) => x.id = documentId).parentProjectId;
            if (this._projectsArchive.find((project) => project.id == documentProjId))
                this._projectsArchive.find((project) => project.id == documentProjId).designs--;
            this._documentsArchive = this._documentsArchive.filter((x) => x.id != documentId);
        }
        catch (response) {
            if (response instanceof HttpErrorResponse) {
                if (response.status == 409) {
                    this.errorHandlerService.showExistingDesignNameModal();
                }
                else if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            }
            throw response;
        }

    }

    private getDesignMetaData(x: any) {
        const metaData: { [key: string]: string } = {};
        x.forEach((item: any) => {
            metaData[item.key] = item.value;
        })
        return metaData;
    }

    private getDocumentCreatedDate(documentId: string) {
        const document = this._documentsArchive.find(document => document.id == documentId);
        return document.created;
    }

    /**
     * Takes the desing id file and returns JSON object, representing it.
     * In some cases object deriving from CantOpenDesignReason is returned.
     * @param design - Design
     */
    public async openDesignExclusivePe(design: IBaseDesign): Promise<ProjectDesignBaseEntity> {
        return this.openDesignExclusive<ProjectDesignBaseEntity>(design, (content, designName, projectName) => {
            content.DesignName = designName;
            content.ProjectName = projectName;
            return content;
        });
    }

    public async openDesignExclusiveC2C(design: IBaseDesign): Promise<ProjectDesignEntityC2C> {
        return this.openDesignExclusive<ProjectDesignEntityC2C>(design, (content, designName, projectName) => {
            content.designName = designName;
            content.projectName = projectName;
            return content;
        });
    }

    public async openDesignExclusiveDecking(design: IBaseDesign): Promise<ProjectDesignBaseEntity> {
        return this.openDesignExclusive<ProjectDesignBaseEntity>(design);
    }

    public async openDesign(design: IBaseDesign): Promise<ProjectDesignBaseEntity> {
        const response = this.getDesignContent(design.id);
        this.openNewSessionForDesign(design.id);

        return response;
    }

    public async openDesignC2C(design: IBaseDesign): Promise<ProjectDesignEntityC2C> {
        const response = await this.getDesignContentC2C(design.id);
        this.openNewSessionForDesign(design.id);

        return response;
    }
    public async addDesign(projectId: string, design: Design, canGenerateUniqueName: boolean, ignoreConflict: boolean, useDeckingDesign: boolean, deckingProject: BaseDesign): Promise<IDesignListItem> {

        // validate
        let project = this.findProjectById(projectId);
        if (project == null) {
            throw new Error('Invalid project ID.');
        }

        const designName = !useDeckingDesign ? design.designName : deckingProject.name;
        if (designName.length > 250) {
            throw new Error('Max length for design name file is 250 !');
        }

        // make new meta-data entity
        const metaData: DesignExternalMetaData = !useDeckingDesign ? DesignExternalMetaData.getMetaDataFromDesign(design)
            : DesignExternalMetaData.getMetaDataFromDeckingDesign(deckingProject);

        // conversion
        const designAsBase64Endoded = useDeckingDesign ? this.browserService.toBase64(deckingProject) : this.GetProjectDesignContent(design);
        const document = await this.addNewFile(projectId, designName, designAsBase64Endoded, metaData, canGenerateUniqueName, ignoreConflict);   // call service to add new file into database

        // TODO: fix enter session key create new design request
        this.openNewSessionForDesign(document.documentid);

        // associate design to project
        project = this.findProjectById(projectId);
        if (project == null) {
            throw new Error('Invalid project ID.');
        }

        // add returned data to internal memory and return new design as promised
        //  To handle in local service
        const utc = this.localizationService.moment().utc();
        const nowUtc = new Date(utc.year(), utc.month(), utc.date(), utc.hour(), utc.minute(), utc.second(), utc.millisecond());
        const newDesign: IDesignListItem = {
            id: document.documentid,
            changeDate: nowUtc,
            createDate: nowUtc,
            designName: document.name,
            locked: false,
            lockedUser: null,
            projectId,
            projectName: '',
            metaData,
            integrationDocument: (!useDeckingDesign) ? design.integrationDocument : null,
            owner: true
        };

        newDesign.projectName = project.name;
        newDesign.isSharedByMe = project.isSharedByMe;

        this.addUpdateDesignInProject(newDesign);

        if (!useDeckingDesign) {
            if (design.isC2C) {
                this.setDesingPropertiesFromServiceRecordToWholeDesingEntitiyC2C(design, newDesign);
            }
            else {
                this.setDesingPropertiesFromServiceRecordToWholeDesingEntitiy(design, newDesign);
            }
        }
        return newDesign;
    }

    private getDesignContent(designId: string): Promise<ProjectDesignBaseEntity> {
        return this.getContent(designId);
    }

    private getDesignContentC2C(designId: string): Promise<ProjectDesignEntityC2C> {
        return this.getContent(designId);
    }

    private async getContent<T>(designId: string): Promise<T> {
        const url = `${environment.documentWebServiceUrl}documentContent/${designId}`;

        this.logServiceRequest('getDesignContent', url);

        try {
            const response = await this.apiService.request<any>(new HttpRequest('GET', url), this.getApiOptions());

            this.logServiceResponse('getDesignContent', response);

            const projectDesignAsJsonString = this.browserService.fromBase64(response.body.filecontent);

            try {
                return JSON.parse(projectDesignAsJsonString) as T;
            }
            catch (error) {
                throw new Error('Invalid design on document service.');
            }
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'get design content');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 404) {
                    this.errorHandlerService.showDesignDoesNotExistModal(response, url);
                }
                else if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            }

            throw response;
        }
    }


    private updateProject(designId: string, projectId: string): void {
        const design = this.findDesignById(designId);
        if (design == null) {
            throw new Error('Invalid design id.');
        }

        const project = this.findProjectById(projectId);
        if (project == null) {
            throw new Error('Invalid project id.');
        }

        design.projectId = projectId;
        design.projectName = project.name;

        // remove design from project
        for (const pKey in this.projectsFlat) {
            const p = this.projectsFlat[pKey];

            if (p.directChildDesigns[design.id]) {
                delete p.directChildDesigns[design.id];
                delete p.designs[design.id];

                //remove design from tree starting from root node
                if (p.parentId == null) {
                    const proj = this.findProject(p.id, this.projects);
                    this.updateProjectTreeDesigns(proj, design.id);
                }
            }
        }

        // remove design from designs
        delete this._desingsFlat[design.id];

        // add design to project
        if (project.directChildDesigns == null) {
            project.directChildDesigns = {};
        }

        this.addUpdateDesignInProject(design);
        this.pendingActionService.setPendingAction(PendingAction.RefershTree);
    }

    private updateProjectTreeDesigns(project: Project, designId: string): Project {
        delete project.designs[designId];
        delete project.directChildDesigns[designId];

        if (project.subProjects && Object.entries(project.subProjects).length > 0) {
            for (const child in project.subProjects) {
                const subProject = project.subProjects[child];
                this.updateProjectTreeDesigns(subProject, designId);
            }
        }
        return project;
    }

    public updateDesignProject(designId: string, projectId: string): Promise<void> {
        const design = this.findDesignById(designId);
        if (design == null) {
            throw new Error('Invalid design id.');
        }

        const project = this.findProjectById(projectId);
        if (project == null) {
            throw new Error('Invalid project id.');
        }

        return this.openDesignExclusivePe(design)
            .then((projectDesign) => {
                projectDesign.ProjectName = project.name;

                return this.updateDesignWithNewContent(design.id, project.id, design.designName, projectDesign, null, null, true, false, DocumentAccessMode.Update);
            })
            .then(() => {
                this.updateProject(designId, projectId);
            });
    }

    public async updateDesignWithNewContent(
        designId: string,
        projectId: string,
        designName: string,
        contentOverride: ProjectDesignBaseEntity,
        metadataOverride: DesignExternalMetaData,
        displayDesign: IDetailedDisplayDesignPe,
        unlock = false,
        exclusiveLock = false,
        documentAccessMode = DocumentAccessMode.Update
    ): Promise<void> {
        // If there are no pending requests start normally
        if (this.defferedRequests[designId] == null || (this.defferedRequests[designId] != null && this.defferedRequests[designId].running == null)) {
            this.defferedRequests[designId] = {
                running: this.putSmallDesignChangePromise({
                    designId,
                    projectId,
                    designName,
                    contentOverride,
                    metadataOverride,
                    unlock,
                    displayDesign,
                    exclusiveLock,
                    documentAccessMode
                }),
                defferedData: null,
                deffered: null
            };

            await this.defferedRequests[designId].running;
        }
        else {
            // Else create deffer object save request data and return deffered promise
            if (this.defferedRequests[designId].deffered == null) {
                this.defferedRequests[designId].deffered = new Deferred();
            }

            this.defferedRequests[designId].defferedData = {
                designId,
                projectId,
                designName,
                contentOverride,
                metadataOverride,
                unlock,
                displayDesign,
                exclusiveLock,
                documentAccessMode
            };

            await this.defferedRequests[designId].deffered.promise;
        }
    }

    public async updateDesignWithNewContentC2C(
        designId: string,
        projectId: string,
        designName: string,
        contentOverride: ProjectDesignEntityC2C,
        metadataOverride: DesignExternalMetaData,
        displayDesign: IDetailedDisplayDesignC2C,
        unlock = false,
        exclusiveLock = false,
        documentAccessMode = DocumentAccessMode.Update
    ): Promise<void> {
        // If there are no pending requests start normally
        if (this.defferedRequests[designId] == null || (this.defferedRequests[designId] != null && this.defferedRequests[designId].running == null)) {
            this.defferedRequests[designId] = {
                running: this.putSmallDesignChangePromiseC2C({
                    designId,
                    projectId,
                    designName,
                    contentOverride,
                    metadataOverride,
                    unlock,
                    displayDesign,
                    exclusiveLock,
                    documentAccessMode
                }),
                defferedData: null,
                deffered: null
            };

            await this.defferedRequests[designId].running;
        }
        else {
            // Else create deffer object save request data and return deffered promise
            if (this.defferedRequests[designId].deffered == null) {
                this.defferedRequests[designId].deffered = new Deferred();
            }

            this.defferedRequests[designId].defferedData = {
                designId,
                projectId,
                designName,
                contentOverride,
                metadataOverride,
                unlock,
                displayDesign,
                exclusiveLock,
                documentAccessMode
            };

            await this.defferedRequests[designId].deffered.promise;
        }
    }

    private async putSmallDesignChangePromiseC2C(data: IDefferedDataC2C): Promise<void> {
        // make new meta-data entity
        const internalDesign = this.findDesignById(data.designId);

        const projectDesign = cloneDeep(data.contentOverride);
        delete projectDesign.designName;
        delete projectDesign.projectName;
        const metaData = cloneDeep(data.metadataOverride);

        if (data.displayDesign != null) {
            detailedDisplayDesignToProjectDesignC2C(projectDesign, data.displayDesign);
        }

        await this.smallDesignChange(internalDesign, projectDesign, data, metaData);
    }

    public archiveDesign(designId: string): Promise<void> {
        throw new Error('Method not implemented.');
    }
    public async removeDesigns(designIds: string[]): Promise<void> {
        for (const designId of designIds) {
            const dToDeleteCandidate = this.findDesignById(designId);
            if (dToDeleteCandidate == null) {
                throw new Error(`Design with ID ${designId} does not exist.`);
            }
        }

        // delete designs
        await this.removeExistingDesigns(designIds);

        for (const designId of designIds) {
            this.deleteDesignFunc(designId);
        }
    }
    private async removeExistingDesigns(designIds: string[]): Promise<void> {
        const url = `${environment.documentWebServiceUrl}document/permanentdelete`;

        this.logServiceRequest('removeExistingDesigns', url);

        const request = new HttpRequest('DELETE', url, designIds, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        });

        try {
            await this.apiService.request(request, this.getApiOptions());
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'remove designs');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 404) {
                    this.errorHandlerService.showProjectArchivedModal(response, url, designIds);
                }
                else if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url,
                        requestPayload: designIds
                    });
                }
            }

            throw response;
        }
    }

    public deleteProjectLocal(projectId: string): void {
        throw new Error('Method not implemented.');
    }

    public async getDeckingDesignContent(designId: string, sessionId: string, documentId: string, isLock: boolean): Promise<any> {
        const url = `${environment.documentWebServiceUrl}documentContent/GetExclusive`;
        const rData = { documentid: designId, islock: isLock, key: sessionId };

        try {
            return await this.getDesignContentExclusiveInternal(url, rData);
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'get design content exclusive');
            // handle document locked error
            if (response instanceof HttpErrorResponse && response.status == 423) {
                // check the detail of the conflict
                const basicDocumentInfo = await this.getDesignBasicDocumentInformation(documentId);
                const alertTitle = 'Agito.Hilti.Profis3.ProjectAndDesing.Alerts.CannotOpenInUseBy.Title';
                const alertMessage = 'Agito.Hilti.Profis3.ProjectAndDesing.Alerts.CannotOpenInUseBy.Description';

                this.modalService.openAlertWarning(
                    this.localizationService.getString(alertTitle),
                    formatKeyValue(this.localizationService.getString(alertMessage), { user: basicDocumentInfo.lockedUser })
                );
            }

            throw response;
        }
    }

    public openNewSessionForDesign(designId: string): string {
        // just return new design if already exists, where we have certain methods where it is not certain if session is allready open
        if (this.designSessionMapping.some(m => m.designId == designId)) {
            return this.getSessionKeyForDesign(designId);
        }

        // create new session
        const key = this.guidService.new();

        this.designSessionMapping.push({ designId, sessionId: key, isCancelled: false });

        return key;
    }
    public getNumberOfDocumentsOwnedByUser(): number {
        return Object.values(this._desingsFlat).filter(x => x.owner).length;
    }

    public async addDesignCommon(projectId: string, design: DesignCommon, canGenerateUniqueName: boolean, ignoreConflict: boolean): Promise<IDesignListItemCommon> {

        let project = this.findProjectById(projectId);
        if (project == null) {
            throw new Error('Invalid project ID.');
        }

        const designName = design.designName;
        if (designName.length > 250) {
            throw new Error('Max length for design name file is 250 !');
        }

        // make new meta-data entity
        const metaData: DesignExternalMetaDataCommon = design.metaData;
        // conversion
        const designAsBase64Endoded = this.browserService.toBase64(design.getDocumentDesign ? design.getDocumentDesign() : design.projectDesign);

        // call service to add new file into database
        const document = await this.addNewFile(projectId, designName, designAsBase64Endoded, metaData, canGenerateUniqueName, ignoreConflict);

        // TODO: fix enter session key create new design request
        this.openNewSessionForDesign(document.documentid);

        // associate design to project
        project = this.findProjectById(projectId);
        if (project == null) {
            throw new Error('Invalid project ID.');
        }

        // add returned data to internal memory and return new design as promised
        const now: Date = new Date();
        const newDesign: IDesignListItemCommon = {
            id: document.documentid,
            changeDate: now,
            createDate: now,
            designName: document.name,
            locked: false,
            lockedUser: null,
            projectId,
            projectName: project.name,
            metaData,
            owner: true
        };
        this.addUpdateDesignInProject(newDesign);

        this.setDesignPropertiesCommon(design, newDesign);

        return newDesign;
    }
    public async updateDesignWithNewContentCommon<TDisplayDesign>(
        design: DesignCommon,
        displayDesign: TDisplayDesign,
        unlock = false,
        exclusiveLock = false,
        documentAccessMode = DocumentAccessMode.Update): Promise<void> {

        // If there are no pending requests start normally
        if (this.defferedRequests[design.id] == null || (this.defferedRequests[design.id] != null && this.defferedRequests[design.id].running == null)) {
            this.defferedRequests[design.id] = {
                running: this.putSmallDesignChangePromiseCommon({
                    designId: design.id,
                    projectId: design.projectId,
                    designName: design.designName,
                    contentOverride: design.getDocumentDesign ? design.getDocumentDesign() : design.projectDesign,
                    metadataOverride: design.metaData,
                    unlock,
                    displayDesign,
                    exclusiveLock,
                    documentAccessMode
                }),
                defferedData: null,
                deffered: null
            };

            await this.defferedRequests[design.id].running;
        }
        else {
            // Else create deffer object save request data and return deffered promise
            if (this.defferedRequests[design.id].deffered == null) {
                this.defferedRequests[design.id].deffered = new Deferred();
            }

            this.defferedRequests[design.id].defferedData = {
                designId: design.id,
                projectId: design.projectId,
                designName: design.designName,
                contentOverride: design.getDocumentDesign ? design.getDocumentDesign() : design.projectDesign,
                metadataOverride: design.metaData,
                unlock,
                displayDesign,
                exclusiveLock,
                documentAccessMode
            };

            await this.defferedRequests[design.id].deffered.promise;
        }
    }

    /**
 * Creates promise to update design with new data.
 * @param data - The request data.
 */
    private async putSmallDesignChangePromiseCommon<TProjectDesign, TDetailedDisplayDesign>(data: IDefferedDataCommon<TProjectDesign, TDetailedDisplayDesign>): Promise<void> {
        // make new meta-data entity
        const internalDesign = this.findDesignById(data.designId);

        const projectDesign = cloneDeep(data.contentOverride);
        const metaData = cloneDeep(data.metadataOverride);

        await this.smallDesignChangeCommon<TProjectDesign, TDetailedDisplayDesign>(internalDesign, projectDesign, data, metaData);
    }

    /**
     * Update design thumbnail image.
     * @param designId Design id
     * @param thumbnailContent Thumbnail image
     * @param respond If server should send response
     */
    public async updateDesignThumbnailImage(designId: string, thumbnailContent: string, respond: boolean): Promise<void> {
        // If there are no pending requests start normally
        if (this.defferedImageUpload[designId] == null || (this.defferedImageUpload[designId] != null && this.defferedImageUpload[designId].running == null)) {
            this.defferedImageUpload[designId] = {
                running: this.updateDesignThumbnailImagePromise({ designId, thumbnailContent, respond } as IDefferedImageData),
                defferedData: null,
                deffered: null
            };

            await this.defferedImageUpload[designId].running;
        }
        else {
            // Else create deffer object save request data and return deffered promise
            if (this.defferedImageUpload[designId].deffered == null) {
                this.defferedImageUpload[designId].deffered = new Deferred();
            }

            this.defferedImageUpload[designId].defferedData = {
                designId,
                thumbnailContent,
                respond,
                isTemplate: false
            };

            await this.defferedImageUpload[designId].deffered.promise;
        }
    }
    /**
      * Publishes the design.
      * @param id - The design id.
      */
    public async publish(id: string): Promise<void> {
        // TODO: should check session here

        if (!this.isCanceledSessionForDesign(id)) {
            await this.postUnlockDocument(id);
            this.closeSessionForDesign(id);
        }
    }
    /**
     * Upload design printscreen image when a design report is ready for export
     * @param designId - design id
     * @param imageContent - the design image content in base64 encoded xml format
     * @param base64ThumbnailXmlContent - the design thumbnail image content in base64 encoded xml format
     */
    public uploadDesignImage(designId: string, imageContent: string, thumbnailContent: string): Promise<void> {
        return this.uploadDesignImageInternal(designId, imageContent, thumbnailContent);
    }

    public async copyDesign(documentId: string, documentName: string, projectId: string): Promise<void> {
        const url = `${environment.documentWebServiceUrl}document/copy`;
        this.logServiceRequest('copyDesign', url);

        const data = {
            documentId,
            documentName,
            projectId
        };

        try {
            const response = await this.apiService.request<IGetDocumentResponse>(new HttpRequest('POST', url, data), this.getApiOptions());

            this.logServiceResponse('copyDesign', response);

            const newDesign = this.toIDesignListItem(response.body);
            newDesign.owner = true;
            this.addUpdateDesignInProject(newDesign);

        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'copy design');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 409 && response.error.content.conflictType == 0) {
                    this.errorHandlerService.showExistingDesignNameModal(response, url, data);
                }
                else if (response.status == 409 && response.error.content.conflictType == 1) {
                    await this.modalService.openMaxDesignsLimitReached();
                }
                else if (response.status == 404) {
                    this.errorHandlerService.showProjectArchivedModal(response, url, data);
                }
                else if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url,
                        requestPayload: data
                    });
                }
            }

            throw response;
        }
    }


    moveDesign(documentId: string, projectId: string): Promise<void> {
        throw new Error('Method not implemented.');
    }
    public async openDesignExclusive<TProjectDesign>(design: IBaseDesign, adjustContent?: (content: TProjectDesign, designName: string, projectName: string) => TProjectDesign): Promise<TProjectDesign> {
        try {
            return await this.getDesignContentExclusive<TProjectDesign>(design.id, this.openNewSessionForDesign(design.id), design.projectName, adjustContent);
        }
        catch (response) {
            await this.catchDesignExclusive(response, design);

            throw response;
        }
    }

    public async catchDesignExclusive(response: unknown, design: IBaseDesign): Promise<never> {
        // handle document locked error
        if (response instanceof HttpErrorResponse && response.status == 423) {
            // check the detail of the conflict
            const d = await this.getDesignBasicDocumentInformation(design.id);
            const ex = new CantOpenDesignBecauseLockedByOtherUser();
            ex.username = d.lockedUser;

            throw ex;
        }

        throw response;
    }

    public async updateDocumentDesignContent(document: IDesignListItem, base64Content: string, unlock: boolean, exclusiveLock: boolean, documentAccessMode: DocumentAccessMode): Promise<void> {
        const url = `${environment.documentWebServiceUrl}documentcontent`;

        const rData = {
            key: this.getSessionKeyForDesign(document.id),
            documentid: document.id,
            projectid: document.projectId,
            name: document.designName,
            filecontent: base64Content,
            unlock,
            exclusiveLock,
            documentAccessMode
        };

        const request = new HttpRequest('PUT', url, rData, {
            responseType: 'json'
        });

        try {
            await this.apiService.request(request);
        }
        catch (response) {
            console.error(response);
        }
    }

    private async getDesignContentExclusiveInternal(url: string, rData: any): Promise<HttpResponse<unknown>> {
        this.logServiceRequest('getDesignContentExclusive', rData, url);
        return await this.apiService.request<unknown>(new HttpRequest('POST', url, rData), this.getApiOptions());
    }

    private getContentFromResponse<TProjectDesign>(response: HttpResponse<unknown>, projectName?: string, adjustContent?: ((conten: TProjectDesign, designName: string, projectName: string) => TProjectDesign) | undefined): TProjectDesign {
        this.logServiceResponse('getDesignContentExclusive', response);

        const body = (response.body as any);
        const projectDesignAsJsonString = this.browserService.fromBase64(body.filecontent);

        const project = this.findProjectById(body.projectid);

        let data: TProjectDesign;
        try {
            data = JSON.parse(projectDesignAsJsonString);
        }
        catch (error) {
            throw new Error('Invalid design on document service.');
        }
        return adjustContent?.(data, body.documentname, (project?.name ?? projectName)) ?? data;
    }

    private async getDesignContentExclusive<TProjectDesign>(designId: string, sessionId: string, projectName?: string, adjustContent?: ((conten: TProjectDesign, designName: string, projectName: string) => TProjectDesign) | undefined): Promise<TProjectDesign> {
        const url = `${environment.documentWebServiceUrl}documentContent/GetExclusive`;
        const rData = { documentid: designId, islock: true, key: sessionId };

        try {
            const response = await this.getDesignContentExclusiveInternal(url, rData);
            return this.getContentFromResponse<TProjectDesign>(response, projectName, adjustContent);
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'get design content exclusive');

            if (response instanceof HttpErrorResponse) {
                // handle document locked error
                if (response.status == 423) {
                    // leave the upper level to deal with it
                    throw response;
                }

                if (response.status == 404) {
                    this.errorHandlerService.showDesignDoesNotExistModal(response, url, rData);
                }
                else if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url,
                        requestPayload: rData
                    });
                }
            }

            throw response;
        }
    }
    public getSessionKeyForDesign(designId: string): string {
        const mapping = this.designSessionMapping.find(x => x.designId == designId);
        if (mapping == null) {
            throw new Error('Session is not opened for the specified design.');
        }

        return mapping.sessionId;
    }

    /**
   * Updates a design with the new project design.
   * @param design - The design entity.
   */
    public async updateDesign(design: Design): Promise<void> {
        const metaData: DesignExternalMetaData = DesignExternalMetaData.getMetaDataFromDesign(design);

        if (design) {
            if (design.isC2C) {
                await this.updateDesignWithNewContentC2C(design.id, design.projectId, design.designName, design.designData.projectDesignC2C, metaData, null, false, false, DocumentAccessMode.Update);
            }
            else {
                await this.updateDesignWithNewContent(design.id, design.projectId, design.designName, design.designData.projectDesign, metaData, null, false, false, DocumentAccessMode.Update);
            }
        }
    }

    public async smallDesignChange(
        internalDesign: IDesignListItem,
        projectDesign: ProjectDesignBaseEntity | ProjectDesignEntityC2C,
        data: IDefferedData | IDefferedDataC2C,
        metaData: DesignExternalMetaData
    ): Promise<void> {
        try {
            const designAsBase64Endoded = this.browserService.toBase64(
                "ProjectDesignType" in projectDesign && projectDesign.ProjectDesignType === DesignType.DiaphragmDesign ? projectDesign.DesignName : projectDesign
            );

            await this.putSmallDesignChange(data.designId, data.projectId, data.designName, designAsBase64Endoded, this.getSessionKeyForDesign(data.designId), metaData, data.unlock, data.exclusiveLock, data.documentAccessMode);
            if (metaData != null) {
                // update meta-data in proxy store
                internalDesign.metaData.productName = metaData.productName;
                internalDesign.metaData.designType = metaData.designType;
                internalDesign.metaData.name = metaData.name;
                internalDesign.metaData.region = metaData.region;
                internalDesign.metaData.standard = metaData.standard;
                internalDesign.metaData.designMethod = metaData.designMethod;
                internalDesign.metaData.approvalNumber = metaData.approvalNumber;
                internalDesign.metaData.calculationType = metaData.calculationType;
                internalDesign.metaData.handrailConnectionType = metaData.handrailConnectionType;
            }

            internalDesign.designName = data.designName;

            const now: Date = new Date();
            const nowUtc: Date = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds()));
            internalDesign.changeDate = nowUtc;

            this.updateProject(data.designId, data.projectId);
        }
        finally {
            this.defferedRequests[data.designId].running = null;

            // start new request for deffered and save reference into runningDefferd to resolve it later
            // clear defferd so new requests can be added
            if (this.defferedRequests[data.designId].deffered != null) {
                const runningDeffered = this.defferedRequests[data.designId].deffered;
                this.defferedRequests[data.designId].running = runningDeffered.promise;

                this.putSmallDesignChangePromise(this.defferedRequests[data.designId].defferedData as IDefferedData)
                    .then(() => runningDeffered.resolve())
                    .catch((err) => runningDeffered.reject(err));

                this.defferedRequests[data.designId].deffered = null;
                this.defferedRequests[data.designId].defferedData = null;
            }
        }
    }

    public async smallDesignChangeCommon<TProjectDesign, TDetailedDisplayDesign>(
        internalDesign: IDesignListItemCommon,
        projectDesign: TProjectDesign,
        data: IDefferedDataCommon<TProjectDesign, TDetailedDisplayDesign>,
        metaData: DesignExternalMetaDataCommon
    ): Promise<void> {
        try {
            const designAsBase64Endoded = this.browserService.toBase64(projectDesign);

            await this.putSmallDesignChange(data.designId, data.projectId, data.designName, designAsBase64Endoded, this.getSessionKeyForDesign(data.designId), metaData, data.unlock, data.exclusiveLock, data.documentAccessMode);
            if (metaData != null) {
                // update meta-data in proxy store
                internalDesign.metaData = metaData;
            }

            internalDesign.designName = data.designName;

            const now: Date = new Date();
            const nowUtc: Date = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds()));
            internalDesign.changeDate = nowUtc;

            this.updateProject(data.designId, data.projectId);
        }
        finally {
            this.defferedRequests[data.designId].running = null;

            // start new request for deffered and save reference into runningDefferd to resolve it later
            // clear defferd so new requests can be added
            if (this.defferedRequests[data.designId].deffered != null) {
                const runningDeffered = this.defferedRequests[data.designId].deffered;
                this.defferedRequests[data.designId].running = runningDeffered.promise;

                this.putSmallDesignChangePromiseCommon(this.defferedRequests[data.designId].defferedData as IDefferedData)
                    .then(() => runningDeffered.resolve())
                    .catch((err) => runningDeffered.reject(err));

                this.defferedRequests[data.designId].deffered = null;
                this.defferedRequests[data.designId].defferedData = null;
            }
        }
    }


    public downloadProject(project: Project): Promise<void> {
        const url = `${environment.baseplateApplicationWebServiceUrl}GetProjectZip/` + project.id;

        const request = new HttpRequest('GET', url, {
            headers: new HttpHeaders({
                Accept: 'application/zip'
            }),
            responseType: 'blob'
        });

        return this.apiService.request<Blob>(request, { supressErrorMessage: true })
            .then(response => {
                const zip = response.body;

                this.browserService.downloadBlob(zip, project.name + '.zip', false, false);
            })
            .catch(response => {
                if (response instanceof Error) {
                    console.error(response);
                }

                if (response.status == 404) {
                    this.errorHandlerService.showProjectArchivedModal(response, url);
                }
                else if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            });
    }

    /* Private Methods */

    private getInternal(data: DocumentGetResponse): void {
        this.internalRawProject = data;
        this._desingsFlat = {};
        this._draftsProject = null;
        this._projectsFlat = {};
        this._projects = {};
        this._myProjects = {};

        interface IResponse {
            projects: { [projectId: string]: IProject };
            documents: { [documentId: string]: IDocument };
        }

        interface IProject {
            id: string;
            name: string;
            parentId: string;
            dateChanged: string;
            dateCreated: string;
            owner: boolean;
            isCompanyProject: boolean;
            readOnly: boolean;
        }

        interface IDocument {
            id: string;
            name: string;
            projectId: string;
            dateChanged: string;
            dateCreated: string;
            locked: boolean;
            lockedUserName: string;
            type: number;
            metadata: Record<string, string>;
            owner: boolean;
        }

        let projects: { [projectId: string]: Project } = {};
        if (data?.projects) {
            projects = Object.fromEntries(Object.entries(data.projects).map(([key, project]) => [key, new Project({
                id: project.id,
                name: this.getProjectName(project.name),
                parentId: project.parentId,
                projectType: this.getProjectType(project.name),
                designs: null,
                subProjects: null,
                changeDate: new Date(project.dateChanged.toString()),
                createDate: new Date(project.dateCreated.toString()),
                owner: project.owner,
                isCompanyProject: project.isCompanyProject,
                expanded: project.expanded,
                expandedState: project.expandedState,
                readOnly: project.readOnly,
                isSharedByMe: project.isSharedByMe
            })]));
        }

        let documents: { [documentId: string]: IDesignListItem } = {};
        if (data?.documents) {
            documents = Object.fromEntries(Object.entries(data.documents).map(([key, document]): [string, IDesignListItem] => [key, {
                id: document.id,
                projectId: document.projectId,
                projectName: projects[document.projectId].name,
                changeDate: new Date(document.dateChanged.toString()),
                createDate: new Date(document.dateCreated.toString()),
                designName: document.name,
                locked: document.locked,
                lockedUser: document.lockedUserName,
                metaData: this.getDesignExternalMetadata(document.metadata),
                integrationDocument: undefined,
                owner: document.owner,
                isFavorite: document.isFavoriteDocument,
                isSharedByMe: document.isSharedByMe
            }]));
        }

        this._desingsFlat = documents;

        const allParentProjects = Object.values(projects)
            .filter(project => project.parentId === null || !new Set(Object.keys(projects))
                .has(project.parentId));

        const allSubProjects = Object.values(projects)
            .filter(project => !new Set(allParentProjects.map(parent => parent.id))
                .has(project.id));

        // add designs to projects
        for (const project of allParentProjects) {

            // Set parent id to null(for building tree) as there might be some projects whose parent is not shared
            project.parentId = null;

            const designs: { [id: string]: IDesignListItem } = Object.fromEntries(Object.entries(documents).filter(([key, document]) => document.projectId == project.id));
            project.directChildDesigns = designs;
            project.designs = designs;

            this._projectsFlat[project.id] = project;
            this._projects[project.id] = project;

            // drafts project
            if (project.projectType == ProjectType.draft) {
                this._draftsProject = project;
            }
        }

        // add designs to subProjects
        for (const project of allSubProjects) {
            this._projectsFlat[project.id] = project;
        }

        const projectRaw: { [projectId: string]: Project } = cloneDeep(projects);
        this._projects = this.buildProjectTree(Object.fromEntries(Object.entries(projectRaw).filter(([key, document]) => document.projectType == ProjectType.common || document.projectType == ProjectType.draft)));
        // TODO - not ussing myProject any more because we need to update designs in every different items
        //this._myProjects = this.buildProjectTree(Object.fromEntries(Object.entries(projectRaw).filter(([key, document]) => document.projectType == ProjectType.common)));

        // drafts project
        if (!Object.values(this._projectsFlat).some((project) => project.projectType == ProjectType.draft)) {
            const draftProject: Project = new Project({ name: '#$draft', projectType: ProjectType.draft });

            // call the actual service to add the project into store
            this.addNewProject(draftProject, ModalDialogType.project).then((project) => {
                this._projectsFlat[project.id] = project;
                this._projects[project.id] = project;
                this._draftsProject = project;
            });
        }

        this.setSharedStatusforPartiallySharedProject();
        this.sortAllProjectList();
    }


    /**
     * Sets the projects isSharedByMe property to true for a partially shared project.
     * This is needed for generating the SharedByMe tree structure
     */
    private setSharedStatusforPartiallySharedProject(): void {
        Object.values(this.projectsFlat).forEach(p => {
            if (p.isSharedByMe || Object.values(this.designsFlat).some(d => d.projectId === p.id && d.isSharedByMe)) {
                this.setSharedByMeTillParentNode(this.findProject(p.id, this.projects), true);
            }
        });
    }

    private getArchiveInternal(dataArchive: ArchiveProjectResponseModel[]): void {
        this._projectsArchive = [];

        for (const item in dataArchive) {
            this._projectsArchive.push(this.mapArchiveProjectResponseEntityToIProjectArchive(dataArchive[item]));
        }
    }

    private getArchiveDocumentsInternal(dataArchive: ArchiveDocumentResponseModel[]): void {
        this._documentsArchive = [];

        for (const item in dataArchive) {
            this._documentsArchive.push(this.mapArchiveDocumentResponseEntityToIDocumentArchive(dataArchive[item]));
        }
    }

    private mapArchiveDocumentResponseEntityToIDocumentArchive(row: ArchiveDocumentResponseModel): IDocumentArchive {
        return {
            archived: row.archived,
            created: row.created,
            id: row.documentId,
            designs: row.designs,
            members: row.members,
            name: row.name,
            parentProjectId: row.parentProjectId,
            projectname: row.projectname
        };
    }

    private mapArchiveProjectResponseEntityToIProjectArchive(row: ArchiveProjectResponseModel): IProjectArchive {
        return {
            archived: row.archived,
            created: row.created,
            id: row.projectid,
            designs: row.designs,
            members: row.members,
            name: row.name,
            parentId: row.parentprojectid
        };
    }

    private setSharedByMeTillParentNode(foundedProject: Project, sharedValue: boolean) {
        if (foundedProject && foundedProject.owner) {
            foundedProject.isSharedByMe = sharedValue;
        }

        if (!sharedValue) {
            Object.values(foundedProject.designs).forEach((design) => {
                if (design.isSharedByMe) {
                    foundedProject.isSharedByMe = true;
                }
            });
        }

        if (foundedProject.parentId) {
            const item = this.findProject(foundedProject.parentId, this.projects);
            this.setSharedByMeTillParentNode(item, sharedValue);
        }
    }

    private setSharedByMeTillChildNode(foundedProject: Project, isSharedByMe: boolean) {
        foundedProject.isSharedByMe = isSharedByMe;
        Object.values(foundedProject.designs).forEach((design) => {
            design.isSharedByMe = isSharedByMe;
        });
        for (const subProject in foundedProject.subProjects) {
            const project = foundedProject.subProjects[subProject];
            this.setSharedByMeTillChildNode(project, isSharedByMe);
        }
    }

    private getProjectName(projectName: string): string {

        if (projectName?.startsWith('#$')) {
            const projectType = projectName.substr(2);
            return this.localizationService.getString(`Agito.Hilti.Profis3.Documents.SpecialProject.${projectType}`);
        }

        return projectName;
    }

    private getProjectType(projectName: string): ProjectType {
        if (projectName?.startsWith('#$')) {
            const projectType = projectName.substr(2);

            switch (projectType) {
                case 'draft':
                    return ProjectType.draft;
                case 'template':
                    return ProjectType.template;
            }
        }

        return ProjectType.common;
    }

    private getDesignExternalMetadata(metadata: Record<string, string>): DesignExternalMetaData {
        const designExternalMetadata = new DesignExternalMetaData();

        for (const key in metadata) {
            const value = metadata[key];

            switch (key) {
                case 'anchorName':
                case 'productName':
                    designExternalMetadata.productName = value;

                    break;
                case 'region':
                    if (value != null && value != '') {
                        designExternalMetadata.region = parseInt(value, 10);
                    }

                    break;
                case 'standard':
                    if (value != null && value != '') {
                        designExternalMetadata.standard = parseInt(value, 10);
                    }

                    break;
                case 'designMethod':
                    if (value != null && value != '') {
                        designExternalMetadata.designMethod = parseInt(value, 10);
                    }

                    break;
                case 'designType':
                    if (value != null && value != '') {
                        designExternalMetadata.designType = parseInt(value, 10);
                    }

                    break;
                case 'approvalNumber':
                    designExternalMetadata.approvalNumber = value;

                    break;
                case 'calculationType':
                    if (value != null && value != '') {
                        designExternalMetadata.calculationType = parseInt(value, 10);
                    }

                    break;
                case 'handrailConnectionType':
                    if (value != null && value != '') {
                        designExternalMetadata.handrailConnectionType = parseInt(value, 10);
                    }

                    break;

            }
        }

        return designExternalMetadata;
    }

    private async addNewProject(project: Project, projectLevelType: ModalDialogType): Promise<Project> {
        const url = `${environment.documentWebServiceUrl}project`;

        const rData = {
            name: project.name,
            parentprojectid: (project.parentId == null)
                ? null
                : project.parentId,
            isCompanyProject: project.isCompanyProject,
            expanded: project.expanded
        };
        this.logServiceRequest('addNewProject', rData, url);

        try {
            const response = await this.apiService.request<any>(new HttpRequest('POST', url, rData), this.getApiOptions());

            this.logServiceResponse('addNewProject', response);
            project.id = response.body.projectid as string;
            this.setSpecialProjectProperties(project);

            return project;
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'post project');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 409) {
                    this.errorHandlerService.showExistingProjectNameModal();
                }
                else if (response.status == 404) {
                    this.errorHandlerService.showProjectArchivedModal(response, url, rData);
                }
                else if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url,
                        requestPayload: rData
                    });
                }
            }

            throw response;
        }
    }

    private getApiOptions(): ApiOptions {
        if (!this.userService.isAuthenticated) {
            throw new Error('Unauthenticated');
        }

        return {
            supressErrorMessage: true,
        };
    }

    // make any special stuff on special folders - for now only draft folder
    private setSpecialProjectProperties(project: Project): void {
        if (project.name.startsWith('#$')) {
            const projectType = project.name.substr(2);
            switch (projectType) {
                case 'draft':
                    project.projectType = ProjectType.draft;
                    break;
                case 'template':
                    project.projectType = ProjectType.template;
                    break;
            }
            project.name = this.localizationService.getString(`Agito.Hilti.Profis3.Documents.SpecialProject.${projectType}`);

        }
    }

    private async updateExistingProject(project: Project, projectLevelType: ModalDialogType): Promise<void> {
        const url = `${environment.documentWebServiceUrl}project/`;

        const rData = {
            projectid: project.id,
            name: project.name,
            parentprojectid: project.parentId
        };
        this.logServiceRequest('updateExistingProject', rData, url);

        try {
            await this.apiService.request(new HttpRequest('PUT', url, rData), this.getApiOptions());
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'put project');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 409) { // conflict; duplicate name
                    this.errorHandlerService.showExistingProjectNameModal();
                }
                else if (response.status == 404) {
                    this.errorHandlerService.showProjectArchivedModal(response, url, rData);
                }
                else if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url,
                        requestPayload: rData
                    });
                }
            }

            throw response;
        }
    }

    private addUpdateDesignInProject(newDesign: IDesignListItemCommon) {
        const project = this.findProjectById(newDesign.projectId);
        if (project == null) {
            throw new Error('Invalid project ID.');
        }

        newDesign.isSharedByMe = project.isSharedByMe;

        project.directChildDesigns[newDesign.id] = newDesign;
        project.designs[newDesign.id] = newDesign;
        // add design to all parent designs
        let parentX: Project = this.findProjectById(project.parentId);
        while (parentX != undefined && parentX != null) {
            parentX.designs[newDesign.id] = newDesign;
            parentX = this.findProjectById(parentX.parentId);
        }

        // add new design to designs in general
        this.internalRawProject.documents[newDesign.id] = {
            id: newDesign.id,
            name: newDesign.designName,
            projectId: newDesign.projectId,
            type: null,
            dateCreated: newDesign.createDate,
            dateChanged: newDesign.changeDate,
            locked: newDesign.locked,
            lockedUserName: newDesign.lockedUser,
            metadata: newDesign.metaData as any,
            owner: newDesign.owner
        };
        this._desingsFlat[newDesign.id] = newDesign;

        const foundedProject = this.findProject(project.id, this.projects);
        this.setDesignTillParentNode(foundedProject, newDesign);
    }

    private setDesignTillParentNode(foundedProject: Project, newDesign: IDesignListItemCommon) {
        foundedProject.designs[newDesign.id] = newDesign;
        foundedProject.directChildDesigns[newDesign.id] = newDesign;

        if (foundedProject.parentId) {
            const item = this.findProject(foundedProject.parentId, this.projects);
            this.setDesignTillParentNode(item, newDesign);
        }
    }

    /**
     * Check is there are already project with the same name
     * @param projectName - Project name
     * @param parentId - Parent project id
     * @param projectId - Project id
     */
    public override projectNameExists(projectName: string, parentId: string, projectId?: string): boolean {
        return Object.values(this._projectsFlat).some(
            (p) => p.name.toLowerCase().trim() == projectName.toLowerCase().trim() &&
                (p.parentId == parentId) &&
                (projectId == null || p.id != projectId) &&
                p.owner
        );
    }
    /**
     * Add new empty file and return document id when done.
     * @param projectId - Project id
     * @param designName - Design name
     * @param fileContent - File conctent
     * @param metaData - Meta data
     */
    private async addNewFile(
        projectId: string,
        designName: string,
        fileContent: string,
        metaData: DesignExternalMetaDataCommon,
        canGenerateUniqueName: boolean,
        ignoreConflict: boolean
    ): Promise<IDocumentServiceDesign> {
        const url = `${environment.documentWebServiceUrl}document`;

        const rData = { projectid: projectId, name: designName, filecontent: fileContent, type: 1, metadata: this.toServiceMetaData(metaData), adjustdocumentname: canGenerateUniqueName };
        this.logServiceRequest('addNewFile', rData, url);

        try {
            const response = await this.apiService.request<any>(new HttpRequest('POST', url, rData), this.getApiOptions());
            this.logServiceResponse('addNewFile', response);
            this.setNewSessionForNewlyCreatedDesign(response.body.documentid, response.body.sessionid);
            return response.body as IDocumentServiceDesign;
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'add new document');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 409 && response.error.content.conflictType == 0) {
                    if (ignoreConflict !== true) {
                        this.errorHandlerService.showExistingDesignNameModal(response, url, rData);
                    }
                }
                else if (response.status == 409 && response.error.content.conflictType == 1) {
                    this.modalService.openMaxDesignsLimitReached();
                }
                else if (response.status == 404) {
                    this.errorHandlerService.showProjectArchivedModal(response, url, rData);
                }
                else if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url,
                        requestPayload: rData
                    });
                }
            }

            throw response;
        }
    }

    private setNewSessionForNewlyCreatedDesign(design: string, session: string): void {
        this.designSessionMapping.push({ designId: design, sessionId: session, isCancelled: false });
    }

    /**
     * converts the metadata entitiy to a form appropriate for sending to the document service.
     * @param metaData - Design meta data
     */
    private toServiceMetaData(metaData: DesignExternalMetaDataCommon): any[] {
        if (metaData == null) {
            return null;
        }

        const ret: any[] = [];

        if (metaData != undefined && metaData != null) {
            (Object.keys(metaData) as (keyof typeof metaData)[]).forEach((key) => {
                const value = metaData[key]?.toString();

                if (value != null && value != '')
                    ret.push({ key: key, value: value });
            });
        }

        return ret;
    }

    private GetProjectDesignContent(design: Design): string {
        if (design.isC2C) {
            const data = { ...design.designData.projectDesignC2C };
            delete data.designName;
            delete data.projectName;
            return this.browserService.toBase64(data);
        }
        else {
            const data = { ...design.designData.projectDesign };
            delete data.DesignName;
            delete data.ProjectName;
            return this.browserService.toBase64(data);
        }
    }

    private async putSmallDesignChangePromise(data: IDefferedData): Promise<void> {
        // make new meta-data entity
        const internalDesign = this.findDesignById(data.designId);

        const projectDesign = cloneDeep(data.contentOverride);
        if (projectDesign.ProjectDesignType !== DesignType.DiaphragmDesign) {
            delete projectDesign.DesignName;
            delete projectDesign.ProjectName;
        }
        const metaData = cloneDeep(data.metadataOverride);

        if (data.displayDesign != null && projectDesign.ProjectDesignType !== DesignType.DiaphragmDesign) {
            detailedDisplayDesignToProjectDesign(projectDesign, data.displayDesign);

            metaData.designMethod = data.displayDesign.designMethodGroup.id;
        }

        await this.smallDesignChange(internalDesign, projectDesign, data, metaData);
    }

    /**
    * Make a small change to the allready exclusevly locked and open design, without closing or releasing the content.
    * @param id - design id
    * @param base64XmlContent - the design content in base64 encoded xml format
    * @param sessionId - id of the design change session
    * @param metaData -
    * @param openDesign - if it's called when you open design or close/save
    */
    private async putSmallDesignChange(
        designId: string,
        projectId: string,
        designName: string,
        base64XmlContent: string,
        sessionId: string,
        metaData: DesignExternalMetaDataCommon,
        unlock = false,
        exclusiveLock = false,
        documentAccessMode: DocumentAccessMode = DocumentAccessMode.Open
    ): Promise<void> {
        const url = `${environment.documentWebServiceUrl}documentcontent`;

        const rData = {
            unlock,
            key: sessionId,
            documentid: designId,
            projectid: projectId,
            name: designName,
            filecontent: base64XmlContent,
            metadata: this.toServiceMetaData(metaData),
            exclusiveLock,
            documentAccessMode
        };
        this.logServiceRequest('putSmallDesignChange', rData, url);

        const request = new HttpRequest('PUT', url, rData, {
            responseType: 'json'
        });

        const design = this.findDesignById(designId);
        const oldDesignName = design.designName;
        try {
            design.designName = designName;
            await this.apiService.request(request, this.getApiOptions());
        }
        catch (response) {
            design.designName = oldDesignName;
            this.loggerService.logServiceError(response, 'document-service', 'small design update failed');

            if (!this.handleSessionExpiredError(response, { designId }, [428])
                && !this.handleDesignFileLocked(response, { designId }, [423], documentAccessMode)
            ) {
                if (response instanceof HttpErrorResponse) {
                    if (response.status == 409) {
                        this.errorHandlerService.showExistingDesignNameModal(response, url, rData);
                    }
                    else if (response.status == 404) {
                        this.errorHandlerService.showProjectArchivedModal(response, url, rData);
                    }
                    else if (response.status != 401) {
                        this.modalService.openAlertServiceError({
                            response,
                            endPointUrl: url,
                            requestPayload: rData
                        });
                    }
                }
            }

            throw response;
        }
    }

    /**
     * the session in which the document was being edited and changed was overtaken.
     * @param response - the entire server error response.
     * @param additionalData - any additional data
     * @param errorsToHandle - what error code should this call handle.
     */
    private handleSessionExpiredError(response: HttpErrorResponse | unknown, additionalData: { designId: string } | unknown, errorsToHandle: number[]): boolean {
        let errorHandeled = false;

        if (errorsToHandle != null) {
            if (typeof response == 'object' && 'status' in response && response.status == 428 && errorsToHandle.includes(428)) {
                errorHandeled = true;

                this.modalService.openConfirmChange({
                    id: 'traSaveLock',
                    title: this.localizationService.getString('Agito.Hilti.Profis3.DocumentService.Alerts.SessionExpired.Title'),
                    message: this.localizationService.getString('Agito.Hilti.Profis3.DocumentService.Alerts.SessionExpired.Description'),
                    confirmButtonText: this.localizationService.getString('Agito.Hilti.Profis3.DocumentService.Alerts.SessionExpired.ConfirmButton'),
                    cancelButtonText: this.localizationService.getString('Agito.Hilti.Profis3.DocumentService.Alerts.SessionExpired.CancelButton'),
                    onConfirm: (modal) => {
                        if (typeof additionalData == 'object' && 'designId' in additionalData && typeof additionalData.designId == 'string') {
                            this.cancelSessionForDesign(additionalData.designId);
                        }

                        this.routingService.navigateToUrl(urlPath.projectAndDesign);
                        modal.close();
                    },
                    onCancel: (modal) => {
                        modal.close();
                    }
                });
            }
        }

        return errorHandeled;
    }

    private cancelSessionForDesign(designId: string): void {
        const mapping = this.designSessionMapping.find(x => x.designId == designId);
        if (mapping == null) {
            return;
        }

        mapping.isCancelled = true;
    }


    private handleDesignFileLocked(response: HttpErrorResponse | unknown, additionalData: { designId: string } | unknown, errorsToHandle: number[], documentAccessMode: DocumentAccessMode = DocumentAccessMode.Open): boolean {
        let errorHandled = false;

        if (errorsToHandle != null &&
            typeof additionalData == 'object' && 'designId' in additionalData && typeof additionalData.designId == 'string') {

            if (typeof response == 'object' && 'status' in response && response.status == 423 && errorsToHandle.includes(423)) {
                errorHandled = true;

                this.getDesignBasicDocumentInformation(additionalData.designId)
                    .then(d => {
                        const ex: CantOpenDesignBecauseLockedByOtherUser = new CantOpenDesignBecauseLockedByOtherUser();
                        ex.username = d.lockedUser;
                        let alertTitle = '';
                        let alertMessage = '';

                        switch (documentAccessMode) {
                            case DocumentAccessMode.Open:
                                alertTitle = 'Agito.Hilti.Profis3.ProjectAndDesing.Alerts.CannotOpenInUseBy.Title';
                                alertMessage = 'Agito.Hilti.Profis3.ProjectAndDesing.Alerts.CannotOpenInUseBy.Description';
                                break;
                            case DocumentAccessMode.Update:
                                alertTitle = 'Agito.Hilti.Profis3.ProjectAndDesing.Alerts.CannotSaveInUseBy.Title';
                                alertMessage = 'Agito.Hilti.Profis3.ProjectAndDesing.Alerts.CannotSaveInUseBy.Description';
                                break;
                        }

                        this.modalService.openAlertWarning(
                            this.localizationService.getString(alertTitle),
                            formatKeyValue(this.localizationService.getString(alertMessage), { user: ex.username })
                        );
                    })
                    .catch(err => {
                        if (err instanceof Error) {
                            console.error(err);
                        }

                        errorHandled = false;
                    });
            }
        }

        return errorHandled;
    }

    private async getDesignBasicDocumentInformation(id: string): Promise<IDesignListItem> {
        const url = `${environment.documentWebServiceUrl}document/${id}`;

        this.logServiceRequest('getDesignBasicDocumentInformation', url);

        try {
            const response = await this.apiService.request<IGetDocumentResponse>(new HttpRequest('GET', url), this.getApiOptions());
            this.logServiceResponse('getDesignBasicDocumentInformation', response);

            return this.toIDesignListItem(response.body);
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'read document');

            if (response instanceof HttpErrorResponse) {
                if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            }

            throw response;
        }
    }

    /**
   * Parse raw data from document service to IDesignListItem.
   * @param data- document data
   */
    private toIDesignListItem(data: IGetDocumentResponse): IDesignListItem {
        const utc = this.localizationService.moment().utc();
        const nowUtc = new Date(utc.year(), utc.month(), utc.date(), utc.hour(), utc.minute(), utc.second(), utc.millisecond());
        const ret: IDesignListItem = {
            id: data.documentid,
            projectId: data.project.projectid,
            projectName: data.project.name,
            changeDate: data.lastchange != null ? nowUtc : null,
            createDate: data.created != null ? nowUtc : null,
            designName: data.name,
            locked: data.locked,
            lockedUser: data.lockedby,
            metaData: this.fromServiceMetaData(data.metadata, data.documentid, data.name),
            integrationDocument: null,
            owner: data.owner
        };
        return ret;
    }

    /**
    * Parses some of the results of the read meta-data methods to the specified entity.
    * @param serviceMetaData - Service meta data
    * @param designId - Design id
    * @param designName - Design name
    */
    private fromServiceMetaData(serviceMetaData: { key: string, value: string }[], designId: string, designName: string): DesignExternalMetaData {
        const ret: DesignExternalMetaData = new DesignExternalMetaData();

        for (const rec of serviceMetaData) {
            switch (rec.key) {
                case 'anchorName':
                case 'productName':
                    ret.productName = rec.value;
                    break;
                case 'region':
                    if (rec.value != undefined && rec.value != null && rec.value != '') {
                        ret.region = parseInt(rec.value);
                    }
                    break;
                case 'standard':
                    if (rec.value != undefined && rec.value != null && rec.value != '') {
                        ret.standard = parseInt(rec.value);
                    }
                    break;
                case 'designMethod':
                    if (rec.value != undefined && rec.value != null && rec.value != '') {
                        ret.designMethod = parseInt(rec.value);
                    }
                    break;
                case 'designType':
                    if (rec.value != undefined && rec.value != null && rec.value != '') {
                        ret.designType = parseInt(rec.value);
                    }
                    break;
                case 'approvalNumber':
                    ret.approvalNumber = rec.value;
                    break;
                case 'calculationType':
                    if (rec.value != undefined && rec.value != null && rec.value != '') {
                        ret.calculationType = parseInt(rec.value);
                    }
                    break;
                case 'handrailConnectionType':
                    if (rec.value != undefined && rec.value != null && rec.value != '') {
                        ret.handrailConnectionType = parseInt(rec.value);
                    }
                    break;
            }
        }

        return ret;
    }

    private isCanceledSessionForDesign(designId: string): boolean {
        const mapping = this.designSessionMapping.find(x => x.designId == designId);
        if (mapping == null) {
            return true;
        }

        return mapping.isCancelled;
    }

    /**
 * Unlock the document on the document service.
 * @param id - design document id.
 */
    private async postUnlockDocument(id: string): Promise<void> {
        const sessionKey = this.getSessionKeyForDesign(id);
        const url = `${environment.documentWebServiceUrl}documentcontent/${id}/false/${sessionKey}`;
        this.logServiceRequest('postUnlockDocument', url);

        const request = new HttpRequest('POST', url, null, {
            responseType: 'json'
        });

        try {
            await this.apiService.request(request, this.getApiOptions());
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'design publish failed');

            if (response instanceof HttpErrorResponse) {
                if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            }

            throw response;
        }
    }

    private closeSessionForDesign(designId: string): void {
        const mapping = this.designSessionMapping.find(x => x.designId == designId);
        if (mapping == null) {
            throw new Error('Session is not opened for the specified design.');
        }

        this.designSessionMapping = this.designSessionMapping.filter(x => x.designId != designId);
    }

    /**
* Creates promise to update thumbnail image.
* @param data Request data
*/
    private async updateDesignThumbnailImagePromise(data: IDefferedImageData): Promise<void> {
        try {
            await this.uploadDesignImageInternal(data.designId, null, data.thumbnailContent, data.respond);
        }
        finally {
            this.defferedImageUpload[data.designId].running = null;

            // start new request for deffered and save reference into runningDefferd to resolve it later
            // clear defferd so new requests can be added
            if (this.defferedImageUpload[data.designId].deffered != null) {
                const runningDeffered = this.defferedImageUpload[data.designId].deffered;
                this.defferedImageUpload[data.designId].running = runningDeffered.promise;

                this.updateDesignThumbnailImagePromise(this.defferedImageUpload[data.designId].defferedData as IDefferedImageData)
                    .then(() => runningDeffered.resolve())
                    .catch((err) => runningDeffered.reject(err));

                this.defferedImageUpload[data.designId].deffered = null;
                this.defferedImageUpload[data.designId].defferedData = null;
            }
        }
    }

    /**
    * Update design printscreen image when a design report is ready for export
    * @param id - design id
    * @param base64XmlContent - the design image content in base64 encoded xml format
    * @param base64ThumbnailXmlContent - the design thumbnail image content in base64 encoded xml format
    */
    private async uploadDesignImageInternal(
        designId: string,
        base64XmlContent: string,
        base64ThumbnailXmlContent: string,
        respond = true
    ): Promise<void> {
        const url = `${environment.documentWebServiceUrl}documentcontent/UploadDocumentImage`;

        const sessionId = this.getSessionKeyForDesign(designId);

        const rData = {
            key: sessionId,
            documentid: designId,
            modelimage: base64XmlContent,
            thumbnail: base64ThumbnailXmlContent,
            respond
        } as any;

        this.logServiceRequest('uploadDocumentImage', rData, url);

        const request = new HttpRequest('POST', url, rData, {
            responseType: 'json'
        });

        try {
            const response = await this.apiService.request<any>(request, this.getApiOptions());

            if (respond) {
                this.logServiceResponse('uploadDocumentImage', response);
                const data = response.body;

                this.documentThumbnails[data.documentid] = data.thumbnailImageContent;
            }
            else {
                this.documentThumbnails[designId] = base64ThumbnailXmlContent;
            }
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'upload design image failed');

            if (!this.handleSessionExpiredError(response, { designId }, [428])) {
                if (typeof response != 'object' || !('status' in response) || response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url,
                        requestPayload: rData
                    });
                }
            }
        }
    }

    public override getMissingThumbnailList(documentIds: string[]): string[] {
        return documentIds.filter(id => this.documentThumbnails[id] == null && this.documentThumbnailPromise[id] == null);
    }

    public async getDocumentThumbnails(documentIds: string[]): Promise<Record<string, string>> {
        const url = `${environment.documentWebServiceUrl}documentcontent/GetMultipleDocumentThumbnail`;

        if (documentIds == null || documentIds.length == 0) {
            return this.documentThumbnails;
        }

        const missingImages = this.getMissingThumbnailList(documentIds);

        if (missingImages.length > 0) {
            const promise = this.apiService.request<unknown>(new HttpRequest('POST', url, missingImages), this.getApiOptions());

            // Assign promise to missing image IDs
            for (const id of missingImages) {
                this.documentThumbnailPromise[id] = promise;
            }

            try {
                const response = await promise;
                const data: any = response.body;

                this.logServiceResponse('getDocumentThumbnails', data);

                // Update documentThumbnails with new images
                for (const item of data) {
                    this.documentThumbnails[item.documentId] = item.thumbnailImageContent || '';
                }
            } finally {
                // Remove promise for each missing image ID
                for (const id of missingImages) {
                    this.documentThumbnailPromise[id] = null;
                }
            }
        }

        return this.documentThumbnails;
    }
    private async convertExistingProject(projectId: string): Promise<void> {
        const url = `${environment.documentWebServiceUrl}project/convertProject/${projectId}`;

        this.logServiceRequest('convertExistingProject', url);

        try {
            await this.apiService.request(new HttpRequest('GET', url), this.getApiOptions());
        }
        catch (response) {
            if (response instanceof HttpErrorResponse) {
                if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            }

            throw response;
        }
    }

    private buildProjectTree(item: { [id: string]: Project }): Record<string, Project> {
        const tree: Record<string, Project> = {};
        for (const id in item) {
            if (!item[id].parentId) {
                tree[id] = this.buildProjectNode(item, id)
            }
        }
        return tree;
    }

    private buildProjectNode(item: { [id: string]: Project }, id: string): Project {
        const node = item[id];
        const children: { [id: string]: Project } = {};
        const designs = Object.fromEntries(Object.entries(this._desingsFlat).filter(([key, document]) => document.projectId == id));
        node.designs = designs;
        node.directChildDesigns = designs;

        for (const childId in item) {
            if (item[childId].parentId === id) {
                const childNode = this.buildProjectNode(item, childId);
                children[childId] = childNode;
                Object.values(childNode.designs).forEach((design) => {
                    item[id].designs[design.id] = design;
                });
            }
        }

        node.subProjects = children;
        return node;
    }

    private updateCompanyProject(project: Project, companyProject: boolean): Project {
        project.isCompanyProject = companyProject;
        const flatProject = this.findProjectById(project.id);
        this._projectsFlat[flatProject.id].isCompanyProject = companyProject;

        if (project.subProjects && Object.entries(project.subProjects).length > 0) {
            for (const child in project.subProjects) {
                const subProject = project.subProjects[child];
                this.updateCompanyProject(subProject, companyProject);
            }
        }
        return project;
    }

    private flattenProjects(projects: Record<string, Project>): Record<string, Project> {
        const flattenedProjects: Record<string, Project> = {};

        function flattenHelper(node: Project) {
            const { id, subProjects } = node;

            flattenedProjects[id] = JSON.parse(JSON.stringify(node));

            for (const childId in subProjects) {
                flattenHelper(subProjects[childId]);
            }
        }

        for (const key in projects) {
            flattenHelper(projects[key]);
        }

        for (const key in flattenedProjects) {
            flattenedProjects[key].subProjects = {};
            flattenedProjects[key].parentId = null;
        }
        return flattenedProjects;
    }

    private updateFavoriteDesigns(documentIds: string[], isFavorite: boolean): void {
        const documents = Object.values(this.designsFlat).filter(x => documentIds.includes(x.id));
        documents.forEach(x => x.isFavorite = isFavorite);
    }

    // private updateSharedProjects(projectIds: string[], isSharedByMe: boolean): void {
    //     const projects = Object.values(this.projectsFlat).filter(x => projectIds.includes(x.id));
    //     projects.forEach(x => x.isSharedByMe = isSharedByMe);
    // }

    public override findDesignById(id: string): IDesignListItem {
        return this.designsFlat[id] as IDesignListItem;
        // const projectId = this.designsFlat[id].projectId;
        // const project = this.findProject(projectId, this.projects);

        // return project.directChildDesigns[id] as IDesignListItem;
    }

    public override async addToFavorite(documentIds: string[], documentType = 1): Promise<void> {
        const url = `${environment.documentWebServiceUrl}UserFavoriteDocuments/markAsFavorite`;
        const requestData = { documentIds: documentIds, documentType: documentType };
        this.logServiceRequest('markAsFavorite', requestData, url);
        const request = new HttpRequest('POST', url, requestData, { responseType: 'json' });

        try {
            await this.apiService.request(request, this.getApiOptions());
            this.updateFavoriteDesigns(documentIds, true);
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'mark as favorite failed');

            if (response instanceof HttpErrorResponse && response.status !== 401) {
                this.modalService.openAlertServiceError({ response, endPointUrl: url });
            }

            throw response;
        }
    }

    public override async removeFromFavorite(documentIds: string[], documentType = 1): Promise<void> {
        const url = `${environment.documentWebServiceUrl}UserFavoriteDocuments/unmarkAsFavorite`;
        const requestData = { documentIds: documentIds, documentType: documentType };
        this.logServiceRequest('unmarkAsFavorite', requestData, url);
        const request = new HttpRequest('DELETE', url, requestData, { responseType: 'json' });

        try {
            await this.apiService.request(request, this.getApiOptions());
            this.updateFavoriteDesigns(documentIds, false);
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'unmark as favorite failed');

            if (response instanceof HttpErrorResponse && response.status !== 401) {
                this.modalService.openAlertServiceError({ response, endPointUrl: url });
            }

            throw response;
        }
    }

    /**
     * Returns users on the project
     * @param projectId - Project id
     */
    public async getUsersOnProjectById(projectId: string): Promise<ProjectUser[]> {
        const response = await this.getUsersOnProjectByIdInternal(projectId);

        return response.map((item) => {
            item.projectid = projectId;
            item.dateadded = item.dateadded != null ? new Date(item.dateadded as any) : item.dateadded;
            return item;
        });
    }

    /**
 * Returns users on the project
 * @param projectId The project id
 */
    private async getUsersOnProjectByIdInternal(projectId: string): Promise<ProjectUser[]> {
        const url = `${environment.documentWebServiceUrl}User?projectid=${projectId}`;

        try {
            return (await this.apiService.request<ProjectUser[]>(new HttpRequest('GET', url), this.getApiOptions())).body;
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'get users on project by id');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 404) {
                    this.errorHandlerService.showProjectArchivedModal(response, url);
                }
                else if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            }

            throw response;
        }
    }

    /**
     * Adds user to the project
     * @param data - Project user data
     */
    public addUsersOnProjectById(data: ProjectUser): Promise<void> {
        return this.addUsersOnProjectByIdInternal(data);
    }

    /**
 * Adds user to the project
 * @param data - Project user data
 */
    private async addUsersOnProjectByIdInternal(data: ProjectUser): Promise<void> {
        const url = `${environment.documentWebServiceUrl}User`;

        const sendData = {
            projectid: data.projectid,
            user: data.user
        };

        try {
            await this.apiService.request(new HttpRequest('POST', url, sendData), this.getApiOptions()).then(() => {
                const foundedProject = this.findProject(data.projectid, this.projects);
                this.setSharedByMeTillParentNode(foundedProject, true);
                this.setSharedByMeTillChildNode(foundedProject, true);
            });
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'add user to the project by id');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 404) {
                    this.modalService.openAlertError(
                        this.localizationService.getString('Agito.Hilti.Profis3.ShareProject.UserNotAdded.Title'),
                        this.localizationService.getString('Agito.Hilti.Profis3.ShareProject.UserNotAdded.Message'),
                        {
                            response,
                            endPointUrl: url,
                            requestPayload: sendData
                        }
                    );
                }
                else {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url,
                        requestPayload: sendData
                    });
                }
            }

            throw response;
        }
    }

    /**
     * Removes user from the project
     * @param data - Project user data
     */
    public removeUsersOnProjectById(data: ProjectUser): Promise<void> {
        return this.removeUsersOnProjectByIdInternal(data);
    }

    /**
 * Removes user from the project
 * @param data - Project user data
 */
    private async removeUsersOnProjectByIdInternal(data: ProjectUser): Promise<void> {
        const url = `${environment.documentWebServiceUrl}User`;
        const sendData = {
            projectid: data.projectid,
            user: data.user
        };

        const request = new HttpRequest('DELETE', url, sendData, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        });

        try {
            await this.apiService.request(request, this.getApiOptions()).then(() => {
                // check if parent project contains any shared users, and if not remove parent also from shared tree
                this.setSharedByMeForProject(data.projectid);
                // Remove child projects as parent project is unshared
                this.setSharedByMeForChild(data.projectid);
            });
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'delete user from the project by id');

            if (response instanceof HttpErrorResponse) {
                if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url,
                        requestPayload: sendData
                    });
                }
            }

            throw response;
        }
    }

    public userHasAccessToProject(projectId: string): Promise<boolean> {
        return this.userHasAccessToProjectInternal(projectId);
    }

    private async userHasAccessToProjectInternal(projectId: string): Promise<boolean> {
        const url = `${environment.documentWebServiceUrl}User/isuseronproject/${projectId}`;

        try {
            return (await this.apiService.request<boolean>(new HttpRequest('GET', url), this.getApiOptions())).body;
        }
        catch (response) {
            console.error(response);

            if (response instanceof HttpErrorResponse) {
                if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            }

            throw response;
        }
    }

    public override async getUsersOnDocumentById(documentId: string): Promise<DocumentUser[]> {
        const response = await this.getUsersOnDocumentByIdInternal(documentId);

        return response.map((item) => {
            item.documentid = documentId;
            item.dateadded = item.dateadded != null ? new Date(item.dateadded as any) : item.dateadded;
            return item;
        });
    }

    /**
* Returns users on the document
* @param documentId The document id
*/
    private async getUsersOnDocumentByIdInternal(documentId: string): Promise<DocumentUser[]> {
        const url = `${environment.documentWebServiceUrl}user/document/${documentId}`;

        try {
            return (await this.apiService.request<DocumentUser[]>(new HttpRequest('GET', url), this.getApiOptions())).body;
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'get users on document by id');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 404) {
                    this.errorHandlerService.showProjectArchivedModal(response, url);
                }
                else if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            }

            throw response;
        }
    }

    public override async addUsersOnDocument(data: SharedDocumentUser): Promise<void> {
        return this.addUsersOnDocumentInternal(data);
    }

    /**
* Adds user to the project
* @param data - Project user data
*/
    private async addUsersOnDocumentInternal(data: SharedDocumentUser): Promise<void> {
        const url = `${environment.documentWebServiceUrl}user/shareDocument`;

        const sendData: SharedDocumentUser = {
            sharedByUserId: this.userService.authentication.userId,
            ...data
        };

        try {
            await this.apiService.request(new HttpRequest('POST', url, sendData), this.getApiOptions()).then(() => {
                const design = this.findDesignById(data.documentId);
                design.isSharedByMe = true;
                const project = this.findProject(design?.projectId, this.projects);
                project.isSharedByMe = true;
                this.setSharedByMeTillParentNode(project, true);
            });
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'add user to the document by id');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 404) {
                    this.modalService.openAlertError(
                        this.localizationService.getString('Agito.Hilti.Profis3.ShareProject.UserNotAdded.Title'),
                        this.localizationService.getString('Agito.Hilti.Profis3.ShareProject.UserNotAdded.Message'),
                        {
                            response,
                            endPointUrl: url,
                            requestPayload: sendData
                        }
                    );
                }
                else {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url,
                        requestPayload: sendData
                    });
                }
            }

            throw response;
        }
    }

    public override async removeUsersOnDocument(data: SharedDocumentUser, markDesign = false): Promise<void> {
        return this.removeUsersOnDocumentInternal(data, markDesign);
    }

    /**
    * Adds user to the document
    * @param data - Document user data
    */
    private async removeUsersOnDocumentInternal(data: SharedDocumentUser, markDesign = false): Promise<void> {
        const url = `${environment.documentWebServiceUrl}user/unshareDocument`;

        const sendData: SharedDocumentUser = {
            ...data,
            sharedByUserId: this.userService.authentication.userId
        };

        try {
            const request = new HttpRequest('DELETE', url, sendData, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            });
            await this.apiService.request(request, this.getApiOptions()).then(() => {
                if (markDesign) {
                    const design = this.findDesignById(data.documentId);
                    design.isSharedByMe = false;
                    const project = this.findProject(design?.projectId, this.projects);
                    this.setSharedByMeForProjectByDesigns(project.id);
                }

            });
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'remove user from the document by id');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 404) {
                    this.modalService.openAlertError(
                        this.localizationService.getString('Agito.Hilti.Profis3.ShareProject.UserNotAdded.Title'),
                        this.localizationService.getString('Agito.Hilti.Profis3.ShareProject.UserNotAdded.Message'),
                        {
                            response,
                            endPointUrl: url,
                            requestPayload: sendData
                        }
                    );
                }
                else {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url,
                        requestPayload: sendData
                    });
                }
            }

            throw response;
        }
    }

    public override deleteDocumentLocal(documentId: string): Promise<void> {
        return Promise.resolve(this.deleteDesignFunc(documentId));
    }


    /**
 * Remove the document from internal store and call external document service.
 * @documentId document
 */
    public override async archiveDocument(documentIds: string[]): Promise<void> {
        const sharedDocParentIds: string[] = [];
        documentIds.forEach((doc) => {
            const pToDeleteCandidate = this.findDesignById(doc);

            if (pToDeleteCandidate == null) {
                throw new Error('Document with ID does not exist.');
            }
            if (pToDeleteCandidate.isSharedByMe) {
                sharedDocParentIds.push(pToDeleteCandidate.projectId);
            }
        });
        // delete document
        const dataArchive: any = await this.archiveDocumentInternal(documentIds);
        for (const item in dataArchive) {
            this._documentsArchive.unshift(this.mapArchiveDocumentResponseEntityToIDocumentArchive(dataArchive[item]));
            this.deleteDesignFunc(dataArchive[item].documentId)
        }
        sharedDocParentIds.forEach((projectId) => {
            // set issharedby me for project and child project to false
            const foundedProject = this.findProject(projectId, this.projects);
            this.setSharedByMeTillParentNode(foundedProject, false);
        });

    }

    private async archiveDocumentInternal(documentIds: string[]): Promise<unknown> {
        const url = `${environment.documentWebServiceUrl}Document/archive`;
        const sendData = { documentIds: documentIds };

        try {
            return (await this.apiService.request(new HttpRequest('PATCH', url, sendData), this.getApiOptions())).body;
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'add design/s to archive failed');

            if (response instanceof HttpErrorResponse) {
                if (response.status == 404) {
                    this.modalService.openAlertError(
                        this.localizationService.getString('Agito.Hilti.Profis3.HomePage.ArchiveFailed.Title'),
                        this.localizationService.getString('Agito.Hilti.Profis3.HomePage.ArchiveFailed.Message'),
                        {
                            response,
                            endPointUrl: url,
                            requestPayload: sendData
                        }
                    );
                }
                else {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url,
                        requestPayload: sendData
                    });
                }
            }

            throw response;
        }
    }

    public removeSubProject(id: string, projects: { [id: string]: Project }, deleteSubProjId?: string): Project {
        if (!id) {
            return null;
        }

        if (projects[id]) {
            delete projects[id].subProjects[deleteSubProjId];
            return projects[id];
        }
        for (const item in projects) {
            const project = projects[item];
            const result = this.removeSubProject(id, project.subProjects, deleteSubProjId);
            if (result) {
                return result;
            }
        }

        return null;
    }

    public removeSubChildDesigns(id: string, projects: { [id: string]: Project }, designList: string[]): Project {
        if (!id) {
            return null;
        }

        if (projects[id]) {
            return projects[id];
        }
        for (const item in projects) {
            const project = projects[item];
            const result = this.removeSubChildDesigns(id, project.subProjects, designList);
            if (result) {
                designList.forEach((design) => {
                    if (project.designs[design]) {
                        delete project.designs[design];
                        delete project.directChildDesigns[design];
                    }
                });
                return result;
            }
        }

        return null;
    }

    // set sharedbyMe for project and upward after removal of user from project
    private async setSharedByMeForProject(projectId: string) {
        const item = this.findProject(projectId, this.projects);
        const projectUsers = await this.getUsersOnProjectByIdInternal(item.id);

        if (projectUsers?.length <= 1) {
            // check if any design is marked as shared by me and if not set it false
            if (Object.values(item.designs).some((design) => design.isSharedByMe) || item.isSharedByMe) {
                this._projectsFlat[item.id].isSharedByMe = false;
                item.isSharedByMe = false;
                Object.values(item.designs).forEach((design) => {
                    design.isSharedByMe = false;
                });
            }
        }

        const parentItem = this.findProject(item.parentId, this.projects); // Find the parent item

        if (parentItem) {
            this.setSharedByMeForProject(parentItem.id); // Recursively call for the parent item
        }
    }

    private async setSharedByMeForChild(projectId: string) {
        const item = this.findProject(projectId, this.projects);
        const projectUsers = await this.getUsersOnProjectByIdInternal(item.id);

        if (projectUsers?.length <= 1) {
            this._projectsFlat[item.id].isSharedByMe = false;
            item.isSharedByMe = false;
            Object.values(item.designs).forEach((design) => {
                design.isSharedByMe = false;
            });
        }
        for (const subProject in item.subProjects) {
            const project = item.subProjects[subProject];
            this.setSharedByMeForChild(project.id);
        }
    }

    // set isSharedByMe to parent project and its parent project by design id
    private async setSharedByMeForProjectByDesigns(projectId: string) {
        const project = this.findProject(projectId, this.projects);
        project.isSharedByMe = false;
        Object.values(project.designs).forEach((design) => {
            if (design.isSharedByMe) {
                project.isSharedByMe = true;
            }
        });
        if (project.parentId) {
            this.setSharedByMeForProjectByDesigns(project.parentId);
        }
    }


    public override async toggleExpandProjectV2(projectId: string, expandedState: ProjectExpandedState): Promise<void> {
        if (this._projectsFlat[projectId] == null) {
            throw new Error('Project with ID does not exist.');
        }

        await this.toggleExpandExistingProject(projectId, expandedState);
    }

    private async toggleExpandExistingProject(projectId: string, expandedState: ProjectExpandedState): Promise<void> {
        const url = `${environment.documentWebServiceUrl}project/setExpanded/${projectId}`;
        const sendData = expandedState;

        this.logServiceRequest('toggleExpandExistingProject', url, expandedState);

        try {
            await this.apiService.request(new HttpRequest('PUT', url, sendData), this.getApiOptions());
        }
        catch (response) {
            this.loggerService.logServiceError(response, 'document-service', 'put project setExpanded');

            if (response instanceof HttpErrorResponse) {
                if (response.status != 401) {
                    this.modalService.openAlertServiceError({
                        response,
                        endPointUrl: url
                    });
                }
            }

            throw response;
        }
    }
    /**
     * Check is there are already design with the same name for a given project
     */
    public override designNameExistsOnNew(project: Project, designName: string): boolean {
        if (project == null || designName == null) {
            return false;
        }

        const designs = this.findAllDesignsByProject(project);
        return Object.values(designs).some((d) => d.designName.toLowerCase().trim() == designName.toLowerCase().trim());
    }

    public override findAllDesignsByProject(project: Project): { [id: string]: IDesignListItemCommon } {
        const parentProject = project.parentId ? this.findParentProjectByProjectId(project) : project;
        return this.projects[parentProject.id].designs;
    }
    public findParentProjectByProjectId(project: Project): Project {
        if (!project.parentId)
            return project;
        return this.findParentProjectByProjectId(this.findProject(project.parentId, this.projects));
    }
}
