import { Subject } from 'rxjs';

import { CommonRegion, ICommonRegionConstructor } from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import { Language } from '@profis-engineering/pe-ui-common/entities/code-lists/language';
import {
    IRegionLanguageConstructor, RegionLanguage
} from '@profis-engineering/pe-ui-common/entities/code-lists/region-language';
import { Separator } from '@profis-engineering/pe-ui-common/entities/code-lists/separator';
import {
    StructuralCalculationSoftware
} from '@profis-engineering/pe-ui-common/entities/code-lists/structural-calculation-software';
import { Unit } from '@profis-engineering/pe-ui-common/entities/code-lists/unit';
import {
    CcmsUserSettings
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.CcmsLibrary.Entities';
import {
    StructuralCalculationSoftware as StructuralCalculationSoftwareType
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import {
    SettingModel
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.UserSettings.Shared.Entities';
import { MouseClickType, getSpecialRegionName } from '@profis-engineering/pe-ui-common/helpers/app-settings-helper';
import { ensureLanguage } from '@profis-engineering/pe-ui-common/helpers/localization-helper';
import { CommonCodeList } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import { LanguageCulture } from '@profis-engineering/pe-ui-common/services/localization.common';
import {
    Controls3dSettings, UserSettingsServiceBase
} from '@profis-engineering/pe-ui-common/services/user-settings.common';

import { environment } from '../../environments/environment';
import { UserSettings } from '../entities/user-settings';
import { CodeListService } from './code-list.service';
import { CommonCodeListService } from './common-code-list.service';
import { FavoritesService } from './favorites.service';
import { FeatureVisibilityService } from './feature-visibility.service';
import { LicenseService } from './license.service';
import { LocalizationService } from './localization.service';
import { LoggerService } from './logger.service';
import { OfflineService } from './offline.service';
import { ReportTemplateService } from './report-template.service';
import { UserService } from './user.service';

export interface UserSettingModel {
    key: string;
    value: string;
}

/**
 * Base implementation of functionality common for local and actual implementation.
 */
export abstract class UserSettingsService extends UserSettingsServiceBase<UserSettings> {
    public settingsLoaded = false;
    protected userSettingsSavingSubject = new Subject<void>();
    public userSettingsSaving = this.userSettingsSavingSubject.asObservable();
    protected userSettingsSavedSubject = new Subject<void>();
    public userSettingsSaved = this.userSettingsSavedSubject.asObservable();
    protected userSettings: UserSettings = null;
    protected ccmsUserSettingsData: CcmsUserSettings;

    constructor(
        protected loggerService: LoggerService,
        protected codeListService: CodeListService,
        protected licenseService: LicenseService,
        protected userService: UserService,
        protected offlineService: OfflineService,
        protected localizationService: LocalizationService,
        protected favoritesService: FavoritesService,
        protected reportTemplateService: ReportTemplateService,
        protected commonCodeListService: CommonCodeListService,
        protected featureVisibilityService: FeatureVisibilityService
    ) {
        super();
    }

    public get deckingEnabled() {
        return this.featureVisibilityService.isFeatureEnabled('Decking_Global') || environment.deckingEnabled;
    }


    public get settings(): UserSettings {
        if (!this.settingsLoaded) {
            throw new Error('Settings are not loaded!');
        }

        return this.userSettings;
    }

    public get ccmsUserSettings(): CcmsUserSettings {
        if (!this.settingsLoaded) {
            throw new Error('Settings are not loaded!');
        }

        return this.ccmsUserSettingsData;
    }


    public get controls3dSettings(): Controls3dSettings {
        return {
            rotate: this.settings.application.controls.rotate.value ?? MouseClickType.Left,
            pan: this.settings.application.controls.pan.value ?? MouseClickType.Right
        };
    }

    public get getProfis3Url(): string {
        let regionId: number = this.settings.application.general.regionId.value;

        const activeRegion = this.getActiveRegion(true);
        if (activeRegion) {
            regionId = activeRegion.id;
        }

        const profis3Url = this.getRegionLanguage(regionId)
            .getProfis3Url;

        return profis3Url;
    }

    public async save(): Promise<void> {
        if (!this.settingsLoaded) {
            throw new Error('Calling save on user settings service before the service has been initiated!');
        }
        await this.writeToService();
    }

    public getCommonRegionById(regionId: number): CommonRegion {
        const regionCodeList = this.commonCodeListService.commonCodeLists[CommonCodeList.Region] as CommonRegion[];
        return regionCodeList.find(region => region.id == regionId);
    }

    public getCommonRegionByIdWithFallback(regionId: number): CommonRegion {
        if (regionId < 0) {
            return this.getDefaultCommonRegion(regionId);
        }

        return this.getCommonRegionById(regionId) ?? this.getDefaultCommonRegion(regionId);
    }

    public getDefaultCommonRegion(regionId: number): CommonRegion {
        return new CommonRegion({
            id: regionId,
            countryCode: getSpecialRegionName(regionId),
            supportPhone: '',
            supportHours: '',
            worldAreaId: 1,
            hiltiOnlineUrl: '',
            onlineTenderTextsUrl: '',
            hubId: 1,
            hiltiDataConsentUrl: '',
            bpRigidityCheckUrl: '',
            dlubalEnabled: false
        } as ICommonRegionConstructor)
    }

    public getRegionByCountryCode(countryCode: string): CommonRegion {
        if (countryCode == null || countryCode == '' || this.commonCodeListService.commonCodeLists == null) {
            return null;
        }

        countryCode = countryCode.toLowerCase();

        const regionCodeList = this.commonCodeListService.commonCodeLists[CommonCodeList.Region] as CommonRegion[];

        const result = regionCodeList.find(region => region.countryCode.toLowerCase() == countryCode);

        return result;
    }

    public getHiltiDataPrivacyUrl(): string {
        const hiltiDataPrivacyUrl = this.getRegionLanguage(this.settings.application.general.regionId.value).hiltiDataPrivacyUrl;

        return hiltiDataPrivacyUrl;
    }

    public getActiveRegion(checkSettings = true): CommonRegion {
        // First check token "country of residence" property
        let region: CommonRegion = null;

        if (this.userService.authentication?.subscription_info != null) {
            region = this.getRegionByCountryCode(
                this.userService.authentication.subscription_info.CountryOfResidence);

            if (region == null) {
                const authorizationEntryList = this.userService.authentication.subscription_info.AuthorizationEntryList;

                // Then check by "country" property.
                if (authorizationEntryList != null &&
                    authorizationEntryList.length > 0) {
                    region = this.getRegionByCountryCode(
                        this.userService.authentication.subscription_info.AuthorizationEntryList[0].Country);
                }

                // Finally check by regionId in user settings, if they are available.
                if (checkSettings &&
                    region == null &&
                    this.settingsLoaded) {
                    region = this.getCommonRegionById(
                        this.settings.application.general.regionId.value);
                }
            }
        }

        return region;
    }

    public isKeylightFeatureAvailable(): boolean {
        return false;
    }

    public isUpgradeLicenseAvailable(): boolean {
        if (this.offlineService.isOffline) {
            return false;
        }

        // LMv1 - we don't have any LMV1 countries anymore
        const region = this.getActiveRegion();

        if (region == null) {
            return false;
        }

        // LMv2 - ADBP or Handrail feature means premium license
        const result = !this.licenseService.isAdvanced() || !this.licenseService.isHandrail();

        return result;
    }

    public getCountryCodeCulture(countryCode: string) {
        const regionCodeList = this.commonCodeListService.commonCodeLists[CommonCodeList.Region] as CommonRegion[];
        return regionCodeList.find((item) => item.countryCode.toLowerCase() == countryCode.toLowerCase())?.culture;
    }

    public getLanguage(): Language {
        const languageCodeList = this.commonCodeListService.commonCodeLists[CommonCodeList.Language] as Language[];
        return languageCodeList.find((item) => item.id == this.userSettings.application.general.languageId.value);
    }

    public getRegionLanguage(regionId = this.settings.application.general.regionId.value, languageId = this.settings.application.general.languageId.value): RegionLanguage {
        const regionLanguageCodeList = this.commonCodeListService.commonCodeLists[CommonCodeList.RegionLanguage] as RegionLanguage[];

        let regionLanguage = regionLanguageCodeList.find((cl) => cl.regionId == regionId && cl.lcid == languageId);

        // if region language combo does not exist, get the default one for region
        if (regionLanguage == null) {
            regionLanguage = regionLanguageCodeList.find((cl) => cl.regionId == regionId && cl.defaultForRegion);
        }

        // if region language does not exist for selected region, set default urls and US-EN language.
        if (regionLanguage == null) {
            const defaultRegionLanguage: IRegionLanguageConstructor = {
                dataSubjectRightsUrl: 'https://www.hilti.com',
                getProfis3Url: 'https://www.hilti.com',
                hiltiDataPrivacyUrl: 'https://www.hilti.com',
                userAgreement: 'https://www.hilti.com',
                lcid: 1033,
            } as IRegionLanguageConstructor;
            regionLanguage = new RegionLanguage(defaultRegionLanguage);
        }

        return regionLanguage;
    }

    public getThousandsSeparator(): Separator {
        const thousandsSeparatorCodeList = this.commonCodeListService.commonCodeLists[CommonCodeList.ThousandsSeparator] as Separator[];
        return thousandsSeparatorCodeList.find((item) => item.id == this.userSettings.application.general.thousandsSeparatorId.value);
    }

    public getDecimalSeparator(): Separator {
        const decimalSeparatorCodeList = this.commonCodeListService.commonCodeLists[CommonCodeList.DecimalSeparator] as Separator[];
        return decimalSeparatorCodeList.find((item) => item.id == this.userSettings.application.general.decimalSeparatorId.value);
    }

    public isSectionCollapsed(settingsControlId?: number | string) {
        let settingsCollapsedKey: string;
        if (typeof settingsControlId === 'number') {
            settingsCollapsedKey = this.getSectionCollapsedKey(settingsControlId);
        }
        else {
            settingsCollapsedKey = settingsControlId;
        }

        const collapsed =
            settingsCollapsedKey != null
            && this.settings.applicationCollapsedState.value[settingsCollapsedKey] != null
            && this.settings.applicationCollapsedState.value[settingsCollapsedKey] == true;
        return collapsed;
    }

    public setSectionCollapsed(settingsControlId?: number | string, collapsed?: boolean) {
        let settingsCollapsedKey: string;
        if (typeof settingsControlId === 'number') {
            settingsCollapsedKey = this.getSectionCollapsedKey(settingsControlId);
        }
        else {
            settingsCollapsedKey = settingsControlId;
        }

        if (settingsCollapsedKey != null) {
            this.settings.applicationCollapsedState.value[settingsCollapsedKey] = collapsed;
            this.save();
        }
    }

    public setFirstTimeRegionLanguage(region: CommonRegion, language: Language) {
        this.settings.application.general.regionId.value = region.id;
        this.settings.application.general.dlubalEnabled.value = region.dlubalEnabled;
        this.settings.application.general.languageId.value = language.id;

        // Set default enabled structural calculation software based on region
        this.setStructuralCalculationSoftwareDefaults(region.id, StructuralCalculationSoftwareType.SAP2000);
        this.setStructuralCalculationSoftwareDefaults(region.id, StructuralCalculationSoftwareType.Robot);
        this.setStructuralCalculationSoftwareDefaults(region.id, StructuralCalculationSoftwareType.ETABS);
        this.setStructuralCalculationSoftwareDefaults(region.id, StructuralCalculationSoftwareType.StaadPro);

        return this.localizationService.getTranslations(ensureLanguage(language.culture, this))
            .then(() => this.reportTemplateService.createDefaultTemplate(region.id))
            .then(() => this.favoritesService.setDefault(region.id))
            .then(() => {
                return this.save();
            });
    }

    public abstract initialize(data: SettingModel[], ccmsData?: CcmsUserSettings): void;

    /**
     * Just set default values for non existing values or ones not read from the service, so that
     * those are the predefined values if user never goes to change settings or is opening the application the first time.
     */
    protected setDefaultsForEmptyOrNonExistingValues(): void {
        const languageCodeList = this.commonCodeListService.commonCodeLists[CommonCodeList.Language] as Language[];
        const userExistingLanguage = languageCodeList.find((language) => language.id === this.userSettings.application.general.languageId.value);

        // If the user's language is not set or the user's language is not supported (does not exist in the language code list), set it to default one
        if (this.userSettings.application.general.languageId.value == null || userExistingLanguage === undefined) {
            const languageDefaultValue = languageCodeList.find((language) => language.culture == (environment.translate ? LanguageCulture.pseudoLanguage : environment.defaultLanguage));

            if (languageDefaultValue == null) {
                throw new Error('Language not found.');
            }

            this.userSettings.application.general.languageId.value = languageDefaultValue.id;
        }

        if (this.userSettings.application.general.decimalSeparatorId.value == null) {
            const decimalSeparatorCodeList = this.commonCodeListService.commonCodeLists[CommonCodeList.DecimalSeparator] as Separator[];
            const separator = decimalSeparatorCodeList != null && decimalSeparatorCodeList.length > 0 ? decimalSeparatorCodeList[0] : null;

            if (separator == null) {
                throw new Error('Number separator not found.');
            }

            this.userSettings.application.general.decimalSeparatorId.value = separator.id;
        }

        if (this.userSettings.application.general.thousandsSeparatorId.value == null) {
            const thousandsSeparatorCodeList = this.commonCodeListService.commonCodeLists[CommonCodeList.ThousandsSeparator] as Separator[];
            const separator = thousandsSeparatorCodeList != null && thousandsSeparatorCodeList.length > 0 ? thousandsSeparatorCodeList[0] : null;

            if (separator == null) {
                throw new Error('Number separator not found.');
            }

            this.userSettings.application.general.thousandsSeparatorId.value = separator.id;
        }

        if (this.userSettings.user.generalName.value == null) {
            this.userSettings.user.generalName.value = '';
        }
    }

    protected abstract writeToService(): Promise<void>;

    protected logServiceRequest(fnName: string, ...args: any[]): void {
        this.loggerService.logServiceRequest('user-settings-service', fnName, ...args);
    }

    protected logServiceResponse(fnName: string, ...args: any[]): void {
        this.loggerService.logServiceResponse('user-settings-service', fnName, ...args);
    }

    /**
     * Reads the code list value based on the string stored in the settings service.
     * Implementation details:
     *      If value returned form service is equal to null or 0, than ste unit is null whitch also indicates that default unit should be used.
     * @param codeListType The code list type
     * @param val The val
     */
    protected getUnitFromCodeList(codeListType: CommonCodeList, val: string | number): Unit {
        let valNum: number;

        if (typeof val === 'number') {
            if (val == null || val == 0) {
                return null;
            }
            valNum = val;
        }
        else if (typeof val === 'string') {
            if (val == null || val == '' || val == '0') {
                return null;
            }
            valNum = parseInt(val, 10);
        }

        let ret: Unit = null;

        const unitsCodeList = this.commonCodeListService.commonCodeLists[codeListType] as Unit[];

        ret = unitsCodeList.find((unit) => unit.id == valNum);

        return ret;
    }

    protected addStringInsideAnotherString(stringToAdd: string, stringToAddTo: string, index: number): string {
        return [stringToAddTo.slice(0, index), stringToAdd, stringToAddTo.slice(index)].join('');
    }

    private getSectionCollapsedKey(settingsControlId?: number) {
        if (settingsControlId != null) {
            return `control-${settingsControlId}`;
        }

        return null;
    }

    private setStructuralCalculationSoftwareDefaults(regionId: number, type: StructuralCalculationSoftwareType): void {
        // Get the regions in which this software should be enabled by default
        const regions = (this.commonCodeListService.commonCodeLists[CommonCodeList.StructuralCalculationSoftware] as StructuralCalculationSoftware[])
            .find(scs => scs.id === type).regions;

        const isDefaultRegion = regions.indexOf(regionId) > -1;

        // If the user's region is not a default region for the specific structural calculation software, no need to do anything
        if (!isDefaultRegion) {
            return;
        }

        // Otherwise enable the correct software by default
        if (type === StructuralCalculationSoftwareType.SAP2000) {
            this.settings.application.general.sap2000Enabled.value = true;
        }
        else if (type === StructuralCalculationSoftwareType.Robot) {
            this.settings.application.general.robotEnabled.value = true;
        }
        else if (type === StructuralCalculationSoftwareType.ETABS) {
            this.settings.application.general.etabsEnabled.value = true;
        }
        else if (type === StructuralCalculationSoftwareType.StaadPro) {
            this.settings.application.general.staadProEnabled.value = true;
        }
    }
}
