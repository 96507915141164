<div class="content-group" *ngIf="dataLoaded">
    <div class="content">
        <p class="title-strike" l10n="Agito.Hilti.Profis3.ApplicationSettings.InterfacesToStructuralCalculationSoftware"></p>

        <pe-checkbox-button
            [items]="structuralCalculationSoftwares.items"
            [selectedValues]="structuralCalculationSoftwares.selectedValues"
            (selectedValuesChange)="onCheckboxChange($any($event).detail)"
            [disabled]="submitted">
        </pe-checkbox-button>
    </div>
</div>
