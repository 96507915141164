import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from '../../../environments/environment';
import { FeatureVisibilityService } from '../../services/feature-visibility.service';

@Component({
  selector: 'app-module-quick-start-button',
  templateUrl: './module-quick-start-button.component.html',
  styleUrls: ['./module-quick-start-button.component.scss'],
})
export class ModuleQuickStartButtonComponent implements OnInit  {

  @Input() regionText: string;

  @Input() imageSprite: string;

  @Input() imageSrc: string;

  @Input() titleText: string;

  @Input() titleTextTranslationKey: string;

  @Input() moduleEnabled: boolean;

  @Input() disabled: boolean;

  @Input() isLoading: boolean;

  @Input() displayIsNewLabel = false;

  @Output() clickEvent = new EventEmitter();

  public readonly loaderColour = '#524f53';
  private _useNewHomePage = false;
  constructor(private featureVisibilityService: FeatureVisibilityService) {
  }
  ngOnInit(): void {
    this._useNewHomePage = this.featureVisibilityService.isFeatureEnabled('PE_EnableNewHomePage');
  }

  public get useNewHomePage() {
    return this._useNewHomePage;
  }
}