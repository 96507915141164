import { Injectable } from '@angular/core';
import {
    IDesignTemplateResponse, TemplateFolderUser
} from '@profis-engineering/pe-ui-common/entities/template';
import {
    ArchiveProjectTemplateResponseModel, ArchiveTemplateDesignResponseModel, DesignTemplateEntity
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.Entities.DesignTemplate';
import {
    ProjectExpandedState
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.Entities.Projects';
import { ApiOptions } from '@profis-engineering/pe-ui-common/services/api.common';
import {
    DesignTemplateServiceBase, IDesignTemplateDocument
} from '@profis-engineering/pe-ui-common/services/design-template.common';

import {
    DesignTemplateFolderDetail, TemplateDocumentRequestModel, TemplateFolderRequestModel
} from '../components/home-page/template-folder';
import { IDocumentArchive } from '../entities/document';
import { IProjectArchive } from '../entities/project';
import { SharedTemplateUser, TemplateUser } from '../entities/template';
import { ApiService } from './api.service';
import { DateTimeService } from './date-time.service';
import { DesignTemplateWebV1Service } from './design-template-web-v1.service';
import { DesignTemplateWebV2Service } from './design-template-web-v2.service';
import { DesignTemplateService, IDesignTemplateFolderUpdate } from './design-template.service';
import { DocumentService } from './document.service';
import { ErrorHandlerService } from './error-handler.service';
import { FeatureVisibilityService } from './feature-visibility.service';
import { LocalizationService } from './localization.service';
import { LoggerService } from './logger.service';
import { ModalService } from './modal.service';
import { OfflineService } from './offline.service';

@Injectable()
export class DesignTemplateServiceImpl extends DesignTemplateService implements DesignTemplateServiceBase {

    constructor(
        private featureVisibilityService: FeatureVisibilityService,
        private designTemplateWebV1Service: DesignTemplateWebV1Service,
        private designTemplateWebV2Service: DesignTemplateWebV2Service,
        loggerService: LoggerService,
        dateTimeService: DateTimeService,
        apiService: ApiService,
        documentService: DocumentService,
        errorHandlerService: ErrorHandlerService,
        modalService: ModalService,
        offlineService: OfflineService,
        localizationService: LocalizationService
    ) {
        super('DesignTemplateService', loggerService, dateTimeService, apiService, documentService, errorHandlerService, modalService, offlineService, localizationService);
    }

    public override initialize(data: DesignTemplateEntity[]) {
        this.designTemplateService.initialize(data);
    }

    // Initialize new home page template and folders
    public override initializeAll(data?: IDesignTemplateResponse, dataProjectTemplateArchive?: ArchiveProjectTemplateResponseModel[], dataDesignTemplateArchive?: ArchiveTemplateDesignResponseModel[]) {
        this.designTemplateService.initializeAll(data, dataProjectTemplateArchive, dataDesignTemplateArchive);
    }
    private get designTemplateService() {
        return this.featureVisibilityService.isFeatureEnabled('PE_EnableNewHomePage') ? this.designTemplateWebV2Service : this.designTemplateWebV1Service;
    }

    public override get templates() {
        return this.designTemplateService.templates;
    }

    public override get templateV2() {
        return this.designTemplateService.templateV2;
    }

    public override get templatesFolders() {
        return this.designTemplateService.templatesFolders;
    }

    public override get templatesFoldersFlat() {
        return this.designTemplateService.templatesFoldersFlat;
    }

    public override get templateFoldersArchive(): IProjectArchive[] {
        return this.designTemplateService.templateFoldersArchive;
    }

    public override get designTemplateArchive(): IDocumentArchive[] {
        return this.designTemplateService.designTemplateArchive;
    }

    public async getDocumentThumbnails(documentIds: string[]): Promise<Record<string, string>> {
        return this.designTemplateService.getDocumentThumbnails(documentIds);
    }

    /**
     * Upload design printscreen image when a design report is ready for export
     * @param designId - desing id
     * @param imageContent - the design image content in base64 encoded xml format
     * @param base64ThumbnailXmlContent - the design thumbnail image content in base64 encoded xml format
     */
    public uploadDesignImage(designId: string, imageContent: string, thumbnailContent: string): Promise<void> {
        return this.designTemplateService.uploadDesignImage(designId, imageContent, thumbnailContent);
    }

    /**
     * Update design thumbnail image.
     * @param designId Design id
     * @param thumbnailContent Thumbnail image
     * @param respond If server should send response
     */
    public async updateDesignThumbnailImage(designId: string, thumbnailContent: string, respond: boolean): Promise<void> {
        return this.designTemplateService.updateDesignThumbnailImage(designId, thumbnailContent, respond);
    }

    /**
     * Get list of all Design Templates
     * @param options - Api Options
     */
    public async getList(options?: ApiOptions) {
        return this.designTemplateService.getList(options);
    }

    /**
     * Get specific template
     * @param templateId - template Id
     */
    public async getById(templateId: string) {
        return this.designTemplateService.getById(templateId);
    }

    public async create(
        designTemplateDocument: IDesignTemplateDocument,
        thumbnailId?: string
    ) {
        return this.designTemplateService.create(designTemplateDocument, thumbnailId);
    }

    public async delete(templateId: string) {
        return this.designTemplateService.delete(templateId);
    }

    public async update(designTemplateDocument: IDesignTemplateDocument) {
        return this.designTemplateService.update(designTemplateDocument);
    }

    public override async updateTemplateFolder(designTemplateFolder: IDesignTemplateFolderUpdate) {
        return this.designTemplateService.updateTemplateFolder(designTemplateFolder);
    }

    override findTemplateFolderById(templateFolderId: string): DesignTemplateFolderDetail {
        return this.designTemplateService.findTemplateFolderById(templateFolderId);
    }

    public override checkExistingTemplateFolder(templateName: string, parentId: string) {
        return this.designTemplateService.checkExistingTemplateFolder(templateName, parentId);
    }


    public override findById(templateId: string): DesignTemplateEntity {
        return this.designTemplateService.findById(templateId);
    }

    public override createTemplateFolder(templateFolder: TemplateFolderRequestModel): Promise<DesignTemplateFolderDetail> {
        return this.designTemplateService.createTemplateFolder(templateFolder);
    }

    override getSortedTemplateFolders(nodes: { [key: string]: DesignTemplateFolderDetail; }): Record<string, DesignTemplateFolderDetail> {
        return this.designTemplateService.getSortedTemplateFolders(nodes);
    }

    public override buildTemplateTree(item: { [key: string]: DesignTemplateFolderDetail }): { [key: string]: DesignTemplateFolderDetail } {
        return this.designTemplateService.buildTemplateTree(item);
    }

    override async getUsersOnTemplateFolderById(templateFolderId: string): Promise<TemplateFolderUser[]> {
        return this.designTemplateService.getUsersOnTemplateFolderById(templateFolderId);
    }

    override async addUsersOnTemplateFolderById(newUser: TemplateFolderUser): Promise<void> {
        return this.designTemplateService.addUsersOnTemplateFolderById(newUser);
    }

    override userHasAccessToTemlateFolder(templateFolderId: string): Promise<boolean> {
        return this.designTemplateService.userHasAccessToTemlateFolder(templateFolderId);
    }

    public override removeUsersOnTemplateFolderById(data: TemplateFolderUser): Promise<void> {
        return this.designTemplateService.removeUsersOnTemplateFolderById(data);
    }

    public override renameTemplateFolder(templateFolder: DesignTemplateFolderDetail): Promise<DesignTemplateFolderDetail> {
        return this.designTemplateService.renameTemplateFolder(templateFolder);
    }

    public override async deleteTemplateFolderLocal(templateFolderId: string): Promise<void> {
        return this.designTemplateService.deleteTemplateFolderLocal(templateFolderId);
    }

    override async archiveExistingFolderTemplate(templateFolderId: string): Promise<unknown> {
        return this.designTemplateService.archiveExistingFolderTemplate(templateFolderId);
    }

    override async archiveExistingTemplate(templateDocumentRequestModel : TemplateDocumentRequestModel): Promise<unknown>{
        return this.designTemplateService.archiveExistingTemplate(templateDocumentRequestModel);
    }

    public override getUsersOnTemplateById(templateId: string): Promise<TemplateUser[]> {
        return this.designTemplateService.getUsersOnTemplateById(templateId);
    }

    public override addUsersOnTemplate(data: SharedTemplateUser): Promise<void> {
        return this.designTemplateService.addUsersOnTemplate(data);
    }

    public override removeUsersOnTemplate(data: SharedTemplateUser, markDesign = false): Promise<void> {
        return this.designTemplateService.removeUsersOnTemplate(data, markDesign);
    }

    public override async toggleExpandTemplateFolder(templateFolderId: string, expandedState: ProjectExpandedState): Promise<void> {
        return this.designTemplateService.toggleExpandTemplateFolder(templateFolderId, expandedState);
    }

    public override async restoreExistingTemplateFolder(templateFolderId: string): Promise<string[]> {
        return this.designTemplateService.restoreExistingTemplateFolder(templateFolderId);
    }

    public override async restoreExisitingTemplate(templateId: string) : Promise<void> {
        return this.designTemplateService.restoreExisitingTemplate(templateId);
    }
}
