<div class="content-group" *ngIf="dataLoaded">
    <div class="content fixed-inputs-50">
        <p class="title-strike" l10n="Agito.Hilti.Profis3.ApplicationSettings.DefaultParameters"></p>

        <div class="content-controls">
            <div class="application-settings-input-container control-row">
                <pe-numeric-text-box
                    [id]="anchorPlateThickness.id"
                    [title]="anchorPlateThickness.title"
                    [placeholder]="getNumericTextBoxPlaceholder('defaultAnchorPlateThickness')"
                    [value]="defaultParametersData.anchorPlateThickness"
                    (valueChange)="defaultParametersData.anchorPlateThickness = $any($event).detail"
                    [unit]="getNumericTextBoxUnit('defaultAnchorPlateThickness')"
                    [minValue]="anchorPlateThickness.minValue"
                    [maxValue]="anchorPlateThickness.maxValue"
                    [disabled]="submitted"
                    [decimalSeparator]="getNumericTextBoxDecimalSeparator">
                </pe-numeric-text-box>
            </div>

            <div class="application-settings-input-container control-row">
                <pe-numeric-text-box
                    [id]="anchorPlateFactor.id"
                    [title]="anchorPlateFactor.title"
                    [placeholder]="getNumericTextBoxPlaceholder('defaultAnchorPlateFactor')"
                    [value]="defaultParametersData.anchorPlateFactor"
                    (valueChange)="defaultParametersData.anchorPlateFactor = $any($event).detail"
                    [unit]="getNumericTextBoxUnit('defaultAnchorPlateFactor')"
                    [minValue]="anchorPlateFactor.minValue"
                    [maxValue]="anchorPlateFactor.maxValue"
                    [disabled]="submitted"
                    [decimalSeparator]="getNumericTextBoxDecimalSeparator"
                    [groupSeparator]="getNumericTextBoxGroupSeparator"
                    [tooltip]="anchorPlateFactor.tooltip">
                </pe-numeric-text-box>
            </div>
        </div>
        <div class="content-controls">
            <div class="application-settings-input-container control-row">
                <pe-numeric-text-box
                    [id]="permanentLoads.id"
                    [title]="permanentLoads.title"
                    [placeholder]="getNumericTextBoxPlaceholder('defaultPermanentLoads')"
                    [value]="defaultParametersData.permanentLoads"
                    (valueChange)="defaultParametersData.permanentLoads = $any($event).detail"
                    [unit]="getNumericTextBoxUnit('defaultPermanentLoads')"
                    [minValue]="permanentLoads.minValue"
                    [maxValue]="permanentLoads.maxValue"
                    [disabled]="submitted"
                    [decimalSeparator]="getNumericTextBoxDecimalSeparator"
                    [groupSeparator]="getNumericTextBoxGroupSeparator">
                </pe-numeric-text-box>
            </div>

            <div class="application-settings-input-container control-row">
                <pe-numeric-text-box
                    [id]="variableLoads.id"
                    [title]="variableLoads.title"
                    [placeholder]="getNumericTextBoxPlaceholder('defaultVariableLoads')"
                    [value]="defaultParametersData.variableLoads"
                    (valueChange)="defaultParametersData.variableLoads = $any($event).detail"
                    [unit]="getNumericTextBoxUnit('defaultVariableLoads')"
                    [minValue]="variableLoads.minValue"
                    [maxValue]="variableLoads.maxValue"
                    [disabled]="submitted"
                    [decimalSeparator]="getNumericTextBoxDecimalSeparator"
                    [groupSeparator]="getNumericTextBoxGroupSeparator">
                </pe-numeric-text-box>
            </div>
        </div>
        <div class="content-controls">
            <div class="application-settings-input-container control-row">
                <pe-numeric-text-box
                    [id]="minimumAnchorToProfileDistance.id"
                    [title]="minimumAnchorToProfileDistance.title"
                    [placeholder]="getNumericTextBoxPlaceholder('defaultMinimumAnchorToProfileDistance')"
                    [value]="defaultParametersData.minimumAnchorToProfileDistance"
                    (valueChange)="defaultParametersData.minimumAnchorToProfileDistance = $any($event).detail"
                    [unit]="getNumericTextBoxUnit('defaultMinimumAnchorToProfileDistance')"
                    [minValue]="minimumAnchorToProfileDistance.minValue"
                    [maxValue]="minimumAnchorToProfileDistance.maxValue"
                    [disabled]="submitted"
                    [decimalSeparator]="getNumericTextBoxDecimalSeparator">
                </pe-numeric-text-box>
            </div>

            <div class="application-settings-input-container control-row" *ngIf="concreteDesignStandardEnBased">
                <pe-numeric-text-box
                    [id]="steelSafetyFactorGammaM0.id"
                    [title]="steelSafetyFactorGammaM0.title"
                    [placeholder]="getNumericTextBoxPlaceholder('steelSafetyFactorGammaM0')"
                    [value]="defaultParametersData.steelSafetyFactorGammaM0"
                    (valueChange)="defaultParametersData.steelSafetyFactorGammaM0 = $any($event).detail"
                    [unit]="getNumericTextBoxUnit('steelSafetyFactorGammaM0')"
                    [minValue]="steelSafetyFactorGammaM0.minValue"
                    [maxValue]="steelSafetyFactorGammaM0.maxValue"
                    [disabled]="submitted"
                    [decimalSeparator]="getNumericTextBoxDecimalSeparator"
                    [groupSeparator]="getNumericTextBoxGroupSeparator">
                </pe-numeric-text-box>
            </div>
        </div>
        <div class="content-controls">
            <div class="application-settings-input-container control-row" *ngIf="concreteDesignStandardEnBased">
                <pe-numeric-text-box
                    [id]="steelSafetyFactorGammaM1.id"
                    [title]="steelSafetyFactorGammaM1.title"
                    [placeholder]="getNumericTextBoxPlaceholder('steelSafetyFactorGammaM1')"
                    [value]="defaultParametersData.steelSafetyFactorGammaM1"
                    (valueChange)="defaultParametersData.steelSafetyFactorGammaM1 = $any($event).detail"
                    [unit]="getNumericTextBoxUnit('steelSafetyFactorGammaM1')"
                    [minValue]="steelSafetyFactorGammaM1.minValue"
                    [maxValue]="steelSafetyFactorGammaM1.maxValue"
                    [disabled]="submitted"
                    [decimalSeparator]="getNumericTextBoxDecimalSeparator"
                    [groupSeparator]="getNumericTextBoxGroupSeparator">
                </pe-numeric-text-box>
            </div>
            <div class="application-settings-input-container control-row" *ngIf="concreteDesignStandardEnBased">
                <pe-numeric-text-box
                    [id]="steelSafetyFactorGammaM2.id"
                    [title]="steelSafetyFactorGammaM2.title"
                    [placeholder]="getNumericTextBoxPlaceholder('steelSafetyFactorGammaM2')"
                    [value]="defaultParametersData.steelSafetyFactorGammaM2"
                    (valueChange)="defaultParametersData.steelSafetyFactorGammaM2 = $any($event).detail"
                    [unit]="getNumericTextBoxUnit('steelSafetyFactorGammaM2')"
                    [minValue]="steelSafetyFactorGammaM2.minValue"
                    [maxValue]="steelSafetyFactorGammaM2.maxValue"
                    [disabled]="submitted"
                    [decimalSeparator]="getNumericTextBoxDecimalSeparator"
                    [groupSeparator]="getNumericTextBoxGroupSeparator">
                </pe-numeric-text-box>
            </div>
            <div class="application-settings-input-container control-row" *ngIf="concreteDesignStandardAiscBased">
                <pe-numeric-text-box
                    [id]="materialSafetyFactor.id"
                    [title]="materialSafetyFactor.title"
                    [placeholder]="getNumericTextBoxPlaceholder('materialSafetyFactor')"
                    [value]="defaultParametersData.materialSafetyFactor"
                    (valueChange)="defaultParametersData.materialSafetyFactor = $any($event).detail"
                    [unit]="getNumericTextBoxUnit('materialSafetyFactor')"
                    [minValue]="materialSafetyFactor.minValue"
                    [maxValue]="materialSafetyFactor.maxValue"
                    [disabled]="submitted"
                    [decimalSeparator]="getNumericTextBoxDecimalSeparator"
                    [groupSeparator]="getNumericTextBoxGroupSeparator">
                </pe-numeric-text-box>
            </div>
        </div>
        <div class="content-controls">
            <div class="application-settings-input-container control-row">
                <pe-numeric-text-box
                    [id]="minimumConcreteCover.id"
                    [title]="minimumConcreteCover.title"
                    [placeholder]="getNumericTextBoxPlaceholder('defaultMinimumConcreteCover')"
                    [value]="defaultParametersData.minimumConcreteCover"
                    (valueChange)="defaultParametersData.minimumConcreteCover = $any($event).detail"
                    [unit]="getNumericTextBoxUnit('defaultMinimumConcreteCover')"
                    [minValue]="minimumConcreteCover.minValue"
                    [maxValue]="minimumConcreteCover.maxValue"
                    [disabled]="submitted"
                    [decimalSeparator]="getNumericTextBoxDecimalSeparator">
                </pe-numeric-text-box>
            </div>
        </div>
        <div class="content-controls">
            <div class="application-settings-input-container control-row" *ngIf="concreteDesignStandardAsBased">
                <pe-numeric-text-box
                    [id]="steelCapacityFactor.id"
                    [title]="steelCapacityFactor.title"
                    [placeholder]="getNumericTextBoxPlaceholder('steelCapacityFactor')"
                    [value]="defaultParametersData.steelCapacityFactor"
                    (valueChange)="defaultParametersData.steelCapacityFactor = $any($event).detail"
                    [unit]="getNumericTextBoxUnit('steelCapacityFactor')"
                    [minValue]="steelCapacityFactor.minValue"
                    [maxValue]="steelCapacityFactor.maxValue"
                    [disabled]="submitted"
                    [decimalSeparator]="getNumericTextBoxDecimalSeparator"
                    [groupSeparator]="getNumericTextBoxGroupSeparator">
                </pe-numeric-text-box>
            </div>
            <div class="application-settings-input-container control-row" *ngIf="concreteDesignStandardAsBased">
                <pe-dropdown
                    [id]="weldsCapacityFactor.id"
                    [title]="weldsCapacityFactor.title"
                    [items]="weldsCapacityFactor.items"
                    [selectedValue]="defaultParametersData.weldsCapacityFactor"
                    (selectedValueChange)="defaultParametersData.weldsCapacityFactor = $any($event).detail"
                    [disabled]="submitted">
                </pe-dropdown>
            </div>
            <div class="application-settings-input-container control-row" *ngIf="concreteDesignStandardAsBased">
                <pe-numeric-text-box
                    [id]="concreteCapacityFactor.id"
                    [title]="concreteCapacityFactor.title"
                    [placeholder]="getNumericTextBoxPlaceholder('concreteCapacityFactor')"
                    [value]="defaultParametersData.concreteCapacityFactor"
                    (valueChange)="defaultParametersData.concreteCapacityFactor = $any($event).detail"
                    [unit]="getNumericTextBoxUnit('concreteCapacityFactor')"
                    [minValue]="concreteCapacityFactor.minValue"
                    [maxValue]="concreteCapacityFactor.maxValue"
                    [disabled]="submitted"
                    [decimalSeparator]="getNumericTextBoxDecimalSeparator"
                    [groupSeparator]="getNumericTextBoxGroupSeparator">
                </pe-numeric-text-box>
            </div>
        </div>
        <div class="content-controls">
            <div class="application-settings-input-container control-row" *ngIf="concreteDesignStandardStoBased">
                <pe-numeric-text-box
                    [id]="stoSteelSafetyFactorGammaM.id"
                    [title]="stoSteelSafetyFactorGammaM.title"
                    [placeholder]="getNumericTextBoxPlaceholder('stoSteelSafetyFactorGammaM')"
                    [value]="stoSteelSafetyFactorGammaM.value"
                    (valueChange)="defaultParametersData.concreteCapacityFactor = $any($event).detail"
                    [unit]="getNumericTextBoxUnit('stoSteelSafetyFactorGammaM')"
                    [minValue]="stoSteelSafetyFactorGammaM.minValue"
                    [maxValue]="stoSteelSafetyFactorGammaM.maxValue"
                    [disabled]="stoSteelSafetyFactorGammaM.disabled"
                    [decimalSeparator]="getNumericTextBoxDecimalSeparator"
                    [groupSeparator]="getNumericTextBoxGroupSeparator">
                </pe-numeric-text-box>
            </div>
        </div>
        <div class="content-controls">
            <div class="application-settings-input-container control-row" *ngIf="concreteDesignStandardInBased">
                <pe-numeric-text-box
                    [id]="inSteelSafetyFactorGammaM0.id"
                    [title]="inSteelSafetyFactorGammaM0.title"
                    [placeholder]="getNumericTextBoxPlaceholder('inSteelSafetyFactorGammaM0')"
                    [value]="defaultParametersData.inSteelSafetyFactorGammaM0"
                    (valueChange)="defaultParametersData.inSteelSafetyFactorGammaM0 = $any($event).detail"
                    [unit]="getNumericTextBoxUnit('inSteelSafetyFactorGammaM0')"
                    [minValue]="inSteelSafetyFactorGammaM0.minValue"
                    [maxValue]="inSteelSafetyFactorGammaM0.maxValue"
                    [disabled]="submitted"
                    [decimalSeparator]="getNumericTextBoxDecimalSeparator"
                    [groupSeparator]="getNumericTextBoxGroupSeparator">
                </pe-numeric-text-box>
            </div>
            <div class="application-settings-input-container control-row" *ngIf="concreteDesignStandardInBased">
                <pe-numeric-text-box
                    [id]="inSteelSafetyFactorGammaM1.id"
                    [title]="inSteelSafetyFactorGammaM1.title"
                    [placeholder]="getNumericTextBoxPlaceholder('inSteelSafetyFactorGammaM1')"
                    [value]="defaultParametersData.inSteelSafetyFactorGammaM1"
                    (valueChange)="defaultParametersData.inSteelSafetyFactorGammaM1 = $any($event).detail"
                    [unit]="getNumericTextBoxUnit('inSteelSafetyFactorGammaM1')"
                    [minValue]="inSteelSafetyFactorGammaM1.minValue"
                    [maxValue]="inSteelSafetyFactorGammaM1.maxValue"
                    [disabled]="submitted"
                    [decimalSeparator]="getNumericTextBoxDecimalSeparator"
                    [groupSeparator]="getNumericTextBoxGroupSeparator">
                </pe-numeric-text-box>
            </div>
            <div class="application-settings-input-container control-row" *ngIf="concreteDesignStandardInBased">
                <pe-numeric-text-box
                    [id]="inSteelSafetyFactorGammaMw.id"
                    [title]="inSteelSafetyFactorGammaMw.title"
                    [placeholder]="getNumericTextBoxPlaceholder('inSteelSafetyFactorGammaMw')"
                    [value]="defaultParametersData.inSteelSafetyFactorGammaMw"
                    (valueChange)="defaultParametersData.inSteelSafetyFactorGammaMw = $any($event).detail"
                    [unit]="getNumericTextBoxUnit('inSteelSafetyFactorGammaMw')"
                    [minValue]="inSteelSafetyFactorGammaMw.minValue"
                    [maxValue]="inSteelSafetyFactorGammaMw.maxValue"
                    [disabled]="submitted"
                    [decimalSeparator]="getNumericTextBoxDecimalSeparator"
                    [groupSeparator]="getNumericTextBoxGroupSeparator">
                </pe-numeric-text-box>
            </div>
            <div class="application-settings-input-container control-row" *ngIf="concreteDesignStandardInBased">
                <pe-numeric-text-box
                    [id]="inSteelSafetyFactorGammaMb.id"
                    [title]="inSteelSafetyFactorGammaMb.title"
                    [placeholder]="getNumericTextBoxPlaceholder('inSteelSafetyFactorGammaMb')"
                    [value]="defaultParametersData.inSteelSafetyFactorGammaMb"
                    (valueChange)="defaultParametersData.inSteelSafetyFactorGammaMb = $any($event).detail"
                    [unit]="getNumericTextBoxUnit('inSteelSafetyFactorGammaMb')"
                    [minValue]="inSteelSafetyFactorGammaMb.minValue"
                    [maxValue]="inSteelSafetyFactorGammaMb.maxValue"
                    [disabled]="submitted"
                    [decimalSeparator]="getNumericTextBoxDecimalSeparator"
                    [groupSeparator]="getNumericTextBoxGroupSeparator">
                </pe-numeric-text-box>
            </div>
        </div>
    </div>
</div>
