export class FeaturesHelper {

    /* eslint-disable @typescript-eslint/no-explicit-any */
    private static getC2CSupportedFeatures = (): string => (window as any).environmentC2C?.supportedFeatures ?? [];
    private static getPeSupportedFeatures = (): string => (window as any).environmentPe?.supportedFeatures ?? [];
    private static getDeckingSupportedFeatures = (): string => (window as any).environmentDecking?.supportedFeatures ?? [];
    /* eslint-enable @typescript-eslint/no-explicit-any */

    public static readonly C2CSupports = (feature: string) => this.getC2CSupportedFeatures()?.includes(feature) ?? false;
    public static readonly PeSupports = (feature: string) => this.getPeSupportedFeatures()?.includes(feature) ?? false;
    public static readonly DeckingSupports = (feature: string) => this.getDeckingSupportedFeatures()?.includes(feature) ?? false;
}
