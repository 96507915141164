import { HttpErrorResponse, HttpResponse, HttpResponseBase } from '@angular/common/http';
import {
    ProjectDesignEntityC2C
} from '@profis-engineering/pe-ui-c2c/generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import { IImportData } from '@profis-engineering/pe-ui-common/entities/import-data';
import {
    IImportDesignProvider, IProjectDesignEntity
} from '@profis-engineering/pe-ui-common/entities/module-initial-data';
import {
    ProjectDesignBaseEntity
} from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign';
import {
    DesignType
} from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';

import { Design, IBaseDesign } from '../entities/design';
import { urlPath } from '../module-constants';
import { CalculationServiceC2C } from './calculation/calculation-c2c.service';
import { CalculationServicePE } from './calculation/calculation-pe.service';
import { LocalizationService } from './localization.service';
import { ModalService } from './modal.service';

/**
 * TODO: Modularization
 * GeneralImportProvider is class which will be exposed as IImportDesignProvider in pe-ui-pe and pe-ui-c2c.
 * For now general import provider is in pe-ui and contains PE and also C2C logic. Needs to be seperated
 * and moved into specific UI projects. Check how curtain wall expose IImportDesignProvider.
 */
export class GeneralImportProvider implements IImportDesignProvider {
    public path = urlPath.main;
    private isC2C = false;

    constructor(
        private calculationServicePE: CalculationServicePE,
        private calculationServiceC2C: CalculationServiceC2C,
        private modalService: ModalService,
        private localizationService: LocalizationService
    ) { }

    /**
     * @deprecated DO NOT USE FOR NEW CODE. Overridden in pe-ui-pe and pe-ui-c2c.
     */
    public getValidFileExtensions(): string[] {
        return ['.pe', '.pa2', '.profis3'];
    }

    /**
     * @deprecated DO NOT USE FOR NEW CODE. Overridden in pe-ui-pe and pe-ui-c2c.
     */
    public checkFile(fileContent: string, fileName?: string): boolean {
        this.isC2C = false;

        if (this.checkPeDesign(fileContent, fileName))  {
            return true;
        }
        else if (this.checkC2CDesign(fileContent)) {
            this.isC2C = true;
            return true;
        }

        return false;
    }

    /**
     * @deprecated DO NOT USE FOR NEW CODE. Overridden in pe-ui-pe and pe-ui-c2c.
     */
    public async createProjectDesignEntity(projectDesign: File | Blob, projectName: string, fileNameProposal: string, designName: string, projectDesignContent?: string): Promise<IProjectDesignEntity> {
        if (this.isC2C) {
            const p = JSON.parse(projectDesignContent);
            p.designName = fileNameProposal;
            return p;
        }
        else {
            return await this.calculationServicePE.createProjectDesignFromFile(projectDesign, projectName, designName, true);
        }
    }

    /**
     * @deprecated DO NOT USE FOR NEW CODE. Overridden in pe-ui-pe and pe-ui-c2c.
     */
    public updateDesignFromExternalFile(oldDesign: IBaseDesign, projectDesign: IProjectDesignEntity, disableCalcMessages?: boolean) {
        if (this.isC2C) {
            return this.calculationServiceC2C.updateDesignFromExternalFileC2C(oldDesign, projectDesign as ProjectDesignEntityC2C, disableCalcMessages);
        }
        return this.calculationServicePE.updateDesignFromExternalFile(oldDesign, projectDesign as ProjectDesignBaseEntity, disableCalcMessages);
    }

    /**
     * @deprecated DO NOT USE FOR NEW CODE. Overridden in pe-ui-pe and pe-ui-c2c.
     */
    public async createDesign(importData: IImportData, projectId: string, name: string, secondTry: boolean, disableCalcMessages: boolean, trackingEnabled?: boolean): Promise<IImportData> {
        if (this.isC2C) {
            return this.createC2CDesign(importData, projectId);
        }
        else {
            const designCreated = async (projectDesign: ProjectDesignBaseEntity, name: string) => {
                if (projectDesign.ProjectDesignType == DesignType.Handrail && name.toLowerCase().endsWith('.pa2')) {
                    await this.modalService.openConfirmChange({
                        id: 'import-design-p2-file',
                        title: this.localizationService.getString('Agito.Hilti.Profis3.Main.Importpa2Dialog.Title'),
                        message: this.localizationService.getString('Agito.Hilti.Profis3.Main.Importpa2Dialog.message'),
                        confirmButtonText: this.localizationService.getString('Agito.Hilti.Profis3.Main.Importpa2Dialog.OK'),
                        onConfirm: (modal) => {
                            modal.close();
                        }
                    }).closed;
                }
            };

            try {
                const designOrImportData = await this.calculationServicePE.createAndOpenFromProjectDesignFile(importData.projectDesign as ProjectDesignBaseEntity, projectId, name, designCreated, secondTry ? false : true, disableCalcMessages, trackingEnabled);

                if (designOrImportData instanceof Design) {
                    importData.design = designOrImportData;

                    return importData;
                }

                return designOrImportData;
            }
            catch (error) {
                const httpResponse = error instanceof HttpResponseBase ? error : undefined;
                const httpBody = error instanceof HttpErrorResponse
                    ? error.error
                    : error instanceof HttpResponse
                        ? error.body
                        : undefined;

                if (httpResponse?.status == 409 && !secondTry && httpBody?.content?.conflictType == 0) {
                    importData.retryImport = true;
                    importData.retryNameProposal = httpBody?.content?.nameProposal;
                    return importData;
                }

                throw error;
            }
        }
    }

    private async createC2CDesign(importData: IImportData, projectId: string) {
        // create design
        const designOrImportData = await this.calculationServiceC2C.createAndOpenFromProjectDesignC2C(importData.projectDesign as ProjectDesignEntityC2C, projectId, null, { supressErrorMessage: true });
        if (designOrImportData instanceof Design) {
            importData.design = designOrImportData;

            return importData;
        }

        return designOrImportData;
    }

    public setDesignName(projectDesignEntity: IProjectDesignEntity, name: string): void {
        if (this.isC2C) {
            (projectDesignEntity as ProjectDesignEntityC2C).designName = name;
        }
        else {
            (projectDesignEntity as ProjectDesignBaseEntity).DesignName = name;
        }
    }

    private checkPeDesign(fileContent: string, fileName?: string) {
        const isPeFile = fileName == null || this.getValidFileExtensions().some(ext => fileName.toLowerCase().endsWith(ext)); // If no file name is provided, we assume it is a PE file

        return isPeFile && (fileContent.startsWith('<?xml') || fileContent.startsWith('<DSProjects'));
    }

    private checkC2CDesign(fileContent: string): boolean {
        try {
            const designEntity = JSON.parse(fileContent);
            return designEntity?.designTypeId == null && designEntity?.options?.connectionType != null;
        }
        catch (err) {
            return false;
        }
    }
}