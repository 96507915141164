import { HttpResponseBase } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ModalDialogType } from '../components/add-edit-design/add-edit-design-models';

import { LocalizationService } from './localization.service';
import { ModalService } from './modal.service';

@Injectable({
    providedIn: 'root'
})
export class ErrorHandlerService {
    private _isModalOpen = false;

    constructor(
        private modalService: ModalService,
        private localizationService: LocalizationService
    ) { }

    public showProjectArchivedModal(response: HttpResponseBase, url?: string, requestData?: object) {
        if (!this._isModalOpen) {
            this._isModalOpen = true;

            this.modalService
                .openAlertError(
                    this.localizationService.getString('Agito.Hilti.Profis3.ProjectArchived.Title'),
                    this.localizationService.getString('Agito.Hilti.Profis3.ProjectArchived.Message'),
                    {
                        response,
                        endPointUrl: url,
                        requestPayload: requestData
                    }
                )
                .closed
                .then(() => {
                    this._isModalOpen = false;
                });
        }
    }

    public showTemplateFolderArchivedModal(response: HttpResponseBase, url?: string, requestData?: object) {
        if (!this._isModalOpen) {
            this._isModalOpen = true;

            this.modalService
                .openAlertError(
                    this.localizationService.getString('Agito.Hilti.Profis3.HomePage.TemplateFolderArchived.Title'),
                    this.localizationService.getString('Agito.Hilti.Profis3.HomePage.TemplateFolderArchived.Message'),
                    {
                        response,
                        endPointUrl: url,
                        requestPayload: requestData
                    }
                )
                .closed
                .then(() => {
                    this._isModalOpen = false;
                });
        }
    }

    public showDesignDoesNotExistModal(response?: HttpResponseBase, url?: string, requestData?: object): void {
        this.modalService
            .openAlertError(
                this.localizationService.getString('Agito.Hilti.Profis3.DocumentService.Alerts.DesignDoesNotExistAnymore.Title'),
                this.localizationService.getString('Agito.Hilti.Profis3.DocumentService.Alerts.DesignDoesNotExistAnymore.Message'),
                {
                    response,
                    endPointUrl: url,
                    requestPayload: requestData
                }
            );
    }

    public showExistingTemplateNameModal(response?: unknown, url?: string, requestData?: object) {
        if (!this._isModalOpen) {
            this._isModalOpen = true;

            this.modalService
                .openAlertError(
                    this.localizationService.getString('Agito.Hilti.Profis3.Naming.TemplateNameExists.Title'),
                    this.localizationService.getString('Agito.Hilti.Profis3.Naming.TemplateNameExists.Message'),
                    {
                        response,
                        endPointUrl: url,
                        requestPayload: requestData
                    }
                )
                .closed
                .then(() => {
                    this._isModalOpen = false;
                });
        }
    }

    public showExistingDesignNameModal(response?: HttpResponseBase, url?: string, requestData?: object) {
        if (!this._isModalOpen) {
            this._isModalOpen = true;

            this.modalService
                .openAlertError(
                    this.localizationService.getString('Agito.Hilti.Profis3.Naming.DesignNameExists.Title'),
                    this.localizationService.getString('Agito.Hilti.Profis3.Naming.DesignNameExists.Message'),
                    {
                        response,
                        endPointUrl: url,
                        requestPayload: requestData
                    }
                )
                .closed
                .then(() => {
                    this._isModalOpen = false;
                });
        }
    }

    public showExistingProjectNameModal() {
        if (!this._isModalOpen) {
            this._isModalOpen = true;

            this.modalService
                .openAlertWarning(
                    this.localizationService.getString('Agito.Hilti.Profis3.Naming.ProjectNameExists.Title'),
                    this.localizationService.getString('Agito.Hilti.Profis3.Naming.ProjectNameExists.Message')
                )
                .closed
                .then(() => {
                    this._isModalOpen = false;
                });
        }
    }

    public showExistingTemplateFolderNameModal() {
        if (!this._isModalOpen) {
            this._isModalOpen = true;

            this.modalService
                .openAlertWarning(
                    this.localizationService.getString('Agito.Hilti.Profis3.HomePage.TemplateFolderExist.Title'),
                    this.localizationService.getString('Agito.Hilti.Profis3.HomePage.TemplateSubExist.Message')
                )
                .closed
                .then(() => {
                    this._isModalOpen = false;
                });
        }
    }


}
