import { Component, OnInit } from '@angular/core';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { AuthenticationService } from '../../services/authentication.service';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'app-unauthorized-access',
    templateUrl: './unauthorized-access.component.html',
    styleUrls: ['./unauthorized-access.component.scss']
})
export class UnauthorizedAccessComponent implements OnInit {
    private loggedOut: boolean;

    constructor(
        private user: UserService,
        private modalInstance: ModalInstance,
        private authentication: AuthenticationService,
    ) { }

    ngOnInit(): void {
        this.modalInstance.setOnClosing((_) => {
            return false;
        });
    }

    public logOut() {
        if (!this.loggedOut) {
            this.loggedOut = true;

            if (this.user.design) {
                this.user.design.processDesignClose(true)
                    .finally(() => {
                        this.authentication.logout();
                    });
            }
            else {
                this.authentication.logout();
            }
        }
    }
}
