import { Component, OnInit } from '@angular/core';

import { environment } from '../../../environments/environment';
import {
    IModuleVersionDetails, IServiceVersionDetails, VersionDetailsService
} from '../../services/version-details.service';

@Component({
    selector: 'app-version',
    templateUrl: './version.component.html',
    styleUrls: ['./version.component.scss']
})
export class VersionComponent implements OnInit {
    public suiteVersion = '';
    public suiteBuildVersion = '';

    public moduleVersionDetails: IModuleVersionDetails[] = [];
    public commonServiceVersionDetails: IServiceVersionDetails[] = [];


    constructor(
        private versionDetailsService: VersionDetailsService
    ) { }


    public async ngOnInit(): Promise<void> {
        this.suiteVersion = environment.applicationVersion;
        this.suiteBuildVersion = environment.version;

        this.moduleVersionDetails = await this.versionDetailsService.getModulesData();
        this.commonServiceVersionDetails = await this.versionDetailsService.getCommonServicesData();
    }
}
