    <div class="box-section box-section-white quick-start main-container" *ngIf="inAppCampaigns?.length && !isOffline">
        <div class="box-section-header">
            <div class="box-section-title container-header">
                <span class="title" l10n="Agito.Hilti.Profis3.HomePage.WhatsNew.InAppMarketing"></span>
            </div>
            <pe-collapse [container]="'box-section-what-new'" [collapsed]="sectionsCollapsed.inAppMarketing.collapsed"
                (collapsedChange)="onSectionCollapsedChange(sectionsCollapsed.inAppMarketing, $any($event).detail)"></pe-collapse>
        </div>
        <div class="box-section-what-new">
            <div class="right-side-panel">
                <div class="box-container" *ngFor="let inAppCampaign of inAppCampaigns"
                    (click)="trackInAppCampaignClick(inAppCampaign)" style="cursor: pointer;">
                    <a *ngIf="inAppCampaign.Link" class="link" target="_blank" [href]="inAppCampaign.Link">
                        <div class="media-container">
                            <iframe *ngIf="inAppCampaign.CampaignType == 0;else inAppImageCampaign" type="text/html"
                                [src]="sanitizerService.bypassSecurityTrustResourceUrl(inAppCampaign.Url)"
                                allowfullscreen  title="video inapp campaign"></iframe>
                            <ng-template #inAppImageCampaign>
                                <div class="image-container" *ngIf="inAppCampaign.CampaignType == 1">
                                    <img [src]="inAppCampaign.Url" *ngIf="inAppCampaign.Url  != null" alt="inAppCampaign">
                                    <span class="sprite sprite-whats-new display"
                                        *ngIf="inAppCampaign.Url  == null"></span>
                                </div>
                            </ng-template>
                        </div>
                        <div class="white-box-section">
                            <div class="title">
                                <span>{{inAppCampaign.Title}}</span><br>
                            </div>
                            <div class="content">
                                <span>{{inAppCampaign.Description}}</span>
                            </div>
                            <span class="learn-more" l10n="Agito.Hilti.Profis3.HomePage.Learning.LearnMore"></span>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="box-section box-section-white quick-start main-container downloads-container" *ngIf="isDownloadEnabled && !isOffline">
        <div class="box-section-header">
            <div class="box-section-title container-header">
                <span class="title" l10n="Agito.Hilti.Profis3.HomePage.Downloads"></span>
            </div>
            <pe-collapse [container]="'box-section-downloads'" [collapsed]="sectionsCollapsed.hiltiApplications.collapsed && !virtaulTourInProgress"
                (collapsedChange)="onSectionCollapsedChange(sectionsCollapsed.hiltiApplications, $any($event).detail)">
            </pe-collapse>
        </div>
        <div class="box-section-downloads">
            <div class="right-panel-download" *ngFor="let application of hiltiApplications">
                <div class="white-box-section">
                    <div class="top-box-section">
                        <div class="top-left-box">
                            <span *ngIf="!application.Image" class="sprite sprite-profis-engineering"></span>
                            <img *ngIf="application.Image" class="app-image"
                                src="{{application.Image}}" alt="Hilti Application">
                        </div>
                        <div class="top-right-box">
                            <span class="text">{{ externalAppsService.getHiltiApplicationTitle(application) }}</span>
                            <span class="text-new-label" *ngIf="application.ShowNewLabel"
                                l10n="Agito.Hilti.Profis3.ProjectAndDesign.HiltiAplications.New"></span>
                        </div>
                    </div>
                </div>
                <div class="seperator"></div>
                <div class="white-box-section">
                    <div class="bottom-left-box">
                        <span class="sprite sprite-download-red bottom-image"></span>
                    </div>
                    <div class="bottom-right-box">
                        <a href="javascript:void(0);" id="home-page-right-panel-download-{{application.DisplayKey}}" class="download-link"
                            (click)="onDownloadClick(application)"><span
                                l10n="Agito.Hilti.Profis3.HomePage.Downloads.DownloadFile"></span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="box-section box-section-white quick-start main-container" *ngIf="isAskHiltiEnabled || isHiltiAcademyEnabled">
        <div class="box-section-header">
            <div class="box-section-title container-header">
                <span class="title" l10n="Agito.Hilti.Profis3.HomePage.Learning"></span>
            </div>
            <pe-collapse [container]="'box-section-learning'"
                [collapsed]="sectionsCollapsed.learning.collapsed"
                (collapsedChange)="onSectionCollapsedChange(sectionsCollapsed.learning, $any($event).detail)"></pe-collapse>
        </div>
        <div class="box-section-learning">
            <div class="right-side-panel">
                <div class="box-container" *ngIf="isAskHiltiEnabled">
                    <span class="sprite sprite-learning-image display"></span>
                    <div class="white-box-section">
                        <div class="title">
                            <span l10n="Agito.Hilti.Profis3.HomePage.Learning.Title"></span><br>
                        </div>
                        <div class="message">
                            <span l10n="Agito.Hilti.Profis3.HomePage.Learning.Message"></span><br>
                        </div>
                        <div class="content">
                            <span l10n="Agito.Hilti.Profis3.HomePage.Learning.Content"></span><br>
                        </div>
                        <pe-button [id]="'home-page-right-panel-ask-hilti'" [look]="'HiltiStyledButtonRed'"
                            [height]="'32px'" [width]="'100%'" (buttonClick)="onAskHiltiClick()"
                            [text]="translate('Agito.Hilti.Profis3.HomePage.Learning.ButtonText')"></pe-button>
                    </div>
                </div>
                <div class="box-container" *ngIf="isHiltiAcademyEnabled">
                    <span class="sprite sprite-elearning-course display" *ngIf="LearningImage  == null"></span>
                    <img [src]="LearningImage" alt="e-learning" *ngIf="LearningImage  != null">
                    <div class="white-box-section">
                        <div class="title">
                            <span l10n="Agito.Hilti.Profis3.HomePage.Learning.Academy.Title"></span><br>
                        </div>
                        <div class="message">
                            <span l10n="Agito.Hilti.Profis3.HomePage.Learning.Academy.Message"></span><br>
                        </div>
                        <div class="content">
                            <span l10n="Agito.Hilti.Profis3.HomePage.Learning.Academy.Content"></span><br>
                        </div>
                        <pe-button [id]="'home-page-right-panel-hilti-academy'" [look]="'HiltiStyledButtonRed'"
                            [height]="'32px'" [width]="'100%'" (buttonClick)="onHiltiAcademyClick()"
                            [text]="translate('Agito.Hilti.Profis3.HomePage.Learning.Academy.ButtonText')"></pe-button>
                    </div>
                </div>
                <div class="box-container" *ngIf="isOffline">
                    <div class="white-box-section">
                        <div class="content">
                            <span l10n="Agito.Hilti.Profis3.Notification.Learn"></span><br>
                        </div>
                        <pe-button [id]="'home-page-right-panel-hilti-academy'" [look]="'HiltiStyledButtonRed'"
                            [height]="'32px'" [width]="'100%'" (buttonClick)="onHiltiOnlineClick()"
                            [text]="translate('Agito.Hilti.Profis3.Notification.GoToHilti')"></pe-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
