import cloneDeep from 'lodash-es/cloneDeep';

import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserSettingsValue } from '@profis-engineering/pe-ui-common/entities/user-settings';
import {
    CcmsUserSettings
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.CcmsLibrary.Entities';
import {
    SettingModel
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.UserSettings.Shared.Entities';
import { LanguageCultureLcid } from '@profis-engineering/pe-ui-common/services/localization.common';

import { environment } from '../../environments/environment';
import { createUserSettings, UserSettings, userSettingsKeys } from '../entities/user-settings';
import { ApiService } from './api.service';
import { CodeListService } from './code-list.service';
import { CommonCodeListService } from './common-code-list.service';
import { FavoritesService } from './favorites.service';
import { FeatureVisibilityService } from './feature-visibility.service';
import { LicenseService } from './license.service';
import { LocalizationService } from './localization.service';
import { LoggerService } from './logger.service';
import { ModulesService } from './modules.service';
import { OfflineService } from './offline.service';
import { ReportTemplateService } from './report-template.service';
import { UserSettingModel, UserSettingsService } from './user-settings.service';
import { UserService } from './user.service';

@Injectable()
export class UserSettingsServiceImpl extends UserSettingsService {
    // store original values here, so that we can only send values that actually changed during update and thus enable server work reduction
    private originalValues: UserSettings;

    private pendingSave: Promise<any> = Promise.resolve();

    constructor(
        loggerService: LoggerService,
        codeListService: CodeListService,
        licenseService: LicenseService,
        userService: UserService,
        commonCodeListService: CommonCodeListService,
        offlineService: OfflineService,
        localizationService: LocalizationService,
        favoritesService: FavoritesService,
        reportTemplateService: ReportTemplateService,
        featureVisibilityService: FeatureVisibilityService,
        private apiService: ApiService,
        private modulesService: ModulesService
    ) {
        super(
            loggerService,
            codeListService,
            licenseService,
            userService,
            offlineService,
            localizationService,
            favoritesService,
            reportTemplateService,
            commonCodeListService,
            featureVisibilityService
        );
    }

    public initialize(data: SettingModel[], ccmsData?: CcmsUserSettings) {
        const rSettings = this.parseServiceResponse(data);

        // make a clone to enable service work reduction
        this.originalValues = cloneDeep(rSettings);
        this.copySettingsValues(this.originalValues, rSettings);

        this.userSettings = rSettings;
        this.ccmsUserSettingsData = ccmsData;
        this.setDefaultsForEmptyOrNonExistingValues();
        this.settingsLoaded = true;
    }

    public parseServiceResponse(data: SettingModel[]) {
        const rSettings = createUserSettings(this.modulesService);

        this.readKeysFromResponse(data, rSettings);

        return rSettings;
    }

    protected async writeToService(): Promise<void> {
        // validation
        if (this.settingsLoaded != true) {
            throw new Error('Updating setting values before they are loaded is not allowed!');
        }
        if (this.userSettings == null) {
            throw new Error('Missing setting values!');
        }
        if (this.originalValues == null) {
            throw new Error('Missing original values!');
        }

        // flag to publish user settings changed event
        let userSettingsSavedSubjectFlag = true;

        // create send object
        const data: { settings: UserSettingModel[] } = { settings: [] };

        this.writeSettings(data.settings, this.userSettings, this.originalValues);

        this.userSettingsSavingSubject.next();

        if (!this.userService.isAuthenticated) {
            throw new Error('Unauthenticated');
        }

        try {
            if (data.settings == null || data.settings.length == 0) {
                return;
            }

            // make sure we don't save the pseudo language
            data.settings = data.settings.filter(setting => setting.key != userSettingsKeys.languageId || setting.value != JSON.stringify(LanguageCultureLcid.pseudoLanguage));

            const url = `${environment.userSettingsWebServiceUrl}settings`;

            this.logServiceRequest('writeToService', url, data);
            const promise = this.pendingSave.then(async () => await this.apiService.request(new HttpRequest('PUT', url, data)));
            this.pendingSave = promise.catch();
            const response = await promise;

            this.logServiceResponse('writeToService', response);
            this.copySettingsValues(this.originalValues, this.userSettings);
        }
        catch (e) {
            userSettingsSavedSubjectFlag = false;
            this.loggerService.logServiceError(e, 'user-settings-service', 'writeToService');

            this.copySettingsValues(this.userSettings, this.originalValues);

            this.userSettingsSavingSubject.next();

            throw e;
        }

        if (userSettingsSavedSubjectFlag) {
            this.userSettingsSavedSubject.next();
        }
    }

    private tryParseJson(userVal: UserSettingsValue<unknown>, str: string) {
        try {
            userVal.parseJSONToValue(str);
        }
        catch (e) {
            return false;
        }
        return true;
    }

    private readKeysFromResponse(data: SettingModel[], settingsData: UserSettings, keyApendx = 'profis3') {
        for (const key in settingsData) {
            const value = settingsData[key];

            if (this.isUserSettingsValue(value)) {
                if (data.some((x) => x.key == `${keyApendx}_${key}`)) {
                    this.tryParseJson(value, data.find((x) => x.key == `${keyApendx}_${key}`).value);
                }
            }
            else if (Object.keys(value).length > 0) {
                this.readKeysFromResponse(data, value as UserSettings, `${keyApendx}_${key}`);
            }
        }
    }

    private writeSettings(dataSettings: UserSettingModel[], settingsData: UserSettings, origSettingsData: UserSettings, keyApendx = 'profis3') {
        for (const key in settingsData) {
            const value = settingsData[key];

            if (this.isUserSettingsValue(value)) {
                const originalSettingsDataValue = origSettingsData?.[key] as UserSettingsValue<unknown>;

                const dataValue = value.parseValueToJSON();

                if (originalSettingsDataValue == null || dataValue != originalSettingsDataValue.parseValueToJSON()) {
                    dataSettings.push({ key: `${keyApendx}_${key}`, value: dataValue });
                }
            }
            else if (Object.keys(settingsData[key]).length > 0) {
                this.writeSettings(dataSettings, value as UserSettings, origSettingsData?.[key] as UserSettings, `${keyApendx}_${key}`);
            }
        }
    }

    private copySettingsValues(cloneData: UserSettings, data: UserSettings) {
        for (const key in data) {
            if (this.isUserSettingsValue(data[key])) {
                cloneData[key] = cloneDeep(data[key]);
            }
            else if (Object.keys(data[key]).length > 0) {
                if (cloneData[key] == null) {
                    cloneData[key] = {};
                }

                this.copySettingsValues(cloneData[key] as UserSettings, data[key] as UserSettings);
            }
        }
    }

    private isUserSettingsValue(value: unknown): value is UserSettingsValue<unknown> {
        return typeof value == 'object' && 'parseValueToJSON' in value && typeof value.parseValueToJSON === 'function'
    }
}
