import isEqual from 'lodash-es/isEqual';
import debounce from 'lodash-es/debounce';
import * as React from 'react';

import { UnitType as Unit, UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { ControlHeader } from '../../ControlHeader';
import { buildHtmlTooltip, isControlHidden } from '../../MainMenuHelper';
import { TooltipType } from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';
import { LocalizationService } from '../../../../services/localization.service';
import { ITextBoxProps } from '@profis-engineering/pe-ui-common/entities/main-menu/textbox-props'

const stepperCommitDelay = 500;
const stepperHoldDelay = 400;
const stepperHoldInterval = 70;

interface ITextboxState {
    textValue: string;
}

export class TextBox extends React.PureComponent<ITextBoxProps, ITextboxState> {
    private commitDebounceFn: (value: string) => void;

    constructor(props?: ITextBoxProps) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onIncrementValue = this.onIncrementValue.bind(this);
        this.onDecrementValue = this.onDecrementValue.bind(this);
        this.onInfoClick = this.onInfoClick.bind(this);

        this.commitDebounceFn = debounce((value: string) => {
            this.commitValue(value);
        }, this.props.stepperCommitDelay != null ? this.props.stepperCommitDelay : stepperCommitDelay);

        this.state = { textValue: this.props.value ?? '' };
    }

    public override componentDidUpdate(prevProps: ITextBoxProps) {
        if (!isEqual(this.props, prevProps)) {
            this.setState({ textValue: this.props.value });
        }
    }

    public override render() {
        if (isControlHidden(this.props)) {
            return null;
        }

        const stepperPadding = this.props.stepper ? 'stepper-padding' : '';
        const inputId = `${this.props.controlId}-textbox`;
        const attrType = this.props.attrType || 'text';
        const attrMin = this.props.attrMin || null;
        const attrMax = this.props.attrMax || null;
        const tooltipTitle = this.props.disabled ? this.props.tooltipDisabledTitle : this.props.tooltipTitle;
        const tooltipText = this.props.disabled && this.props.tooltipDisabled != null ? this.props.tooltipDisabled : this.props.tooltip;
        const tooltip = this.props.title != null && this.props.title != '' && !this.props.disabled ? null : buildHtmlTooltip(tooltipText, tooltipTitle);

        const infoTooltipKey = 'Agito.Hilti.Profis3.ControlTooltip.Popup';

        return (
            <div
                data-control-id={this.props.controlId}
                className={`control react-text-box ${this.props.sizeClass}`}
                data-tip={tooltip}
                data-html={tooltip != null ? true : null}>
                <div className='header-container'>
                    <ControlHeader
                        text={this.props.title}
                        controlId={inputId}
                        tooltip={tooltipText}
                        tooltipTitle={tooltipTitle}
                        localization={this.props.localization}
                        kbLink={this.props.kbLink}
                        kbTooltip={this.props.kbTooltip}
                        infoClick={this.props.tooltipType == TooltipType.Popup ? this.onInfoClick : undefined}
                        infoTooltip={this.props.tooltipType == TooltipType.Popup ? infoTooltipKey : undefined}
                    />
                </div>

                <div className='text-box-container stepper-container'>
                    <div
                        className='input-container'
                        data-tip={tooltip}
                        data-html={tooltip != null ? true : null}>
                        <input
                            data-control-id={inputId}
                            className={`input data stepper-input ${stepperPadding}`}
                            type={attrType}
                            minLength={attrMin}
                            maxLength={attrMax}
                            // undefined and null values are not allowed and ignored for input - use empty string
                            value={this.state.textValue ?? ''}
                            onChange={this.onChange}
                            onKeyPress={this.onKeyPress}
                            onBlur={this.onBlur}
                            disabled={this.props.disabled}
                            placeholder={this.props.placeholder}
                            autoComplete='off'
                            autoCorrect='off'
                            autoCapitalize='off'
                            spellCheck={false}
                            data-tip={tooltip}
                            data-html={tooltip != null ? true : null}
                            title={this.props.valueTitle} />
                    </div>

                    <Stepper
                        visible={this.props.stepper}
                        disabled={this.props.disabled}
                        incrementValue={() => this.onIncrementValue(this.props.stepValue)}
                        decrementValue={() => this.onDecrementValue(this.props.stepValue)}
                        localization={this.props.localization as LocalizationService}
                        stepperCommitDelay={this.props.stepperCommitDelay}
                    />
                </div>
            </div>
        );
    }

    private onInfoClick() {
        if (this.props.infoClicked != null) {
            this.props.infoClicked();
        }
    }

    private changeValue(value: string, exactValue: number) {
        if (this.props.valueChanged != null) {
            this.props.valueChanged(value, exactValue, this.props);
        }
    }

    private beforeChangeValue() {
        if (this.props.beforeValueChanged != null) {
            this.props.beforeValueChanged(this.props);
        }
    }

    private commitValue(value: string) {
        const unitGroup = this.props.unitGroup ?? UnitGroup.None;

        if (value != this.props.value || this.props.fixedDecimals) {
            const unitValue = this.props.unitService.parseUnitValue(value, unitGroup);
            if (unitValue != null && !Number.isNaN(unitValue.value)) {
                const precision = this.props.unitService.getPrecision(unitValue.unit, this.props.uiProperty);
                const formattedValue = this.props.unitService.formatUnitValueArgs(unitValue.value, unitValue.unit, precision, null, null, this.props.uiProperty, true, this.props.fixedDecimals);
                const internalValue = this.props.unitService.convertUnitValueToInternalUnitValue(unitValue);
                this.changeValue(formattedValue, internalValue.value);
            }
        }

        if (this.props.commitValue != null) {
            this.props.commitValue(value, this.props);
        }
    }

    private onChange(event: React.ChangeEvent<HTMLInputElement>) {
        const value = event.target.value;
        const unitValue = this.props.unitService.parseUnitValue(value, this.props.unitGroup ?? UnitGroup.None);

        if (unitValue != null && !Number.isNaN(unitValue.value)) {
            const internalValue = this.props.unitService.convertUnitValueToInternalUnitValue(unitValue);

            this.changeValue(value, internalValue.value);
        }
        else {
            this.changeValue(value, null);
        }

        this.setState({ textValue: value });
    }

    private onKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
        this.beforeChangeValue();
        if (event.key == 'Enter') {
            let value = this.props.unitService.formatInternalValueAsDefault(this.props.exactValue, this.props.unitGroup ?? UnitGroup.None, Number.MAX_SAFE_INTEGER) ?? this.props.value;
            this.commitValue(value);
        }
    }

    private onBlur(event: React.FocusEvent) {
        let value = this.props.unitService.formatInternalValueAsDefault(this.props.exactValue, this.props.unitGroup ?? UnitGroup.None, Number.MAX_SAFE_INTEGER) ?? this.props.value;
        this.commitValue(value);
    }

    private onIncrementValue(stepValue?: number) {
        this.beforeChangeValue();
        const unitValue = this.props.unitService.parseUnknownUnitValue(this.props.value);
        if (unitValue != null && !Number.isNaN(unitValue.value)) {
            if (unitValue.unit == null) {
                unitValue.unit = Unit.None;
            }

            const incrementValue = this.props.unitService.incDecValueByUnit(unitValue.unit, stepValue);

            unitValue.value += incrementValue;

            const internalValue = this.props.unitService.convertUnitValueToInternalUnitValue(unitValue);
            if (this.props.maxValue != null && internalValue.value > this.props.maxValue) {
                unitValue.value -= incrementValue;
            }
            else {
                const value = this.props.unitService.formatUnitValue(unitValue, null, null, null, this.props.uiProperty);
                this.changeValue(value, internalValue.value);
                this.commitDebounceFn(value);
            }
        }
    }

    private onDecrementValue(stepValue?: number) {
        this.beforeChangeValue();
        const unitValue = this.props.unitService.parseUnknownUnitValue(this.props.value);
        if (unitValue != null && !Number.isNaN(unitValue.value)) {
            if (unitValue.unit == null) {
                unitValue.unit = Unit.None;
            }

            const decrementValue = this.props.unitService.incDecValueByUnit(unitValue.unit, stepValue);

            unitValue.value -= decrementValue;

            const internalValue = this.props.unitService.convertUnitValueToInternalUnitValue(unitValue);
            if (this.props.minValue != null && internalValue.value < this.props.minValue) {
                unitValue.value += decrementValue;
            }
            else {
                const value = this.props.unitService.formatUnitValue(unitValue, null, null, null, this.props.uiProperty);
                this.changeValue(value, internalValue.value);
                this.commitDebounceFn(value);
            }
        }
    }
}

interface IStepperProps {
    visible: boolean;
    disabled: boolean;
    incrementValue: () => void;
    decrementValue: () => void;
    localization: LocalizationService;
    stepperCommitDelay?: number;
}

class Stepper extends React.PureComponent<IStepperProps> {
    private holdDelay: number;
    private holdInterval: number;

    constructor(props?: IStepperProps) {
        super(props);

        this.onPlusClick = this.onPlusClick.bind(this);
        this.onPlusMouseDown = this.onPlusMouseDown.bind(this);
        this.onPlusMouseUp = this.onPlusMouseUp.bind(this);
        this.onMinusClick = this.onMinusClick.bind(this);
        this.onMinusMouseDown = this.onMinusMouseDown.bind(this);
        this.onMinusMouseUp = this.onMinusMouseUp.bind(this);
    }

    public override render() {
        const plusButtonTooltip = this.props.localization.getString('Agito.Hilti.Profis3.TextBox.StepperIncrement');
        const minusButtonTooltip = this.props.localization.getString('Agito.Hilti.Profis3.TextBox.StepperDecrement');

        if (this.props.visible) {
            return (
                <div className='stepper'>
                    <button
                        className='stepper-button increment button'
                        tabIndex={-1}
                        type='button'
                        disabled={this.props.disabled}
                        onClick={this.onPlusClick}
                        onMouseDown={this.onPlusMouseDown}
                        onMouseUp={this.onPlusMouseUp}
                        onMouseLeave={this.onPlusMouseUp}
                        data-tip={plusButtonTooltip} >
                        +</button>
                    <button
                        className='stepper-button decrement button'
                        tabIndex={-1}
                        type='button'
                        disabled={this.props.disabled}
                        onClick={this.onMinusClick}
                        onMouseDown={this.onMinusMouseDown}
                        onMouseUp={this.onMinusMouseUp}
                        onMouseLeave={this.onMinusMouseUp}
                        data-tip={minusButtonTooltip}>
                        -</button>
                </div>
            );
        }

        return null;
    }

    private onPlusClick(event: React.MouseEvent) {
        if (this.props.incrementValue != null) {
            this.props.incrementValue();
        }
    }

    private onPlusMouseDown(Event: React.MouseEvent) {
        if (this.props.incrementValue != null) {
            this.holdDelay = setTimeout(() => {
                this.holdInterval = setInterval(() => {
                    this.props.incrementValue();
                }, this.props.stepperCommitDelay != null ? this.props.stepperCommitDelay : stepperHoldInterval);
            }, stepperHoldDelay);
        }
    }

    private onPlusMouseUp(Event: React.MouseEvent) {
        clearTimeout(this.holdDelay);
        this.holdDelay = null;
        clearInterval(this.holdInterval);
        this.holdInterval = null;
    }

    private onMinusClick(event: React.MouseEvent) {
        if (this.props.decrementValue != null) {
            this.props.decrementValue();
        }
    }

    private onMinusMouseDown(Event: React.MouseEvent) {
        if (this.props.decrementValue != null) {
            this.holdDelay = setTimeout(() => {
                this.holdInterval = setInterval(() => {
                    this.props.decrementValue();
                }, this.props.stepperCommitDelay != null ? this.props.stepperCommitDelay : stepperHoldInterval);
            }, stepperHoldDelay);
        }
    }

    private onMinusMouseUp(Event: React.MouseEvent) {
        clearTimeout(this.holdDelay);
        this.holdDelay = null;
        clearInterval(this.holdInterval);
        this.holdInterval = null;
    }
}
