import saveAs from 'file-saver';
import JSZip from 'jszip';

import { Component, OnInit } from '@angular/core';
import { IExportReportRowResult } from '@profis-engineering/pe-ui-common/components/export-report-row-base/export-report-row-base.common';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import {
    DesignType
} from '@profis-engineering/pe-ui-shared/generated-modules//Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { Subject } from 'rxjs';
import { IDisplayDesign } from '../../entities/display-design';
import { CommonTrackingService } from '../../services/common-tracking.service';
import { LocalizationService } from '../../services/localization.service';

@Component({
    selector: 'app-export-reports',
    templateUrl: './export-reports.component.html',
    styleUrls: ['./export-reports.component.scss']
})
export class ExportReportsComponent implements OnInit {

    public displayDesigns: Array<IDisplayDesign> = [];
    public results: Array<IExportReportRowResult> = [];

    private cancelExport = new Subject<boolean>();
    public cancelObservable = this.cancelExport.asObservable();

    private pendingZip = false;

    constructor(
        private localization: LocalizationService,
        public modalInstance: ModalInstance<Set<IDisplayDesign>>,
        private commonTrackingService: CommonTrackingService,
    ) { }

    ngOnInit(): void {
        this.displayDesigns = Array.from(this.modalInstance.input);
    }

    get pendingExport(): boolean {
        return this.results.length != this.displayDesigns.length || this.pendingZip;
    }

    get exportPossible(): boolean {
        return this.results.length > 0 && this.results.filter(x => !x.file).length == 0;
    }

    public isC2C(displayDesign: IDisplayDesign): boolean {
        return displayDesign.designType == DesignType.Concrete2Concrete;
    }

    public translate(key: string) {
        return this.localization.getString(key);
    }

    public close() {
        this.modalInstance.close();
    }
    public cancel() {
        this.cancelExport.next(true);
    }

    public exportZip() {
        this.pendingZip = true;
        const zip = new JSZip();
        const totalSuccessReport = this.results.filter(x => x.file);
        if (totalSuccessReport?.length > 0) {
            this.commonTrackingService.trackBulkReports(totalSuccessReport.length, totalSuccessReport.filter(x => (x.displayDesign as IDisplayDesign).isCBFEM).length);
        }

        for (const result of this.results) {
            if (result.file) {
                zip.file(`${result.displayDesign.name}.pdf`, result.file, { binary: true });
            }
        }
        zip.generateAsync({ type: "blob" }).then((content: Blob) => this.saveZip(content));
    }

    public deleteExport(displayDesign: IDisplayDesign) {
        const indexDisplayDesign = this.displayDesigns.map(item => item.id).indexOf(displayDesign.id);
        ~indexDisplayDesign && this.displayDesigns.splice(indexDisplayDesign, 1);
        const indexResult = this.results.map(item => item.displayDesign.id).indexOf(displayDesign.id);
        ~indexResult && this.results.splice(indexResult, 1);
    }

    private saveZip(content: Blob) {
        saveAs(content, `${this.translate('Agito.Hilti.Profis3.ExportReports.ZipFile.Name')}.zip`);
        this.pendingZip = false;
    }
}
