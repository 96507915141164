import { HttpRequest } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import debounce from 'lodash-es/debounce';
import { BehaviorSubject } from 'rxjs';

import {
    IFavoriteTabRegions, IUserMenuFavorites
} from '@profis-engineering/pe-ui-common/entities/favorites';
import { IMenuFavorites, MenuType } from '@profis-engineering/pe-ui-common/entities/main-menu/menu';
import {
    Feature
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import { Deferred } from '@profis-engineering/pe-ui-common/helpers/deferred';
import {
    SafeFunctionInvokerHelper
} from '@profis-engineering/pe-ui-common/helpers/safe-function-invoker-helper';
import { FavoritesServiceBase } from '@profis-engineering/pe-ui-common/services/favorites.common';

import { environment } from '../../environments/environment';
import { ApiService } from './api.service';
import { FeaturesVisibilityInfoService } from './features-visibility-info.service';
import { GuidService } from './guid.service';
import { ModulesService } from './modules.service';
import { UserService } from './user.service';

const updateDelay = 500;

@Injectable({
    providedIn: 'root'
})
export class FavoritesService extends FavoritesServiceBase {
    public order: string[] = [];
    public order2D: string[] = [];
    public leftMenuProjectOrder: string[] = [];
    public rightMenuProjectOrder: string[] = [];
    public menu3DRightOrder: string[] = [];

    private _dataChange = new BehaviorSubject<object>(null);
    public dataChange = this._dataChange.asObservable();

    private debounceSave: typeof this.saveInternal;

    constructor(
        private apiService: ApiService,
        private userService: UserService,
        private guidService: GuidService,
        private featuresVisibilityInfoService: FeaturesVisibilityInfoService,
        private modulesService: ModulesService
    ) {
        super();

        this.debounceSave = debounce((...args: Parameters<typeof this.saveInternal>) => {
            NgZone.assertInAngularZone();

            return this.saveInternal(...args);
        }, updateDelay);
    }

    public get updating() {
        return Object.values(this.info).some(info => info.updating);
    }

    public get favorites() {
        return {
            isHidden: this.featuresVisibilityInfoService.isHidden(Feature.Menu_Favorites, this.userService.design.region.id),
            isDisabled: this.featuresVisibilityInfoService.isDisabled(Feature.Menu_Favorites, this.userService.design.region.id),
            tooltipText: this.featuresVisibilityInfoService.tooltip(Feature.Menu_Favorites),
            update: (menuType: number, newfavoritesOrder: string[]) => {
                const designTypeId = this.userService.design.designType.id;
                const connectionTypeId = this.userService.design.isC2C ? this.userService.design.connectionType : undefined;
                const designStandardId = this.userService.design.isC2C ? this.userService.design.designStandardC2C.id : this.userService.design.designStandard.id;
                return this.update(menuType, designTypeId, newfavoritesOrder, connectionTypeId, designStandardId);
            },
            getRegionId: (id: string) => { return this.getRegionId(id) },
        } as IMenuFavorites
    }

    public async initialize() {
        let favoritesData = {} as IUserMenuFavorites;

        if (environment.enableMenuFavorites) {
            const url = `${environment.userSettingsWebServiceUrl}Favorites/UserMenus`;
            const response = await this.apiService.request<IUserMenuFavorites>(new HttpRequest('GET', url));
            favoritesData = response.body;
        }

        if (favoritesData.menus != null) {
            this.setMenuOrders(favoritesData.menus);
        }

        // Notify system about data changed
        this._dataChange.next(favoritesData);
    }

    public async setDefault(regionId: number) {
        if (!this.userService.isAuthenticated) {
            throw new Error('Unauthenticated');
        }

        if (!environment.enableMenuFavorites) {
            return;
        }

        const url = `${environment.userSettingsWebServiceUrl}Favorites/SetDefaultUserMenuFavorites`;
        const data = {
            RegionId: regionId
        };

        const response = await this.apiService.request<IFavoriteTabRegions>(new HttpRequest('POST', url, data));

        // Notify system about data changed
        const favoritesData: IUserMenuFavorites = {
            favorites: response.body,
            menus: {}
        };
        this._dataChange.next(favoritesData);
    }

    public get(menuType: MenuType, designType: number, connectionType?: number, designStandard?: number) {
        const info = this.getInfo(menuType, designType, connectionType, designStandard);
        if (info != null) {
            return info.favorites;
        }
        return [];
    }

    public getRegionId(id: string) {
        const favoritesInfo = this.modulesService.getFavoritesInfo();
        for (const info of favoritesInfo) {
            const retVal = SafeFunctionInvokerHelper.safeInvoke(() => info.getMenuRegionIdFromFavorites(id, this.userService.design?.designTypeId ?? 0), null);
            if (retVal != null) {
                return retVal;
            }
        }

        return undefined;
    }


    private setMenuOrders(menus: { [key: number]: string[] }) {
        // Menu3D
        this.order = menus[MenuType.Menu3D] ?? [];

        // Menu3DRight
        this.menu3DRightOrder = menus[MenuType.Menu3DRight] ?? [];

        // LeftMenuProject
        this.leftMenuProjectOrder = menus[MenuType.LeftMenuProject] ?? [];

        // RightMenuProject
        this.rightMenuProjectOrder = menus[MenuType.RightMenuProject] ?? [];

        // Menu2D
        this.order2D = menus[MenuType.Menu2D] ?? [];
    }

    private async update(menuType: MenuType, designType: number, favorites: string[], connectionType?: number, designStandardId?: number) {
        favorites = favorites || [];

        const info = this.getInfo(menuType, designType, connectionType, designStandardId);
        const updateId = this.guidService.new();

        info.favorites = favorites;
        info.updating = true;
        info.updateId = updateId;

        if (environment.enableMenuFavorites) {
            this.debounceSave(menuType, designType, favorites, updateId, connectionType, designStandardId);
        }
    }

    private async saveToDatabase(menuType: MenuType, designType: number, favorites: string[], connectionType?: number, designStandardId?: number) {
        if (!this.userService.isAuthenticated) {
            throw new Error('Unauthenticated');
        }

        const info = this.getInfo(menuType, designType, connectionType, designStandardId);

        // don't update if favorites didn't change
        if (favorites == info.dbFavorites || favorites != null && favorites.length == info.dbFavorites.length && favorites.every((x, i) => x == info.dbFavorites[i])) {
            return;
        }

        const url = `${environment.userSettingsWebServiceUrl}Favorites/UpdateUserMenuFavorites`;

        const data = {
            menuId: menuType,
            designTypeId: designType,
            favoriteTabRegionIds: favorites,
            connectionTypeId: connectionType,
            designStandardId: designStandardId
        };

        await this.apiService.request(new HttpRequest('PUT', url, data));
        info.dbFavorites = favorites;
    }

    private async saveInternal(menuType: MenuType, designType: number, favorites: string[], updateId: string, connectionType?: number, designStandardId?: number) {
        const info = this.getInfo(menuType, designType, connectionType, designStandardId);

        try {
            await this.saveToDatabase(menuType, designType, favorites, connectionType, designStandardId);
            info.setDefer.resolve(favorites);
        }
        catch (error) {
            info.setDefer.reject(error);
        }

        // remove updating flag
        if (info.updateId == updateId) {
            info.updating = false;
        }

        info.setDefer = new Deferred<string[]>();
    }

    private getFavoritesType(menuType: MenuType, designType: number, designStandard: number, connectionType: number) {
        const favoritesInfo = this.modulesService.getFavoritesInfo();
        for (const info of favoritesInfo) {
            const retVal = SafeFunctionInvokerHelper.safeInvoke(() => info.getFavoritesType(menuType, designType, designStandard, connectionType), null);
            if (retVal != null) {
                return retVal;
            }
        }

        return undefined;
    }

    private getInfo(menuType: MenuType, designType: number, connectionType?: number, designStandard?: number) {
        const favoritesType = this.getFavoritesType(menuType, designType, designStandard, connectionType);
        if (favoritesType == null) {
            throw new Error('Could not find favorites type.');
        }

        if (this.info[favoritesType] == null) {
            this.info[favoritesType] = {
                favoritesType,
                dbFavorites: [],
                favorites: [],
                setDefer: new Deferred<string[]>(),
                updateId: undefined,
                updating: false
            };
        }

        return this.info[favoritesType];
    }
}
