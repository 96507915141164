import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    DesignType, ProjectOpenType as ProjectOpenTypeC2C,
    TrackOnProjectCloseRequestC2C,
    TrackOnProjectDesignOpenRequestC2C, UsageCounterC2C
} from '@profis-engineering/pe-ui-c2c/entities/tracking-data';
import {
    ProjectDesignEntityC2C
} from '@profis-engineering/pe-ui-c2c/generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import {
    ConnectionType as ConnectionTypeC2C, DesignStandard as DesignStandardC2C
} from '@profis-engineering/pe-ui-c2c/generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import { CommonRegion } from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import {
    TrackDesignChangeRequest, TrackDesignCloseRequest,
    TrackOnOpenRequest
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.CommonTrackingService.Shared.Entities';
import { TrackingDesignType } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.CommonTrackingService.Shared.Enums';
import { getJSONDateWithTimezoneOffset } from '@profis-engineering/pe-ui-common/helpers/date-time-helper';
import { CommonCodeList } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import { environment } from '../../environments/environment';
import { ApiService } from './api.service';
import { CommonCodeListService } from './common-code-list.service';
import { CommonTrackingService } from './common-tracking.service';
import { LocalizationService } from './localization.service';

/**
 * proxy for tracking service that tracks design details
 * tracking service is responsible for:
 * - tracking details when new design is created or existing design is opened
 * - tracking details when design is closed
 */
@Injectable({
    providedIn: 'root'
})
export class TrackingServiceC2C {
    constructor(
        private apiService: ApiService,
        private localizationService: LocalizationService,
        private commonTrackingService: CommonTrackingService,
        private codeListService: CommonCodeListService
    ) {}

    public async saveTrackingActDataC2C(
        projectDesign: ProjectDesignEntityC2C,
        usageCounter: UsageCounterC2C,
        projectOpenType: ProjectOpenTypeC2C,
        designId: string,
        createdFromTemplate: boolean,
        templateId: string
    ): Promise<void> {
        usageCounter.dateClosed = new Date();

        //TODO C2C Team: seperate template tracking from design tracking.
        const endpoint = designId ? 'TrackOnProjectDesignChange' : 'TrackOnProjectTemplateChange';
        const url = `${environment.peTrackingServiceUrl}Tracking/${endpoint}`;
        const trackingData = this.getDesignTrackingDataC2C(projectDesign, usageCounter, projectOpenType, designId, createdFromTemplate, templateId);

        const data: TrackDesignChangeRequest = {
            DesignType: TrackingDesignType.Concrete2Concrete,
            TrackingData: trackingData,
            DesignId: designId,
            TemplateId: templateId
        };

        const request = new HttpRequest<TrackDesignChangeRequest>('POST', url, data, {
            responseType: 'json'
        });

        await this.apiService.request(request, { supressErrorMessage: true });
    }

    public async trackOnTemplateCloseC2C(
        projectDesign: ProjectDesignEntityC2C,
        usageCounter: UsageCounterC2C,
        projectOpenType: ProjectOpenTypeC2C,
        createdFromTemplate: boolean,
        templateId: string
    ): Promise<void> {
        const url = `${environment.peTrackingServiceUrl}Tracking/TrackOnProjectTemplateClose`;
        const trackingData = this.getDesignTrackingDataC2C(projectDesign, usageCounter, projectOpenType, null, createdFromTemplate, templateId);

        const data: TrackDesignCloseRequest = {
            DesignType: TrackingDesignType.Concrete2Concrete,
            TrackingData: trackingData,
            TemplateId: templateId
        };

        const request = new HttpRequest<TrackDesignCloseRequest>('POST', url, data, {
            responseType: 'json'
        });

        await this.apiService.request(request, { supressErrorMessage: true });
    }

    public async trackOnDesignOpen(designType: DesignType, designId: string, designStandard: DesignStandardC2C, connectionType: ConnectionTypeC2C): Promise<void> {
        // set date
        const date = getJSONDateWithTimezoneOffset();
        // set user details
        const userDetails = this.commonTrackingService.getUserDetails(date.timezoneOffset);

        const url = `${environment.peTrackingServiceUrl}Tracking/TrackOnProjectDesignOpen`;

        const trackingData: TrackOnProjectDesignOpenRequestC2C = {
            connectionType,
            designId,
            designStandard,
            designType,
            timestamp: date.timestamp as any,
            userDetails,
            uiVersion: environment.applicationVersion,
        };

        const data: TrackOnOpenRequest = {
            DesignType: TrackingDesignType.Concrete2Concrete,
            TrackingData: trackingData,
            DesignId: designId
        };

        const request = new HttpRequest<TrackOnOpenRequest>('POST', url, data, {
            responseType: 'json'
        });

        await this.apiService.request(request, { supressErrorMessage: true });
    }

    public async trackOnDesignCloseC2C(
        projectDesign: ProjectDesignEntityC2C,
        usageCounter: UsageCounterC2C,
        projectOpenType: ProjectOpenTypeC2C,
        designId: string,
        createdFromTemplate: boolean,
        templateId: string
    ): Promise<void> {
        usageCounter.dateClosed = new Date();

        const url = `${environment.peTrackingServiceUrl}Tracking/TrackOnProjectDesignClose`;
        const trackingData = this.getDesignTrackingDataC2C(projectDesign, usageCounter, projectOpenType, designId, createdFromTemplate, templateId);

        const data: TrackDesignCloseRequest = {
            DesignType: TrackingDesignType.Concrete2Concrete,
            TrackingData: trackingData,
            DesignId: designId
        };

        const request = new HttpRequest<TrackDesignCloseRequest>('POST', url, data, {
            responseType: 'json'
        });

        await this.apiService.request(request, { supressErrorMessage: true });
    }

    public getDesignTrackingDataC2C(projectDesign: ProjectDesignEntityC2C, usageCounter: UsageCounterC2C, projectOpenType: ProjectOpenTypeC2C, designId: string, createdFromTemplate: boolean, templateId: string): TrackOnProjectCloseRequestC2C {
        // set date
        const date = getJSONDateWithTimezoneOffset();
        // set user details
        const userDetails = this.commonTrackingService.getUserDetails(date.timezoneOffset);

        return {
            designId,
            projectDesign,
            language: this.localizationService.selectedLanguage,
            region: this.getRegionNameById(projectDesign.options.regionId),
            usageCounter,
            timestamp: date.timestamp as any,
            userDetails,
            projectOpenType,
            createdFromTemplate,
            templateId,
            uiVersion: environment.applicationVersion
        };
    }

    private getRegionNameById(regionId: number) {
        const codeLists = this.codeListService.commonCodeLists[CommonCodeList.Region] as CommonRegion[];
        return codeLists.find(x => x.id == regionId)?.displayKey ?? '';
    }
}
