import * as React from 'react';

import { ControlHeader } from '../../ControlHeader';
import { buildHtmlTooltip, isControlHidden } from '../../MainMenuHelper';
import { IRadioButtonProps } from '@profis-engineering/pe-ui-common/entities/main-menu/radio-button-group-props';
import { TooltipType } from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';

const keyEnter = 13;
const keySpace = 32;

export class RadioButton extends React.PureComponent<IRadioButtonProps> {
    private input: HTMLInputElement;

    constructor(props?: IRadioButtonProps) {
        super(props);

        this.onClick = this.onClick.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.onInfoClick = this.onInfoClick.bind(this);
    }

    public override render() {
        if (isControlHidden(this.props)) {
            return null;
        }

        const inputId = `${this.props.controlId}-radio`;
        const checked = this.props.checked != null ? this.props.checked : false;
        const tabIndex = this.props.disabled ? -1 : 0;
        const text = this.props.text != null ? this.props.text : '';
        const tooltip = this.props.title != null && this.props.title != '' ? null : buildHtmlTooltip(this.props.tooltip, this.props.tooltipTitle);
        const infoTooltipKey = 'Agito.Hilti.Profis3.ControlTooltip.Popup';

        const tooltipTitle = this.props.disabled ? this.props.tooltipDisabledTitle : this.props.tooltipTitle;
        const tooltipText = this.props.disabled ? this.props.tooltipDisabled : this.props.tooltip;

        const radioButtonLabelClass = 'text radio-label';
        let controlTooltipInfoClass = 'control-tooltip-popup sprite sprite-info-tooltip';

        if (this.props.tooltipType == TooltipType.Popup) {
            controlTooltipInfoClass += ' info-margin';
        }

        return (
            <div data-control-id={this.props.controlId} className={`control react-radio-button-control ${this.props.sizeClass}`}>
                <ControlHeader
                    text={this.props.title}
                    controlId={inputId}
                    tooltip={tooltipText}
                    tooltipTitle={tooltipTitle}
                    localization={this.props.localization}
                />

                <div className='control-container'>
                    <div className='radio'>
                        <div
                            className='radio-button'
                            onClick={this.onClick}
                            tabIndex={tabIndex}
                            onKeyPress={this.onKeyPress} >
                            <input
                                data-control-id={inputId}
                                type='radio'
                                checked={checked}
                                onChange={this.onChange}
                                disabled={this.props.disabled}
                                ref={(e) => this.input = e} />
                            <span></span>
                        </div>
                        <div>
                            <span
                                className={radioButtonLabelClass}
                                onClick={this.onClick}
                                data-tip={tooltip}
                                data-html={tooltip != null ? true : null}>
                                {text}
                                {this.props.tag != null ? <span className='tag'>{this.props.localization.getString(this.props.tag)}</span> : null}
                            </span>

                            {this.props.tooltipType == TooltipType.Popup &&
                                <button
                                    type='button'
                                    className={controlTooltipInfoClass}
                                    onClick={this.onInfoClick}
                                    data-tip={this.props.localization.getString(infoTooltipKey)}>
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private onClick(event: React.MouseEvent) {
        this.input.click();
    }

    private onChange(event: React.FormEvent) {
        const checked = (event.target as HTMLInputElement).checked;

        if (this.props.checkedChanged != null) {
            this.props.checkedChanged(checked, this.props.value);
        }
    }

    private onKeyPress(event: React.KeyboardEvent) {
        const eventCode = event.which || event.keyCode || event.charCode;
        if (eventCode == keyEnter || eventCode == keySpace) {
            event.preventDefault();
            this.input.click();
        }
    }

    private onInfoClick(event: React.MouseEvent) {
        if (this.props.infoClicked != null) {
            this.props.infoClicked();
        }
    }
}
