import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {
    CheckboxButtonProps
} from '@profis-engineering/pe-ui-common/components/checkbox-button/checkbox-button.common';
import {
    RegionMarketingCampaign
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.ProductInformationService.Shared.Entities';
import {
    MarketingCampaignType
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.ProductInformationService.Shared.Enums';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { hasProperty } from '@profis-engineering/pe-ui-common/helpers/object-helper';
import {
    SimpleCheckboxButtonHelper
} from '@profis-engineering/pe-ui-common/helpers/simple-checkbox-button-helper';
import { CommonTrackingService } from '../../services/common-tracking.service';
import { LocalizationService } from '../../services/localization.service';
import { UserSettingsService } from '../../services/user-settings.service';

interface IMarketingCampaignComponentInput {
    marketingCampaign: RegionMarketingCampaign;
    dontShowOnLaunchVisible: boolean;
}

@Component({
    selector: 'app-marketing-campaign',
    templateUrl: './marketing-campaign.component.html',
    styleUrls: ['./marketing-campaign.component.scss']
})
export class MarketingCampaignComponent implements OnInit {
    public dontShowOnLaunchCheckbox: CheckboxButtonProps<boolean>;
    public link: SafeResourceUrl;
    public url: SafeResourceUrl;

    private campaign: RegionMarketingCampaign;

    constructor(
        private localization: LocalizationService,
        private userSettings: UserSettingsService,
        private commonTrackingService: CommonTrackingService,
        private sanitizer: DomSanitizer,
        private modalInstance: ModalInstance<IMarketingCampaignComponentInput>
    ) { }

    public get isVideo() {
        return this.campaign.CampaignType == MarketingCampaignType.Video;
    }

    public get isImage() {
        return this.campaign.CampaignType == MarketingCampaignType.Image;
    }

    public get hasLink() {
        return this.campaign.Link != null;
    }

    public get dontShowOnLaunchVisible() {
        if (this.campaign == null) {
            return false;
        }

        return this.campaign.DisplayOnLaunch && (this.modalInstance.input?.dontShowOnLaunchVisible ?? false);
    }

    public get campaignName() {
        return 'Marketing Campaign ' + this.campaign.MarketingCampaignId;
    }

    ngOnInit(): void {
        this.campaign = this.modalInstance.input?.marketingCampaign;

        this.link = this.campaign?.Link != null
            ? this.sanitizer.bypassSecurityTrustResourceUrl(this.campaign.Link)
            : null;
        this.url = this.campaign != null
            ? this.sanitizer.bypassSecurityTrustResourceUrl(this.campaign.Url)
            : null;

        this.commonTrackingService.trackPopupShown(this.campaign.CampaignType, this.campaignName);

        if (this.dontShowOnLaunchVisible) {
            // load don't show
            this.dontShowOnLaunchCheckbox = SimpleCheckboxButtonHelper.createSimpleCheckbox({
                itemText: this.localization.getString('Agito.Hilti.Profis3.MarketingCampaign.DontShow'),
                checked: hasProperty(this.userSettings.settings.marketingCampaignDontShowOnLaunch.value, this.campaign.MarketingCampaignId.toString())
            });

            // save don't show
            this.modalInstance.setOnClosing((_) => {
                if (!SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.dontShowOnLaunchCheckbox)) {
                    this.userSettings.settings.marketingCampaignDontShowOnLaunch.value = Object.fromEntries(
                        Object.entries(this.userSettings.settings.marketingCampaignDontShowOnLaunch.value)
                            .filter(([id]) => id != this.campaign.MarketingCampaignId.toString())
                    );
                }
                else {
                    this.userSettings.settings.marketingCampaignDontShowOnLaunch.value = {
                        ...this.userSettings.settings.marketingCampaignDontShowOnLaunch.value,
                        [this.campaign.MarketingCampaignId.toString()]: null
                    };

                    this.commonTrackingService.trackPopupDontShowAgain(this.campaign.CampaignType, this.campaignName);
                }

                this.userSettings.save();
                return true;
            });
        }
    }

    public trackLinkClicked() {
        this.commonTrackingService.trackPopupLinkClicked(this.campaign?.CampaignType, this.campaignName, this.campaign?.Link);
    }

    public close() {
        this.modalInstance.close();
    }
}
