import sortBy from 'lodash-es/sortBy';
import { Subject, Subscription } from 'rxjs';

import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CollapseLook } from '@profis-engineering/pe-ui-common/components/section/section.common';
import {
    IAppSettingsComponent
} from '@profis-engineering/pe-ui-common/entities/app-settings-component';
import {
    IAppSettingsConcreteComponent, IConcreteFixingUpdate
} from '@profis-engineering/pe-ui-common/entities/app-settings-concrete-component';
import { CommonRegion } from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import { Language } from '@profis-engineering/pe-ui-common/entities/code-lists/language';
import {
    Separator as SeparatorEntity
} from '@profis-engineering/pe-ui-common/entities/code-lists/separator';
import {
    StructuralCalculationSoftware
} from '@profis-engineering/pe-ui-common/entities/code-lists/structural-calculation-software';
import { DesignEvent } from '@profis-engineering/pe-ui-common/entities/design';
import {
    AppSettings, AppSettingsSection
} from '@profis-engineering/pe-ui-common/entities/module-initial-data';
import {
    StructuralCalculationSoftware as StructuralCalculationSoftwareType
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import {
    DataIntegrationType
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities.Enums';
import {
    ApplicationSettingsDisplayType, SpecialRegion
} from '@profis-engineering/pe-ui-common/helpers/app-settings-helper';
import { ensureLanguage } from '@profis-engineering/pe-ui-common/helpers/localization-helper';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import {
    SafeFunctionInvokerHelper
} from '@profis-engineering/pe-ui-common/helpers/safe-function-invoker-helper';
import { CommonCodeList } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import {
    IDeckingDesignListInfo
} from '@profis-engineering/pe-ui-decking/src/decking/entities/decking-design-list-info';
import {
    IDeckingUserSettings
} from '@profis-engineering/pe-ui-decking/src/decking/entities/settings/decking-user-settings';
import {
    DesignType
} from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import {
    AdvancedCalculationInputsHelper
} from '@profis-engineering/pe-ui-shared/helpers/advanced-calculation-inputs-helper';
import {
    AppSettingsHelper, reductionFactorSP
} from '@profis-engineering/pe-ui-shared/helpers/app-settings-helper';
import {
    findSteelCalculationMethod
} from '@profis-engineering/pe-ui-shared/helpers/steel-guideline-helper';

import { environment } from '../../../environments/environment';
import { CollapsingControls } from '../../entities/collapsing-controls';
import { Design } from '../../entities/design';
import {
    CalculationMethodState, IAdvancedBaseplateCalculationSettings, IAdvancedCalculationData,
    IAdvancedCalculationInputs, IAppSettingsDefaultParametersData, IAppSettingsInteractionData,
    IAppSettingsRegionLanguageData, IStructuralCalculationSoftwareData
} from '../../helpers/app-settings-helper';
import { CodeListService } from '../../services/code-list.service';
import { CommonCodeListService } from '../../services/common-code-list.service';
import { FeatureVisibilityService } from '../../services/feature-visibility.service';
import { FeaturesVisibilityInfoService } from '../../services/features-visibility-info.service';
import { IntegrationsDataService } from '../../services/integrations-data.service';
import { LocalizationService } from '../../services/localization.service';
import { DesignTypeId, ModulesService } from '../../services/modules.service';
import { NumberService } from '../../services/number.service';
import { OfflineService } from '../../services/offline.service';
import { UserSettingsService } from '../../services/user-settings.service';

interface IModuleAppSettingsComponentInternal extends IAppSettingsComponent, HTMLElement { }

/*
Currently Advanced anchor plate calculation settings are dependant on selected steel guideline and design (PE specific).
In order to keep this working the interface below is a workaround.
This should be removed with https://jira.hilti.com/browse/BUDQBP-30905.
*/
interface IAppSettingsConcreteHtmlComponent extends IModuleAppSettingsComponentInternal, IAppSettingsConcreteComponent { }

interface IAppSettingsComponentInput {
    initialDisplayType: ApplicationSettingsDisplayType;
}

interface ISectionCollapsed {
    control: CollapsingControls;
    collapsed: boolean;
}

interface ISectionsCollapsed {
    general: ISectionCollapsed;
}


@Component({
    selector: 'app-app-settings',
    templateUrl: './app-settings.component.html',
    styleUrls: ['./app-settings.component.scss']
})
export class AppSettingsComponent implements OnInit, AfterViewInit, OnDestroy {
    // Modules - public
    public moduleAppSettings: AppSettings[] = [];

    private moduleInfoProvided: Subscription;

    @ViewChild('componentInjector')
    public componentInjector: ElementRef<HTMLElement>;

    // Modules - public
    public submitted: boolean;
    public displayType: ApplicationSettingsDisplayType;
    public collapseSectionBoxLook = CollapseLook.SectionBox;
    public sectionsCollapsed: ISectionsCollapsed = {
        general: {
            collapsed: false,
            control: CollapsingControls.MenuGeneral
        }
    };

    public displayTypeEnum = ApplicationSettingsDisplayType;


    // Data objects
    // Region and language
    public regionLanguageData: IAppSettingsRegionLanguageData = {
        language: null,
        region: null,
        decimalSeparator: null,
        thousandsSeparator: null
    };

    // Default parameters
    public defaultParametersData: IAppSettingsDefaultParametersData = {
        anchorPlateFactor: null,
        anchorPlateThickness: null,
        concreteCapacityFactor: null,
        inSteelSafetyFactorGammaM0: null,
        inSteelSafetyFactorGammaM1: null,
        inSteelSafetyFactorGammaMb: null,
        inSteelSafetyFactorGammaMw: null,
        materialSafetyFactor: null,
        minimumAnchorToProfileDistance: null,
        minimumConcreteCover: null,
        permanentLoads: null,
        steelCapacityFactor: null,
        steelSafetyFactorGammaM0: null,
        steelSafetyFactorGammaM1: null,
        steelSafetyFactorGammaM2: null,
        variableLoads: null,
        weldsCapacityFactor: null
    };

    // Interaction
    public interactionData: IAppSettingsInteractionData = {
        rotate: null,
        pan: null
    };

    // Advanced Baseplate Calculation
    public advancedCalculationData: IAdvancedCalculationData = {
        alphaCC: null,
        concreteInCompressionMethod: null,
        divergentIterationsCount: null,
        divisionOfArcsOfRHS: null,
        divisionOfSurfaceOfCHS: null,
        effectiveArea: null,
        effectiveAreaAISC: null,
        jointCoefficientBj: null,
        limitPlasticStrain: null,
        loadDistributionAngle: null,
        maximumSizeOfElement: null,
        minimumSizeOfElement: null,
        numberOfAnalysisIterations: null,
        numberOfElementsOfEdge: null,
        useULSStresses: null
    };

    // Structural software
    public structuralCalculationData: IStructuralCalculationSoftwareData = {
        dlubalEnabled: null,
        risaEnabled: null,
        sap2000Enabled: null,
        robotEnabled: null,
        etabsEnabled: null,
        staadProEnabled: null,
        ramEnabled: null
    };

    // Advanced calculation inputs
    public advancedBaseplateInputsData: IAdvancedCalculationInputs = {
        designTypeId: undefined,
        designStandardId: undefined,
        isApplicationSettings: true,
        selectedRegionId: undefined,
        defaultAdvancedCalculationValues: undefined,
        selectedSteelGuideline: undefined,
        lengthUnit: undefined
    };

    public advancedCalculationInputs: IAdvancedBaseplateCalculationSettings = {
        calculationMethodState: null,
        advancedBaseplateInputs: null,
        advancedBaseplateData: null
    };

    // For Decking Settings update and saving
    public reloadDeckingSettings$ = new Subject<void>();
    public saveDeckingSettings$ = new Subject<void>();
    public deckingSelectedRegion: CommonRegion;
    // Internal decking data
    public deckingData: IDeckingUserSettings = {
        id: null,
        eTag: null,
        region: null,
        length: null,
        area: null,
        stress: null,
        moment: null,
        force: null,
        forcePerLength: null,
        momentPerLength: null,
        designMethod: null,
        relevantLoads: null,
        definitionOfSidelapConnectors: null,
        sidelapsNumber: null,
        sidelapsSpacing: null,
        requiredShearStiffness: null,
        calculateIntegration: null,
        windAndSeismicLoadsAtZoneLevel: null,
        shearStiffness: null,
        designMode: null,
        wasteFactor: null
    };


    // Concrete steel calculation method
    public concreteSteelCalculationMethodId: number;

    private design: Design;
    private appSettingsHelper: AppSettingsHelper;
    private pendingSave = false;

    private initialLanguage: Language;
    private initialStructuralCalculationData: IStructuralCalculationSoftwareData;

    // Modules - private
    private static readonly moduleSectionComponentClass = 'app-setting-section-component';

    private moduleAppSettingsSectionData: { [name: string]: unknown } = {};
    private shownModuleAppSettingsSection?: string;

    private static concreteFixingComponent = 'pe-app-settings-concrete-fixing';
    private concreteFixingData: IConcreteFixingUpdate = {
        concreteRegion: SpecialRegion.Default,
        concreteDesignStandardId: undefined,
        concreteSteelCalculationMethod: undefined
    };

    // Modules - private

    constructor(
        public localization: LocalizationService,
        private userSettings: UserSettingsService,
        private featuresVisibilityInfo: FeaturesVisibilityInfoService,
        private integrationsData: IntegrationsDataService,
        private codeList: CodeListService,
        private commonCodeList: CommonCodeListService,
        private featureVisibilityService: FeatureVisibilityService,
        private modalInstance: ModalInstance<IAppSettingsComponentInput>,
        private offlineService: OfflineService,
        private numberService: NumberService,
        private modulesService: ModulesService
    ) {
        this.appSettingsHelper = new AppSettingsHelper(this.localization, this.userSettings, this.codeList, this.commonCodeList, this.numberService, environment.useDevFeatures);
    }

    public get deckingEnabled() {
        return this.featureVisibilityService.isFeatureEnabled('Decking_Global') || environment.deckingEnabled;
    }

    public get canUseBPRigidityCheck() {
        return this.modulesService.isRigidityCheckPossible(
            this.advancedCalculationInputs.advancedBaseplateInputs.designStandardId,
            this.advancedCalculationInputs.advancedBaseplateInputs.selectedRegionId
        );
    }

    public get isAdvancedCalculationPossible() {
        const advancedBaseplateInputs = this.advancedCalculationInputs.advancedBaseplateInputs;
        return this.modulesService.isAdvancedCalculationPossible(
            DesignType.Concrete,
            advancedBaseplateInputs.designStandardId,
            advancedBaseplateInputs.selectedRegionId
        );
    }

    public get isNotDesktopMode() {
        return !this.offlineService.isOffline;
    }

    public get advancedBaseplateCalculationNavigationKey() {
        if (this.canUseBPRigidityCheck) {
            return 'Agito.Hilti.Profis3.ApplicationSettings.Menu.AdvancedBaseplateCalculation.BPRigidityCheck';
        }

        return 'Agito.Hilti.Profis3.ApplicationSettings.Menu.AdvancedBaseplateCalculation';
    }

    // Modules
    private get moduleAppSettingsSections() {
        return this.moduleAppSettings.flatMap(settings => settings.sections);
    }
    // Modules

    ngOnInit(): void {
        // Do not close modal if save is pending
        this.modalInstance.setOnClosing(() => {
            return !this.pendingSave;
        });

        // Menu
        this.loadModuleAppSettings();

        this.sectionsCollapsed.general.collapsed = this.userSettings.isSectionCollapsed(this.sectionsCollapsed.general.control);

        // Navigation
        this.displayType = ApplicationSettingsDisplayType.regionLanguageUnits;
        if (this.modalInstance.input?.initialDisplayType != null) {
            this.displayType = this.modalInstance.input.initialDisplayType;
        }

        this.reloadSettings();

        this.moduleInfoProvided = this.modulesService.designInfoProvided.subscribe(() => {
            this.loadModuleAppSettings();
            this.loadModuleAppSettingsSections();
        });
    }

    ngAfterViewInit(): void {
        if (this.displayType != null) {
            for (const moduleSettings of this.moduleAppSettings) {
                for (const section of moduleSettings.sections) {
                    if (section.type == this.displayType) {
                        this.showSectionComponent(section);
                    }
                }
            }
        }
    }

    ngOnDestroy(): void {
        // Remove subscriptions
        this.moduleInfoProvided?.unsubscribe();
    }

    public loadModuleAppSettings() {
        const moduleAppSettings = sortBy(this.modulesService.getAppSettingsData(), s => s.order);
        moduleAppSettings.forEach(settings => settings.collapsed = this.userSettings.isSectionCollapsed(settings.name));
        this.moduleAppSettings = moduleAppSettings;
    }

    public loadModuleAppSettingsSections() {
        this.moduleAppSettingsSections.forEach(section => {
            this.moduleAppSettingsSectionData[section.componentTagName] = SafeFunctionInvokerHelper.safeInvoke(() => section.loadSettings(), null);

            const concreteFixingData = this.moduleAppSettingsSectionData[AppSettingsComponent.concreteFixingComponent];
            if (concreteFixingData != null) {
                this.concreteFixingValuesChanged(concreteFixingData as IConcreteFixingUpdate);
            }
        });
    }

    public setDisplayType(displayType: ApplicationSettingsDisplayType) {
        this.hideModuleComponents();
        this.displayType = displayType;
    }

    public isSectionSelected(appSettingsSection: AppSettingsSection) {
        if (appSettingsSection.componentTagName != null && this.componentInjector != null && this.shownModuleAppSettingsSection != null) {
            return appSettingsSection.componentTagName == this.shownModuleAppSettingsSection;
        }

        return this.displayType == appSettingsSection.type;
    }

    public dismiss() {
        if (this.deckingEnabled) {
            this.reloadDeckingSettings();
        }
        this.modalInstance.dismiss();
    }

    public close() {
        if (this.deckingEnabled) {
            this.reloadDeckingSettings();
        }
        this.modalInstance.close();
    }

    public save(form: NgForm) {
        if (this.submitted || (form.enabled && !form.valid)) {
            return;
        }

        this.setSubmitted(true);
        this.pendingSave = true;
        this.moduleAppSettingsSections.forEach(section => {
            SafeFunctionInvokerHelper.safeInvoke(() => section.updateSettings(this.moduleAppSettingsSectionData[section.componentTagName]));
        });

        // Region & language data
        this.updateRegionLanguageUserSettings();

        // Default parameters
        this.updateDefaultParametersUserSettings();

        // Interaction data
        this.updateInteractionUserSettings();

        // Structural calculation software data
        this.updateStructuralCalculationSwUserSettings();

        // Decking data
        if (this.deckingEnabled) {
            this.saveDeckingSettings$.next();
        }

        // Advanced baseplate calculation settings
        this.updateAdvancedCalculationInputsUserSettings();

        // call user settings save method
        this.userSettings.save()
            .then(() => {
                if (this.initialLanguage != undefined && this.initialLanguage.id != this.userSettings.settings.application.general.languageId.value) {
                    const language = ensureLanguage(this.regionLanguageData.language.culture, this.userSettings);
                    if (this.deckingEnabled) {
                        const deckingDesignListInfo = this.modulesService.getDesignListInfoByDesignType(DesignTypeId.DiaphragmDesign) as IDeckingDesignListInfo;
                        deckingDesignListInfo.reloadUserSettings(language);
                    }

                    return this.localization.getTranslations(language)
                        .then(() => {
                            this.localization.separatorHasChanged();
                        });
                }
                else {
                    const separatorChanged =
                        (this.userSettings.settings.application.general.decimalSeparatorId.value != undefined && this.userSettings.settings.application.general.decimalSeparatorId.value != this.regionLanguageData.decimalSeparator.id)
                        ||
                        (this.userSettings.settings.application.general.thousandsSeparatorId.value != undefined && this.userSettings.settings.application.general.thousandsSeparatorId.value != this.regionLanguageData.thousandsSeparator.id);

                    if (separatorChanged) {
                        this.localization.separatorHasChanged();
                    }

                    this.triggerStructuralCalculationSwIntegrationAvailability();
                }

                if (this.design != null) {
                    this.design.trigger(DesignEvent.applicationSettingChanged);
                }

                return undefined;
            })
            .finally(() => {
                this.pendingSave = false;
            })
            .then(() => {
                // save language
                this.initialLanguage = this.regionLanguageData.language;
                this.initialStructuralCalculationData = this.structuralCalculationData;

                this.close();
            })
            .catch((err) => {
                if (err instanceof Error) {
                    console.error(err);
                }

                this.setSubmitted(false);
            });
    }

    public handleRegionChanged(region: CommonRegion) {
        this.moduleAppSettingsSections.forEach(section => {
            SafeFunctionInvokerHelper.safeInvoke(() => section.handleRegionChange(this.moduleAppSettingsSectionData[section.componentTagName], region.id));

            const concreteFixingData = this.moduleAppSettingsSectionData[AppSettingsComponent.concreteFixingComponent];
            if (concreteFixingData != null) {
                this.concreteFixingValuesChanged(concreteFixingData as IConcreteFixingUpdate);
            }
        });

        if (this.deckingEnabled) {
            this.deckingSelectedRegion = region;
        }

        this.concreteSteelCalculationMethodId = this.concreteFixingData.concreteSteelCalculationMethod;

        this.handleRegionChangedAdvancedCalculationInputs(region);
    }

    public onSectionCollapsedChange(section: ISectionCollapsed, collapsed: boolean) {
        section.collapsed = collapsed;
        this.userSettings.setSectionCollapsed(section.control, collapsed);
    }

    // Event emitter from decking-settings-diaphragm component
    public handleDeckingSettingChanged(data: IDeckingUserSettings) {
        this.deckingData = data;
    }

    // Modules
    public showSectionComponent(appSettingsSection: AppSettingsSection) {
        if (appSettingsSection.componentTagName != null) {
            this.displayType = -1 as ApplicationSettingsDisplayType;
            this.hideModuleComponents();


            this.componentInjector.nativeElement.insertAdjacentHTML('beforeend', `<${appSettingsSection.componentTagName}></${appSettingsSection.componentTagName}>`);

            const sectionComponent = this.getModuleComponent(appSettingsSection.componentTagName);
            sectionComponent.classList.add(AppSettingsComponent.moduleSectionComponentClass);

            sectionComponent.selectedRegionId = this.regionLanguageData.region.id;
            sectionComponent.submitted = this.submitted;
            sectionComponent.decimalSeparatorId = this.regionLanguageData.decimalSeparator.id;
            sectionComponent.thousandsSeparatorId = this.regionLanguageData.thousandsSeparator.id;
            sectionComponent.settings = this.moduleAppSettingsSectionData[appSettingsSection.componentTagName];

            /*
            Currently Advanced anchor plate calculation settings are dependant on selected steel guideline and design (PE specific).
            In order to keep this working the code below is a workaround.
            This should be removed with https://jira.hilti.com/browse/BUDQBP-30905.
            */
            if (appSettingsSection.componentTagName == AppSettingsComponent.concreteFixingComponent) {
                const sectionComponentConcrete = sectionComponent as unknown as IAppSettingsConcreteHtmlComponent;
                sectionComponentConcrete.advancedBaseplateInputsData = this.advancedBaseplateInputsData;

                sectionComponentConcrete.addEventListener('concreteFixingValueChange', this.handleConcreteFixingValueChanges.bind(this));
            }

            this.shownModuleAppSettingsSection = appSettingsSection.componentTagName;
        }
        else {
            this.shownModuleAppSettingsSection = undefined;
            this.setDisplayType(appSettingsSection.type);
        }
    }

    public handleConcreteFixingValueChanges(event: Event) {
        const data = (event as CustomEvent).detail as IConcreteFixingUpdate;
        this.concreteFixingValuesChanged(data);
    }

    public onModuleSectionCollapsedChange(moduleAppSettings: AppSettings, collapsed: boolean) {
        moduleAppSettings.collapsed = collapsed;
        this.userSettings.setSectionCollapsed(moduleAppSettings.name, collapsed);
    }

    private getModuleComponent(componentNameTag: string): IModuleAppSettingsComponentInternal {
        return this.componentInjector.nativeElement.querySelector<IModuleAppSettingsComponentInternal>(componentNameTag);
    }

    private hideModuleComponents() {
        if (this.shownModuleAppSettingsSection == null) {
            return;
        }

        /*
        Currently Advanced anchor plate calculation settings are dependant on selected steel guideline and design (PE specific).
        In order to keep this working the code below is a workaround.
        This should be removed with https://jira.hilti.com/browse/BUDQBP-30905.
        */
        if (this.shownModuleAppSettingsSection == AppSettingsComponent.concreteFixingComponent) {
            const component = this.getModuleComponent(this.shownModuleAppSettingsSection);
            component.removeEventListener('concreteFixingValueChange', this.handleConcreteFixingValueChanges);
        }

        this.componentInjector.nativeElement.innerHTML = '';
        this.shownModuleAppSettingsSection = undefined;
    }

    /*
        Currently Advanced anchor plate calculation settings are dependant on selected steel guideline and design (PE specific).
        In order to keep this working the code below is a workaround.
        This should be removed with https://jira.hilti.com/browse/BUDQBP-30905.
    */
    private concreteFixingValuesChanged(data: IConcreteFixingUpdate) {
        this.concreteFixingData = data;
        this.concreteSteelCalculationMethodId = data.concreteSteelCalculationMethod;

        if (this.advancedBaseplateInputsData) {
            this.advancedBaseplateInputsData.selectedSteelGuideline = this.concreteSteelCalculationMethodId;
            this.handleRegionChangedAdvancedCalculationInputs(this.regionLanguageData.region);
        }
    }
    // Modules

    // Handle region changed
    private handleRegionChangedAdvancedCalculationInputs(region: CommonRegion) {
        if (this.advancedBaseplateInputsData && this.concreteFixingData.concreteRegion == SpecialRegion.Default) {
            this.reloadAdvancedCalculationInputs(region);
        }
    }

    // Load settings
    private reloadSettings() {
        this.submitted = true;

        this.reloadRegionLanguageSettings();
        this.reloadDefaultParameterSettings();
        this.reloadInteractionSettings();
        this.reloadStructuralSoftwareSettings();

        const regionLanguagePeRegion = this.appSettingsHelper.getRegionById(this.regionLanguageData.region.id);
        this.concreteSteelCalculationMethodId = findSteelCalculationMethod(this.codeList.projectCodeLists, regionLanguagePeRegion.defaultConcreteDesignStandardId, regionLanguagePeRegion);

        // Modules
        this.loadModuleAppSettingsSections();

        if (this.deckingEnabled) {
            this.reloadDeckingSettings();
        }

        this.reloadAdvancedCalculationInputs(this.regionLanguageData.region);
        this.reloadAdvancedBaseplateCalculationSettings();

        this.initialLanguage = this.regionLanguageData.language;
        this.initialStructuralCalculationData = this.structuralCalculationData;

        this.submitted = false;
    }

    private reloadRegionLanguageSettings() {
        if (this.userSettings.settings.application.general.languageId.value != null) {
            this.regionLanguageData.language = this.appSettingsHelper.getLanguageById(this.userSettings.settings.application.general.languageId.value);
        }
        else {
            // Select language corresponding to this.localization.selectedLanguage
            const languageCodeList = this.commonCodeList.commonCodeLists[CommonCodeList.Language] as Language[];
            this.regionLanguageData.language = languageCodeList.find((item) => item.culture == this.localization.selectedLanguage);
        }

        if (this.userSettings.settings.application.general.regionId.value != null) {
            this.regionLanguageData.region = this.userSettings.getCommonRegionByIdWithFallback(this.userSettings.settings.application.general.regionId.value);
        }
        else {
            // Select first region available
            const regionCodeList = this.commonCodeList.commonCodeLists[CommonCodeList.Region] as CommonRegion[];
            this.regionLanguageData.region = regionCodeList[0];
        }

        const decimalSeparatorCodeList = this.commonCodeList.commonCodeLists[CommonCodeList.DecimalSeparator] as SeparatorEntity[];
        if (this.userSettings.settings.application.general.decimalSeparatorId.value != null) {
            this.regionLanguageData.decimalSeparator = decimalSeparatorCodeList.find((item) => item.id == this.userSettings.settings.application.general.decimalSeparatorId.value);
        }
        else {
            // Select first available separator
            this.regionLanguageData.decimalSeparator = decimalSeparatorCodeList[0];
        }

        const thousandsSeparatorCodeList = this.commonCodeList.commonCodeLists[CommonCodeList.ThousandsSeparator] as SeparatorEntity[];
        if (this.userSettings.settings.application.general.thousandsSeparatorId.value != null) {
            this.regionLanguageData.thousandsSeparator = thousandsSeparatorCodeList.find((item) => item.id == this.userSettings.settings.application.general.thousandsSeparatorId.value);
        }
        else {
            // Select first available separator
            this.regionLanguageData.thousandsSeparator = thousandsSeparatorCodeList[0];
        }
    }

    public translate(key: string) {
        return this.localization.getString(key);
    }

    private reloadDefaultParameterSettings() {
        this.defaultParametersData.anchorPlateFactor = this.userSettings.settings.application.defaults.anchorPlateFactor?.value ?? null;
        this.defaultParametersData.anchorPlateThickness = this.userSettings.settings.application.defaults.anchorPlateThinckness?.value ?? null;
        this.defaultParametersData.concreteCapacityFactor = this.userSettings.settings.application.defaults.concreteCapacityFactor.value;
        this.defaultParametersData.inSteelSafetyFactorGammaM0 = this.userSettings.settings.application.defaults.inSteelSafetyFactorGammaM0.value;
        this.defaultParametersData.inSteelSafetyFactorGammaM1 = this.userSettings.settings.application.defaults.inSteelSafetyFactorGammaM1.value;
        this.defaultParametersData.inSteelSafetyFactorGammaMb = this.userSettings.settings.application.defaults.inSteelSafetyFactorGammaMb.value;
        this.defaultParametersData.inSteelSafetyFactorGammaMw = this.userSettings.settings.application.defaults.inSteelSafetyFactorGammaMw.value;
        this.defaultParametersData.materialSafetyFactor = this.userSettings.settings.application.defaults.materialSafetyFactor.value;
        this.defaultParametersData.minimumAnchorToProfileDistance = this.userSettings.settings.application.defaults.minimumAnchorToProfileDistance?.value ?? null;
        this.defaultParametersData.minimumConcreteCover = this.userSettings.settings.application.defaults.minimumConcreteCover.value;
        this.defaultParametersData.permanentLoads = this.userSettings.settings.application.defaults.permenentLoads?.value ?? null;
        this.defaultParametersData.steelCapacityFactor = this.userSettings.settings.application.defaults.steelCapacityFactor.value;
        this.defaultParametersData.steelSafetyFactorGammaM0 = this.userSettings.settings.application.defaults.steelSafetyFactorGammaM0.value;
        this.defaultParametersData.steelSafetyFactorGammaM1 = this.userSettings.settings.application.defaults.steelSafetyFactorGammaM1.value;
        this.defaultParametersData.steelSafetyFactorGammaM2 = this.userSettings.settings.application.defaults.steelSafetyFactorGammaM2.value;
        this.defaultParametersData.variableLoads = this.userSettings.settings.application.defaults.variableLoads?.value ?? null;

        if (this.userSettings.settings.application.defaults.weldsCapacityFactor.value != null) {
            this.defaultParametersData.weldsCapacityFactor = this.userSettings.settings.application.defaults.weldsCapacityFactor.value;
        }
        else {
            this.defaultParametersData.weldsCapacityFactor = reductionFactorSP;
        }
    }

    private reloadInteractionSettings() {
        this.interactionData.rotate = this.userSettings.controls3dSettings.rotate;
        this.interactionData.pan = this.userSettings.controls3dSettings.pan;
    }

    private reloadStructuralSoftwareSettings() {
        const available = this.commonCodeList.commonCodeLists[CommonCodeList.StructuralCalculationSoftware] as StructuralCalculationSoftware[];

        available.forEach(el => {
            this.setInitialStructuralCalculationSoftwareEnabled(el.id);
        });
    }

    private setInitialStructuralCalculationSoftwareEnabled(type: StructuralCalculationSoftwareType): void {
        const regions = (this.commonCodeList.commonCodeLists[CommonCodeList.StructuralCalculationSoftware] as StructuralCalculationSoftware[]).find(scs => scs.id === type).regions;
        const isDefaultRegion = regions.indexOf(this.userSettings.settings.application.general.regionId.value) > -1;

        // If a setting for a software does not exists and user's region is one of those that require the software to be enabled by default, we must enable it
        // Otherwise we take the setting value or false to leave the check box unchecked
        switch (type) {
            case StructuralCalculationSoftwareType.Risa: {
                if (this.userSettings.settings.application.general.risaEnabled.value == null && isDefaultRegion) {
                    this.structuralCalculationData.risaEnabled = true;
                }
                else {
                    this.structuralCalculationData.risaEnabled = this.userSettings.settings.application.general.risaEnabled.value || false;
                }
                break;
            }
            case StructuralCalculationSoftwareType.Dlubal: {
                this.structuralCalculationData.dlubalEnabled = this.userSettings.settings.application.general.dlubalEnabled.value || false;
                break;
            }
            case StructuralCalculationSoftwareType.SAP2000: {
                if (this.userSettings.settings.application.general.sap2000Enabled.value == null && isDefaultRegion) {
                    this.structuralCalculationData.sap2000Enabled = true;
                }
                else {
                    this.structuralCalculationData.sap2000Enabled = this.userSettings.settings.application.general.sap2000Enabled.value || false;
                }
                break;
            }
            case StructuralCalculationSoftwareType.Robot: {
                if (this.userSettings.settings.application.general.robotEnabled.value == null && isDefaultRegion) {
                    this.structuralCalculationData.robotEnabled = true;
                }
                else {
                    this.structuralCalculationData.robotEnabled = this.userSettings.settings.application.general.robotEnabled.value || false;
                }
                break;
            }
            case StructuralCalculationSoftwareType.ETABS: {
                if (this.userSettings.settings.application.general.etabsEnabled.value == null && isDefaultRegion) {
                    this.structuralCalculationData.etabsEnabled = true;
                }
                else {
                    this.structuralCalculationData.etabsEnabled = this.userSettings.settings.application.general.etabsEnabled.value || false;
                }
                break;
            }
            case StructuralCalculationSoftwareType.StaadPro: {
                if (this.userSettings.settings.application.general.staadProEnabled.value == null && isDefaultRegion) {
                    this.structuralCalculationData.staadProEnabled = true;
                }
                else {
                    this.structuralCalculationData.staadProEnabled = this.userSettings.settings.application.general.staadProEnabled.value || false;
                }
                break;
            }
            case StructuralCalculationSoftwareType.RAM: {
                if (this.userSettings.settings.application.general.ramEnabled.value == null && isDefaultRegion) {
                    this.structuralCalculationData.ramEnabled = true;
                }
                else {
                    this.structuralCalculationData.ramEnabled = this.userSettings.settings.application.general.ramEnabled.value || false;
                }

                break;
            }
            default:
                throw new Error('Unknown calculation software type.');
        }
    }

    /**
     * Fill decking data with the initial user settings
     */
    private reloadDeckingSettings() {
        this.reloadDeckingSettings$.next();
    }

    private reloadAdvancedCalculationInputs(region: CommonRegion) {
        this.advancedBaseplateInputsData.defaultAdvancedCalculationValues = this.appSettingsHelper.defaultAdvancedCalculationDefaultValues(region.id);
        this.advancedBaseplateInputsData.designTypeId = this.appSettingsHelper.designTypeConcrete.id;
        this.advancedBaseplateInputsData.designStandardId = this.concreteFixingData.concreteDesignStandardId;
        this.advancedBaseplateInputsData.selectedRegionId = region.id;
        this.advancedBaseplateInputsData.selectedSteelGuideline = this.concreteSteelCalculationMethodId;

        // Set selected compresion method only if not set before (in application settings India region not selected as default for concrete).
        // This should be only called if user didn't save value in application settings and new selected region is India (only India has this value for now)
        const peRegion = this.appSettingsHelper.getRegionById(region.id);
        const newConcreteInCompressionMethod = peRegion.defaultConcreteInCompressionMethod;
        if (this.advancedCalculationData.concreteInCompressionMethod == null && newConcreteInCompressionMethod != null) {
            this.advancedCalculationData.concreteInCompressionMethod = newConcreteInCompressionMethod;
        }
    }

    private reloadAdvancedBaseplateCalculationSettings() {
        this.advancedCalculationInputs.calculationMethodState = this.userSettings.settings.application.advancedCalculation.calculationMethodState.value || CalculationMethodState.AlwaysAskMe;
        this.advancedCalculationInputs.advancedBaseplateInputs = this.advancedBaseplateInputsData;
        this.advancedCalculationInputs.advancedBaseplateData = AdvancedCalculationInputsHelper.loadAdvancedCalculationData(this.userSettings.settings.application.advancedCalculation, this.advancedCalculationData);
    }

    // Update settings
    private updateRegionLanguageUserSettings() {
        this.userSettings.settings.application.general.languageId.value = this.regionLanguageData.language.id;
        this.userSettings.settings.application.general.regionId.value = this.regionLanguageData.region.id;
        this.userSettings.settings.application.general.decimalSeparatorId.value = this.regionLanguageData.decimalSeparator.id;
        this.userSettings.settings.application.general.thousandsSeparatorId.value = this.regionLanguageData.thousandsSeparator.id;
    }

    private updateDefaultParametersUserSettings() {
        this.userSettings.settings.application.defaults.anchorPlateFactor.value = this.defaultParametersData.anchorPlateFactor;
        this.userSettings.settings.application.defaults.anchorPlateThinckness.value = this.defaultParametersData.anchorPlateThickness;
        this.userSettings.settings.application.defaults.concreteCapacityFactor.value = this.defaultParametersData.concreteCapacityFactor;
        this.userSettings.settings.application.defaults.inSteelSafetyFactorGammaM0.value = this.defaultParametersData.inSteelSafetyFactorGammaM0;
        this.userSettings.settings.application.defaults.inSteelSafetyFactorGammaM1.value = this.defaultParametersData.inSteelSafetyFactorGammaM1;
        this.userSettings.settings.application.defaults.inSteelSafetyFactorGammaMb.value = this.defaultParametersData.inSteelSafetyFactorGammaMb;
        this.userSettings.settings.application.defaults.inSteelSafetyFactorGammaMw.value = this.defaultParametersData.inSteelSafetyFactorGammaMw;
        this.userSettings.settings.application.defaults.materialSafetyFactor.value = this.defaultParametersData.materialSafetyFactor;
        this.userSettings.settings.application.defaults.minimumAnchorToProfileDistance.value = this.defaultParametersData.minimumAnchorToProfileDistance;
        this.userSettings.settings.application.defaults.minimumConcreteCover.value = this.defaultParametersData.minimumConcreteCover;
        this.userSettings.settings.application.defaults.permenentLoads.value = this.defaultParametersData.permanentLoads;
        this.userSettings.settings.application.defaults.steelCapacityFactor.value = this.defaultParametersData.steelCapacityFactor;
        this.userSettings.settings.application.defaults.steelSafetyFactorGammaM0.value = this.defaultParametersData.steelSafetyFactorGammaM0;
        this.userSettings.settings.application.defaults.steelSafetyFactorGammaM1.value = this.defaultParametersData.steelSafetyFactorGammaM1;
        this.userSettings.settings.application.defaults.steelSafetyFactorGammaM2.value = this.defaultParametersData.steelSafetyFactorGammaM2;
        this.userSettings.settings.application.defaults.variableLoads.value = this.defaultParametersData.variableLoads;
        this.userSettings.settings.application.defaults.weldsCapacityFactor.value = this.defaultParametersData.weldsCapacityFactor;
    }

    private updateInteractionUserSettings() {
        this.userSettings.settings.application.controls.rotate.value = this.interactionData.rotate;
        this.userSettings.settings.application.controls.pan.value = this.interactionData.pan;
    }

    private updateAdvancedCalculationInputsUserSettings() {
        this.userSettings.settings.application.advancedCalculation.calculationMethodState.value = this.advancedCalculationInputs.calculationMethodState;
        AdvancedCalculationInputsHelper.updateUserSettings(this.userSettings.settings.application.advancedCalculation, this.advancedCalculationData);
    }

    private updateStructuralCalculationSwUserSettings() {
        this.userSettings.settings.application.general.dlubalEnabled.value = this.structuralCalculationData.dlubalEnabled;
        this.userSettings.settings.application.general.risaEnabled.value = this.structuralCalculationData.risaEnabled;
        this.userSettings.settings.application.general.sap2000Enabled.value = this.setStructuralCalculationSoftwareEnableValue(StructuralCalculationSoftwareType.SAP2000, this.structuralCalculationData.sap2000Enabled);
        this.userSettings.settings.application.general.robotEnabled.value = this.setStructuralCalculationSoftwareEnableValue(StructuralCalculationSoftwareType.Robot, this.structuralCalculationData.robotEnabled);
        this.userSettings.settings.application.general.etabsEnabled.value = this.setStructuralCalculationSoftwareEnableValue(StructuralCalculationSoftwareType.ETABS, this.structuralCalculationData.etabsEnabled);
        this.userSettings.settings.application.general.staadProEnabled.value = this.setStructuralCalculationSoftwareEnableValue(StructuralCalculationSoftwareType.StaadPro, this.structuralCalculationData.staadProEnabled);
        this.userSettings.settings.application.general.ramEnabled.value = this.structuralCalculationData.ramEnabled;
    }

    private setStructuralCalculationSoftwareEnableValue(type: StructuralCalculationSoftwareType, enabled: boolean): boolean {
        // Get the regions in which this software should be enabled by default
        const regions = (this.commonCodeList.commonCodeLists[CommonCodeList.StructuralCalculationSoftware] as StructuralCalculationSoftware[]).find(scs => scs.id === type).regions;
        const isDefaultRegion = regions.indexOf(this.userSettings.settings.application.general.regionId.value) > -1;

        if (isDefaultRegion) {
            switch (type) {
                case StructuralCalculationSoftwareType.SAP2000:
                    if (this.userSettings.settings.application.general.sap2000Enabled.value == null
                        && enabled === this.initialStructuralCalculationData.sap2000Enabled) {
                        return true;
                    }
                    break;
                case StructuralCalculationSoftwareType.Robot:
                    if (this.userSettings.settings.application.general.robotEnabled.value == null
                        && enabled === this.initialStructuralCalculationData.robotEnabled) {
                        return true;
                    }
                    break;

                case StructuralCalculationSoftwareType.ETABS:
                    if (this.userSettings.settings.application.general.etabsEnabled.value == null
                        && enabled === this.initialStructuralCalculationData.etabsEnabled) {
                        return true;
                    }
                    break;

                case StructuralCalculationSoftwareType.StaadPro:
                    if (this.userSettings.settings.application.general.staadProEnabled.value == null
                        && enabled === this.initialStructuralCalculationData.staadProEnabled) {
                        return true;
                    }
                    break;

                default:
                    break;
            }
        }

        return enabled;
    }

    private triggerStructuralCalculationSwIntegrationAvailability() {
        if (this.design == null) {
            return;
        }

        if (!this.featuresVisibilityInfo.areIntegrationsEnabled) {
            return;
        }

        const dlubalEnabledChanged = this.userSettings.settings.application.general.dlubalEnabled.value != this.initialStructuralCalculationData.dlubalEnabled;
        const risaEnabledChanged = this.userSettings.settings.application.general.risaEnabled.value != this.initialStructuralCalculationData.risaEnabled;
        const sap2000EnabledChanged = this.userSettings.settings.application.general.sap2000Enabled.value != this.initialStructuralCalculationData.sap2000Enabled;
        const robotEnabledChanged = this.userSettings.settings.application.general.robotEnabled.value != this.initialStructuralCalculationData.robotEnabled;
        const etabsEnabledChanged = this.userSettings.settings.application.general.etabsEnabled.value != this.initialStructuralCalculationData.etabsEnabled;
        const staadProEnabledChanged = this.userSettings.settings.application.general.staadProEnabled.value != this.initialStructuralCalculationData.staadProEnabled;
        const ramEnabledChanged = this.userSettings.settings.application.general.ramEnabled.value != this.initialStructuralCalculationData.ramEnabled;

        if (dlubalEnabledChanged) {
            this.integrationsData.refreshIntegrationAvailability(DataIntegrationType.Dlubal);
            this.design.trigger(DesignEvent.structuralCalculationSoftwareChanged, this, dlubalEnabledChanged);
        }

        if (risaEnabledChanged) {
            this.design.trigger(DesignEvent.structuralCalculationSoftwareChanged, this, risaEnabledChanged);
        }

        if (sap2000EnabledChanged) {
            this.integrationsData.refreshIntegrationAvailability(DataIntegrationType.SAP2000);
            this.design.trigger(DesignEvent.structuralCalculationSoftwareChanged, this, sap2000EnabledChanged);
        }

        if (robotEnabledChanged) {
            this.integrationsData.refreshIntegrationAvailability(DataIntegrationType.Robot);
            this.design.trigger(DesignEvent.structuralCalculationSoftwareChanged, this, robotEnabledChanged);
        }

        if (etabsEnabledChanged) {
            this.integrationsData.refreshIntegrationAvailability(DataIntegrationType.ETABS);
            this.design.trigger(DesignEvent.structuralCalculationSoftwareChanged, this, etabsEnabledChanged);
        }

        if (staadProEnabledChanged) {
            this.integrationsData.refreshIntegrationAvailability(DataIntegrationType.StaadPro);
            this.design.trigger(DesignEvent.structuralCalculationSoftwareChanged, this, staadProEnabledChanged);
        }
        if (ramEnabledChanged) {
            this.design.trigger(DesignEvent.structuralCalculationSoftwareChanged, this, ramEnabledChanged);
        }
    }

    private setSubmitted(submitted: boolean) {
        this.submitted = submitted;

        if (this.shownModuleAppSettingsSection != null) {
            const component = this.getModuleComponent(this.shownModuleAppSettingsSection);
            component.submitted = submitted;
        }
    }
}
