import escape from 'lodash-es/escape';
import moment from 'moment';
import { Subject } from 'rxjs';

import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiOptions } from '@profis-engineering/pe-ui-common/services/api.common';
import {
    IGetStringOptions, ISanitizeTags, GetTranslationsHook, LocalizationServiceBase
} from '@profis-engineering/pe-ui-common/services/localization.common';
import { LogType } from '@profis-engineering/pe-ui-common/services/logger.common';
import {
    DateTimeFormat, LanguageTranslations, NumberFormat
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.TranslationsService.Shared.Entities';
import { environment } from '../../environments/environment';
import { ApiService } from './api.service';
import { LoggerService } from './logger.service';
import { BimCadLibLanguage } from '@profis-engineering/pe-ui-common/entities/code-lists/bim-cad-lib-language';
import { CommonCodeList } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import { CommonCodeListService } from './common-code-list.service';

@Injectable({
    providedIn: 'root'
})
export class LocalizationService extends LocalizationServiceBase {
    private _translationLibrary = 'ProfisEngineering';
    private _numberFormat: NumberFormat;

    public translationsLoaded = false;
    public dateTimeFormat: DateTimeFormat;

    public htmlStartTagRegExp = RegExp('<\\s*html\\s*>', 'gi');
    public htmlEndTagRegExp = RegExp('<\\s*/\\s*html\\s*>', 'gi');
    private isHtmlRegExp = RegExp('^\\s*<\\s*html\\s*>.*<\\s*/\\s*html\\s*>\\s*$', 'is');

    private _localizationChange = new Subject<void>();
    public localizationChange = this._localizationChange.asObservable();

    private _separatorChange = new Subject<void>();
    public separatorChange = this._separatorChange.asObservable();

    private _selectedLanguage: string;
    private _selectedLanguageLCID: number;
    private _resourceFileLoaded = false;
    private _warnings: Record<string, string> = {};

    private _translations: Record<string, string> = {};
    private _selectTranslations: Record<string, Record<string, string>> = {};

    private _onGetTranslationsHooks: GetTranslationsHook[] = [];

    constructor(
        private loggerService: LoggerService,
        private commonCodeListService: CommonCodeListService,
        private apiService: ApiService
    ) {
        super();
    }

    get onGetTranslationsHooks(): GetTranslationsHook[] {
        return this._onGetTranslationsHooks;
    }

    public override addGetTranslationsHook(loadTranslations: GetTranslationsHook): void {
        this.onGetTranslationsHooks.push(loadTranslations);
    }

    public get selectedLanguage() {
        return this._selectedLanguage;
    }

    public get selectedLanguageLCID() {
        return this._selectedLanguageLCID;
    }

    public get selectedBimCadLibLanguage() {
        const lcid = this.selectedLanguageLCID;
        const bimCadLibLanguages = this.commonCodeListService.commonCodeLists[CommonCodeList.BimCadLibLanguages];
        let bimCadLibLanguage = (bimCadLibLanguages as BimCadLibLanguage[]).find((item) => item.LCID == lcid);
        if (bimCadLibLanguage == null) {
            // get default
            bimCadLibLanguage = (bimCadLibLanguages as BimCadLibLanguage[]).find((item) => item.LCID == 1033);
        }

        return bimCadLibLanguage;
    }

    public moment(date?: Date) {
        return moment(date).locale(this.selectedLanguage);
    }

    public setNumberFormat(format: NumberFormat): void {
        this._numberFormat = format;
    }

    public numberFormat(): NumberFormat {
        return this._numberFormat;
    }

    /**
     * Get translations for requested language
     *
     * @param1: requested language translations
     */
    public async getTranslations(language: string, options?: ApiOptions): Promise<void> {
        // preload all required information
        // don't do anything if selected language is equal to requested language
        if (this._selectedLanguage == language) {
            return;
        }

        // use language set by this function call
        if (language) {
            this._selectedLanguage = language;
        }

        // use language chosen by user settings
        if (!this._selectedLanguage) {
            throw new Error('Language not selected!');
        }

        // call service to receive translations for selected language
        const translationsPromise = this.getTranslationsFromService(this._selectedLanguage, options);

        // additional translations promises set by modules
        await Promise.allSettled(
            this._onGetTranslationsHooks.map(f => f())
        );
        const translations = await translationsPromise;

        this.setTranslations(translations);
        this.translationsLoaded = true;
    }

    public async selectTranslations(language: string, keys: string[], options?: ApiOptions): Promise<void> {
        keys = keys.filter(key => key != null && key != '');

        // Translations are already loaded because of the default language
        if (this._selectedLanguage == language) {
            return;
        }

        let missingKeys: string[] = [];
        if (this._selectTranslations[language] != null) {
            for (const k of keys) {
                if (this._selectTranslations[language][k] == null) {
                    missingKeys.push(k);
                }
            }
        } else {
            missingKeys = keys;
        }

        // No new keys are required
        if (missingKeys.length == 0) {
            return;
        }

        // call service to receive translations for selected language
        const translations = await this.selectTranslationsFromService(language, missingKeys, options);

        if (this._selectTranslations[language] == null) {
            this._selectTranslations[language] = {};
        }

        for (const t in translations.Translations) {
            this._selectTranslations[language][t] = translations.Translations[t];
        }
    }

    public getLocalizedStringByCulture(key: string, culture: string, tags?: ISanitizeTags): string {
        if (this._selectedLanguage == culture) {
            return this.getLocalizedString(key, tags);
        }

        if (key == null) {
            return null;
        }

        if (!this._resourceFileLoaded) {
            return '';
        }

        let result: string;
        // make sure the dictionary has valid data
        if (this._selectTranslations != null && this._selectTranslations[culture] != null) {

            // set the result
            result = this._selectTranslations[culture][key];

            // html escape the string
            if (tags != null) {
                result = this.sanitizeText(result, tags);
            }
        }

        // return the value to the call
        if (result == null) {
            // log missing key
            if (this._warnings[key] == null) {
                this._warnings[key] = `Missing localized string: ${key} for culture: ${culture}`;

                this.loggerService.log(this._warnings[key], LogType.warn);
            }

            return `#?${key}?#`;
        }
        else {
            return result;
        }
    }

    public getString(key: string, opts?: IGetStringOptions): string {
        return this.getLocalizedString(key, opts?.tags, opts?.optional, opts?.defaultString);
    }

    /**
     * Get localized string from key
     * @deprecated DO NOT USE FOR NEW CODE. Use getString(key, opts) instead!
     */
    public getLocalizedString(key: string, tags?: ISanitizeTags, optional?: boolean, defaultString?: string): string {
        if (key == null) {
            return null;
        }

        if (!this._resourceFileLoaded) {
            return defaultString ?? '';
        }

        let result: string;
        // make sure the dictionary has valid data
        if (this._translations != null) {

            // set the result
            result = this._translations[key];

            // html escape the string
            if (tags != null) {
                result = this.sanitizeText(result, tags);
            }
        }

        // return the value to the call
        if (result == null && !optional) {
            // log missing key
            if (this._warnings[key] == null) {
                this._warnings[key] = `Missing localized string: ${key}`;

                this.loggerService.log(this._warnings[key], LogType.warn);
            }

            return `#?${key}?#`;
        }
        else {
            return result;
        }
    }

    /**
     * Get if translation key exists
     */
    public getKeyExists(key: string): boolean {
        return !this._resourceFileLoaded ? false : this._translations[key] != null;
    }

    /**
     * Get if translation key exists or translation has value
     */
    public hasTranslation(translationKey: string): boolean {
        if (translationKey == null || translationKey.trim() == '' || !this.getKeyExists(translationKey)) {
            return false;
        }

        const translation = this.getLocalizedString(translationKey);

        return translation != null && translation.trim() != '';
    }

    public sanitizeText(value: string, tags: ISanitizeTags): string {
        if (value == null || value == '') {
            return value;
        }

        const whiteSpace = new RegExp('[ ]{2,}|[\\t]{1,}|[\\r\\n]+', 'gi'); // white spaces, TABs and newlines are present. Remove all of this occurances.
        value = value.replace(whiteSpace, '');

        if (this.isHtml(value)) {
            return value.replace(this.htmlStartTagRegExp, '').replace(this.htmlEndTagRegExp, '');
        }

        value = escape(value);

        const brTagRegExp = new RegExp('&lt;\\s*br\\s*/\\s*&gt;', 'gi');

        Object.entries(tags).forEach(([key, tagValue]: [string, boolean]) => {
            if (tagValue) {
                if (key == 'br') {
                    value = value.replace(brTagRegExp, '<br />');
                }
                else if (key == 'a') {
                    value = value
                        .replace(/&lt;/g, '<')
                        .replace(/&gt;/g, '>')
                        .replace(/&quot;/g, '"');
                }
                else {
                    const reg = new RegExp('&lt;' + key + '&gt;(.*?)&lt;/' + key + '&gt;', 'gi');

                    let index = value.indexOf('&lt;' + key + '&gt;') >= 0 ? value.indexOf(key) : 0; // regular expression will (group) replace only HTML tags on top level. Loop will take care of the ones, that are nested.

                    for (let i = 0; i < index; i++) {

                        value = value.replace(reg, (fullMatch, group) => {
                            return '<' + key + '>' + group + '</' + key + '>';
                        });

                        index = value.indexOf('&lt;' + key + '&gt;') >= 0 ? value.indexOf(key) : 0; // re-evaluate if replacent is needed
                    }
                }
            }
        });

        return value;
    }

    public separatorHasChanged(): void {
        // broadcast that separator has changed
        this._separatorChange.next();
    }

    public isHtml(value: string): boolean {
        return this.isHtmlRegExp.test(value);
    }

    /**
     * Save translations into dictionary
     */
    private setTranslations(data: LanguageTranslations): void {
        if (data == null) {
            this.loggerService.log('No translations!', LogType.error);
            return;
        }

        // set translations
        this._translations = data.Translations;
        // update current LCID and Language name
        // update LCID
        this._selectedLanguageLCID = data.Language.LCID;
        // update language name
        this._selectedLanguage = data.Language.Name;
        // set language formats
        this.setLanguageFormats(data);
        // set the flag that the resource are loaded
        this._resourceFileLoaded = true;
        // broadcast that the file has been loaded
        this._localizationChange.next();
    }

    private setLanguageFormats(language: LanguageTranslations): void {
        this.dateTimeFormat = language.DateTimeFormat;
        this.setNumberFormat(language.NumberFormat);
    }

    private async getTranslationsFromService(languageCultureName: string, options?: ApiOptions): Promise<LanguageTranslations> {
        const url = `${environment.translationsWebServiceUrl}${this._translationLibrary}/${languageCultureName}`;

        return (await this.apiService.request<LanguageTranslations>(new HttpRequest('GET', url), options)).body;
    }

    private async selectTranslationsFromService(languageCultureName: string, keys: string[], options?: ApiOptions): Promise<LanguageTranslations> {
        const url = `${environment.translationsWebServiceUrl}${this._translationLibrary}/${languageCultureName}/keys`;

        return (await this.apiService.request<LanguageTranslations>(new HttpRequest('POST', url, keys), options)).body;
    }
}
