<div id="trimbleConnectBrowserModal" (click)="onModalClick($event)">
    <div class="modal-header">
        <h3 class="modal-title" [l10n]="title"></h3>

        <button type="button" class="close" (click)="dismiss('x-click')"
            [disabled]="submitted || loading == loadingEnum.delete || loading == loadingEnum.rename">
            <span>&times;</span>
        </button>
    </div>

    <form class="form" name="form" #form="ngForm" (ngSubmit)="select()" novalidate>
        <div class="modal-body">
            <div class="server control-row">
                <pe-dropdown
                    id="trimbleConnectLoginServerDropdown"
                    [title]="'Agito.Hilti.Profis3.TrimbleConnectLogin.Server' | l10n:localization.selectedLanguage"
                    [items]="serverDropdown.items"
                    [selectedValue]="serverDropdown.selectedValue"
                    (selectedValueChange)="serverDropdown.selectedValue = $any($event).detail; onServerChanged()"
                    [validators]="[requiredValidator]"
                    (isValid)="serverDropdown.isValid = $any($event).detail">
                </pe-dropdown>
            </div>

            <div class="project">
                <pe-dropdown
                    id="trimbleConnectProjectDropdown"
                    [title]="projectDropdown.title"
                    [items]="projectDropdown.items"
                    [selectedValue]="projectDropdown.selectedValue"
                    (selectedValueChange)="projectDropdown.selectedValue = $any($event).detail; onProjectChanged()"
                    [validators]="[requiredValidator]"
                    (isValid)="projectDropdown.isValid = $any($event).detail"
                    [disabled]="projectDropdown.selectedValue == null || submitted || loading == loadingEnum.project || loading == loadingEnum.delete || loading == loadingEnum.rename">
                </pe-dropdown>
            </div>

            <div class="browser-container">
                <div class="browser-section tree-view"
                    [class.loading]="loading == loadingEnum.project || loading == loadingEnum.delete || loading == loadingEnum.rename">
                    <div class="tree-view-container browser-section-content">
                        <app-trimble-connect-browser-tree-node
                            [item]="treeNodeItem"
                            [context]="treeContext"
                            [expanded]="true"
                            [level]="0">
                        </app-trimble-connect-browser-tree-node>
                    </div>

                    <div class="disabled-section" *ngIf="submitted"></div>

                    <div class="loading-container">
                        <pe-loading [width]="32" [height]="10" [color]="'#524f53'"></pe-loading>
                    </div>
                </div>

                <div class="browser-section folder-view"
                    [class.loading]="loading == loadingEnum.project || loading == loadingEnum.folder || loading == loadingEnum.delete || loading == loadingEnum.rename">
                    <div class="folder-view-table-container browser-section-content">
                        <table class="folder-view-table">
                            <caption>Folder view table</caption>
                            <thead>
                                <tr>
                                    <th class="name">
                                        <div class="content-wrapper"
                                            l10n="Agito.Hilti.Profis3.TrimbleConnectBrowser.FolderView.Name"></div>
                                    </th>
                                    <th class="date-modified">
                                        <div class="content-wrapper"
                                            l10n="Agito.Hilti.Profis3.TrimbleConnectBrowser.FolderView.DateModified"></div>
                                    </th>
                                    <th class="type">
                                        <div class="content-wrapper"
                                            l10n="Agito.Hilti.Profis3.TrimbleConnectBrowser.FolderView.Type"></div>
                                    </th>
                                    <th class="size">
                                        <div class="content-wrapper"
                                            l10n="Agito.Hilti.Profis3.TrimbleConnectBrowser.FolderView.Size"></div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody *ngIf="opendFolder != null">
                                <tr *ngFor="let item of opendFolder.items; trackBy: trackOpendFolderItemById"
                                    class="folder-item noselect"
                                    [class.selected]="selectedFolderItem?.id == item.id"
                                    (click)="folderItemSelected($event, item)"
                                    (dblclick)="folderItemOpened($event, item)">
                                    <td class="name">
                                        <div class="name-container" [hidden]="renamingItem?.id == item.id">
                                            <span class="sprite" [ngClass]="formatFolderItemIcon(item.type)"></span>
                                            <p class="text" [ngbTooltip]="item.name">{{item.name}}</p>
                                            <div class="space"></div>
                                        </div>

                                        <div class="rename-container" [hidden]="renamingItem?.id != item.id">
                                            <pe-text-box
                                                class="rename-textbox"
                                                [value]="renameValue"
                                                (valueChange)="renameValue = $any($event).detail"
                                                (inputBlur)="blurRenameItem(item)"
                                                [preventFormSubmit]="true"
                                                [marginBottom]="'None'"
                                                [borderRight]="'None'">
                                            </pe-text-box>

                                            <div class="button-group">
                                                <button class="button button-default ok" type="button"
                                                    (click)="renameItem(item)">
                                                    <span class="sprite sprite-ok"></span>
                                                </button>
                                                <button class="button button-default dismiss" type="button"
                                                    (click)="dismissRenameItem()" >
                                                    <span class="sprite sprite-x"></span>
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="date-modified">
                                        <div class="content-wrapper">
                                            {{formatFolderItemDateModified(item.dateModified)}}</div>
                                    </td>
                                    <td class="type">
                                        <div class="content-wrapper">{{formatFolderItemType(item.type)}}</div>
                                    </td>
                                    <td class="size">
                                        <div class="content-wrapper">{{formatFolderItemSize(item.size)}}</div>

                                        <div class="dropdown-menu-container" *ngIf="item.type == trimbleConnectItemTypeEnum.folder"
                                            ngbDropdown container="body">
                                            <button class="options-toggle-button dropdown-menu-button" type="button" ngbDropdownToggle>
                                                <span class="sprite sprite-dots"></span>
                                            </button>

                                            <div ngbDropdownMenu class="trimble-connect-item-dropdown-menu">
                                                <div class="options-buttons-container">
                                                    <button ngbDropdownItem class="rename-button dropdown-item-button"
                                                        l10n="Agito.Hilti.Profis3.TrimbleConnectBrowser.FolderView.Rename"
                                                        (click)="openRenaming($event, item)" type="button">
                                                    </button>

                                                    <button ngbDropdownItem class="delete-button dropdown-item-button"
                                                        l10n="Agito.Hilti.Profis3.TrimbleConnectBrowser.FolderView.Delete"
                                                        (click)="deleteItem($event, item)" type="button">
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>

                                <tr class="no-items-container" *ngIf="opendFolder.items.length == 0">
                                    <td class="no-items" rowspan="4">
                                        <p class="no-items-text"
                                            l10n="Agito.Hilti.Profis3.TrimbleConnectBrowser.FolderView.NoItems"></p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="disabled-section" *ngIf="submitted"></div>

                    <div class="loading-container">
                        <pe-loading [width]="32" [height]="10" [color]="'#524f53'"></pe-loading>
                    </div>
                </div>
            </div>

            <div class="file-name" *ngIf="showLocationTextBox">
                <pe-text-box
                    [title]="fileName.title"
                    [value]="fileName.value"
                    (valueChange)="fileNameChanged($any($event).detail)"
                    [maxLength]="fileName.maxLength"
                    [validators]="[requiredValidator]"
                    (isValid)="fileName.isValid = $any($event).detail"
                    (enterPressed)="select()"
                    [disabled]="submitted || loading == loadingEnum.project || loading == loadingEnum.delete || loading == loadingEnum.rename">
                </pe-text-box>
            </div>

            <div class="location">
                <pe-text-box
                    [title]="location.title"
                    [value]="location.value"
                    (valueChange)="location.value = $any($event).detail"
                    [disabled]="true">
                </pe-text-box>
            </div>
        </div>

        <div class="modal-footer">
            <button type="button" class="modal-button button button-default cancel-button"
                l10n="Agito.Hilti.Profis3.TrimbleConnectBrowser.Cancel" (click)="dismiss()"
                [disabled]="submitted || loading == loadingEnum.delete || loading == loadingEnum.rename">
            </button>

            <button type="submit" class="modal-button button button-CTA select-button"
                [disabled]="selectDisabled">
                <pe-loading-button
                    [loading]="submitted"
                    [text]="selectText | l10n:localization.selectedLanguage">
                </pe-loading-button>
            </button>
        </div>
    </form>
</div>
